export const hasIncompleteRequiredFields = (formFields, team) => {
    const requiredFields = formFields.filter((field) => field.usageLevel === 'REQUIRED');
    if (!requiredFields.length) {
        return false;
    }
    return requiredFields.some((field) => {
        var _a;
        const teamFormField = (_a = team.formFields) === null || _a === void 0 ? void 0 : _a.find(({ formFieldId }) => formFieldId === field.propertyDefinitionId);
        if (!teamFormField || !teamFormField.values) {
            return true;
        }
        if (field.type === 'MULTIPLE_CHECKBOXES' || field.type === 'PICK_LIST') {
            const value = teamFormField.values[0];
            return !field.items.find((item) => item.itemId.toString() === value);
        }
        return !teamFormField.values[0];
    });
};
export const getIncompleteRequiredFieldsTeams = (formFields, teams) => {
    return teams.filter((team) => hasIncompleteRequiredFields(formFields, team));
};
