import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';

export const ActionList = styled.ul({
  listStyle: 'none',
  margin: '-16px -24px',
  padding: 0,
});

export const TriggerIcon = styled.span<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'inline-flex',
    alignItems: 'center',

    svg: {
      stroke: theme.semantic?.secondary.reactiveTextColor,
    },
  })
);

export const Item = styled.li({
  margin: 0,
  padding: 0,
});

type ItemTriggerProps = ThemedComponent<{ $disabled?: boolean }>;

export const ItemTrigger = styled.button<ItemTriggerProps>(
  ({ $disabled, theme }: ItemTriggerProps) => ({
    ...theme.typography.ui.large,
    backgroundColor: 'transparent',
    display: 'block',
    width: '100%',
    color: $disabled ? theme.foundational.blueGrey[400] : 'inherit',
    margin: 0,
    padding: '8px 28px',
    textAlign: 'left',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: !$disabled ? theme.semantic?.primary[50] : 'inherit',
    },
  })
);
