import styled from 'styled-components';
import { media } from '../../lib/media-queries/mixins';
export const ColumnGrid = styled.div `
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min-content;
  column-gap: 16px;
  max-width: 1440px;
  width: 100%;
  // padding: 0 16px;

  ${media.TabletPortraitUp `
    grid-template-columns: repeat(8, 1fr);
  `}

  ${media.TabletLandscapeUp `
    grid-template-columns: repeat(16, 1fr);
  `}
`;
