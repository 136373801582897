import { useEffect, useState } from 'react';
import { ErrorResponse } from 'react-router-dom';
import axios from 'axios';
import { extractAccessToken } from '@la/utilities';
import { getBaseURL } from 'lib/utils/urlUtils';
import { FamilyYouthAthleteAccess } from 'domains/YouthAthleteAccess/YouthAthleteAccess.types';

type YouthAthleteData = {
  userId: number;
  youthAthleteId?: number;
  firstName: string;
  lastName: string;
  email?: string;
  canChat?: boolean;
};

type GroupAccountYouthAthletesData = {
  groupAccountId: number;
  isChatEnabled: boolean;
  hasStoredPayment: boolean;
  youthAthletes: YouthAthleteData[] | undefined;
};

type GetGroupAccountAthleteParams = {
  siteId: number;
  groupAccountId: number;
};

const baseUrl = getBaseURL();

const getGroupAccountYouthAthletes = async ({
  siteId,
  groupAccountId: grpAcctId,
}: GetGroupAccountAthleteParams): Promise<FamilyYouthAthleteAccess> => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/groupAccount/${grpAcctId}/youthAthletes`;
  const token = extractAccessToken();
  const { data } = await axios.get<GroupAccountYouthAthletesData>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const { groupAccountId, isChatEnabled, youthAthletes, hasStoredPayment } =
    data;
  return {
    groupAccountId: groupAccountId,
    isChatEnabled: isChatEnabled,
    hasStoredPayment: hasStoredPayment,
    youthAthletes: youthAthletes
      ? youthAthletes.map((ya) => {
          return {
            ...ya,
            siteId,
            hasYouthAthletePermission: !!ya.youthAthleteId,
          };
        })
      : [],
  };
};

export function useGetGroupAccountAthletes({
  siteId,
  groupAccountId,
}: GetGroupAccountAthleteParams): {
  youthAthleteData: FamilyYouthAthleteAccess | null;
  setYouthAthleteData: (data: FamilyYouthAthleteAccess | null) => void;
  youthAthleteResponseError: ErrorResponse | null;
} {
  const [youthAthleteResponseError, setYouthAthleteResponseError] =
    useState<ErrorResponse | null>(null);
  const [youthAthleteData, setYouthAthleteData] =
    useState<FamilyYouthAthleteAccess | null>(null);

  useEffect(() => {
    getGroupAccountYouthAthletes({ siteId, groupAccountId })
      .then((data) => {
        setYouthAthleteData(data);
      })
      .catch((err) => {
        setYouthAthleteResponseError(err.response as ErrorResponse);
      });
  }, [siteId, groupAccountId]);

  return { youthAthleteData, setYouthAthleteData, youthAthleteResponseError };
}
