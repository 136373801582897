// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yMONupig6de6PKshF_rg{background:none;display:flex;opacity:0;position:absolute;top:0;width:60px;height:100%;margin:0;padding:0;border:none;outline:none}.yMONupig6de6PKshF_rg.eXcCTa5gtSjQwRL414vf{opacity:1}.yMONupig6de6PKshF_rg.PdlOVpD65sZ04bNHGlQg{left:0}.yMONupig6de6PKshF_rg.PdlOVpD65sZ04bNHGlQg .tEKoX3aRwQgtxq1ZSFRg{background:linear-gradient(to right, var(--primary-35), transparent)}.yMONupig6de6PKshF_rg.F6ojVZtKSSY1tJ2hu8ib{left:calc(100vw - 60px)}.yMONupig6de6PKshF_rg.F6ojVZtKSSY1tJ2hu8ib .tEKoX3aRwQgtxq1ZSFRg{background:linear-gradient(to right, transparent, var(--primary-35))}.yMONupig6de6PKshF_rg .tEKoX3aRwQgtxq1ZSFRg{flex-grow:1;height:100%}.yMONupig6de6PKshF_rg .k1hmI4oIVlMWs5NgRWHS{background:var(--primary-35);display:flex;align-items:center;justify-content:center;width:36px;height:100%}`, "",{"version":3,"sources":["webpack://./src/domains/Tournaments/TournamentDetailsPage/components/SecondaryNavigationBar/SecondaryNavigationBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,SAAA,CACA,iBAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,WAAA,CACA,YAAA,CAEA,2CACE,SAAA,CAGF,2CACE,MAAA,CACA,iEACE,oEAAA,CAGJ,2CACE,uBAAA,CACA,iEACE,oEAAA,CAIJ,4CACE,WAAA,CACA,WAAA,CAGF,4CACE,4BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollButton": `yMONupig6de6PKshF_rg`,
	"visible": `eXcCTa5gtSjQwRL414vf`,
	"left": `PdlOVpD65sZ04bNHGlQg`,
	"fade": `tEKoX3aRwQgtxq1ZSFRg`,
	"right": `F6ojVZtKSSY1tJ2hu8ib`,
	"icon": `k1hmI4oIVlMWs5NgRWHS`
};
export default ___CSS_LOADER_EXPORT___;
