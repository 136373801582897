import styled, { CSSObject } from 'styled-components/macro';
import { ThemedComponent, Typography } from '@la/ds-ui-components';

type TeamProps = ThemedComponent<{
  $hasClickHandler?: boolean;
  $selected?: boolean;
}>;

export const Team = styled.button<TeamProps>(
  ({ $hasClickHandler = false, $selected = false, theme }: TeamProps) => {
    const baseStyles: CSSObject = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      width: '100%',
      textAlign: 'left',
      borderRadius: '4px',
      outline: 'none',
    };

    if ($selected) {
      return {
        ...baseStyles,
        background: 'none',
        border: 'none',
        pointerEvents: $hasClickHandler ? 'all' : 'none',
      };
    }

    return {
      ...baseStyles,
      background: theme.foundational.white,
      padding: '12px',
      border: `1px solid ${theme.foundational.blueGrey[400]}`,
      cursor: 'pointer',

      '&:hover': {
        borderColor: theme.semantic?.secondary.hover,
        color: theme.semantic?.secondary.hover,
      },
      '&:focus-within': {
        outline: '2px solid',
        outlineOffset: '1px',
        outlineColor: theme.foundational.blueGrey[600],
      },
      '&:active': {
        borderColor: theme.semantic?.secondary.active,
        backgroundColor: theme.foundational.blueGrey[50],
        color: theme.semantic?.secondary.active,
      },
    };
  }
);

export const TeamDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const Name = styled(Typography)<{ $selected?: boolean }>(
  ({ $selected }) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    lineHeight: '20px',
    marginBottom: $selected ? '8px' : 'auto',
  })
);

export const Details = styled(Typography)<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    color: theme.foundational.blueGrey[600],
  })
);

export const SelectedIndicator = styled.div({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
});

export const SelectedBadge = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 0,
});

export const CreatedLabel = styled(Typography)<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    color: theme.foundational.blueGrey[600],
  })
);
