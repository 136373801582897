import { DateTime } from 'luxon';
import { Team, TeamInfo } from '@la/types';
import * as S from './TeamDetails.styles';

export type TeamDetailsProps = {
  showCreationDate?: boolean;
  /**
   * Team whose following details should be rendered:
   * - Site
   * - Grouped program name (if applicable)
   * - Program name (if applicable)
   */
  team: (Team | TeamInfo) & {
    createdOn?: string;
    groupedProgramName?: string;
    siteName?: string;
  };
};

export function TeamDetails({
  showCreationDate = false,
  team,
}: Readonly<TeamDetailsProps>) {
  const creationDateText = getCreationDateText(team.createdOn);

  return (
    <S.TeamDetails size="small" variant="ui">
      <span>
        {team.siteName ? `${team.siteName} / ` : null}
        {team.groupedProgramName ? `${team.groupedProgramName} / ` : ''}
        {team.programName}
      </span>
      {showCreationDate ? (
        <S.CreationDate>{creationDateText}</S.CreationDate>
      ) : null}
    </S.TeamDetails>
  );
}

function getCreationDateText(dateString?: string): string {
  if (!dateString) {
    return '';
  }

  const date = DateTime.fromISO(dateString);

  if (!date.isValid) {
    return '';
  }

  const today = DateTime.now();
  if (date.hasSame(today, 'day')) {
    return 'Today';
  }

  const yesterday = today.minus({ days: 1 });
  if (date.hasSame(yesterday, 'day')) {
    return 'Yesterday';
  }

  if (date.hasSame(today, 'week')) {
    return 'This week';
  }

  const lastWeek = today.minus({ weeks: 1 });
  if (date.hasSame(lastWeek, 'week')) {
    return 'Last week';
  }

  return date.toFormat('LLL d, yyyy');
}
