import { useState } from 'react';
import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';
import { Registration } from '@la/types';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import {
  useRemoveMemberFromRosterMutation,
  useUpdateRosterMembersMutation,
} from 'redux/services/rosterManagementApi';
import { TableType } from './RosterTable';

/* RosterSection */
export default function DeleteRegistrantModal({
  pendingRegistrations,
  registrationId,
  userId,
  memberName,
  programId,
  tableType,
  teamId,
  onRemoveSuccess,
  onRemoveError,
  closeModal,
}: {
  pendingRegistrations: Registration[];
  registrationId?: string;
  userId: number;
  memberName: string;
  programId: string;
  tableType: TableType;
  teamId: string;
  onRemoveSuccess: () => void;
  onRemoveError: () => void;
  closeModal: () => void;
}) {
  const siteSubdomain = getLAHostnameParts().subdomain;
  const memberType = tableType === 'players' ? 'player' : 'staff member';

  const [isRemoveMemberLoading, setIsRemovePlayerLoading] =
    useState<boolean>(false);

  const [removeMemberFromRoster] = useRemoveMemberFromRosterMutation();
  const [removePendingMemberFromRoster] = useUpdateRosterMembersMutation();

  const removeMember = () => {
    setIsRemovePlayerLoading(true);

    if (registrationId) {
      // When a registration id is provided, it is implied
      // that the registration is from registration service
      // and therefore we perform the removal using registration service.
      const registration = pendingRegistrations.find(
        (registration) => registration.id === registrationId
      );
      if (registration) {
        removePendingMemberFromRoster({
          siteSubdomain,
          programId: parseInt(programId),
          content: { remove: [registration] },
        })
          .unwrap()
          .then(onRemoveSuccess)
          .catch(onRemoveError)
          .finally(() => {
            setIsRemovePlayerLoading(false);
            closeModal();
          });
      }
    } else {
      removeMemberFromRoster({
        siteSubdomain,
        programId,
        teamId,
        userId: userId.toString(),
      })
        .unwrap()
        .then(onRemoveSuccess)
        .catch(onRemoveError)
        .finally(() => {
          setIsRemovePlayerLoading(false);
          closeModal();
        });
    }
  };

  return (
    <>
      <Modal
        hideClose={false}
        open={true}
        onOpenChange={closeModal}
        primaryAction={
          <ModalPrimaryActionButton
            loading={isRemoveMemberLoading}
            onClick={removeMember}
          >{`Remove ${memberType}`}</ModalPrimaryActionButton>
        }
        tertiaryAction={
          <ModalClose>
            <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
          </ModalClose>
        }
        size="small"
        title={`Remove ${memberType} from roster?`}
      >
        Are you sure you want to remove <strong>{memberName}</strong> from your
        roster? You will need to re-send their invitation if you remove them by
        mistake.
      </Modal>
    </>
  );
}
/* */
