export const getNumberOfSpotsLeft = (division) => {
    let numberOfSpotsLeft;
    if (division.maxNumberOfTeams) {
        if (division.numberOfRegisteredTeams) {
            numberOfSpotsLeft =
                division.maxNumberOfTeams - division.numberOfRegisteredTeams;
        }
        else {
            numberOfSpotsLeft = division.maxNumberOfTeams;
        }
    }
    return numberOfSpotsLeft;
};
