import { RegistrationSettings } from '@la/types';
import { CartItemData } from 'domains/Checkout/Checkout.types';
import baseApi from './baseApi';

export type RegistrationIds = {
  registrationId: string;
  registrationIdOg: number;
};
export type RegistrationIdsMap = Record<string, RegistrationIds>;

export type UpdateRegistrationsPayload = {
  programId: string;
  teams: { id: string; name: string }[];
};

export type NGBMembershipType = {
  membershipType: 'USA_LACROSSE' | 'USA_HOCKEY';
  enabled: boolean;
  id: string;
};

export const registrationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrationIds: builder.query<
      RegistrationIdsMap,
      { siteDomain: string; registrationIds: string[] }
    >({
      query: ({ siteDomain, registrationIds }) => {
        return {
          url: `/api/member-portal/${siteDomain}/registrations`,
          params: {
            registrationIds,
          },
        };
      },
    }),
    updateRegistrations: builder.mutation<
      CartItemData,
      {
        siteDomain: string;
        programId: string;
        editURL: string;
        registrations: UpdateRegistrationsPayload[];
        includeRegistrationTriageFix?: boolean;
      }
    >({
      query: ({
        siteDomain,
        programId,
        editURL,
        registrations,
        includeRegistrationTriageFix,
      }) => {
        return {
          url: `/api/member-portal/${siteDomain}/registration`,
          method: 'POST',
          body: {
            programId,
            editURL,
            registrations,
            includeRegistrationTriageFix,
          },
        };
      },
      invalidatesTags: ['CartSummary', 'Registration'],
    }),
    getRegistrationSettings: builder.query<
      RegistrationSettings,
      { programId: string; subdomain: string }
    >({
      query: ({ subdomain, programId }) => ({
        url: `/api/member-portal/${subdomain}/registrationSettings`,
        method: 'GET',
        params: {
          programId,
        },
      }),
    }),
    getExposeNGBMembershipFields: builder.query<
      string | null,
      { programId?: string; subdomain: string }
    >({
      query: ({ subdomain, programId }) => ({
        url: `/api/member-portal/${subdomain}/ngbVerifications/settings`,
        method: 'GET',
        params: {
          programId,
        },
      }),
      transformResponse: (resp: NGBMembershipType[]) => {
        return resp?.find((resp) => resp.enabled)?.membershipType ?? null;
      },
    }),
    validateNGBMembership: builder.mutation<
      null,
      {
        membershipCode: string;
        membershipType: string;
        playerId: string;
        programId: string;
        siteDomain: string;
      }
    >({
      query: ({
        membershipCode,
        membershipType,
        playerId,
        programId,
        siteDomain,
      }) => {
        return {
          url: `/api/member-portal/${siteDomain}/ngbVerifications/validate`,
          method: 'POST',
          body: {
            playerId,
            programId,
            membershipType,
            membershipCode,
          },
        };
      },
    }),
  }),
});

export const {
  useGetExposeNGBMembershipFieldsQuery,
  useLazyGetRegistrationIdsQuery,
  useGetRegistrationSettingsQuery,
  useUpdateRegistrationsMutation,
  useValidateNGBMembershipMutation,
} = registrationApi;
