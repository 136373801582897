import styled from 'styled-components';
import { Typography } from '@la/ds-ui-components';
export const TypeaheadContainer = styled.div `
  margin-top: -8px;
`;
export const TypeaheadHelperText = styled.div `
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  gap: 2px;
`;
export const OptionContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const OptionLabel = styled(Typography)(() => {
    return {};
});
export const OptionEmail = styled(Typography)(({ theme, }) => {
    return {
        color: theme.foundational.blueGrey[600],
    };
});
