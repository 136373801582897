import { MutableRefObject } from 'react';
import { Division } from '@la/types';
import { DivisionDisplay } from '../DivisionDisplay/DivisionDisplay';
import { DivisionsModal } from '../DivisionsModal/DivisionsModal';
import * as S from './DivisionsDisplay.styles';

export interface DivisionsDisplayProps {
  addRef: (
    divisionId: string,
    ref: MutableRefObject<HTMLElement | null>
  ) => void;
  divisions: Division[];
  refs: Map<string, MutableRefObject<HTMLElement | null>>;
}

export function DivisionsDisplay({
  addRef,
  divisions,
  refs,
}: DivisionsDisplayProps) {
  return (
    <S.Container>
      <S.Header>Participating teams</S.Header>
      <S.ModalButtonContainer>
        <DivisionsModal divisions={divisions} refs={refs} />
      </S.ModalButtonContainer>
      {divisions.map((division) => (
        <DivisionDisplay
          addRef={addRef}
          division={division}
          key={`participantsDivision${division.id}`}
        />
      ))}
    </S.Container>
  );
}
