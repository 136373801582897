import styled from 'styled-components';
export const ReviewInvoicesContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const TotalAmountContainer = styled.div `
  display: flex;
  justify-content: space-between;
`;
export const PartialAmountContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;
export const PricePerTeam = styled.div `
  display: flex;
  justify-content: space-between;
`;
export const DescriptionSection = styled.div({
    display: 'flex',
    gap: '16px',
});
export const TitlesSection = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});
