import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from '@la/ds-ui-components';
import { Button, TextInput, Form } from '@la/ds-ui-components';
import { createValidationSchema } from '@la/shared-components';
import { MessagingContext } from 'lib/context/MessagingContext';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useValidateNGBMembershipMutation } from 'redux/services/registrationApi';
import {
  CustomFields,
  CustomFieldsFormFields,
  CustomFieldsFormProps,
} from './CustomFieldsForm/CustomFieldsForm';
import styles from './RegistrationInfoCard.module.scss';

export type NGBFormFields = {
  ngbMembershipNumber: string;
} & CustomFieldsFormFields;

type NGBValidationFormProps = Omit<CustomFieldsFormProps, 'onSubmit'> & {
  memberId: string;
  ngbOrgName?: string;
  onSubmit: (values: NGBFormFields) => void;
};

const displayNames = new Map([
  ['USA_HOCKEY', 'USA Hockey'],
  ['USA_LACROSSE', 'USA Lacrosse'],
]);

export const MEMBERSHIP_NUMBER_REQUIRED_ERROR =
  'Please enter and verify your membership number.';

export const NGBValidationForm = ({
  columns = 2,
  formFields,
  gap,
  id,
  memberId,
  ngbOrgName = 'USA_LACROSSE',
  onSubmit,
}: NGBValidationFormProps) => {
  const [isVerifyButtonEnabled, setIsVerifyButtonEnabled] =
    useState<boolean>(false);
  const [isNGBVerified, setIsNGBVerified] = useState<boolean>(false);
  const [validateNGBMembership, { isLoading: isNGBValidationLoading }] =
    useValidateNGBMembershipMutation();
  const { addMessage } = useContext(MessagingContext);

  const { decodedData, setHasErrors } = useRegistration();
  const { subdomain } = getLAHostnameParts();

  const playerDetailsFormValidation = yup
    .object({
      ngbMembershipNumber: yup
        .string()
        .trim()
        .test('is-verified', MEMBERSHIP_NUMBER_REQUIRED_ERROR, (value) => {
          return isNGBVerified;
        })
        .required(MEMBERSHIP_NUMBER_REQUIRED_ERROR),
    })
    .concat(createValidationSchema(id));

  function handleNGBMembershipNumberValidityCheck() {
    const ngbMembershipNumber: string = getValues('ngbMembershipNumber');
    if (decodedData?.prid) {
      const validationBody = {
        membershipCode: ngbMembershipNumber,
        membershipType: ngbOrgName,
        playerId: memberId,
        programId: decodedData.prid,
        siteDomain: subdomain,
      };
      validateNGBMembership(validationBody)
        .unwrap()
        .then((response) => handleNGBVerificationSuccess())
        .catch((err) => {
          setError('ngbMembershipNumber', {
            type: err.data.status,
            message: err.data.message,
          });
        });
    }
  }

  const methods = useForm<NGBFormFields>({
    defaultValues: {
      [id]: formFields,
      ngbMembershipNumber: undefined,
    },
    resolver: yupResolver(playerDetailsFormValidation),
  });

  const {
    clearErrors,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    setHasErrors(Object.keys(errors).length > 0);
  }, [errors, setHasErrors]);

  const ngbOrgLabel = displayNames.get(ngbOrgName);

  function handleNGBVerificationSuccess() {
    clearErrors('ngbMembershipNumber');
    setIsVerifyButtonEnabled(false);
    setIsNGBVerified(true);
    addMessage('toast', {
      description: `Your ${ngbOrgLabel} membership has been successfully verified.`,
      variant: 'success',
    });
  }

  function handleNGBFieldChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const fieldValue: string = evt.target.value;
    setIsVerifyButtonEnabled(!!fieldValue);
    setIsNGBVerified(false);
    setValue('ngbMembershipNumber', fieldValue);
  }

  return (
    <FormProvider {...methods}>
      <Form
        id={id}
        noValidate
        onSubmit={handleSubmit((values) => {
          if (!isSubmitting) {
            onSubmit(values as NGBFormFields);
          }
        })}
      >
        <CustomFields columns={columns} gap={gap} name={id} />

        <div className={styles.inputGroupContainer}>
          <div className={styles.membershipVerificationContainer}>
            <div className={styles.textInputWithButton}>
              <TextInput
                {...register('ngbMembershipNumber', {
                  required: true,
                  onChange: handleNGBFieldChange,
                })}
                errorMessage={errors.ngbMembershipNumber?.message}
                hasError={!!errors.ngbMembershipNumber}
                id="ngb-membership-number"
                label={`${ngbOrgLabel} number`}
                placeholder={`Enter your ${ngbOrgLabel} membership number`}
                required={true}
                size="medium"
                value={watch('ngbMembershipNumber')}
                width="100%"
              />
              <Button
                disabled={!isVerifyButtonEnabled}
                loading={isNGBValidationLoading}
                onClick={handleNGBMembershipNumberValidityCheck}
                size="medium"
                variant="outline"
              >
                Verify
              </Button>
            </div>
            <p className={styles.membershipVerificationHelperText}>
              {`This player’s ${ngbOrgLabel} membership must be valid throughout the entire program to participate.`}
            </p>
            <div className={styles.membershipVerificationHelperLinks}>
              <Link
                size="small"
                href="https://pub.usalacrosse.com/"
                target="_blank"
              >
                Lookup membership number
              </Link>
              <Link
                size="small"
                href="https://support.leagueapps.com/hc/en-us/articles/360039865033-How-To-Validate-Your-USA-Lacrosse-Number"
                target="_blank"
              >
                Need help?
              </Link>
            </div>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
