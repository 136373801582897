import styled, { CSSObject } from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';
import { getCursorProperty } from '../utils/drag';

type MemberTileProps = ThemedComponent<{
  $active?: boolean;
  $removeable?: boolean;
  $selectable?: boolean;
  $selected?: boolean;
  $disabled?: boolean;
}>;

export const RemoveIcon = styled.span({
  visibility: 'hidden',
  marginTop: '4px',
});

export const MemberTileBorder = styled.div<MemberTileProps>(
  ({ $active, $removeable, $selectable, $selected, $disabled, theme }) => {
    let stateProperties: CSSObject;
    if ($active) {
      stateProperties = {
        padding: '1px',
        border: `2px solid ${theme.foundational.blueGrey[600]}`,
      };
    } else if ($selected) {
      stateProperties = {
        padding: '2px',
        border: `1px solid ${theme.foundational.blueGrey[400]}`,
      };
    } else {
      stateProperties = {
        padding: '3px',
      };
    }

    return {
      ...stateProperties,
      height: '100%',
      borderRadius: '4px',
      overflow: 'hidden',
      border: $disabled ? 'none' : stateProperties.border,
      '&:hover': {
        ...($selectable && !$disabled
          ? {
              padding: '2px',
              border: `1px solid ${theme.foundational.blueGrey[600]}`,
            }
          : {}),

        [RemoveIcon]: {
          visibility: $removeable ? 'visible' : 'hidden',
        },
      },
    };
  }
);

export const MemberTile = styled.div<MemberTileProps>(
  ({ $active, $selectable, $selected, $disabled, theme }) => ({
    display: 'flex',
    position: 'relative',
    height: '100%',
    padding: $disabled ? '2px 4px 2px 24px' : '2px 4px',
    border: `1px solid ${$active ? theme.foundational.blueGrey[400] : 'transparent'}`,
    borderRadius: '4px',
    cursor: getCursorProperty($selected, $selectable),

    'button[role="checkbox"]': {
      alignSelf: 'flex-start',
      marginTop: '2px',
      opacity: $selectable ? 1 : 0,
      pointerEvents: $selectable ? 'all' : 'none',
      cursor: getCursorProperty($selected, $selectable),
    },
  })
);

export const MemberTileDetails = styled.div<ThemedComponent<MemberTileProps>>(
  ({
    $active,
    $selectable,
    $selected,
    $disabled,
    theme,
  }: ThemedComponent<MemberTileProps>) => ({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
    columnGap: '8px',
    paddingLeft: '6px',
    cursor: getCursorProperty($selected, $selectable),

    p: {
      color:
        !$active && $disabled ? theme.foundational.blueGrey[600] : undefined,
    },
  })
);
