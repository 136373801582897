import styled from 'styled-components';
import { Typography, } from '@la/ds-ui-components';
export const ReactiveTypography = styled(Typography)(({ theme, background, isWhite = false, }) => {
    const { semantic, foundational: { blueGrey, white }, } = theme;
    if (!semantic) {
        return {
            color: isWhite ? white : blueGrey[900],
        };
    }
    return {
        color: semantic[background].reactiveTextColor,
    };
});
