import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './Breadcrumbs.styles';
export const SEPARATOR = '/';
export const Breadcrumbs = ({ breadcrumbs }) => {
    const getBreadcrumb = (breadcrumb) => {
        if (breadcrumb.link) {
            const { href, newTab = false } = breadcrumb.link;
            return (_jsx(S.BreadcrumbLink, Object.assign({ href: href, target: newTab ? '_blank' : '_self' }, { children: breadcrumb.label })));
        }
        return breadcrumb.label;
    };
    return (_jsx(S.Breadcrumbs, { children: breadcrumbs.map((breadcrumb, index) => {
            const breadcrumbId = getBreadcrumbId(index);
            return (_jsxs(S.Breadcrumb, Object.assign({ "data-testid": breadcrumbId }, { children: [_jsx(S.BreadcrumbLabel, { children: getBreadcrumb(breadcrumb) }), index < breadcrumbs.length - 1 ? (_jsx(S.BreadcrumbSeparator, { children: SEPARATOR })) : null] }), breadcrumbId));
        }) }));
};
export const getBreadcrumbId = (index) => {
    return `breadcrumb-${index}`;
};
