import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Typography } from '@la/ds-ui-components';
import { FileUploadFormField } from '@la/types';
import { RegistrationInfoCard } from 'components/RegistrationInfoCard/RegistrationInfoCard';
import { RegistrantInfoPayload } from 'lib/apis/getRegistrantInfoFromFacade';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import * as S from './RegistrationReviewCard.styles';

export const RegistrationReviewCard = () => {
  const [firstRender, setFirstRender] = useState(true);
  const {
    formFields,
    waivers,
    signedWaivers,
    fileUploadFormFields,
    decodedData,
    ngbMembershipType,
    setHasErrors,
    showNGBVerification,
  } = useRegistration();

  useEffect(() => {
    if (firstRender) {
      setHasErrors(false);
      setFirstRender(false);
    }
  }, [firstRender, setHasErrors]);

  const renderFieldsCard = () => {
    // We will always show the info card.
    // If it is a staff registration and there is no fields we show the user's name.
    // In case of a player registration with no fields we show the player's name.
    const allowEdit =
      decodedData?.type !== 'staff' ||
      (formFields && formFields.nonFileUploadFormFields?.length > 0) ||
      (showNGBVerification && ngbMembershipType);

    return <RegistrationInfoCard allowEditMode={Boolean(allowEdit)} />;
  };

  const renderUploadDocumentsCard = () => {
    const filenames = Object.values(fileUploadFormFields);

    if (!filenames.length) {
      return null;
    }

    return (
      <Card>
        <CardHeader>Uploaded Documents</CardHeader>
        <CardBody>
          <Files fileUploadFormFields={filenames} />
        </CardBody>
      </Card>
    );
  };

  return (
    <S.RegistrationReviewContainer>
      {renderFieldsCard()}
      {renderUploadDocumentsCard()}
      <Card>
        <CardHeader>Signed Documents</CardHeader>
        <CardBody>
          <S.EntriesContainer>
            <Waivers waivers={waivers} signedWaivers={signedWaivers} />
          </S.EntriesContainer>
        </CardBody>
      </Card>
    </S.RegistrationReviewContainer>
  );
};

const Files = ({
  fileUploadFormFields,
}: {
  fileUploadFormFields: FileUploadFormField[];
}) => {
  return (
    <>
      {fileUploadFormFields.map((fileUploadFormField, i) => {
        return (
          <S.WaiversContainer key={i}>
            <Typography variant="ui" size="large">
              {fileUploadFormField.name}:
            </Typography>
            <Typography variant="ui" size="large" weight="bold">
              {fileUploadFormField.value?.name}
            </Typography>
          </S.WaiversContainer>
        );
      })}
    </>
  );
};

const Waivers = ({
  waivers,
  signedWaivers,
}: {
  waivers: RegistrantInfoPayload['waivers'] | null;
  signedWaivers: Record<string, boolean>;
}) => {
  if (!waivers?.length) {
    return null;
  }

  return (
    <>
      {waivers.map((waiver, i) => {
        const isSigned = signedWaivers[waiver.waiverId];
        return (
          <S.WaiversContainer key={waiver.waiverId ?? i}>
            <Typography variant="ui" size="large">
              {waiver.name}:
            </Typography>
            <Typography variant="ui" size="large" weight="bold">
              {isSigned ? 'Signed' : 'Not signed'}
            </Typography>
          </S.WaiversContainer>
        );
      })}
    </>
  );
};
