export const PHONE_NUMBER_MAX_LENGTH = 12;
const ALLOWED_ACTIONS = [
    'Backspace',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
];
const formatPhoneNumber = (value) => {
    // Add first missing dash
    if (value.indexOf('-') < 0 && value.length >= 3 && value.length <= 6) {
        return value.substring(0, 3) + '-' + value.substring(3);
    }
    if (value.length >= 7) {
        // Add both missing dashes
        if (value.indexOf('-') < 0) {
            return (value.substring(0, 3) +
                '-' +
                value.substring(3, 6) +
                '-' +
                value.substring(6));
        }
        // Add the second missing dash
        if (value.lastIndexOf('-') !== 7) {
            return value.substring(0, 7) + '-' + value.substring(7);
        }
    }
    return value;
};
const maskPhoneNumberKeyUp = (e) => {
    var _a;
    let value = (_a = e.currentTarget.value) !== null && _a !== void 0 ? _a : '';
    if (ALLOWED_ACTIONS.includes(e.key)) {
        return value;
    }
    // Remove dashes, spaces, non-numeric values
    value = value
        .replace(/[-\s]/g, '')
        .replace(/[^0-9]/g, '')
        .substring(0, PHONE_NUMBER_MAX_LENGTH - 2);
    return formatPhoneNumber(value);
};
const maskPhoneNumberKeyDown = (e) => {
    var _a;
    const value = (_a = e.currentTarget.value) !== null && _a !== void 0 ? _a : '';
    if (ALLOWED_ACTIONS.includes(e.key)) {
        return value;
    }
    if (!['-'].includes(e.key) &&
        !e.ctrlKey &&
        !e.metaKey &&
        isNaN(Number(e.key))) {
        e.preventDefault();
    }
    return value;
};
export const getMaskPhoneNumberHandlers = (
// eslint-disable-next-line no-unused-vars
callback) => ({
    onKeyUp: (e) => callback(maskPhoneNumberKeyUp(e)),
    onKeyDown: (e) => callback(maskPhoneNumberKeyDown(e)),
});
