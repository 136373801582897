import styled from 'styled-components';
export const NavigationPanelContainer = styled.div({
    display: 'block',
    marginRight: 16,
    minHeight: '100vh',
});
export const NavigationPanelInnerContainer = styled.div({
    position: 'sticky',
    top: 56,
    left: 0,
    width: 350,
    height: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: 0,
        backgroundColor: 'transparent',
    },
});
export const NavigationItemContainer = styled.div(({ theme, $isSelected, $hasBorder }) => {
    const { foundational: { blueGrey, green, white }, semantic, } = theme;
    const borderColor = semantic ? semantic.primary[600] : green[600];
    return {
        padding: 8,
        cursor: 'pointer',
        borderRight: $hasBorder && $isSelected ? `4px solid ${borderColor}` : 'none',
        backgroundColor: $isSelected ? white : 'transparent',
        borderRadius: 4,
        width: '100%',
        '& h4': {
            color: $isSelected ? blueGrey[900] : blueGrey[400],
        },
        '& p': {
            color: $isSelected ? blueGrey[900] : blueGrey[600],
        },
        '&:hover': {
            backgroundColor: white,
        },
    };
});
export const BottomDrawer = styled.div({
    position: 'fixed',
    bottom: 0,
    left: 0,
    height: 64,
    width: '100vw',
    backgroundColor: 'white',
    padding: '12px 16px',
    zIndex: 1200,
    '& button': {
        height: 40,
    },
});
