import styled from 'styled-components';
export const SubNavigation = styled.div `
  display: flex;
  grid-area: ${(props) => props.$gridAreaName};
  position: relative;
  width: 100%;
  height: 48px;
  min-height: 48px;
  padding: 0 16px;
  text-align: left;
  background-color: var(--white);
  box-shadow: 2px 2px 7px var(--drop-shadow);
`;
export const SubNavigationSection = styled.div `
  display: inline-block;
  height: 100%;

  > a {
    color: var(--blue-grey-700);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    margin-right: 20px;
    text-decoration: none;
    line-height: 48px;
  }

  &:hover {
    border-bottom: 2px var(--blue-grey-900) solid;

    > a {
      color: var(--green-700);
    }

    > div {
      display: block;
    }
  }
`;
export const SubNavigationSectionMenu = styled.div `
  display: none;
  position: absolute;
  z-index: 110;
  min-width: 120px;
  padding: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  background-color: var(--white);
`;
export const SubNavigationMenuItem = styled.div `
  > a {
    display: block;
    font-size: 14px;
    line-height: 1.75;
    color: var(--primary-500);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
