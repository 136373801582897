import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  BlockerFunction,
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Loader } from '@la/ds-ui-components';
import { useGetStaffRolesQuery } from '@la/services';
import { MainContent, MainContentCenter, Stepper } from '@la/shared-components';
import {
  FacadeRegistrant,
  PaymentStatus,
  Player,
  Registration,
  RegistrationStatus,
  RegistrationType,
  Roster,
  Staff,
  Team,
} from '@la/types';
import { getLAHostnameParts, isPlayer, isStaff } from '@la/utilities';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { getSiteIdentityData } from 'redux/coreSlice';
import {
  useGetInvitesQuery,
  useGetRosterQuery,
  useLazyGetRosterQuery,
  useUpdateRosterMutation,
} from 'redux/services/rosterManagementApi';
import { useGetFacadeTeamsQuery } from 'redux/services/teamApi';
import { useAppSelector } from 'redux/store';
import { getUserId } from 'redux/userSlice';
import Page from 'domains/Shell/Page/Page';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { DiscardChangesModal } from './DiscardChangesModal/DiscardChangesModal';
import { RolloverSummary } from './RolloverSummary/RolloverSummary';
import { RolloverWizard } from './RolloverWizard/RolloverWizard';
import { TeamSelectionModal } from './TeamSelectionModal/TeamSelectionModal';
import { useTeamFilters } from './hooks/useTeamFilters';
import {
  ModalName,
  RosterRolloverActionType,
  rosterRolloverReducer,
  RosterRolloverState,
} from './utils/reducer';
import * as S from './RosterRollover.styles';

const PAGE_CONTENT_ID = 'roster-rollover-page-content';
const TEAM_PAGE_SIZE = 10;

const ROSTER_ROLLOVER_STEPS: Record<number, string> = {
  1: 'Add players and staff from a previous roster to your new roster',
  2: 'Review and invite players and staff to register',
};
const TOTAL_STEPS = Object.entries(ROSTER_ROLLOVER_STEPS).length;

const ROSTER_ROLLOVER_NEXT_ACTIONS: Record<number, string> = {
  1: 'Review changes',
  2: 'Complete rollover',
};

export const INITIAL_ROSTER_ROLLOVER_STATE: RosterRolloverState = {
  step: 1,
  members: [],
  showTeamSelectionLoadMoreOption: true,
  teamDataPageCount: 1,
  teamDataPage: 1,
};

const SITE_ID_NOT_FOUND = 'Site id not found';
const PROGRAM_ID_NOT_FOUND = 'Program id not found';
const TEAM_ID_NOT_FOUND = 'Team id not found';

/* RosterRollover */
export function RosterRollover() {
  const { siteId } = useAppSelector(getSiteIdentityData);
  const userId = useAppSelector(getUserId);
  const { subdomain } = getLAHostnameParts();
  const params = useParams();
  const { programId, teamId } = params;

  if (!siteId) {
    throw new Error(SITE_ID_NOT_FOUND);
  }
  if (!programId) {
    throw new Error(PROGRAM_ID_NOT_FOUND);
  }
  if (!teamId) {
    throw new Error(TEAM_ID_NOT_FOUND);
  }

  const navigate = useNavigate();

  const [rosterUpdated, setRosterUpdated] = useState(false);
  const [state, dispatch] = useReducer(
    rosterRolloverReducer,
    INITIAL_ROSTER_ROLLOVER_STATE
  );

  const {
    error,
    step,
    members,
    selectedTeamRoster,
    showTeamSelectionLoadMoreOption,
    teamDataPageCount,
    teamDataPage,
    openModal,
  } = state;
  const totalVisibleResults = TEAM_PAGE_SIZE * teamDataPage;

  const [getRoster, { isError: hasGetRosterError }] = useLazyGetRosterQuery();

  const [updateRoster] = useUpdateRosterMutation();
  const {
    teamName,
    programId: programIdFilter,
    setTeamName,
    setProgramId,
    subprogramId,
    setSubprogramId,
    sort,
    setSort,
  } = useTeamFilters();

  const resetTeamsFetchPage = (): void => {
    dispatch({
      type: RosterRolloverActionType.UpdateTeamDataPageCount,
      payload: 1,
    });
  };

  const onProgramIdUpdate = (id: string): void => {
    setProgramId(id);
    resetTeamsFetchPage();
  };

  const onSubprogramIdUpdate = (id: string): void => {
    setSubprogramId(id);
    resetTeamsFetchPage();
  };

  const {
    data: destinationTeam,
    isLoading: isRosterLoading,
    isError: hasRosterError,
  } = useGetRosterQuery(
    {
      siteSubdomain: subdomain,
      siteId,
      programId: programId ?? '',
      teamId: teamId ?? '',
    },
    {
      skip: !programId || !teamId,
    }
  );

  const getProgramFilter = () => {
    if (programIdFilter === 'all' && subprogramId === 'all') {
      return 'all';
    }
    if (subprogramId === 'all') {
      return programIdFilter;
    }

    return subprogramId;
  };

  const {
    data: rawTeams,
    isLoading: isTeamsLoading,
    isError: hasTeamsError,
  } = useGetFacadeTeamsQuery(
    {
      pageNum: teamDataPageCount,
      role: 'staff',
      userId: userId.toString() || '',
      teamName,
      programId: getProgramFilter(),
      sort,
    },
    { skip: !userId }
  );

  const {
    data: invites,
    isLoading: isInvitesLoading,
    isError: hasInvitesError,
  } = useGetInvitesQuery({ programId, siteSubdomain: subdomain, teamId });

  const {
    data: staffRoles,
    isLoading: isStaffRolesLoading,
    isError: hasStaffRolesError,
  } = useGetStaffRolesQuery({ siteId });

  const [showDiscardChangesModal, setShowDiscardChangesModal] =
    useState<boolean>(false);

  const teams: Team[] = useMemo(() => {
    if (rawTeams) {
      const teams = rawTeams.teams
        .slice(0, totalVisibleResults)
        .filter((team) => team.id !== teamId);
      return teams;
    }
    return [];
  }, [rawTeams, teamId, totalVisibleResults]);

  /**
   * Scrolls user to top of page content upon error.
   */
  useEffect(() => {
    if (error) {
      const content = document.getElementById(PAGE_CONTENT_ID);
      content?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [error]);

  /**
   * Redirects the user back to roster management's page. Serializes the invite links
   * and number of rolled over players and staff to be displayed inside `InviteModal`.
   */
  useEffect(() => {
    if (invites && rosterUpdated) {
      const { programType, programId, teamId } = params;
      const totalPlayers = members.filter(isPlayer).length;
      const totalStaff = members.filter(isStaff).length;

      const data = {
        totalPlayers,
        totalStaff,
        invites,
      };
      const encodedData = window.btoa(JSON.stringify(data));
      const path = `/app/${programType}/${programId}/teams/${teamId}/roster`;
      navigate(`${path}?rolloverData=${encodedData}`);
    }
  }, [invites, navigate, params, members, rosterUpdated]);

  /**
   * Triggers the query to run again if there are more results to fetch. We
   * essentially keep fetching until we have all the results, but we do it
   * paginated to be safe.
   */
  useEffect(() => {
    if (rawTeams?.hasMoreResults) {
      dispatch({
        type: RosterRolloverActionType.UpdateTeamDataPageCount,
        payload: teamDataPageCount,
      });
    }
  }, [rawTeams, teamDataPageCount]);

  /**
   * Toggles the load more button in the team selection modal.
   */
  useEffect(() => {
    if (rawTeams) {
      const { teams } = rawTeams;

      const isSameLength =
        teams.slice(0, totalVisibleResults).length === rawTeams?.teams.length;
      dispatch({
        type: RosterRolloverActionType.ToggleShowTeamSelectionLoadMoreOption,
        payload: !isSameLength,
      });
    }
  }, [dispatch, rawTeams, totalVisibleResults]);

  /**
   * Display the default browser alert when navigating outside of the
   * application (e.g. refreshing, closing the tab, external link). Only
   * if there are members added.
   */
  const displayBrowserAlert = (e: BeforeUnloadEvent): void => {
    e.preventDefault();
  };
  useEffect(() => {
    if (members.length > 0) {
      window.addEventListener('beforeunload', displayBrowserAlert);
      return () => {
        window.removeEventListener('beforeunload', displayBrowserAlert);
      };
    }
  }, [members]);

  /**
   * Display a modal when navigating away from the page but within the
   * application. Only if there are members added.
   */
  const navigateToNextLocation = (): void => {
    blocker.proceed?.();
  };
  const shouldBlockNavigation = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      const shouldBlock =
        members.length > 0 &&
        currentLocation.pathname !== nextLocation.pathname &&
        !rosterUpdated;

      if (shouldBlock) {
        setShowDiscardChangesModal(true);
      }
      return shouldBlock;
    },
    [members, rosterUpdated]
  );
  const blocker = useBlocker(shouldBlockNavigation);
  useEffect(() => {
    if (blocker.state === 'blocked' && !showDiscardChangesModal) {
      blocker.reset();
    }
  }, [blocker, showDiscardChangesModal]);

  if (
    isRosterLoading ||
    isTeamsLoading ||
    isInvitesLoading ||
    isStaffRolesLoading
  ) {
    return (
      <Page>
        <MainContentCenter>
          <Loader description="We are loading your team data" loading />
        </MainContentCenter>
      </Page>
    );
  }

  if (
    hasRosterError ||
    hasTeamsError ||
    hasInvitesError ||
    hasStaffRolesError
  ) {
    return (
      <Page>
        <MainContent>
          <ErrorCard message="There was an error loading this page. Please try again in a few seconds." />
        </MainContent>
      </Page>
    );
  }

  const onRemove = (member: FacadeRegistrant): void => {
    dispatch({
      type: RosterRolloverActionType.UpdateMembers,
      payload: { remove: [member] },
    });
  };

  const onRollover = (updatedMembers: FacadeRegistrant[]): void => {
    const add: FacadeRegistrant[] = [];
    const update: FacadeRegistrant[] = [];

    updatedMembers.forEach((member) => {
      if (members.find((m) => m.registeredUserId === member.registeredUserId)) {
        update.push(member);
      } else {
        add.push(member);
      }
    });

    dispatch({
      type: RosterRolloverActionType.UpdateMembers,
      payload: {
        add,
        update,
      },
    });
  };

  const onTeamSelectionClick = (): void => {
    dispatch({
      type: RosterRolloverActionType.ToggleModal,
      payload: ModalName.TeamSelection,
    });
  };

  const onLoadMoreClick = (): void => {
    dispatch({
      type: RosterRolloverActionType.IncrementTeamDataPage,
    });
  };

  const onTeamSelect = (team: Team): void => {
    getRoster({
      siteSubdomain: subdomain,
      siteId: team.siteId?.toString() ?? '',
      programId: team.programId?.toString() ?? '',
      teamId: team.id,
    })
      .unwrap()
      .then((roster: Roster) => {
        const selectedTeam = teams.find((t) => t.id === team.id);
        dispatch({
          type: RosterRolloverActionType.SelectTeamRoster,
          payload: {
            ...roster,
            createdOn: selectedTeam?.createdOn,
          },
        });
      });
  };

  const navigateBack = (): void => {
    if (step > 1) {
      dispatch({
        type: RosterRolloverActionType.UpdateStep,
        payload: step - 1,
      });
    }
  };

  const submitRoster = () => {
    const memberData: Pick<
      Registration,
      | 'deleted'
      | 'paymentStatus'
      | 'programId'
      | 'registeringUserId'
      | 'registrationStatus'
      | 'siteId'
      | 'teamIdOg'
    > = {
      deleted: false,
      paymentStatus: PaymentStatus.Unpaid,
      programId: parseInt(programId),
      registeringUserId: userId,
      registrationStatus: RegistrationStatus.PendingInvite,
      siteId: parseInt(siteId),
      teamIdOg: parseInt(teamId),
    };

    const players = state.members
      .filter((m): m is Player => isPlayer(m))
      .map<Registration>((m) => {
        const email = m.parent[0]?.email;
        return {
          ...memberData,
          properties: [
            {
              name: 'email',
              values: email ? [email] : [],
            },
            {
              name: 'playerName',
              values: [m.name],
            },
            {
              name: 'programRole',
              values: ['PLAYER'],
            },
          ],
          registrationType: RegistrationType.InvitePlayer,
        };
      });

    const staff = state.members
      .filter((m): m is Staff => isStaff(m))
      .map<Registration>((m) => {
        const type = 'STAFF';
        const roleId = m.roles[0]?.roleId;
        return {
          ...memberData,
          properties: [
            {
              name: 'email',
              values: [m.email],
            },
            {
              name: 'programStaffId',
              values: roleId ? [roleId.toString()] : [],
            },
            {
              name: 'staffName',
              values: [m.name],
            },
            {
              name: 'type',
              values: [type],
            },
          ],
          registrationType: RegistrationType.InviteStaff,
        };
      });

    return Promise.all([
      updateRoster({
        siteSubdomain: subdomain,
        programId: parseInt(programId),
        content: {
          add: [...players, ...staff],
        },
      }).unwrap(),
    ]);
  };

  const navigateForward = (): void => {
    if (step === TOTAL_STEPS) {
      submitRoster()
        .then(() => {
          setRosterUpdated(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      dispatch({
        type: RosterRolloverActionType.UpdateStep,
        payload: step + 1,
      });
    }
  };

  const renderStep = (): ReactNode => {
    switch (step) {
      case 1:
        return destinationTeam ? (
          <RolloverWizard
            destinationTeam={destinationTeam}
            members={members}
            onRemove={onRemove}
            onRollover={onRollover}
            onTeamSelectionClick={onTeamSelectionClick}
            selectedTeamRoster={selectedTeamRoster}
            staffRoles={staffRoles ?? []}
          />
        ) : null;
      case 2:
        return <RolloverSummary members={members} />;
      default:
        return <></>;
    }
  };

  let teamSelectionError;
  if (hasGetRosterError) {
    teamSelectionError = `There was an error retrieving your team's roster. Please try again in a few seconds.`;
  }

  return (
    <Page>
      <PageTitle>Rollover roster</PageTitle>
      <S.RosterRollover id={PAGE_CONTENT_ID}>
        <Stepper
          currentNextAction={ROSTER_ROLLOVER_NEXT_ACTIONS[step]}
          currentStep={ROSTER_ROLLOVER_STEPS[step]}
          error={!!error}
          errorMessage={error}
          form={{}}
          handleNextClick={navigateForward}
          isMC={false}
          numberOfTotalSteps={TOTAL_STEPS}
          onBackClick={navigateBack}
          showDrawer
          stepNumber={step}
          showSteps
          type="button"
        >
          {renderStep()}
        </Stepper>
      </S.RosterRollover>
      {teams ? (
        <TeamSelectionModal
          allTeams={rawTeams?.teams}
          error={teamSelectionError}
          onLoadMoreClick={onLoadMoreClick}
          onOpenChange={(open: boolean) => {
            if (!open) {
              dispatch({ type: RosterRolloverActionType.ToggleModal });
            }
          }}
          onTeamSelect={onTeamSelect}
          open={openModal === ModalName.TeamSelection}
          selectedTeam={selectedTeamRoster}
          showLoadMoreOption={showTeamSelectionLoadMoreOption}
          teams={teams}
          teamName={teamName}
          programId={programIdFilter}
          setTeamName={setTeamName}
          setProgramId={onProgramIdUpdate}
          subprogramId={subprogramId}
          setSubprogramId={onSubprogramIdUpdate}
          sort={sort}
          setSort={setSort}
        />
      ) : null}
      {showDiscardChangesModal ? (
        <DiscardChangesModal
          onDiscardChangesClick={navigateToNextLocation}
          onOpenChange={setShowDiscardChangesModal}
          open={showDiscardChangesModal}
        />
      ) : null}
    </Page>
  );
}
