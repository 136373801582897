import { useState } from 'react';
import { formatAsUSD } from '@la/utilities';
import {
  BillingSummaryItemLineItem,
  CombinedCartItemData,
} from '../Checkout.types';
import PaymentPlansModal from '../PaymentPlansModal/PaymentPlansModal';
import OrderSummaryCardBody from './OrderSummaryCardBody';
import OrderSummaryCardHeader from './OrderSummaryCardHeader';
import * as S from './OrderSummaryCard.styles';

export type CartSummaryCardProps = {
  cartItemData: CombinedCartItemData;
  isShowingAdditionalButtons?: boolean;
};

/* OrderSummaryCard */
export default function OrderSummaryCard({
  cartItemData,
  isShowingAdditionalButtons,
}: Readonly<CartSummaryCardProps>) {
  const {
    cartItemName,
    cartItemUuid,
    editUrl,
    iconAltText,
    iconUrl,
    lineItems,
    location,
    paymentPlanSummaries,
    price,
    programDateInfo,
    selectedPaymentPlan,
    subprograms,
    subtype,
    totalAmount,
    type,
  } = cartItemData;

  const [isPaymentPlansModalOpen, setIsPaymentPlansModalOpen] = useState(false);

  const adjustedPrice = totalAmount
    ? formatAsUSD(parseFloat(totalAmount))
    : null;

  const discountCodeBadgeLabel = lineItems
    ? getDiscountCodeBadgeLabel(lineItems)
    : null;

  return (
    <>
      <S.OrderSummaryCard>
        <OrderSummaryCardHeader
          adjustedPrice={adjustedPrice}
          cartItemName={cartItemName}
          iconAltText={iconAltText}
          iconUrl={iconUrl}
          originalPrice={price}
          subtype={subtype}
          type={type}
        />
        <OrderSummaryCardBody
          cartItemUuid={cartItemUuid}
          discountCodeBadgeLabel={discountCodeBadgeLabel}
          editUrl={editUrl}
          isShowingAdditionalButtons={isShowingAdditionalButtons}
          location={location}
          openPaymentPlansModal={() => setIsPaymentPlansModalOpen(true)}
          programDateInfo={programDateInfo}
          selectedPaymentPlan={selectedPaymentPlan}
          subprograms={subprograms}
        />
      </S.OrderSummaryCard>
      {isPaymentPlansModalOpen ? (
        <PaymentPlansModal
          cartItemName={cartItemName}
          cartItemUuid={cartItemUuid}
          closeModal={() => setIsPaymentPlansModalOpen(false)}
          open={isPaymentPlansModalOpen}
          paymentPlanPrice={price}
          paymentPlanSummaries={paymentPlanSummaries ?? []}
          selectedPaymentPlan={selectedPaymentPlan}
        />
      ) : null}
    </>
  );
}
/* */

function getDiscountCodeBadgeLabel(lineItems: BillingSummaryItemLineItem[]) {
  const discountCode = lineItems?.[0]?.discountCodes?.[0] ?? null;
  let priceAdjustment;
  if (discountCode?.fixedAmount) {
    priceAdjustment = formatAsUSD(
      Math.abs(parseFloat(discountCode.fixedAmount))
    );
  } else if (discountCode?.percentage) {
    priceAdjustment = `${Math.abs(parseFloat(discountCode.percentage))}%`;
  }

  return discountCode && priceAdjustment
    ? `"${discountCode.code}" ${priceAdjustment}`
    : null;
}
