import {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import { PaymentMethodInfo, PaymentTerm } from '@la/types';
import { getLAHostnameParts } from '@la/utilities';
import { ToastVariant } from 'components/Toast/Toast';
import {
  useDeleteCartItemMutation,
  useGetCheckoutSummaryBFFQuery,
  useUpdateCheckoutSummaryBFFMutation,
} from 'redux/services/checkoutApi';
import {
  CartDataFromUpdate,
  CheckoutSummaryData,
  CombinedCartItemData,
  DiscountCode,
  PaymentMethod,
  PaymentPlanSummary,
  SingleUseCard,
} from 'domains/ConsolidatedCheckout/Checkout.types';
import { useCheckoutInfo } from '../CheckoutInfoContext';
import { MessagingContext } from '../MessagingContext';

export type DiscountCodeToastType = 'add' | 'duplicate' | 'remove';

export type ConsolidatedCheckoutData = {
  cartItems: CombinedCartItemData[];
  cartUuid?: string;
  currency?: string;
  discountCodeCodes: string[];
  discountCodes: DiscountCode[];
  dueToday: string;
  errorMessages: {
    discountCodes: string[];
    paymentMethods: string[];
  };
  isLoading: string[];
  selectedPaymentMethod: PaymentMethod;
  selectedPaymentPlans?: PaymentPlanSummary[];
  storedPaymentMethods: PaymentMethodInfo;
  subtotal?: string;
  temporaryPaymentMethod?: SingleUseCard;
  totalAmount?: string;
  transactionFees: string;
  updateCartItems: (updatedCartItems: CombinedCartItemData[]) => void;
  updateDataAfterCartItemDeletion: (
    updatedDataAfterCartItemDeletion: CartDataFromUpdate
  ) => void;
  updateDataAfterDiscountCodesUpdate: (
    updatedDataAfterDiscountCodesUpdate: CartDataFromUpdate
  ) => void;
  updateDataAfterPaymentMethodSelection: (
    updatedDataAfterPaymentMethodSelectionUpdate: CartDataFromUpdate
  ) => void;
  updateDataAfterPaymentPlanSelection: (
    updatedDataAfterPaymentPlanSelectionUpdate: CartDataFromUpdate
  ) => void;
  updateErrorMessages: (errorType: string, errorMessages: string[]) => void;
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod) => void;
  updateTemporaryPaymentMethod: (singleUseCard: SingleUseCard) => void;
};

export const consolidatedCheckoutDefaults: ConsolidatedCheckoutData = {
  cartItems: [],
  cartUuid: '',
  currency: 'USD',
  discountCodes: [],
  discountCodeCodes: [],
  dueToday: '0.00',
  errorMessages: { discountCodes: [], paymentMethods: [] },
  isLoading: ['page'],
  selectedPaymentMethod: null,
  selectedPaymentPlans: [],
  storedPaymentMethods: {
    paymentOptionsCount: 0,
    storedBankAccounts: [],
    storedCreditCards: [],
  },
  subtotal: '0.00',
  temporaryPaymentMethod: undefined,
  totalAmount: '0.00',
  transactionFees: '0.00',
  updateCartItems: () => {},
  updateDataAfterCartItemDeletion: () => {},
  updateDataAfterDiscountCodesUpdate: () => {},
  updateDataAfterPaymentMethodSelection: () => {},
  updateDataAfterPaymentPlanSelection: () => {},
  updateErrorMessages: () => {},
  updateSelectedPaymentMethod: () => {},
  updateTemporaryPaymentMethod: () => {},
};

function checkoutCartReducer(
  state: ConsolidatedCheckoutData,
  action: { type: string; payload: any }
): ConsolidatedCheckoutData {
  const { type, payload } = action;
  switch (type) {
    // Set Initial State
    case 'SET_INITIAL_LOAD_STATE': {
      return { ...state, ...payload };
    }

    // Payment Method
    case 'UPDATE_STORED_PAYMENT_METHODS':
      return {
        ...state,
        storedPaymentMethods: payload,
      };
    case 'UPDATE_SELECTED_PAYMENT_METHOD':
      return { ...state, selectedPaymentMethod: payload };
    case 'UPDATE_TEMPORARY_PAYMENT_METHOD':
      return { ...state, temporaryPaymentMethod: payload };

    // Payment Plan Selection
    case 'UPDATE_SELECTED_PAYMENT_PLANS':
      return { ...state, selectedPaymentPlans: payload };

    // Discount Codes
    case 'UPDATE_DISCOUNT_CODES':
      return {
        ...state,
        discountCodes: payload,
      };
    case 'UPDATE_DISCOUNT_CODE_CODES':
      return {
        ...state,
        discountCodeCodes: payload,
      };

    // Error Messages
    case 'UPDATE_DISCOUNT_CODE_ERRORS':
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          discountCodes: payload,
        },
      };
    case 'UPDATE_PAYMENT_METHOD_ERRORS':
      return {
        ...state,
        errorMessages: {
          ...state.errorMessages,
          paymentMethods: payload,
        },
      };

    case 'SET_CART_UUID':
      return { ...state, cartUuid: payload };
    case 'SET_CURRENCY':
      return { ...state, currency: payload };
    case 'UPDATE_CART_ITEMS':
      return { ...state, cartItems: payload };
    case 'UPDATE_DUE_TODAY':
      return { ...state, dueToday: payload };
    case 'UPDATE_SUBTOTAL':
      return { ...state, subtotal: payload };
    case 'UPDATE_TOTAL_AMOUNT':
      return { ...state, totalAmount: payload };
    case 'UPDATE_TRANSACTION_FEES':
      return { ...state, transactionFees: payload };

    default:
      return state;
  }
}

const ConsolidatedCheckoutContext = createContext<ConsolidatedCheckoutData>(
  consolidatedCheckoutDefaults
);

const ConsolidatedCheckoutProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    checkoutCartReducer,
    consolidatedCheckoutDefaults
  );

  const { subdomain } = getLAHostnameParts();
  // TODO: Remove references to useCheckoutInfo() TI-3205;
  const { updateSelectedPaymentMethod: updateSelectedPaymentMethodV1 } =
    useCheckoutInfo();

  const {
    data: checkoutSummary,
    // error: orderSummaryError,
    // isLoading: isOrderSummaryLoading,
  } = useGetCheckoutSummaryBFFQuery({
    subdomain: subdomain,
  });

  const updateCartItems = useCallback(
    (updatedCartItems: CombinedCartItemData[]) => {
      dispatch({ type: 'UPDATE_CART_ITEMS', payload: updatedCartItems });
    },
    []
  );

  const updateSelectedPaymentMethod = useCallback(
    (selectedPaymentMethod: PaymentMethod) => {
      dispatch({
        type: 'UPDATE_SELECTED_PAYMENT_METHOD',
        payload: selectedPaymentMethod,
      });
      // TODO: Remove references to useCheckoutInfo() TI-3205
      updateSelectedPaymentMethodV1(selectedPaymentMethod);
    },
    [updateSelectedPaymentMethodV1]
  );

  const updateTemporaryPaymentMethod = useCallback(
    (temporaryPaymentMethod: SingleUseCard) => {
      dispatch({
        type: 'UPDATE_TEMPORARY_PAYMENT_METHOD',
        payload: temporaryPaymentMethod,
      });
    },
    []
  );

  const updateSelectedPaymentMethodWithDefault = useCallback(
    (storedPaymentMethods: PaymentMethodInfo) => {
      const defaultPaymentMethod =
        getDefaultPaymentMethod(storedPaymentMethods);
      if (defaultPaymentMethod) {
        updateSelectedPaymentMethod(defaultPaymentMethod);
      }
    },
    [updateSelectedPaymentMethod]
  );

  const handleConditionalUpdates = useCallback(
    (checkoutSummaryData: CheckoutSummaryData) => {
      if (!state.selectedPaymentMethod) {
        updateSelectedPaymentMethodWithDefault(
          checkoutSummaryData.storedPaymentMethods
        );
      }
    },
    [state.selectedPaymentMethod, updateSelectedPaymentMethodWithDefault]
  );

  const setCheckoutSummary = useCallback(
    (checkoutSummaryData: CheckoutSummaryData) => {
      dispatch({
        type: 'SET_INITIAL_LOAD_STATE',
        payload: { ...checkoutSummaryData, isLoading: [] },
      });
      handleConditionalUpdates(checkoutSummaryData);
    },
    [handleConditionalUpdates]
  );

  const updateDataAfterCartItemDeletion = useCallback(
    async (updatedData: any) => {
      const { cartItems, dueToday, totalAmount, transactionFees } = updatedData;

      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
    },
    []
  );

  const updateDataAfterDiscountCodesUpdate = useCallback(
    async (updatedData: CartDataFromUpdate) => {
      const {
        cartItems,
        discountCodes,
        dueToday,
        totalAmount,
        transactionFees,
      } = updatedData;
      const discountCodeCodes: string[] =
        getUniqueDiscountCodeCodes(discountCodes);

      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_DISCOUNT_CODES',
        payload: discountCodes,
      });
      dispatch({
        type: 'UPDATE_DISCOUNT_CODE_CODES',
        payload: discountCodeCodes,
      });
      dispatch({
        type: 'UPDATE_DISCOUNT_CODE_ERRORS',
        payload: [],
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
    },
    []
  );

  const updateErrorMessages = useCallback(
    (errorType: string, errorMessages: string[]) => {
      if (errorType === 'discountCodes') {
        dispatch({
          type: 'UPDATE_DISCOUNT_CODE_ERRORS',
          payload: errorMessages,
        });
      }
    },
    []
  );

  const updateDataAfterPaymentMethodSelection = useCallback(
    (updatedData: CartDataFromUpdate) => {
      const { cartItems, dueToday, totalAmount, transactionFees } = updatedData;
      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
    },
    []
  );

  const updateDataAfterPaymentPlanSelection = useCallback(
    (updatedData: CartDataFromUpdate) => {
      const { cartItems, dueToday, totalAmount, transactionFees } = updatedData;
      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
    },
    []
  );

  useEffect(() => {
    if (checkoutSummary) {
      setCheckoutSummary(checkoutSummary);
    }
  }, [checkoutSummary, setCheckoutSummary]);

  const memoizedState = useMemo(
    () => ({
      ...state,
      updateCartItems,
      updateDataAfterCartItemDeletion,
      updateDataAfterDiscountCodesUpdate,
      updateDataAfterPaymentMethodSelection,
      updateDataAfterPaymentPlanSelection,
      updateErrorMessages,
      updateSelectedPaymentMethod,
      updateTemporaryPaymentMethod,
    }),
    [
      state,
      updateCartItems,
      updateDataAfterCartItemDeletion,
      updateDataAfterDiscountCodesUpdate,
      updateDataAfterPaymentMethodSelection,
      updateDataAfterPaymentPlanSelection,
      updateErrorMessages,
      updateSelectedPaymentMethod,
      updateTemporaryPaymentMethod,
    ]
  );

  return (
    <ConsolidatedCheckoutContext.Provider value={memoizedState}>
      {children}
    </ConsolidatedCheckoutContext.Provider>
  );
};

const useConsolidatedCheckout = () => {
  const {
    cartItems,
    cartUuid,
    currency,
    discountCodeCodes,
    discountCodes,
    dueToday,
    errorMessages,
    isLoading,
    selectedPaymentMethod,
    subtotal,
    totalAmount,
    transactionFees,
    storedPaymentMethods,
    temporaryPaymentMethod,
    updateCartItems,
    updateDataAfterCartItemDeletion,
    updateDataAfterDiscountCodesUpdate,
    updateDataAfterPaymentMethodSelection,
    updateDataAfterPaymentPlanSelection,
    updateErrorMessages,
    updateSelectedPaymentMethod,
    updateTemporaryPaymentMethod,
  } = useContext<ConsolidatedCheckoutData>(ConsolidatedCheckoutContext);
  const { subdomain } = getLAHostnameParts();
  const [
    updateCheckoutSummary,
    // { error: updateCheckoutError, isLoading: isUpdateCheckoutSummaryLoading },
  ] = useUpdateCheckoutSummaryBFFMutation();
  const [
    deleteCartItem,
    // { isError: hasDeleteCartItemError, isLoading: isDeletingCartItem },
  ] = useDeleteCartItemMutation();
  const { addMessage } = useContext(MessagingContext);

  async function handleCartItemDeletion(cartItemUuid: string) {
    // First connect to the API to delete the cart item
    await deleteCartItem({
      siteDomain: subdomain,
      cartItemId: cartItemUuid,
    })
      .unwrap()
      .then(() => {
        getCheckoutSummaryFollowingDeletion(cartItemUuid);
      })
      .catch((error) => {
        console.log('error' + JSON.stringify(error));
      });
  }

  function getCheckoutSummaryFollowingDeletion(deletedCartItemUuid: string) {
    // Delete the cart item locally before passing cartItems to the BFF
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.cartItemUuid !== deletedCartItemUuid
    );
    updateCheckoutSummary({
      combinedCartItems: updatedCartItems,
      discountCodes: discountCodeCodes,
      paymentMethod: selectedPaymentMethod?.paymentType || 'CARD',
      subdomain,
    })
      .unwrap()
      .then((checkoutSummaryData) => {
        updateDataAfterCartItemDeletion(checkoutSummaryData);
      })
      .catch((error) => {
        console.log('error' + JSON.stringify(error));
      });
  }

  async function handleDiscountCodeUpdateSubmission(
    discountCodeToUpdate: string,
    addOrRemove: string
  ) {
    // Early return if no cartItems in state
    if (!cartItems || cartItems.length === 0) {
      return;
    }
    if (
      addOrRemove === 'add' &&
      discountCodeCodes.includes(discountCodeToUpdate)
    ) {
      showDiscountCodeToast(
        discountCodeToUpdate,
        'duplicate' as DiscountCodeToastType
      );
    } else {
      await handleDiscountCodesUpdate(discountCodeToUpdate, addOrRemove);
    }
  }

  async function handleDiscountCodesUpdate(
    discountCodeToUpdate: string,
    addOrRemove: string
  ) {
    const paymentMethod: string = selectedPaymentMethod?.paymentType || 'CARD';
    const discountCodesToApply: string[] = discountCodes
      ? getUpdatedDiscountCodeCodes(
          discountCodes,
          discountCodeToUpdate,
          addOrRemove
        )
      : [];

    updateCheckoutSummary({
      combinedCartItems: cartItems,
      discountCodes: discountCodesToApply,
      paymentMethod,
      subdomain,
    })
      .unwrap()
      .then((response) => {
        if (
          response.messages?.some(
            (message: { level: 'INFO' | 'WARN'; message: string }) =>
              message.level === 'WARN' &&
              message.message.includes('Could not apply discount code')
          )
        ) {
          const errorMessageToDisplay = extractFormattedMessageFromError(
            response.messages
          );
          throw Error(errorMessageToDisplay, {
            cause: 'Warning from code validation response.',
          });
        }

        showDiscountCodeToast(
          discountCodeToUpdate,
          addOrRemove as DiscountCodeToastType
        );
        updateDataAfterDiscountCodesUpdate(response);
      })
      .catch((error) => {
        if (error.cause === 'Warning from code validation response.') {
          updateErrorMessages('discountCodes', [error.message]);
        } else {
          console.log('error' + JSON.stringify(error));
        }
      });
  }

  function showDiscountCodeToast(
    discountCodeName: string,
    updateType: DiscountCodeToastType
  ) {
    let message: string;
    let variant: ToastVariant;

    switch (updateType) {
      case 'add':
        message = 'has been successfully applied.';
        variant = 'success';
        break;
      case 'duplicate':
        message = 'has already been applied.';
        variant = 'info';
        break;
      case 'remove':
        message = 'has been successfully removed.';
        variant = 'success';
        break;
      default:
        message = 'was not applied';
        variant = 'info';
        break;
    }

    addMessage('toast', {
      description: `"${discountCodeName}" ${message}`,
      variant: variant,
    });
  }

  function handlePayLaterSelection(paymentPlanId: string, payLater: boolean) {
    const updatedCartItems = updatePayLaterInCartItemPaymentPlans(
      cartItems,
      paymentPlanId,
      payLater
    );
    updateCartItems(updatedCartItems);
  }

  async function handlePaymentMethodSelection(
    selectedPaymentMethod: PaymentMethod
  ) {
    const { subdomain } = getLAHostnameParts();
    if (!selectedPaymentMethod) {
      return;
    }

    updateSelectedPaymentMethod(selectedPaymentMethod);

    const paymentMethod: string = selectedPaymentMethod.paymentType;
    updateCheckoutSummary({
      combinedCartItems: cartItems,
      discountCodes: discountCodeCodes,
      paymentMethod,
      subdomain,
    })
      .unwrap()
      .then((checkoutSummaryData) => {
        updateDataAfterPaymentMethodSelection(checkoutSummaryData);
      });
  }

  async function handlePaymentPlanSelection(
    selectedCartItemUuid: string,
    updatedPaymentPlanSelection: PaymentPlanSummary
  ) {
    // Early return if no cartItems in state
    if (!cartItems || cartItems.length === 0) {
      return;
    }
    const { subdomain } = getLAHostnameParts();

    const updatedCartItems = updatePaymentPlansInCartItems(
      cartItems,
      selectedCartItemUuid,
      updatedPaymentPlanSelection
    );

    await updateCartItems(updatedCartItems);

    const paymentMethod: string = selectedPaymentMethod?.paymentType || 'CARD';
    updateCheckoutSummary({
      combinedCartItems: updatedCartItems,
      discountCodes: discountCodeCodes,
      paymentMethod,
      subdomain,
    })
      .unwrap()
      .then((checkoutSummaryData) => {
        updateDataAfterPaymentPlanSelection(checkoutSummaryData);
      });
  }

  function handleTemporaryPaymentMethodSelection(
    temporaryPaymentMethod: SingleUseCard
  ) {
    updateTemporaryPaymentMethod(temporaryPaymentMethod);
  }

  return {
    cartItems: cartItems,
    cartUuid: cartUuid,
    currency: currency,
    discountCodeNames: discountCodeCodes,
    discountCodes: discountCodes,
    dueToday: dueToday,
    errorMessages: errorMessages,
    isLoading: isLoading,
    selectedPaymentMethod: selectedPaymentMethod,
    subtotal: subtotal,
    temporaryPaymentMethod: temporaryPaymentMethod,
    totalAmount: totalAmount,
    transactionFees: transactionFees,
    storedPaymentMethods: storedPaymentMethods,
    handleCartItemDeletion: handleCartItemDeletion,
    handleDiscountCodeUpdateSubmission: handleDiscountCodeUpdateSubmission,
    handlePayLaterSelection: handlePayLaterSelection,
    handlePaymentMethodSelection: handlePaymentMethodSelection,
    handlePaymentPlanSelection: handlePaymentPlanSelection,
    handleTemporaryPaymentMethodSelection:
      handleTemporaryPaymentMethodSelection,
  };
};

export function updatePayLaterInCartItemPaymentPlans(
  cartItems: CombinedCartItemData[],
  ngPaymentPlanId: string,
  payLater: boolean
): CombinedCartItemData[] {
  // Create a deep copy of the cart items to avoid mutating the original
  const updatedCartItems = JSON.parse(
    JSON.stringify(cartItems)
  ) as CombinedCartItemData[];

  for (const cartItem of updatedCartItems) {
    // Update in paymentPlanSummaries
    if (cartItem.paymentPlanSummaries) {
      for (const planSummary of cartItem.paymentPlanSummaries) {
        if (planSummary.ngPaymentPlanId === ngPaymentPlanId) {
          planSummary.payLater = payLater;
        }
      }
    }
    // Update in selectedPaymentPlan if it matches
    if (cartItem.selectedPaymentPlan.ngPaymentPlanId === ngPaymentPlanId) {
      cartItem.selectedPaymentPlan.payLater = payLater;
    }
  }
  return updatedCartItems;
}

export function updateDiscountCodes(
  state: ConsolidatedCheckoutData,
  discountCode: DiscountCode,
  action: 'add' | 'remove'
): ConsolidatedCheckoutData {
  const { discountCodes = [] } = state;
  const newDiscountCodes =
    action === 'add'
      ? [...discountCodes, discountCode]
      : discountCodes.filter((code) => code.code !== discountCode.code);
  return { ...state, discountCodes: newDiscountCodes };
}

export function combineCartItems(
  state: ConsolidatedCheckoutData,
  newCartItems: CombinedCartItemData[]
): ConsolidatedCheckoutData {
  const { cartItems } = state;
  const updatedCartItems = newCartItems.map((cartItem) => {
    const existingCartItem = cartItems?.find(
      (existingCartItem) =>
        existingCartItem.cartItemUuid === cartItem.cartItemUuid
    );
    if (existingCartItem) {
      return {
        ...existingCartItem,
        ...cartItem,
      };
    } else {
      return cartItem;
    }
  });
  return { ...state, cartItems: updatedCartItems };
}

export function updatePaymentPlansInCartItems(
  cartItems: CombinedCartItemData[],
  selectedCartItemUuid: string,
  updatedPaymentPlanSelection: PaymentPlanSummary
) {
  const cartItemsToUpdate = [...cartItems];
  const cartItemToUpdate = cartItemsToUpdate.findIndex(
    (cartItem) => cartItem.cartItemUuid === selectedCartItemUuid
  );
  const updatedCartItem = {
    ...cartItemsToUpdate[cartItemToUpdate],
    paymentTerm: getPaymentTermFromPaymentPlan(updatedPaymentPlanSelection),
    selectedPaymentPlan: updatedPaymentPlanSelection,
  };
  cartItemsToUpdate.splice(cartItemToUpdate, 1, updatedCartItem);
  return cartItemsToUpdate;
}

export function getPaymentTermFromPaymentPlan(
  paymentPlan: PaymentPlanSummary
): PaymentTerm {
  return paymentPlan.installments && paymentPlan.installments.length > 0
    ? 'PAYMENT_PLAN'
    : 'FULL';
}

export function extractPaymentTermsFromCartItems(
  cartItems: CombinedCartItemData[]
) {
  return cartItems.map((cartItem) => {
    return {
      autopayRequired: cartItem.selectedPaymentPlan?.autopayRequired,
      cartItemUuid: cartItem.cartItemUuid,
      paymentTerm: cartItem.paymentTerm,
      paymentPlanId: cartItem.selectedPaymentPlan?.ngPaymentPlanId,
      priceAdjustment: cartItem.selectedPaymentPlan?.priceAdjustment,
    };
  });
}

export function getDefaultPaymentMethod(
  storedPaymentMethods: PaymentMethodInfo
) {
  const { paymentOptionsCount, storedCreditCards, storedBankAccounts } =
    storedPaymentMethods;
  if (paymentOptionsCount === 0) {
    return null;
  } else if (
    storedCreditCards.some(
      (storedCreditCard) => storedCreditCard.isPrimaryPaymentOption
    )
  ) {
    return storedCreditCards.find(
      (storedCreditCard) => storedCreditCard.isPrimaryPaymentOption
    );
  } else if (
    storedBankAccounts.some(
      (storedBankAccount) => storedBankAccount.isPrimaryPaymentOption
    )
  ) {
    return storedBankAccounts.find(
      (storedBankAccount) => storedBankAccount.isPrimaryPaymentOption
    );
  }
  return null;
}

export function getUpdatedDiscountCodeCodes(
  discountCodes: DiscountCode[],
  discountCodeToUpdate: string,
  addOrRemove: string
) {
  if (addOrRemove === 'add') {
    return [
      ...discountCodes.map((discountCode) => discountCode.code),
      discountCodeToUpdate,
    ];
  } else {
    return discountCodes
      .filter((discountCode) => discountCode.code !== discountCodeToUpdate)
      .map((discountCode) => discountCode.code);
  }
}

export function getUniqueDiscountCodeCodes(discountCodes: DiscountCode[]) {
  const discountCodeNames: string[] = [];
  for (const discountCode of discountCodes) {
    if (!discountCodeNames.includes(discountCode.code)) {
      discountCodeNames.push(discountCode.code);
    }
  }
  return discountCodeNames;
}

export function extractFormattedMessageFromError(
  messages: { level: 'INFO' | 'WARN'; message: string }[]
) {
  const warningMessage = messages.find((message) => message.level === 'WARN');
  if (warningMessage?.message) {
    const discountCode = extractDiscountCode(warningMessage.message);
    return `Could not apply ${discountCode} because it is invalid or it has expired.`;
  }
  return 'This code is invalid or expired.';
}

/**
 * Extracts a discount code from an error message string
 * @param errorMessage - The error message containing the discount code
 * @returns The extracted discount code or null if not found
 */
function extractDiscountCode(errorMessage: string): string | null {
  // Using regex to match the pattern "discount code X because"
  const regex = /could not apply discount code\s+(\S+)\s+because/i;
  const match = regex.exec(errorMessage);

  // Return the captured group (the discount code) if found, otherwise null
  return match ? match[1] : null;
}

export {
  ConsolidatedCheckoutContext,
  ConsolidatedCheckoutProvider,
  useConsolidatedCheckout,
};
