/*ProgramRegistrationsTable */
import {
  CartItemOptionsSubprogramsDetail,
  CartItemOptionsSubprogramsRegistration,
} from '../Checkout.types';
import styles from './OrderSummaryCard.module.scss';

const DIVISION_TABLE_HEADER: string[] = ['Division', 'Team', 'Total price'];

export default function ProgramRegistrationsTable({
  subprograms,
}: Readonly<{
  subprograms: CartItemOptionsSubprogramsDetail[];
}>) {
  return (
    <table
      className={styles.programRegistrationsTable}
      aria-label="Program Registrations"
    >
      <ProgramRegistrationsTableHeader headers={DIVISION_TABLE_HEADER} />
      <ProgramRegistrationsTableBody subprograms={subprograms} />
    </table>
  );
}
/* */

/*ProgramRegistrationsTableHeader */
function ProgramRegistrationsTableHeader({
  headers,
}: Readonly<{ headers: string[] }>) {
  return (
    <thead>
      <tr>
        {headers.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
}
/* */

/*ProgramRegistrationsTableBody */
function ProgramRegistrationsTableBody({
  subprograms,
}: Readonly<{
  subprograms: CartItemOptionsSubprogramsDetail[];
}>) {
  return (
    <tbody>
      {subprograms.map(
        ({ subprogramId, subprogramName, basePrice, registrations }) => (
          <ProgramRegistrationsTableRows
            key={subprogramId}
            subprogramName={subprogramName}
            teams={registrations}
            totalCost={basePrice}
          />
        )
      )}
    </tbody>
  );
}
/* */

/*ProgramRegistrationsTableRows */
function ProgramRegistrationsTableRows({
  subprogramName,
  teams,
  totalCost,
}: Readonly<{
  subprogramName: string;
  teams: CartItemOptionsSubprogramsRegistration[];
  totalCost: string;
}>) {
  return (
    <>
      {teams.map(({ registrationId, teamName }) => (
        <tr key={registrationId}>
          <td>{subprogramName}</td>
          <td>{teamName}</td>
          <td>${totalCost}</td>
        </tr>
      ))}
    </>
  );
}
/* */
