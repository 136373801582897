/* Apply Discount Codes Section */
import { ChangeEvent, useState } from 'react';
import { Tag as TagIcon } from '@phosphor-icons/react';
import { Button, TextInput } from '@la/ds-ui-components';
import { getUniqueStrings } from '@la/utilities';
import Tag from 'components/Tag/Tag';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import styles from './DiscountCodesCard.module.scss';

export default function DiscountCodesCard() {
  const { errorMessages, handleDiscountCodeUpdateSubmission } =
    useConsolidatedCheckout();
  const [discountCode, setDiscountCode] = useState('');

  async function onUpdateDiscountCode(
    discountCodeName: string,
    updateType: 'add' | 'remove'
  ) {
    await handleDiscountCodeUpdateSubmission(discountCodeName, updateType);
    setDiscountCode('');
  }

  function onDiscountCodeChange(evt: ChangeEvent<HTMLInputElement>) {
    // setDiscountCodeError('');
    setDiscountCode(evt.target.value);
  }

  return (
    <div className={styles.applyDiscountCodesSection}>
      <div className={styles.applyDiscountCodeSectionTitle}>
        Apply discount codes
      </div>
      <div className={styles.textInputWithButton}>
        <TextInput
          hasError={errorMessages.discountCodes.length > 0}
          errorMessage={errorMessages.discountCodes[0]}
          type="text"
          // disabled={isLoading}
          id="apply-discount-codes"
          size="medium"
          value={discountCode}
          placeholder="Enter discount code"
          onChange={onDiscountCodeChange}
        />
        <Button
          onClick={() => onUpdateDiscountCode(discountCode, 'add')}
          variant="outline"
          size="medium"
          disabled={
            discountCode.length === 0 /* || isLoading  ||
            !!errorMessages.discountCodes[0] */
          }
        >
          Apply
        </Button>
      </div>
      <DiscountCodeTags removeTagHandler={onUpdateDiscountCode} />
    </div>
  );
}

export function DiscountCodeTags({
  removeTagHandler,
}: Readonly<{
  removeTagHandler: (
    discountCodeName: string,
    updateType: 'add' | 'remove'
  ) => void;
}>) {
  const { discountCodeNames } = useConsolidatedCheckout();
  const uniqueDiscountCodeNames = getUniqueStrings(discountCodeNames);
  const discountCodeTags = uniqueDiscountCodeNames.map(
    (discountCodeName, idx) => {
      return (
        <Tag
          closeHandler={() => removeTagHandler(discountCodeName, 'remove')}
          icon={<TagIcon width="16px" height="16px" weight="bold" />}
          label={discountCodeName}
          key={`${discountCodeName}-${idx}`}
        />
      );
    }
  );

  return (
    <div className={styles.discountCodesContainer}>{discountCodeTags}</div>
  );
}
