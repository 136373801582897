import { capitalizeFirstLetter } from '@la/utilities';
import styles from './OrderSummaryCard.module.scss';

export type CartItemHeaderProps = {
  adjustedPrice: string | null;
  cartItemName?: string;
  iconAltText?: string;
  iconUrl?: string;
  originalPrice?: string;
  subtype?: 'staff' | 'team' | 'player';
  type?: 'tournament';
};

/* OrderSummaryCardHeader*/
export default function OrderSummaryCardHeader({
  adjustedPrice,
  cartItemName,
  iconAltText,
  iconUrl,
  originalPrice,
  subtype = 'team',
  type = 'tournament',
}: Readonly<CartItemHeaderProps>) {
  return (
    <div className={styles.orderSummaryCardHeader}>
      {iconUrl ? (
        <img
          className={styles.orderSummaryCardLogo}
          src={iconUrl}
          alt={`${iconAltText} logo`}
        />
      ) : null}
      <div className={styles.textColumn}>
        <div className={styles.topRow}>
          <div className={styles.itemType}>{capitalizeFirstLetter(type)}</div>
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.bottomRowLeft}>
            {cartItemName} &bull; {capitalizeFirstLetter(subtype)}
          </div>
          <div className={styles.bottomRowRight}>
            {
              <PriceInformation
                adjustedPrice={adjustedPrice}
                originalPrice={originalPrice}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
/* */

export function PriceInformation({
  adjustedPrice,
  originalPrice,
}: Pick<CartItemHeaderProps, 'adjustedPrice' | 'originalPrice'>) {
  let priceInformation = null;

  if (adjustedPrice && originalPrice && adjustedPrice !== originalPrice) {
    priceInformation = (
      <>
        <span className={styles.originalPrice}>{originalPrice}</span>
        <span className={styles.currentPrice}>{adjustedPrice}</span>
      </>
    );
  } else if (
    (adjustedPrice && !originalPrice) ||
    adjustedPrice === originalPrice
  ) {
    priceInformation = (
      <span className={styles.currentPrice}>{adjustedPrice}</span>
    );
  } else if (!adjustedPrice && originalPrice) {
    priceInformation = (
      <span className={styles.currentPrice}>{originalPrice}</span>
    );
  }

  return priceInformation;
}
