var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext, } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileInput, Form, InputErrorMessage, InputLabel, Select, TextArea, TextInput, } from '@la/ds-ui-components';
import { CheckboxGroupFormComponent } from './CheckboxGroupFormComponent/CheckboxGroupFormComponent';
import { chunkArray } from './utils/layout';
import { createValidationSchema } from './utils/validation';
import * as S from './CustomFieldsForm.styles';
export { createValidationSchema } from './utils/validation';
function CustomFieldsForm({ columns = 2, formFields, gap, id, onSubmit, }) {
    const validation = useMemo(() => createValidationSchema(id), [id]);
    const methods = useForm({
        defaultValues: {
            [id]: formFields,
        },
        resolver: yupResolver(validation),
    });
    const { formState: { isSubmitting }, handleSubmit, } = methods;
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsx(Form, Object.assign({ id: id, noValidate: true, onSubmit: handleSubmit((values) => {
                if (!isSubmitting) {
                    onSubmit(values);
                }
            }) }, { children: _jsx(CustomFields, { columns: columns, gap: gap, name: id }) })) })));
}
/**
 * Renders a set of custom fields with two inputs per row.
 * @param name The name of the form field that represents the array of
 * fields. Should match the name used in the containing useForm() component.
 */
function CustomFields({ columns = 2, gap, name, inputSize = 'medium', }) {
    const { control, formState: { errors, submitCount }, register, setValue, watch, } = useFormContext();
    const { fields, update } = useFieldArray({
        control,
        name,
    });
    const [customFileErrors, setCustomFileErrors] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const fieldGroups = chunkArray(fields, columns);
    return (_jsx(_Fragment, { children: fieldGroups.map((fieldGroup, fieldGroupIndex) => (_jsx(S.InputGroupContainer, Object.assign({ "$gap": gap }, { children: fieldGroup.map((field, fieldIndex) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                // Multiply `fieldGroupIndex` by `columns` so that the resulting index corresponds
                // to the index of the field from the `useFieldArray`'s `fields` array
                const index = fieldGroupIndex * columns + fieldIndex;
                const { id, name: fieldName, propertyDefinitionId, type, usageLevel, } = field;
                const error = (_c = (_b = (_a = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.message;
                const required = usageLevel === 'REQUIRED';
                const hasFileError = uploadedFiles.find((fileId) => fileId === id)
                    ? false
                    : Boolean((_d = !!error) !== null && _d !== void 0 ? _d : customFileErrors[id]);
                switch (type) {
                    case 'TEXT_BOX':
                        return (_jsx(S.TextInputContainer, Object.assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: _createElement(TextInput, Object.assign({}, register(`${name}.${index}.value`), { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, required: required, size: inputSize, value: watch(`${name}.${index}.value`) })) }), propertyDefinitionId));
                    case 'NUMERIC':
                        const _j = register(`${name}.${index}.value`), { min: _min, max: _max } = _j, numericRegisterProps = __rest(_j, ["min", "max"]);
                        return (_jsx(S.TextInputContainer, Object.assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: _createElement(TextInput, Object.assign({}, numericRegisterProps, { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, onChange: (e) => {
                                    setValue(`${name}.${index}.value`, e.target.value.toString());
                                }, required: required, size: inputSize, value: watch(`${name}.${index}.value`), type: "number", forceLeftAlignment: true })) }), propertyDefinitionId));
                    case 'PICK_LIST':
                        const { items } = field;
                        const currentItemId = watch(`${name}.${index}.value`);
                        const currentItem = items.find((item) => item.itemId === currentItemId);
                        const currentValue = (currentItem === null || currentItem === void 0 ? void 0 : currentItem.value) || (currentItem === null || currentItem === void 0 ? void 0 : currentItem.name);
                        return (_jsx(S.InputContainer, Object.assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: _createElement(Select, Object.assign({}, register(`${name}.${index}.value`), { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, onChange: (value) => {
                                    var _a;
                                    const itemId = (_a = items.find((item) => item.value === value || item.name === value)) === null || _a === void 0 ? void 0 : _a.itemId;
                                    setValue(`${name}.${index}.value`, itemId, {
                                        shouldValidate: submitCount > 0,
                                    });
                                }, options: items.map((item) => ({
                                    label: item.name,
                                    value: item.value ? item.value : item.name,
                                })), placeholder: `Select an option`, required: required, size: inputSize, value: currentValue })) }), propertyDefinitionId));
                    case 'TEXT_AREA':
                        return (_jsx(S.InputContainer, Object.assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: _createElement(TextArea, Object.assign({}, register(`${name}.${index}.value`), { errorMessage: error === null || error === void 0 ? void 0 : error.toString(), hasError: !!error, key: id, id: id, label: fieldName, required: required, size: inputSize, value: watch(`${name}.${index}.value`) })) }), propertyDefinitionId));
                    case 'MULTIPLE_CHECKBOXES':
                        const _k = register(`${name}.${index}.value`), { ref: _ref } = _k, checkboxesRegisterProps = __rest(_k, ["ref"]);
                        const options = field.items.map((item) => ({
                            label: item.name,
                            value: item.itemId.toString(),
                            selected: false,
                        }));
                        return (_jsxs(S.InputContainer, Object.assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: [_createElement(CheckboxGroupFormComponent, Object.assign({}, checkboxesRegisterProps, { checkboxGroupData: {
                                        hasError: !!error,
                                        isRequired: required,
                                        name: fieldName,
                                        label: fieldName,
                                        value: (_f = (_e = field.value) === null || _e === void 0 ? void 0 : _e.join(',')) !== null && _f !== void 0 ? _f : '',
                                        options,
                                        type: 'MULTIPLE_CHECKBOXES',
                                    }, key: id, onChangeValue: (value) => {
                                        // Need to use `update` instead of `setValue` here because
                                        // update performs an unmount and remount and we need it
                                        // in order to appropriately update the value.
                                        update(index, Object.assign(Object.assign({}, field), { value: value
                                                .split(',')
                                                .map((v) => parseInt(v))
                                                .filter((v) => !!v) }));
                                    }, value: (_g = watch(`${name}.${index}.value`)) === null || _g === void 0 ? void 0 : _g.join(',') })), error ? (_jsx(InputErrorMessage, { children: error.toString() })) : null] }), propertyDefinitionId));
                    case 'FILE_UPLOAD':
                        return (_jsxs(S.InputContainer, Object.assign({ "$columnSpan": columns === 1 ? 2 : 1 }, { children: [_jsx(InputLabel, Object.assign({ htmlFor: id, inputState: hasFileError ? 'error' : 'default', required: required }, { children: fieldName })), _jsx(FileInput, { accept: ['.jpg', '.png', '.jpeg', '.pdf'], alignment: "center", errorMessage: (_h = customFileErrors[id]) !== null && _h !== void 0 ? _h : error === null || error === void 0 ? void 0 : error.toString(), hasError: hasFileError, id: id, infoMessage: "Max upload size is 500kb. Supported file types are .jpg, .png, and .pdf.", multiple: false, onDismiss: () => {
                                        setValue(`${name}.${index}.value`, undefined);
                                        setUploadedFiles((prev) => prev.filter((fileId) => fileId !== id));
                                    }, onError: ({ message }) => setCustomFileErrors((prev) => (Object.assign(Object.assign({}, prev), { [id]: message }))), initialValue: fields
                                        .filter((field) => field.type === 'FILE_UPLOAD' &&
                                        field.value &&
                                        field.id === id)
                                        .map((field) => {
                                        return field.value.name;
                                    }), onUpload: (file) => {
                                        if (file) {
                                            setValue(`${name}.${index}.value`, file);
                                            setUploadedFiles((prev) => prev.concat(id));
                                        }
                                    } }, id)] }), propertyDefinitionId));
                    default:
                        return null;
                }
            }) }), `${name}-${fieldGroupIndex}`))) }));
}
export { CustomFieldsForm, CustomFields };
