import styled from 'styled-components';
export const WizardSummary = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid var(--blue-grey-400);
  border-radius: 4px;
  padding: 16px;
`;
export const SummaryPlaceholderContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;

  & > svg {
    margin-top: 4px;
  }
`;
export const PlaceholderTipContainer = styled.div(({ theme: ThemedComponent }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
    };
});
