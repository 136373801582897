import { useEffect } from 'react';
import { Button } from '@la/ds-ui-components';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useAppSelector } from 'redux/store';
import {
  ActivityFormWrapperScreens,
  useActivityForm,
} from '../ActivityContext';
import { updateActivity } from '../apis/updateActivity';
import { updateAllDayActivity } from '../apis/updateAllDayActivity';
import { Activity, UpdateActivityRequestBody } from '../openapi-client/models';
import { REQUEST_DELAY } from '../utils/ActivityForm.utils';
import { getUpdateTimesRequestBody } from '../utils/ActivityFormSubmit.utils';
import * as S from './CancelActivity.styles';

enum ActivityState {
  Unscheduled = 'unscheduled',
  Scheduled = 'scheduled',
  Canceled = 'canceled',
}

export function UncancelActivity({
  uncancelActivity,
  handleSetActivityFormTitle,
  handleSetCurrentScreen,
}: {
  uncancelActivity?: Activity;
  handleSetActivityFormTitle: (arg: string) => void;
  handleSetCurrentScreen: (arg: ActivityFormWrapperScreens) => void;
}) {
  const { siteId } = useAppSelector(getSiteIdentityData);
  const { handleSetSubmittedForm } = useActivityForm();

  useEffect(() => {
    handleSetActivityFormTitle('Uncancel Activity');
  }, [handleSetActivityFormTitle]);

  const handleDiscardChanges = () => {
    handleSetCurrentScreen(ActivityFormWrapperScreens.ActivityForm);
  };

  const handleUncancelActivity = () => {
    if (!uncancelActivity) {
      return;
    }

    handleSetCurrentScreen(ActivityFormWrapperScreens.Loading);

    if (uncancelActivity.allDay) {
      updateAllDayActivity({
        activityId: uncancelActivity.id,
        siteId: Number(siteId),
        updateAllDayActivityRequestBody: {
          state: ActivityState.Scheduled,
        },
      })
        .then((data) => {
          setTimeout(() => {
            handleSetSubmittedForm({
              activity: [data],
              submitType: 'uncanceled',
            });
            handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
          }, REQUEST_DELAY);
        })
        .catch((e) => {
          const errorMessage = typeof e === 'string' ? e : e.message;
          handleSetSubmittedForm({ errorMessage });
          handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
        });
    } else {
      const updateActivityTimes = getUpdateTimesRequestBody(
        uncancelActivity
      ) as UpdateActivityRequestBody;
      updateActivity({
        activityId: uncancelActivity.id,
        updateActivityRequestBody: {
          siteId: Number(siteId),
          state: ActivityState.Scheduled,
          ...updateActivityTimes,
        },
      })
        .then((data) => {
          setTimeout(() => {
            handleSetSubmittedForm({
              activity: [data],
              submitType: 'uncanceled',
            });
            handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
          }, REQUEST_DELAY);
        })
        .catch((e) => {
          const errorMessage = typeof e === 'string' ? e : e.message;
          handleSetSubmittedForm({ errorMessage });
          handleSetCurrentScreen(ActivityFormWrapperScreens.Submitted);
        });
    }
  };

  return (
    <>
      <S.ContentBody>Would you like to uncancel this activity?</S.ContentBody>
      <S.FormButtons>
        <Button onClick={handleDiscardChanges} variant="text" size="large">
          Discard Changes
        </Button>
        <Button variant="primary" size="large" onClick={handleUncancelActivity}>
          Undo Cancellation
        </Button>
      </S.FormButtons>
    </>
  );
}
