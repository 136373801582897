import { useState } from 'react';
import { Button, Modal, Select, Typography } from '@la/ds-ui-components';
import { Staff, StaffRole } from '@la/types';
import * as S from './AssignStaffRolesModal.styles';

export const SAVE_BUTTON_LABEL = 'Save role assignments';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const SELECT_ROLE_ERROR = 'Select a role for the rollover staff';

export type AssignStaffRolesModalProps = {
  /**
   * Whether or not the team the staff member is being rolled to
   * is a part of the same site they are from. If so, we will compare
   * the role ids, rather than just the name of the role.
   */
  hasSameSiteRoles?: boolean;
  /**
   * Triggered when user clicks the "Cancel" button.
   */
  onCancel: () => void;
  /**
   * Triggered when the modal's open state changes.
   */
  onOpenChange: () => void;
  /**
   * Triggered when the staff role(s) are changed.
   * @param staff Staff with changes roles.
   */
  onStaffRollover: (staff: Staff[]) => void;
  /**
   * Whether or not the modal should be rendered.
   */
  open: boolean;
  /**
   * The staff from the roster.
   */
  staffRoster: Staff[];
  /**
   * The available roles to be selected from.
   */
  staffRoles: StaffRole[];
};

export const AssignStaffRolesModal = ({
  hasSameSiteRoles = false,
  onCancel,
  onOpenChange,
  onStaffRollover,
  open,
  staffRoster,
  staffRoles,
}: AssignStaffRolesModalProps) => {
  const [showError, setShowError] = useState(false);
  const [assignedStaff, setAssignedStaff] = useState<Staff[]>(
    mapDefaultStaffRoles(staffRoster, staffRoles, hasSameSiteRoles)
  );

  const rolesOptions = staffRoles.map(({ id, role }) => ({
    label: role,
    value: id.toString(),
  }));

  const onSaveRoleAssignments = (): void => {
    const hasEmptyRole = assignedStaff.some(
      (staff) => staff.roles.length === 0
    );

    if (hasEmptyRole) {
      setShowError(true);
      return;
    }

    onStaffRollover(assignedStaff);
  };

  const onRoleSelect = (roleId: string, staffId: number): void => {
    setAssignedStaff((currentStaff) =>
      currentStaff.map((staff) => {
        if (staff.registeredUserId !== staffId) {
          return staff;
        }

        const name =
          staffRoles.find((r) => r.id === parseInt(roleId))?.role ?? '';
        return {
          ...staff,
          roles: [{ roleId: parseInt(roleId), name }],
        };
      })
    );
  };

  return (
    <Modal
      hideClose
      open={open}
      onOpenChange={onOpenChange}
      primaryAction={
        <Button size="large" onClick={onSaveRoleAssignments}>
          {SAVE_BUTTON_LABEL}
        </Button>
      }
      size="small"
      tertiaryAction={
        <Button variant="text" onClick={onCancel}>
          {CANCEL_BUTTON_LABEL}
        </Button>
      }
      title="Assign staff roles"
    >
      <Typography variant="ui" size="large" weight="bold">
        Assign staff roles for your new roster.
      </Typography>
      <S.StaffRosterContainer>
        {assignedStaff.map((staff) => (
          <S.StaffContainer key={`assign-staff-role-${staff.registeredUserId}`}>
            <Typography variant="ui" size="large">
              {staff.name}
            </Typography>
            <S.StaffSelectContainer>
              <Select
                defaultValue={getDefaultStaffRole(
                  staff,
                  staffRoles,
                  hasSameSiteRoles
                )?.toString()}
                hidePlaceholder
                options={rolesOptions}
                placeholder="Select a role"
                id={`${staff.registeredUserId}-select-role`}
                value={staff.roles[0]?.roleId.toString()}
                width="100%"
                hasError={showError && !staff.roles[0]?.roleId}
                errorMessage={SELECT_ROLE_ERROR}
                onChange={(roleId) =>
                  onRoleSelect(roleId, staff.registeredUserId)
                }
              />
            </S.StaffSelectContainer>
          </S.StaffContainer>
        ))}
      </S.StaffRosterContainer>
    </Modal>
  );
};

function mapDefaultStaffRoles(
  staffRoster: Staff[],
  staffRoles: StaffRole[],
  hasSameSiteRoles: boolean
): Staff[] {
  return staffRoster.map((staff) => {
    const defaultRoleId = getDefaultStaffRole(
      staff,
      staffRoles,
      hasSameSiteRoles
    );
    const defaultRole = staffRoles.find((role) => role.id === defaultRoleId);
    return {
      ...staff,
      roles: defaultRole
        ? [{ name: defaultRole.role, roleId: defaultRole.id }]
        : [],
    };
  });
}

function getDefaultStaffRole(
  staff: Staff,
  staffRoles: StaffRole[],
  hasSameSiteRoles: boolean
): number | undefined {
  if (!staff.roles.length || staff.roles.length > 1) {
    return;
  }

  const role = staff.roles[0];
  const sameIdStaffRole = staffRoles.find((r) => r.id === role.roleId);
  if (hasSameSiteRoles && sameIdStaffRole) {
    return sameIdStaffRole.id;
  }

  const sameNameStaffRole = staffRoles.find(
    (r) => r.role.trim().toLowerCase() === role.name.trim().toLowerCase()
  );
  if (sameNameStaffRole) {
    return sameNameStaffRole.id;
  }
}
