import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { components } from 'react-select';
import Select from 'react-select/async';
import { debounce } from 'lodash';
import { InfoIcon, InputLabel, Typography, generateTheme, } from '@la/ds-ui-components';
import { searchMembers } from '@la/services';
import { TeamRegistrationContext } from '../../tournaments/registration/TeamRegistration/TeamRegistrationContext';
import * as S from './Typeahead.styles';
const memberToOptions = (members) => {
    if (!members) {
        return [];
    }
    return members.map((member) => ({
        value: member,
        label: `${member.firstName} ${member.lastName}`,
    }));
};
const theme = generateTheme();
const { blueGrey, green, red } = theme.foundational;
const borderColor = {
    default: blueGrey[400],
    hover: blueGrey[600],
    focus: green[600],
    error: red[500],
    readonly: blueGrey[50],
    disabled: blueGrey[200],
};
const selectStyles = {
    control: (baseStyles, state) => (Object.assign(Object.assign({}, baseStyles), { borderColor: state.isFocused ? borderColor.focus : borderColor.default, height: 40, width: '100%' })),
    option: (baseStyles) => (Object.assign(Object.assign(Object.assign(Object.assign({}, baseStyles), { cursor: 'pointer', display: 'flex', alignItems: 'center', flex: 1, outline: 'none', fontStyle: 'normal' }), theme.typography.ui['medium']), { '&[data-highlighted]': {
            fontStyle: 'bold',
            backgroundColor: theme.palette.foundational.blueGrey[50],
        }, '&[data-disabled]': {
            color: theme.palette.foundational.blueGrey[250],
            pointerEvents: 'none',
        } })),
    placeholder: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { fontStyle: 'italic' })),
    menuPortal: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { zIndex: 9999 })),
    menu: (baseStyles) => (Object.assign(Object.assign({}, baseStyles), { marginTop: 1, boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.20), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)', borderRadius: '0px 0px 4px 4px', borderRight: '1px solid', borderBottom: '1px solid ', borderLeft: '1px solid', borderColor: 'var(--blue-grey-400)' })),
};
const CustomOption = (props) => {
    return (_jsx(components.Option, Object.assign({}, props, { children: _jsxs(S.OptionContainer, { children: [_jsx(S.OptionLabel, Object.assign({ variant: "ui", size: "large" }, { children: props.data.label })), _jsx(S.OptionEmail, Object.assign({ variant: "ui", size: "small" }, { children: props.data.value.email }))] }) })));
};
export const Typeahead = () => {
    const { setPayer, state } = useContext(TeamRegistrationContext);
    const { siteId } = useParams();
    const searchMemberOptions = (inputValue, callback) => {
        searchMembers({
            siteId,
            search: inputValue,
        }).then((members) => {
            const options = memberToOptions(members);
            callback(options);
        });
    };
    const debouncedSearchMemberOptions = debounce(searchMemberOptions, 1000);
    const onMemberSelect = (option) => {
        setPayer(option.value);
    };
    const defaultValue = state.payer
        ? {
            value: state.payer,
            label: `${state.payer.firstName} ${state.payer.lastName}`,
        }
        : undefined;
    return (_jsxs(S.TypeaheadContainer, { children: [_jsx(InputLabel, Object.assign({ required: true, htmlFor: "select-payer", inputState: 'default' }, { children: "Select Payer" })), _jsx(Select, { isClearable: false, isSearchable: true, name: "select-payer", id: "select-payer", loadOptions: debouncedSearchMemberOptions, placeholder: "Select a payer...", menuPortalTarget: document.body, styles: selectStyles, onChange: onMemberSelect, defaultValue: defaultValue, components: {
                    Option: CustomOption,
                } }), _jsxs(S.TypeaheadHelperText, { children: [_jsx(InfoIcon, { variant: "regular", fill: "#78909C", size: "xl" }), _jsx(Typography, Object.assign({ variant: "ui", size: "large" }, { children: "Enter at least 3 characters to begin search." }))] })] }));
};
