import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, BaseColors } from '@la/ds-ui-components';
import { AccountInfoContext } from 'lib/apis/getAccountInfo';
import useGetRoutePaths from 'lib/hooks/useGetRoutePaths';
import {
  injectHeapScript,
  parseHTML,
  removeHeapScript,
} from 'lib/utils/analytics';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { setSiteInfo } from 'redux/coreSlice';
import { useGetAccountInfoQuery } from 'redux/services/accountApi';
import {
  useGetSiteInfoBySubdomainQuery,
  useGetSiteThemeColorsQuery,
  useGetSiteTrackingSettingsQuery,
} from 'redux/services/siteInfo';
import { useAppDispatch } from 'redux/store';
import './App.css';
import { CheckoutInfoProvider } from './lib/context/CheckoutInfoContext';

const fallbackThemeColors: BaseColors = {
  primary: '#37474F',
  secondary: '#007A00',
};

export default function App() {
  const subDomain = getLAHostnameParts().subdomain;

  const { data: siteInfoData } = useGetSiteInfoBySubdomainQuery(subDomain);

  const { data: accountInfo } = useGetAccountInfoQuery(
    { context: AccountInfoContext.Admin, siteId: siteInfoData?.siteId ?? '' },
    {
      skip: !siteInfoData?.siteId,
    }
  );

  const { data: siteTrackingSettings } = useGetSiteTrackingSettingsQuery(
    { siteId: siteInfoData?.siteId ? parseInt(siteInfoData.siteId) : -1 },
    { skip: !siteInfoData?.siteId }
  );

  const { data: themeColorsData, isFetching: isThemeColorsFetching } =
    useGetSiteThemeColorsQuery(siteInfoData?.siteId, {
      skip: !siteInfoData?.siteId,
    });
  const dispatch = useAppDispatch();

  const [themeColors, setThemeColors] = useState<BaseColors>();

  const routePaths = useGetRoutePaths();

  useEffect(() => {
    if (accountInfo) {
      injectHeapScript(accountInfo);
    }

    return () => {
      removeHeapScript();
    };
  }, [accountInfo]);

  useEffect(() => {
    if (themeColorsData) {
      setThemeColors({
        primary: themeColorsData.theme.primaryColor,
        secondary: themeColorsData.theme.secondaryColor,
      });
    }
  }, [themeColorsData]);

  useEffect(() => {
    if (siteTrackingSettings) {
      if (siteTrackingSettings.pixelTrackingHeader) {
        document.head.appendChild(
          parseHTML(siteTrackingSettings.pixelTrackingHeader)
        );
      }

      if (siteTrackingSettings.pixelTrackingBody) {
        document.body.appendChild(
          parseHTML(siteTrackingSettings.pixelTrackingBody)
        );
      }
    }
  }, [siteTrackingSettings]);

  useEffect(() => {
    function updateStoreWithSiteInfo(
      logoHref: string,
      logoImageSrc: string,
      siteId: string,
      siteName: string
    ) {
      dispatch(
        setSiteInfo({
          logoHref: logoHref,
          logoImageSrc: logoImageSrc,
          siteId: siteId,
          siteName: siteName,
        })
      );
    }

    if (siteInfoData) {
      const { logoHref, logoImageSrc, siteId, siteName } = siteInfoData;
      updateStoreWithSiteInfo(logoHref, logoImageSrc, siteId, siteName);
    }
  }, [dispatch, siteInfoData]);

  return siteInfoData && !isThemeColorsFetching ? (
    <ThemeProvider baseColors={themeColors || fallbackThemeColors}>
      <CheckoutInfoProvider>
        <RouterProvider router={routePaths} />
      </CheckoutInfoProvider>
    </ThemeProvider>
  ) : null;
}
