import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Tournament } from '@la/types';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetTournamentQuery } from 'redux/services/tournamentApi';

type UseTournamentArgs = {
  /**
   * The tournament id
   */
  id: string;
};

export function useTournament({ id }: UseTournamentArgs): {
  tournament: Tournament | undefined;
  isLoading: boolean;
  error?: FetchBaseQueryError | SerializedError;
} {
  const { subdomain } = getLAHostnameParts();
  const {
    data: tournament,
    isLoading,
    error,
  } = useGetTournamentQuery({
    siteDomain: subdomain,
    tournamentId: id,
  });

  return { tournament: tournament as any, isLoading, error };
}
