import { Tooltip, TooltipProvider } from '@la/ds-ui-components';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import * as S from '../EditMobileAppAccess.styles';

export default function MissingStoredPaymentCard({
  hasStoredPayment,
}: {
  hasStoredPayment: boolean;
}) {
  if (!hasStoredPayment) {
    return (
      <ErrorCard
        message={
          <>
            <S.InlineTypography size="medium" variant="ui">
              <div>
                You must have a stored payment method on file for you to have
                access to athlete mobile app access features.{' '}
                <a href="/addEditStoredPayments?redirectToMobileAppAccess=true">
                  Add your stored payment method here
                </a>{' '}
                before this banner will disappear.&nbsp;
              </div>
              <TooltipProvider>
                <Tooltip content="There are no charges associated with this feature, your stored payment method is required to verify your identity and age." />
              </TooltipProvider>
            </S.InlineTypography>
          </>
        }
      />
    );
  }
  return <></>;
}
