import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalProps,
  ModalTertiaryActionButton,
} from '@la/ds-ui-components';

export const DISCARD_CHANGES_MODAL_TITLE = 'Discard changes?';
export const DISCARD_CHANGES_LABEL = 'Discard changes';

export type DiscardChangesModalProps = Pick<
  ModalProps,
  'onOpenChange' | 'open'
> & {
  onDiscardChangesClick: () => void;
};

export function DiscardChangesModal({
  onDiscardChangesClick,
  onOpenChange,
  open,
}: Readonly<DiscardChangesModalProps>) {
  return (
    <Modal
      onOpenChange={onOpenChange}
      open={open}
      primaryAction={
        <ModalPrimaryActionButton onClick={onDiscardChangesClick}>
          {DISCARD_CHANGES_LABEL}
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalClose>
          <ModalTertiaryActionButton>
            Continue editing
          </ModalTertiaryActionButton>
        </ModalClose>
      }
      title={DISCARD_CHANGES_MODAL_TITLE}
    >
      If you leave this page, any changes you have made will be discarded.{' '}
      <strong>Are you sure you want to leave?</strong>
    </Modal>
  );
}
