import { ReactNode, useCallback, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import styles from './SecondaryNavigationBar.module.scss';
import * as S from './SecondaryNavigationBar.styles';

type Link = {
  label: string;
  newTab?: boolean;
  to: string;
};

enum ScrollPosition {
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
}

export type SecondaryNavigationBarProps = {
  hasVisibleTeams: boolean;
  registerSection?: ReactNode;
  tournamentId: string;
};

export function SecondaryNavigationBar({
  hasVisibleTeams,
  registerSection,
  tournamentId,
}: SecondaryNavigationBarProps) {
  const { tournamentDetailsRedesign } = useFlags();

  const isTabletPortraitDown = useMediaQuery(
    breakpointQueries.tabletPortraitDown
  );

  const bar = useRef<HTMLDivElement>(null);
  const leftScrollButton = useRef<HTMLButtonElement>(null);
  const rightScrollButton = useRef<HTMLButtonElement>(null);
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>(
    ScrollPosition.Left
  );

  const viewWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const getMaxScrollLeft = useCallback((): number => {
    if (bar.current) {
      const barWidth = bar.current.scrollWidth;
      return barWidth - viewWidth;
    }
    return 0;
  }, [viewWidth]);

  const updateScrollPosition = (scrollLeft: number): void => {
    const maxScrollLeft = getMaxScrollLeft();
    if (scrollLeft < 0) {
      setScrollPosition(ScrollPosition.Left);
    } else if (scrollLeft > maxScrollLeft) {
      setScrollPosition(ScrollPosition.Right);
    } else {
      setScrollPosition(ScrollPosition.Middle);
    }
  };

  const scrollBar = (direction: 'left' | 'right'): void => {
    const barElement = bar.current;
    if (barElement) {
      let scrollLeft;
      if (direction === 'left') {
        scrollLeft = barElement.scrollLeft - viewWidth;
        barElement.scrollLeft = scrollLeft < 0 ? 0 : scrollLeft;
      } else {
        const maxScrollLeft = getMaxScrollLeft();
        scrollLeft = barElement.scrollLeft + viewWidth;
        barElement.scrollLeft =
          scrollLeft > maxScrollLeft ? maxScrollLeft : scrollLeft;
      }

      updateScrollPosition(scrollLeft);

      const maxScrollLeft = getMaxScrollLeft();
      let adjustedScrollLeft = scrollLeft;
      if (adjustedScrollLeft < 0) {
        adjustedScrollLeft = 0;
      } else if (adjustedScrollLeft > maxScrollLeft) {
        adjustedScrollLeft = maxScrollLeft;
      }

      const leftScroll = leftScrollButton.current;
      if (leftScroll) {
        leftScroll.style.left = `${adjustedScrollLeft}px`;
      }

      const rightScroll = rightScrollButton.current;
      if (rightScroll) {
        rightScroll.style.left = `calc(100vw - 60px + ${adjustedScrollLeft}px)`;
      }
    }
  };

  const links: Link[] = [
    {
      label: 'Tournament profile',
      to: `/app/tournaments/${tournamentId}`,
    },
  ];

  if (
    hasVisibleTeams &&
    !links.some((link) => link.label === 'Participating Teams')
  ) {
    links.push({
      label: 'Participating Teams',
      to: `/app/tournaments/participants/${tournamentId}`,
    });
  }

  if (tournamentDetailsRedesign) {
    links.push(
      {
        label: 'Schedule',
        newTab: true,
        to: `/tournaments/${tournamentId}/schedule`,
      },
      {
        label: 'Standings',
        newTab: true,
        to: `/tournaments/${tournamentId}/standings`,
      }
    );
  }

  return (
    <S.SecondaryNavigationBar ref={bar}>
      <NavigationMenu.Root>
        <S.NavigationMenuList>
          {links.map((link) => (
            <NavigationMenu.Item key={link.to}>
              <S.NavigationMenuLink asChild>
                <NavLink target={link.newTab ? '_blank' : '_self'} to={link.to}>
                  {link.label}
                </NavLink>
              </S.NavigationMenuLink>
            </NavigationMenu.Item>
          ))}
        </S.NavigationMenuList>
      </NavigationMenu.Root>
      {registerSection}
      {isTabletPortraitDown ? (
        <>
          <button
            ref={leftScrollButton}
            className={clsx(styles.scrollButton, styles.left, {
              [styles.visible]: scrollPosition !== ScrollPosition.Left,
            })}
            onClick={() => scrollBar('left')}
            type="button"
          >
            <div className={styles.icon}>
              <CaretLeft fill="var(--blue-grey-900)" weight="bold" />
            </div>
            <div className={styles.fade} />
          </button>
          <button
            ref={rightScrollButton}
            className={clsx(styles.scrollButton, styles.right, {
              [styles.visible]: scrollPosition !== ScrollPosition.Right,
            })}
            onClick={() => scrollBar('right')}
            type="button"
          >
            <div className={styles.fade} />
            <div className={styles.icon}>
              <CaretRight fill="var(--blue-grey-900)" weight="bold" />
            </div>
          </button>
        </>
      ) : null}
    </S.SecondaryNavigationBar>
  );
}
