import styled from 'styled-components';
import { Link } from '@la/ds-ui-components';
export const Breadcrumbs = styled.div `
  display: flex;
  flex-wrap: wrap;
`;
export const Breadcrumb = styled.div(({ theme }) => (Object.assign(Object.assign({}, theme.typography.ui.small), { display: 'flex', color: theme.foundational.white, margin: '0 0 4px', whiteSpace: 'nowrap', a: {
        color: theme.foundational.white,
        '&:hover': {
            color: theme.foundational.white,
        },
    } })));
export const BreadcrumbLabel = styled.div `
  white-space: wrap;
`;
export const BreadcrumbLink = styled(Link).attrs({
    size: 'small',
    variant: 'text',
}) ``;
export const BreadcrumbSeparator = styled(Breadcrumb) `
  margin 0 4px;
`;
