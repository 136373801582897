import { DragEvent, useMemo } from 'react';
import { Typography } from '@la/ds-ui-components';
import { FacadeRegistrant, Roster } from '@la/types';
import { RolloverTeamType } from '../../utils/team';
import { RolloverTeam, RolloverTeamState } from './RolloverTeam/RolloverTeam';
import * as S from './RolloverDropzone.styles';

export const HOVERED_STATE_TEXT = 'Drop players and staff here.';

export type DestinationRolloverDropzoneProps = {
  /**
   * The members being added to the roster.
   */
  addedMembers: FacadeRegistrant[];
  /**
   * Triggered when a player or staff member is being removed
   * from the destination roster.
   * @param member The player or staff being removed.
   */
  onRemove: (member: FacadeRegistrant) => void;
  /**
   * Handler called when one or more staff or player are dropped in the destination zone.
   */
  onRollover: (members: FacadeRegistrant[]) => void;
  /**
   * Whether or not to display the hovered state of the dropzone. Should use when
   * a member is currently being dragged.
   */
  showHoveredState?: boolean;
  /**
   * Team roster the user is rolling members from. Cannot be undefined.
   */
  teamRoster: Roster;
};

/* DestinationRolloverDropzone */
export function DestinationRolloverDropzone({
  addedMembers,
  onRemove,
  onRollover,
  showHoveredState,
  teamRoster,
}: Readonly<DestinationRolloverDropzoneProps>) {
  const rolloverMembersState: RolloverTeamState = useMemo(() => {
    return {
      players:
        teamRoster.players?.map((player) => ({
          ...player,
          disabled: true,
        })) ?? [],
      staff:
        teamRoster.staff?.map((staff) => ({ ...staff, disabled: true })) ?? [],
    };
  }, [teamRoster]);

  const onDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  const onDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();

    const updatedMembers: FacadeRegistrant[] = JSON.parse(
      e.dataTransfer.getData('application/json')
    );
    onRollover(updatedMembers);
  };

  return (
    <S.Dropzone
      $hasSelectedTeam
      $isHovered={showHoveredState}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {showHoveredState ? (
        <Typography size="large" variant="ui" weight="bold">
          {HOVERED_STATE_TEXT}
        </Typography>
      ) : (
        <RolloverTeam
          addedMembers={addedMembers}
          onRemove={onRemove}
          rolloverMembersState={rolloverMembersState}
          teamRoster={teamRoster}
          type={RolloverTeamType.Destination}
        />
      )}
    </S.Dropzone>
  );
}
/* */
