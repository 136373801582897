import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateTime } from 'luxon';
import { CalendarIcon, MapPinIcon } from '@la/ds-ui-components';
import { genderNameMap } from '@la/utilities';
import { DivisionCardSection } from '../DivisionCard.styles';
import * as S from './DivisionCardInfoSection.styles';
export const ACTIVITY_DATE_FORMAT = 'M/d/yyyy';
export function DivisionCardInfoSection({ startDate, endDate, ageGroup, datesValue, datesLabel, experienceLevel, gender, location, rightSection, variant, }) {
    const activityDates = formatActivityDates(startDate, endDate);
    return (_jsxs(DivisionCardSection, Object.assign({ "$variant": variant }, { children: [_jsxs(S.DivisionCardPrimaryInfoContainer, { children: [_jsxs(S.DivisionCardPrimaryInfo, { children: [ageGroup ? (_jsxs(S.DivisionCardInfo, Object.assign({ "data-testid": "reg-wiz-division-card-age-group-info" }, { children: [_jsx(S.DivisionCardInfoLabel, { children: "Age group:" }), ' ', _jsx(S.DivisionCardInfoValue, { children: ageGroup })] }))) : null, experienceLevel ? (_jsxs(S.DivisionCardInfo, Object.assign({ "data-testid": "regWizard-divisionCard-experienceLevel" }, { children: [_jsxs(S.DivisionCardInfoLabel, { children: [experienceLevel.label, ":"] }), ' ', _jsx(S.DivisionCardInfoValue, { children: experienceLevel.value })] }))) : null, gender ? (_jsxs(S.DivisionCardInfo, Object.assign({ "data-testid": "reg-wiz-division-card-gender-info" }, { children: [_jsx(S.DivisionCardInfoLabel, { children: "Gender:" }), ' ', _jsx(S.DivisionCardInfoValue, { children: genderNameMap[gender] })] }))) : null, datesValue ? (_jsxs(S.DivisionCardInfo, Object.assign({ "data-testid": "reg-wiz-division-card-date-info" }, { children: [_jsxs(S.DivisionCardInfoLabel, { children: [datesLabel, ":"] }), ' ', _jsx(S.DivisionCardInfoValue, { children: datesValue })] }))) : null] }), rightSection] }), _jsxs(S.DivisionCardSecondaryInfoContainer, { children: [location ? (_jsxs(S.DivisionCardSecondaryInfo, Object.assign({ "data-testid": "regWizard-divisionCard-location" }, { children: [_jsx(MapPinIcon, { "aria-label": "location", size: "medium", variant: "filled" }), _jsx(S.DivisionCardSecondaryInfoValue, { children: location.name })] }))) : null, _jsxs(S.DivisionCardSecondaryInfo, Object.assign({ "data-testid": "regWizard-divisionCard-activityDates" }, { children: [_jsx(CalendarIcon, { "aria-label": "", size: "medium", variant: "filled" }), _jsx(S.DivisionCardSecondaryInfoValue, { children: activityDates })] }))] })] })));
}
export function formatActivityDates(startDate, endDate) {
    const formattedStartDate = DateTime.fromISO(startDate).toFormat(ACTIVITY_DATE_FORMAT);
    const formattedEndDate = endDate
        ? DateTime.fromISO(endDate).toFormat(ACTIVITY_DATE_FORMAT)
        : null;
    return `${formattedStartDate}${formattedEndDate ? ` - ${formattedEndDate}` : ''}`;
}
