import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { DateTime } from 'luxon';
import { CalendarIcon, Link, Loader, Typography } from '@la/ds-ui-components';
import { Stepper } from '@la/shared-components';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { formatDateRange } from 'lib/utils/dateUtils';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetCountriesAndAdministrativeDivisionsQuery } from 'redux/services/countryApi';
import { useGetTournamentRegistrantQuery } from 'redux/services/tournamentApi';
import { MainContent } from 'domains/Shell/MainContent/MainContent';
import { MainContentCenter } from 'domains/Shell/MainContent/MainContent.styles';
import { API_ERROR_MESSAGE } from 'domains/Tournaments/Registration/Registration';
import { WizardHeader } from 'domains/Tournaments/Registration/Wizard/components/WizardHeader/WizardHeader';
import { TOURNAMENT_NOT_FOUND_ERROR_MESSAGE } from 'domains/Tournaments/utils/errorMessages';
import * as S from './MemberRegistration.styles';

const MemberRegistration = () => {
  const {
    currentStep,
    decodedData,
    dispatch,
    existingRegistrationError,
    fileUploadFormFields,
    formFields,
    formSteps,
    loggedInUserId,
    masterProgramName,
    nonFileUploadFormFields,
    numberOfTotalSteps,
    onBackClick,
    selectedPlayer,
    stepNumber,
    steps,
    teamName,
    tournamentId,
    waivers,
    setTouched,
    hasErrors,
    onWaiversSubmit,
    updateWithExistingRegistration,
    submitRegistration,
    roleName,
    registrationOptions,
    setRegistrationOptions,
  } = useRegistration();

  const { subdomain } = getLAHostnameParts();
  const { tabletLandscapeUp } = breakpointQueries;
  const isTabletLandscapeUp = useMediaQuery(tabletLandscapeUp);
  const [isUpdated, setIsUpdated] = useState(false);
  /**
   * Perform the query here so we can handle the loading and error states, as
   * well as to cache the data for use in child components (PlayerDetailsModal).
   */
  const { isLoading: isCountriesLoading, error: countriesError } =
    useGetCountriesAndAdministrativeDivisionsQuery();

  const {
    data: tournament,
    error: tournamentError,
    isLoading: isTournamentLoading,
  } = useGetTournamentRegistrantQuery({
    tournamentId: tournamentId ?? '',
    siteDomain: subdomain,
  });

  useEffect(() => {
    if (decodedData?.type === 'staff' && !isUpdated) {
      updateWithExistingRegistration(loggedInUserId.toString());
      setIsUpdated(true);
    }
  }, [decodedData, loggedInUserId, updateWithExistingRegistration, isUpdated]);

  // Populating fields state with form fields data
  useEffect(() => {
    if (
      formFields &&
      Object.values(nonFileUploadFormFields).length === 0 &&
      Object.values(fileUploadFormFields).length === 0
    ) {
      for (const field of formFields.nonFileUploadFormFields) {
        dispatch({
          type: 'SET_NON_FILE_UPLOAD_FORM_FIELD',
          payload: {
            id: field.propertyDefinitionId,
            field,
          },
        });
      }

      for (const field of formFields.fileUploadFormFields) {
        dispatch({
          type: 'SET_FILE_UPLOAD_FORM_FIELD',
          payload: {
            id: field.propertyDefinitionId,
            field,
          },
        });
      }
    }
  }, [formFields, fileUploadFormFields, nonFileUploadFormFields, dispatch]);

  useEffect(() => {
    if (tournament && !registrationOptions) {
      setRegistrationOptions(tournament.registrationOptions);
    }
  }, [tournament, registrationOptions, setRegistrationOptions]);

  if (!decodedData || isTournamentLoading || isCountriesLoading) {
    return (
      <MainContentCenter>
        <Loader description="Loading registration..." loading />
      </MainContentCenter>
    );
  }

  if (tournamentError || countriesError) {
    return (
      <MainContent>
        <S.RegistrationContent>
          <ErrorCard message={API_ERROR_MESSAGE} />
        </S.RegistrationContent>
      </MainContent>
    );
  }

  if (!tournament) {
    throw new Error(TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
  }

  if (!formFields || !waivers) {
    throw new Error('temporary error');
  }

  const { name, startDate, endDate } = tournament;

  const getTitle = () => {
    if (decodedData.type === 'player') {
      return 'Player Registration';
    }
    return `${roleName ?? decodedData.role} Registration`;
  };

  const title = getTitle();

  const formattedDate = formatDateRange(
    DateTime.fromISO(startDate),
    endDate ? DateTime.fromISO(endDate) : undefined
  );

  const composedHeader = (
    <S.ComposedHeaderContainer>
      <Typography
        variant="ui"
        size={isTabletLandscapeUp ? 'xl' : 'large'}
        customTag="h2"
      >
        <strong>{teamName}</strong>
        &nbsp;in&nbsp;
        <strong>{name}</strong>, &nbsp;<strong>{masterProgramName}</strong>
      </Typography>
      <S.ComposedHeaderDate $isTabletLandscapeUp={isTabletLandscapeUp}>
        <CalendarIcon
          fill="var(--blue-grey-400)"
          size="medium"
          variant="filled"
        />
        {formattedDate}
      </S.ComposedHeaderDate>
    </S.ComposedHeaderContainer>
  );

  const checkTouched = () => {
    if (decodedData.type === 'player' && !selectedPlayer) {
      setTouched(true);
    }
  };

  const handleNextClick = () => {
    if (formSteps[currentStep]) {
      checkTouched();
    }

    if (currentStep === 'waivers') {
      onWaiversSubmit();
    }

    if (currentStep === 'review') {
      submitRegistration();
    }
  };

  let nextButtonFormProps = {};
  if (formSteps[currentStep]) {
    nextButtonFormProps = { form: formSteps[currentStep] };
  }

  if (existingRegistrationError && decodedData.type === 'staff') {
    return (
      <MainContent>
        <WizardHeader
          name={name}
          title={title}
          startDate={DateTime.fromISO(startDate)}
          endDate={endDate ? DateTime.fromISO(endDate) : undefined}
          composedHeader={composedHeader}
        />
        <S.RegistrationContent>
          <ErrorCard
            message={
              <>
                {existingRegistrationError}{' '}
                <Link href="/dashboard" isBold size="large" variant="text">
                  View dashboard
                </Link>
              </>
            }
          />
        </S.RegistrationContent>
      </MainContent>
    );
  }

  return (
    <MainContent>
      <WizardHeader
        name={name}
        title={title}
        startDate={DateTime.fromISO(startDate)}
        endDate={endDate ? DateTime.fromISO(endDate) : undefined}
        composedHeader={composedHeader}
      />
      <Stepper
        currentStep={steps[currentStep]}
        error={hasErrors}
        form={nextButtonFormProps}
        handleNextClick={handleNextClick}
        numberOfTotalSteps={numberOfTotalSteps}
        onBackClick={onBackClick}
        showDrawer={currentStep !== 'success'}
        showSteps={currentStep !== 'success'}
        stepNumber={stepNumber}
        type={formSteps[currentStep] ? 'submit' : 'button'}
        isMC={false}
      >
        <S.OutletContainer>
          <Outlet />
        </S.OutletContainer>
      </Stepper>
    </MainContent>
  );
};

export { MemberRegistration };
