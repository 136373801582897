import { getNumberOfSpotsLeft } from '../../../tournaments/registration/Wizard/components/DivisionCard/utils/capacity';
export const AT_CAPACITY_DISABLED_REASON = 'You have selected the maximum number of teams allowed for this division.';
export const isDivisionAtCapacity = (division, teamSelections) => {
    const numberOfSpotsLeft = getNumberOfSpotsLeft(division);
    return (numberOfSpotsLeft !== undefined &&
        teamSelections.filter(({ teamId }) => teamId.length).length >=
            numberOfSpotsLeft);
};
export const disableTeamForAtCapacity = (teamOption, selections) => {
    return Object.assign(Object.assign({}, teamOption), { disabled: !teamOption.checkboxState ||
            !!selections.find((teamSelection) => teamSelection.teamId === teamOption.value), disabledReason: teamOption.disabled
            ? teamOption.disabledReason
            : AT_CAPACITY_DISABLED_REASON });
};
export const enableTeamForAtCapacity = (teamOption) => {
    return Object.assign(Object.assign({}, teamOption), { disabled: teamOption.disabledReason === AT_CAPACITY_DISABLED_REASON
            ? false
            : teamOption.disabled, disabledReason: teamOption.disabledReason === AT_CAPACITY_DISABLED_REASON
            ? undefined
            : teamOption.disabledReason });
};
