import { FormField } from '@la/types';
import { uploadFile } from 'lib/apis/uploadFile';

export const uploadCustomFileField = async ({
  fields,
  siteId,
  userId,
}: {
  fields: FormField[];
  siteId: string | number | null;
  userId: string | number | null;
}) => {
  const formattedFields: FormField[] = [];

  for (const field of fields) {
    if (field.type !== 'FILE_UPLOAD') {
      formattedFields.push(field);
      continue;
    }

    if (!siteId || !field.value || !siteId || !userId) {
      formattedFields.push(field);
      continue;
    }

    const { uuid } = await uploadFile({
      siteId: siteId.toString(),
      file: field.value.file as ArrayBuffer,
      filename: field.value.name,
      propertyDefinitionId: field.propertyDefinitionId.toString(),
      userId: userId.toString(),
    });

    const fieldWithFile = {
      ...field,
      value: {
        ...field.value,
        uuid,
      },
    };
    formattedFields.push(fieldWithFile);
  }

  return formattedFields;
};
