// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ay9_QRSua1isG7RqhiNF{margin:0 0 12px;padding:0}.oJlNebV3XMWZq3r3ZdOs{list-style:none;display:flex;flex-direction:column;gap:8px;margin:0 0 8px;padding:0}.oJlNebV3XMWZq3r3ZdOs li{margin:0;padding:0;margin-bottom:4px}.oJlNebV3XMWZq3r3ZdOs .dGhMjENvP5lJstj1zf3M{display:flex;flex-direction:column;gap:8px;margin-top:32px}.oJlNebV3XMWZq3r3ZdOs .R0h1Uw44XFvIz9o2oDh8{display:flex;flex-direction:column;gap:8px;margin-top:12px;width:100%}.oJlNebV3XMWZq3r3ZdOs .R0h1Uw44XFvIz9o2oDh8 .qGh_eZ7xJuU75dS7bO4s,.oJlNebV3XMWZq3r3ZdOs .R0h1Uw44XFvIz9o2oDh8 .kbZ2kwzwGpbxZjyzN6a0{flex-grow:1}@media(min-width: 600px){.oJlNebV3XMWZq3r3ZdOs .R0h1Uw44XFvIz9o2oDh8{flex-direction:row}.oJlNebV3XMWZq3r3ZdOs .R0h1Uw44XFvIz9o2oDh8 .qGh_eZ7xJuU75dS7bO4s{max-width:40%}.oJlNebV3XMWZq3r3ZdOs .R0h1Uw44XFvIz9o2oDh8 .kbZ2kwzwGpbxZjyzN6a0{max-width:20%}}.oJlNebV3XMWZq3r3ZdOs .AG1ip9qevWkluZpXAhfE p{color:var(--blue-grey-600);padding-top:32px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/domains/RosterManagement/RosterRollover/TeamSelectionModal/TeamList/TeamList.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,eAAA,CACA,SAAA,CAGF,sBACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CACA,cAAA,CACA,SAAA,CAEA,yBACE,QAAA,CACA,SAAA,CACA,iBAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,eAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,eAAA,CACA,UAAA,CAEA,oIAEE,WAAA,CC/BJ,yBDsBA,4CAaI,kBAAA,CAEA,kEACE,aAAA,CAGF,kEACE,aAAA,CAAA,CAKN,8CACE,0BAAA,CACA,gBAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedTeam": `Ay9_QRSua1isG7RqhiNF`,
	"teamListContainer": `oJlNebV3XMWZq3r3ZdOs`,
	"teamItems": `dGhMjENvP5lJstj1zf3M`,
	"selectionFiltersContainer": `R0h1Uw44XFvIz9o2oDh8`,
	"selectionFilter": `qGh_eZ7xJuU75dS7bO4s`,
	"selectionSort": `kbZ2kwzwGpbxZjyzN6a0`,
	"noTeamsText": `AG1ip9qevWkluZpXAhfE`
};
export default ___CSS_LOADER_EXPORT___;
