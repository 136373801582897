import styled from 'styled-components';
import {
  CaretDownIcon,
  CaretUpIcon,
  ThemedComponent,
} from '@la/ds-ui-components';

export const PageContainer = styled.div`
  width: 100%;
`;

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  scroll-margin-top: 176px; // Height of navigation + 8px for padding
`;

export const TeamNameHeader = styled.section`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const TeamNameDisplay = styled.span`
  font-family: var(--secondary-font-family);
  font-size: var(--headline-size-small);
  font-style: normal;
  font-weight: var(--ui-weight-semi-bold);
  line-height: var(--headline-size-medium);
  letter-spacing: 0.15px;
  color: var(--blue-grey-900, #263238);
`;

export const AgeGroupDisplay = styled.span`
  display: flex;
  height: var(--ui-size-large);
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #546e7a;
  color: #fff;
  text-align: center;
  font-family: var(--primary-font-family);
  font-size: var(--ui-size-small);
  font-style: normal;
  font-weight: var(--ui-weight-bold);
  line-height: var(--ui-size-large);
`;

export const DisplayButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
`;

export const AccordionCaretDown = styled(CaretDownIcon).attrs(() => ({
  size: 'small',
  variant: 'filled',
  fill: '#CC0000',
}))({});

export const AccordionCaretUp = styled(CaretUpIcon).attrs(() => ({
  size: 'small',
  variant: 'filled',
  fill: '#CC0000',
}))({});

export const EmptyTeamListContainer = styled.div`
  width: 100%;
  padding: 16px 24px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CollapsedListRule = styled.div<ThemedComponent>`
  border-top: 1px solid
    ${({ theme }: ThemedComponent) => theme.semantic?.primary[100]};
`;
