import { Roster, TeamInfo } from '@la/types';
import { mockPlayers, mockStaff } from './mockRegistrants';

export const mockTeamInfo: TeamInfo = {
  ageGroup: 8,
  city: 'Boston',
  country: 'US',
  formFields: [],
  name: 'Bruins',
  organization: 'LeagueApps',
  paymentStatus: 'PAID',
  programName: 'Ice Cup',
  registrationStatus: 'REGISTERED',
  teamIdOg: 828282,
  teamRepresentative: {
    email: 'vphillips@leagueapps.com',
    name: 'Victoria Phillips',
    phone: '123-456-7890',
    userId: 484,
  },
};

export const mockRoster: Roster = {
  id: '123-451-2-tractor',
  programId: 1234567,
  registeredOn: '2024-10-14T13:41:47',
  registeringUserId: 123,
  registrationId: 'some-other-uuid-123',
  registrationIdOg: 12345,
  siteId: 1,
  teamInfo: mockTeamInfo,
  playerInvites: [],
  players: mockPlayers,
  staffInvites: [],
  staff: mockStaff,
};
