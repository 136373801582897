import styled from 'styled-components/macro';
import { ThemedComponent, Typography } from '@la/ds-ui-components';

export const TeamDetails = styled(Typography)<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    color: theme.foundational.blueGrey[400],
  })
);

export const CreationDate = styled.div({
  whiteSpace: 'nowrap',
});
