import { useEffect } from 'react';
import {
  Button,
  CaretDownIcon,
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalProps,
} from '@la/ds-ui-components';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import { Roster, Team, Team as TeamType } from '@la/types';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { TeamList } from './TeamList/TeamList';

export const TEAM_SELECTION_MODAL_TITLE = 'Select a team';
export const LOAD_MORE_LABEL = 'Load more';

export type TeamFilters = {
  /**
   * Current team name filter tied to get team's endpoint.
   */
  teamName: string;
  /**
   * Current program id filter tied to get team's endpoint.
   */
  programId: string;
  /**
   * Handler that updates the current team name filter.
   */
  setTeamName: (name: string) => void;
  /**
   * Handler that updates the current program id filter.
   */
  setProgramId: (id: string) => void;
  /**
   * Current subprogram id filter tied to get team's endpoint.
   */
  subprogramId: string;
  /**
   * Handler that updates the current subprogram filter.
   */
  setSubprogramId: (name: string) => void;
  /**
   * Current sorting type.
   */
  sort: 'createdOn' | 'teamName';
  /**
   * Handler that updates the current subprogram filter.
   */
  setSort: (name: 'createdOn' | 'teamName') => void;
};

export type TeamSelectionModalProps = Pick<
  ModalProps,
  'onOpenChange' | 'open'
> & {
  /**
   * All teams that the user is a staff member of.
   */
  allTeams?: Team[];
  /**
   * Error to display within the modal.
   */
  error?: string;
  /**
   * Triggered when the load more button is clicked.
   */
  onLoadMoreClick: () => void;
  /**
   * Triggered when a non-selected team is clicked.
   * @param team The team being selected
   */
  onTeamSelect: (team: TeamType) => void;
  /**
   * The currently selected team. Will render in the top of the list and
   * is not clickable. Does not render in the list of selectable teams.
   */
  selectedTeam?: Roster;
  /**
   * Whether or not to show the load more button at the bottom of the
   * team list.
   */
  showLoadMoreOption?: boolean;
  /**
   * Teams to render as options for selection.
   */
  teams: TeamType[];
} & TeamFilters;

/* TeamSelectionModal */
export function TeamSelectionModal({
  allTeams = [],
  error,
  onLoadMoreClick,
  onOpenChange,
  onTeamSelect,
  open,
  selectedTeam,
  showLoadMoreOption = false,
  teams,
  teamName,
  setTeamName,
  programId,
  setProgramId,
  subprogramId,
  setSubprogramId,
  sort,
  setSort,
}: Readonly<TeamSelectionModalProps>) {
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  /**
   * Scrolls user to top of modal content to see the error, if there is one.
   */
  useEffect(() => {
    if (error) {
      const content = document.querySelectorAll('[role="dialog"] > div');
      content.forEach((c) =>
        c?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      );
    }
  }, [error]);

  return (
    <Modal
      onOpenChange={onOpenChange}
      open={open}
      primaryAction={
        <ModalClose>
          <ModalPrimaryActionButton width={isTabletPortaitUp ? 'auto' : '100%'}>
            Go back
          </ModalPrimaryActionButton>
        </ModalClose>
      }
      title={TEAM_SELECTION_MODAL_TITLE}
    >
      {error ? <ErrorCard message={error} /> : null}
      <TeamList
        allTeams={allTeams}
        teams={teams}
        selectedTeam={selectedTeam}
        onTeamSelect={onTeamSelect}
        teamName={teamName}
        programId={programId}
        setTeamName={setTeamName}
        setProgramId={setProgramId}
        subprogramId={subprogramId}
        setSubprogramId={setSubprogramId}
        sort={sort}
        setSort={setSort}
      />
      {teams.length > 0 && showLoadMoreOption ? (
        <Button
          onClick={onLoadMoreClick}
          rightIcon={
            <CaretDownIcon fill="var(--secondary-600)" variant="bold" />
          }
          size="large"
          variant="outline"
          width="100%"
        >
          {LOAD_MORE_LABEL}
        </Button>
      ) : null}
    </Modal>
  );
}
/* */

export function getTeamId(id: string | number, programId: number): string {
  return `team-${id}-${programId}`;
}

export function transformRosterToTeam(roster: Roster): TeamType {
  return {
    ...roster.teamInfo,
    id: roster.teamInfo.teamIdOg.toString(),
    teamRepresentative: {
      ...roster.teamInfo.teamRepresentative,
      phoneNumber: roster.teamInfo.teamRepresentative.phone,
    },
  };
}
