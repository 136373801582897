import { formatAgeGroup } from '@la/utilities';
export const ALL_AGES_GROUP_LABEL = 'All ages';
export const MIN_AGE_GROUP = 6;
export const MAX_AGE_GROUP = 19;
export const MIN_LOWER_AGE_GROUP = 2;
/**
 * Generates array of whole numbers given a range.
 * @param start The starting age group.
 * @param end The ending age group.
 * @returns Array of numbers from `start` to `end` (inclusive).
 */
function range(start, end) {
    return Array.from({ length: end - start + 1 }, (_, k) => k + start);
}
/**
 * Creates the select options for the age group field. Will generate the options
 * based off of the provided max. Includes an "any" option as well.
 * @param maxAgeGroup The max age group to list.
 * @returns Array of options for a select.
 */
export function createAgeGroupOptions(maxAgeGroup) {
    const minAgeGroup = maxAgeGroup === 0 || maxAgeGroup - MIN_LOWER_AGE_GROUP < MIN_AGE_GROUP
        ? MIN_AGE_GROUP
        : maxAgeGroup - MIN_LOWER_AGE_GROUP;
    let validAgeGroups = maxAgeGroup === 0
        ? range(MIN_AGE_GROUP, MAX_AGE_GROUP)
        : range(minAgeGroup, maxAgeGroup);
    const ageGroupOptions = validAgeGroups
        .map((ageGroup) => {
        return {
            label: formatAgeGroup(ageGroup),
            value: ageGroup.toString(),
        };
    })
        .concat([{ label: ALL_AGES_GROUP_LABEL, value: '0' }]);
    return ageGroupOptions;
}
