import { createSelector, createSlice } from '@reduxjs/toolkit';
const initialState = {
    logoHref: '',
    logoImageSrc: '',
    siteId: null,
    siteName: '',
};
const coreSlice = createSlice({
    name: 'core',
    initialState,
    reducers: {
        setSiteInfo(state, action) {
            state.logoHref = action.payload.logoHref;
            state.logoImageSrc = action.payload.logoImageSrc;
            state.siteId = action.payload.siteId;
            state.siteName = action.payload.siteName;
        },
    },
});
export const { setSiteInfo } = coreSlice.actions;
export const getLogoHref = (state) => state.core.logoHref;
export const getLogoImageSrc = (state) => state.core.logoImageSrc;
export const getSiteId = (state) => state.core.siteId;
export const getSiteName = (state) => state.core.siteName;
export const getSiteIdentityData = createSelector([getLogoHref, getLogoImageSrc, getSiteId, getSiteName], (logoHref, logoImageSrc, siteId, siteName) => {
    return {
        logoHref: logoHref,
        logoImageSrc: logoImageSrc,
        siteId: siteId,
        siteName: siteName,
    };
});
export default coreSlice.reducer;
