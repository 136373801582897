import { FacadeRegistrant, Player, Staff } from '@la/types';

export const mockPlayers: Player[] = [
  {
    email: 'joe@bob.com',
    lastModifiedOn: '2024-10-14T13:41:47',
    name: 'Joe Bob',
    parent: [
      {
        email: 'bob@bob.com',
        name: 'Bob Bob',
        userId: 556,
      },
    ],
    paymentStatus: 'PAID',
    registeredOn: '2024-10-14T13:41:47',
    registeredUserId: 890,
    registeringUserId: 123,
    registrationId: '123-456-789u0z-aasd',
    registrationIdOg: 12345,
    registrationStatus: 'REGISTERED',
    role: 'PLAYER',
  },
  {
    email: 'waltlloyd@theisland.com',
    lastModifiedOn: '2024-10-14T13:41:47',
    name: 'Walter Lloyd',
    parent: [
      {
        email: 'michaeldawson@theisland.com',
        name: 'Michael Dawson',
        userId: 124,
      },
    ],
    paymentStatus: 'PAID',
    registeredOn: '2024-10-14T13:41:47',
    registeredUserId: 1642,
    registeringUserId: 124,
    registrationId: '123-456-789u0z-ahhh',
    registrationIdOg: 567890,
    registrationStatus: 'REGISTERED',
    role: 'PLAYER',
  },
];

export const mockStaff: Staff[] = [
  {
    email: 'johnlocke@theisland.com',
    lastModifiedOn: '2024-10-14T13:41:47',
    name: 'John Locke',
    paymentStatus: 'PAID',
    registeredOn: '2024-10-14T13:41:47',
    registeredUserId: 123,
    registeringUserId: 123,
    registrationId: 'udx291-2304-asd-2',
    registrationIdOg: 12346,
    registrationStatus: 'REGISTERED',
    roles: [
      {
        name: 'Club Director',
        roleId: 1,
      },
    ],
  },
  {
    email: 'michaeldawson@theisland.com',
    lastModifiedOn: '2024-10-14T13:41:47',
    name: 'Michael Dawson',
    paymentStatus: 'PAID',
    registeredOn: '2024-10-14T13:41:47',
    registeredUserId: 124,
    registeringUserId: 123,
    registrationId: 'udx291-2304-asd-234',
    registrationIdOg: 12348,
    registrationStatus: 'REGISTERED',
    roles: [
      {
        name: 'Coach',
        roleId: 2,
      },
      {
        name: 'Volunteer',
        roleId: 3,
      },
    ],
  },
];

export const mockRegistrants: FacadeRegistrant[] = [
  ...mockPlayers,
  ...mockStaff,
];
