import styled from 'styled-components';
import { Typography } from '@la/ds-ui-components';
import { media } from '../../../../../../lib/media-queries/mixins';
export const DivisionCardPrimaryInfoContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 32px;

  ${media.TabletPortraitUp `
    align-items: center;
  `}
`;
export const DivisionCardPrimaryInfo = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.TabletPortraitUp `
    flex-direction: row;
    gap: 24px;
  `}
`;
export const DivisionCardInfo = styled.div `
  p {
    display: inline-block;
  }
`;
export const DivisionCardInfoLabel = styled(Typography).attrs({
    size: 'large',
    variant: 'ui',
}) ``;
export const DivisionCardInfoValue = styled(Typography).attrs({
    size: 'large',
    variant: 'ui',
    weight: 'bold',
}) ``;
export const DivisionCardSecondaryInfoContainer = styled.div `
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;
export const DivisionCardSecondaryInfo = styled.div `
  display: flex;
  align-items: center;

  svg {
    fill: var(--blue-grey-300);
    margin-right: 4px;
  }
`;
export const DivisionCardSecondaryInfoValue = styled(Typography).attrs({
    size: 'small',
    variant: 'ui',
}) `
  color: var(--blue-grey-600);
`;
