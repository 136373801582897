import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, InputLabel } from '@la/ds-ui-components';
import * as S from './CheckboxGroupFormComponent.styles';
export function CheckboxGroupFormComponent({ checkboxGroupData, onChangeValue, value, }) {
    const { hasError, name: groupName, isRequired } = checkboxGroupData;
    const selectedValueArray = value ? value.split(',') : [];
    const checkboxGroupOptions = setOptionsAsSelected(selectedValueArray, checkboxGroupData === null || checkboxGroupData === void 0 ? void 0 : checkboxGroupData.options);
    function handleCheckboxChange(selected, value) {
        if (selected) {
            selectedValueArray.push(value);
        }
        else {
            const selectedValueIdx = selectedValueArray.indexOf(value);
            selectedValueArray.splice(selectedValueIdx, 1);
        }
        const updatedValue = selectedValueArray.join();
        onChangeValue(updatedValue);
    }
    const checkboxes = checkboxGroupOptions.map(({ label, name, selected, value }, idx) => {
        return (_jsx(Checkbox, { ariaLabel: label, checked: selected, id: `${name}-${value}`, label: label, onCheckedChange: (isSelected) => {
                handleCheckboxChange(isSelected, value);
            }, size: "large" }, `${name}-${value}`));
    });
    return (_jsxs(_Fragment, { children: [_jsx(InputLabel, Object.assign({ htmlFor: groupName, inputState: hasError ? 'error' : 'default', required: isRequired }, { children: checkboxGroupData.label })), _jsx(S.CheckboxGroup, { children: checkboxes })] }));
}
export function setOptionsAsSelected(selectedValueArray, options = []) {
    return options.map((option) => {
        return selectedValueArray.includes(option.value)
            ? Object.assign(Object.assign({}, option), { selected: true }) : Object.assign(Object.assign({}, option), { selected: false });
    });
}
