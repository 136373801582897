import styled, { keyframes } from 'styled-components';
import { IconButton } from '@la/ds-ui-components';
import { Backdrop } from '../Backdrop/Backdrop.styles';
const fadeInBackdrop = keyframes `
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
const fadeOutBackdrop = keyframes `
  0% { opacity: 1; }
  100% { opacity: 0;  }
`;
function animation({ $open, $side }) {
    if ($open) {
        if ($side === 'right') {
            return keyframes `
        0% { right: -100%; }
        100% { right: 0; }
       `;
        }
        return keyframes `
      0% { left: -100%; }
      100% { left: 0; }
    `;
    }
    else {
        if ($side === 'right') {
            return keyframes `
        0% { right: 0; }
        100% { right: -100%; }
       `;
        }
        return keyframes `
      0% { left: 0; }
      100% { left: -100%; }
    `;
    }
}
export const MenuBackdrop = styled(Backdrop) `
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  animation: ${({ $open }) => ($open ? fadeInBackdrop : fadeOutBackdrop)} 0.3s
    ease-in-out;
`;
export const MenuContainer = styled.div `
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 390px;
  background-color: var(--white);

  ${({ $side }) => {
    if ($side === 'right') {
        return 'right: 0px;';
    }
    return 'left: 0px;';
}}

  animation: ${({ $open, $side }) => animation({ $open, $side })} 0.3s ease-in-out;
  height: 100vh;
`;
export const MenuHeader = styled.div(({ theme }) => {
    const { foundational: { green, white }, } = theme;
    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px',
        backgroundColor: green[600],
        h4: {
            color: white,
        },
        button: {
            minHeight: 'auto',
        },
    };
});
export const HeaderCloseIcon = styled(IconButton)({
    '& svg': {
        height: '12px',
        width: '12px',
        strokeWidth: '24px',
    },
});
export const MenuBody = styled.div({
    padding: '24px',
    height: '100vh',
    overflowY: 'auto',
});
export const MenuActionsFooter = styled.div(({ theme }) => {
    const { foundational: { blueGrey, white }, } = theme;
    return {
        borderTop: `1px solid ${blueGrey[100]}`,
        background: `${white}`,
        boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.05)',
        padding: '12px',
    };
});
