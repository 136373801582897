import { RolloverTeamType } from '../../../../utils/team';
import { getMemberTileId, MemberTile } from '../MemberTile/MemberTile';
import { RegistrantState } from '../types/member';
import * as S from './SelectedMembersOptions.styles';

export type SelectedMembersOptionsProps = {
  /**
   * The members to render a tile for.
   */
  members: RegistrantState[];
  /**
   * The type of team (ORIGIN or DESTINATION). Determines the ids generated for
   * member tiles.
   */
  teamType: RolloverTeamType;
};

/* SelectedMembersOptions */
export function SelectedMembersOptions({
  members,
  teamType,
}: Readonly<SelectedMembersOptionsProps>) {
  return (
    <S.SelectedMembers>
      {members.map((member) => {
        return (
          <MemberTile
            {...member}
            active
            key={getMemberTileId(member.registeredUserId, teamType)}
            // Pass in empty handler intentionally to
            // show same state as selectable member options in the
            // drag image. Didn't feel it was worth it to have a whole
            // additional prop for "selectable".
            onSelectChange={() => {}}
            selected
            teamType={teamType}
          />
        );
      })}
    </S.SelectedMembers>
  );
}
/* */
