import styled from 'styled-components/macro';
import { Button, ThemedComponent } from '@la/ds-ui-components';

export const RolloverWizard = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',

    [theme.mediaQueries.mobile]: {
      flexDirection: 'row',
    },
  })
);

export const RolloverButton = styled(Button)<
  ThemedComponent<{ $disabled: boolean }>
>(({ theme, $disabled }: ThemedComponent<{ $disabled: boolean }>) => ({
  ':not(:active)': {
    backgroundColor: theme.foundational.white,
  },

  svg: {
    width: '24px',
    height: '24px',
    fill: $disabled ? theme.palette.disabled.medium : undefined,
  },
}));
