import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Button, Typography } from '@la/ds-ui-components';
import { formatDateRange } from '@la/utilities';
import styles from '../TournamentDetailsPage.module.scss';

export const NO_REGISTRATION_WINDOW_TEXT = 'N/A';
export const OPEN_REGISTRATION_TEXT = 'Open';
export const REGISTER_LABEL = 'Register now';

export type RegistrationWindowCardProps = {
  /**
   * The day registration closes
   */
  endDate?: string;
  /**
   * When `false`, shows `N/A` instead of standard window text. This
   * should be used when there are no divisions, for which there would
   * be no registration window.
   */
  hasRegistrationWindow?: boolean;
  /**
   * Link to registration page
   */
  href: string;
  /**
   * When `true`, the register button should be shown
   */
  showRegisterButton?: boolean;
  /**
   * The day registration opens
   */
  startDate?: string;
};

/* RegistrationWindowCard */
export function RegistrationWindowCard({
  endDate,
  hasRegistrationWindow = true,
  href,
  showRegisterButton,
  startDate,
}: RegistrationWindowCardProps) {
  let registrationWindow;

  if (!hasRegistrationWindow) {
    registrationWindow = NO_REGISTRATION_WINDOW_TEXT;
  } else if (!startDate || !endDate) {
    registrationWindow = OPEN_REGISTRATION_TEXT;
  } else {
    registrationWindow = formatDateRange(
      DateTime.fromISO(startDate),
      DateTime.fromISO(endDate)
    );
  }

  const navigateToRegistration = (): void => {
    window.location.href = href;
  };

  return (
    <div
      className={clsx(styles.tournamentDetail, styles.registrationWindowCard)}
    >
      <Typography
        className={styles.label}
        size="small"
        uppercase
        variant="ui"
        weight="bold"
      >
        Registration Window
      </Typography>
      <Typography size="xl" variant="ui">
        {registrationWindow}
      </Typography>
      {showRegisterButton ? (
        <Button
          className={styles.action}
          onClick={navigateToRegistration}
          variant="outline"
        >
          {REGISTER_LABEL}
        </Button>
      ) : null}
    </div>
  );
}
/* */
