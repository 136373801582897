import { getStaffTeams } from 'redux/api/getStaffTeam';
import { paginatedGet } from 'domains/ActivityForm/apis/paginatedGet';
import { TeamWithProps } from 'domains/ActivityForm/utils/ActivityForm.utils';
import baseApi from './baseApi';
import { Team } from './types/team';

const FACADE_TEAMS_PAGE_SIZE = 100;

export const teamApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['Teams'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createTeam: builder.mutation<
        Team,
        {
          siteDomain: string;
          programId: string;
          team: Omit<Team, 'id' | 'status' | 'ageGroup'>;
        }
      >({
        query: ({ siteDomain, programId, team }) => {
          return getTeamPostPayload(siteDomain, programId, team);
        },
        invalidatesTags: ['Teams'],
      }),
      updateTeam: builder.mutation<
        Team,
        { siteDomain: string; programId: string; team: Omit<Team, 'status'> }
      >({
        query: ({ siteDomain, programId, team }) => {
          return getTeamPostPayload(siteDomain, programId, team);
        },
        invalidatesTags: ['Teams'],
      }),
      deleteTeam: builder.mutation<
        void,
        {
          siteDomain: string;
          programId: string;
          editURL: string;
          teamId: string;
        }
      >({
        query: ({ siteDomain, programId, editURL, teamId }) => {
          return {
            url: `/api/member-portal/${siteDomain}/teams`,
            method: 'DELETE',
            body: {
              programId,
              editURL,
              teamId,
            },
          };
        },
        invalidatesTags: ['Teams', 'Registration'],
      }),
      getTeams: builder.query<
        Team[],
        {
          siteDomain: string;
        }
      >({
        query: ({ siteDomain }) => {
          return {
            url: `/api/member-portal/${siteDomain}/teams`,
            method: 'GET',
          };
        },
        providesTags: ['Teams'],
      }),
      getFacadeTeams: builder.query<
        {
          hasMoreResults: boolean;
          programId?: string;
          sort?: string;
          teamName?: string;
          teams: Team[];
        },
        {
          pageNum?: number;
          role?: 'player' | 'staff' | 'primaryStaff';
          userId: string;
          teamName?: string;
          programId?: string;
          sort?: 'createdOn' | 'teamName';
        }
      >({
        query: ({ pageNum, role, userId, teamName, programId, sort }) => {
          return {
            url: `/api/users/${userId}/teams`,
            method: 'GET',
            params: {
              include: ['programName'],
              pageNum,
              pageSize: FACADE_TEAMS_PAGE_SIZE,
              role,
              teamName,
              programId: programId === 'all' ? undefined : programId,
              sort,
            },
          };
        },
        transformResponse: async (
          teams: Team[],
          _,
          { programId, teamName, sort }
        ) => {
          // Same team in same program can return when user has multiple staff roles on the team,
          // so we filter out the duplicates here.
          const filteredNewTeams = teams.reduce<Team[]>(
            (currentTeams, team) => {
              const existingTeam = currentTeams.find(
                (t) => t.id === team.id && t.programId === team.programId
              );
              return existingTeam ? currentTeams : currentTeams.concat(team);
            },
            []
          );
          return {
            hasMoreResults: teams.length === FACADE_TEAMS_PAGE_SIZE,
            programId,
            sort,
            teamName,
            teams: filteredNewTeams,
          };
        },
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName;
        },
        merge: (
          {
            programId: originalProgramId,
            sort: originalSort,
            teamName: originalTeamName,
            teams: originalTeams,
          },
          { hasMoreResults, programId, sort, teamName, teams }
        ) => {
          const newTeams = [...originalTeams];

          if (
            originalProgramId !== programId ||
            originalSort !== sort ||
            originalTeamName !== teamName
          ) {
            return {
              hasMoreResults,
              programId,
              sort,
              teamName,
              teams,
            };
          }

          // Same team in same program can return when user has multiple staff roles on the team,
          // so we filter out the duplicates here.
          const filteredNewTeams = teams.filter((team) => {
            const existingTeam = originalTeams.find(
              (t) =>
                t.id === team.id &&
                t.programId === team.programId &&
                t.name === team.name
            );
            return !existingTeam;
          });
          newTeams.push(...filteredNewTeams);

          return {
            hasMoreResults,
            programId,
            sort,
            teamName,
            teams: newTeams,
          };
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
        providesTags: ['Teams'],
      }),
      getStaffTeams: builder.query({
        queryFn: async ({ userId }: { userId: number }) => {
          const data = await paginatedGet<TeamWithProps>({
            apiRequest: getStaffTeams,
            requestData: { userId },
          });
          return { data };
        },
        providesTags: ['Teams'],
      }),
      createExternalTeam: builder.mutation<
        TeamWithProps,
        {
          siteId: number;
          externalTeam: ExternalTeam;
        }
      >({
        query: ({ siteId, externalTeam }) => {
          return {
            url: `/api/sites/${siteId}/externalTeams`,
            method: 'POST',
            body: {
              ...externalTeam,
            },
          };
        },
        invalidatesTags: ['Teams'],
      }),
    }),
  });

function getTeamPostPayload(
  siteDomain: string,
  programId: string,
  team: Partial<Team>
) {
  return {
    url: `/api/member-portal/${siteDomain}/teams`,
    method: 'POST',
    body: {
      programId,
      team,
    },
  };
}

export type ExternalTeam = {
  name?: string;
  programId?: number;
};

export const {
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useGetTeamsQuery,
  useGetFacadeTeamsQuery,
  useGetStaffTeamsQuery,
  useCreateExternalTeamMutation,
} = teamApi;
