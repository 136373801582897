import { CheckCircle } from '@phosphor-icons/react/dist/ssr';
import clsx from 'clsx';
import { Tooltip, TooltipProvider, Typography } from '@la/ds-ui-components';
import styles from './CapacityProgressBar.module.scss';
import * as S from './CapacityProgressBar.styles';

export type CapacityProgressBarProps = (
  | { min: number; max?: never }
  | { min?: never; max: number }
  | { min: number; max: number }
) & {
  filled: number;
  pending: number;
  inviteType: 'player' | 'staff';
};

export const CapacityProgressBar = ({
  min,
  max,
  filled,
  pending,
  inviteType,
}: CapacityProgressBarProps) => {
  const filledPercentage = (filled / (max ?? min)) * 100;
  const pendingPercentage = (pending / (max ?? min)) * 100;
  const isFilled = max ? filled >= max : min ? filled >= min : false;
  const isPendingFilled = max
    ? filled + pending >= max
    : min
      ? filled + pending >= min
      : false;

  return (
    <div className={styles.capacityProgressBar}>
      <div className={styles.progressBar}>
        <TooltipProvider>
          <Tooltip
            content={`${filled} ${inviteType === 'player' ? 'players' : 'staff'} registered`}
            translationThreshold={filledPercentage / 2}
          >
            <S.FilledSegment
              $filled={isFilled}
              $percentage={filledPercentage}
            />
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip
            content={`${pending} pending or started registrations`}
            translationThreshold={filledPercentage + pendingPercentage / 2}
          >
            <S.PendingSegment
              $filled={isPendingFilled}
              $hasMin={!!min}
              $offset={filledPercentage}
              $percentage={pendingPercentage}
            />
          </Tooltip>
        </TooltipProvider>
      </div>
      <div
        className={clsx({
          [styles.footer]: true,
          [styles.hasMinAndMax]: !!min && !!max,
        })}
      >
        {min ? (
          <div
            className={clsx({
              [styles.capacity]: true,
              [styles.iconRight]: !!max,
            })}
          >
            <div className={styles.subtitle}>
              {filled >= min ? <CheckCircle size={12} weight="fill" /> : null}
            </div>
            <Typography variant="ui" size="small">
              min. {min}
            </Typography>
          </div>
        ) : null}

        {max ? (
          <div className={styles.capacity}>
            <div className={styles.subtitle}>
              {filled >= max ? <CheckCircle size={12} weight="fill" /> : null}
            </div>
            <Typography variant="ui" size="small">
              max. {max}
            </Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
};
