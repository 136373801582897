import { formatAsUSD } from '@la/utilities';

const formatAsUSDWithoutZeroCents = (value: number): string => {
  return formatAsUSD(value, { hideZeroCents: true });
};

/**
 * Formats cost with min and max. If max is equal to min, return only
 * a single formatted value.
 */
export const formatCostRange = (min: string, max: string): string => {
  const minValue = Number(min);
  const maxValue = Number(max);

  if (isNaN(minValue) || isNaN(maxValue)) {
    throw new Error('Value provided is not a number');
  }

  if (minValue === maxValue) {
    return formatAsUSDWithoutZeroCents(minValue);
  }
  return `${formatAsUSDWithoutZeroCents(minValue)} - ${formatAsUSDWithoutZeroCents(maxValue)}`;
};
