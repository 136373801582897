// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V4y16mJZYVbgpiIPW9fw{display:flex;flex-direction:column;gap:12px;padding:0 16px 16px}.sGp_cPpgF3xMSSOyUOcY{border:none;border-top:1px solid var(--primary-35);margin:0 0 12px 0}@media(min-width: 900px){.sGp_cPpgF3xMSSOyUOcY{margin:0 0 16px 0}}.yA11xvIyCbI9Vv4WMsEU{font:var(--ui-xtra-large-bold)}.y8dvA2AkPC5vNcDi3VX5{display:flex;flex-direction:row;gap:8px;width:100%}.y8dvA2AkPC5vNcDi3VX5>div:first-of-type{flex:1}@media(min-width: 600px){.y8dvA2AkPC5vNcDi3VX5{width:380px}}.ijte5UT0c0Zcv7qCKXzL{display:flex;flex-direction:row;gap:8px;flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/domains/Checkout/DiscountCodes/ApplyDiscountCodesSection.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,mBAAA,CAGF,sBACE,WAAA,CACA,sCAAA,CACA,iBAAA,CCDA,yBDFF,sBAMI,iBAAA,CAAA,CAIJ,sBACE,8BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CAEA,wCACE,MAAA,CCxBF,yBDiBF,sBAWI,WAAA,CAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"applyDiscountCodesSection": `V4y16mJZYVbgpiIPW9fw`,
	"applyDiscountCodeDivider": `sGp_cPpgF3xMSSOyUOcY`,
	"applyDiscountCodeSectionTitle": `yA11xvIyCbI9Vv4WMsEU`,
	"textInputWithButton": `y8dvA2AkPC5vNcDi3VX5`,
	"discountCodesContainer": `ijte5UT0c0Zcv7qCKXzL`
};
export default ___CSS_LOADER_EXPORT___;
