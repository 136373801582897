import { css } from 'styled-components';
import { breakpointQueries } from './breakpoints';
export const media = {
    PhoneOnly: (...args) => css `
    @media ${breakpointQueries.phoneOnly} {
      ${css(...args)};
    }
  `,
    TabletPortraitUp: (...args) => css `
    @media ${breakpointQueries.tabletPortraitUp} {
      ${css(...args)};
    }
  `,
    TabletPortraitDown: (...args) => css `
    @media ${breakpointQueries.tabletPortraitDown} {
      ${css(...args)};
    }
  `,
    TabletLandscapeUp: (...args) => css `
    @media ${breakpointQueries.tabletLandscapeUp} {
      ${css(...args)};
    }
  `,
    TabletLandscapeDown: (...args) => css `
    @media ${breakpointQueries.tabletLandscapeDown} {
      ${css(...args)};
    }
  `,
    DesktopUp: (...args) => css `
    @media ${breakpointQueries.desktopUp} {
      ${css(...args)};
    }
  `,
    BigDesktopUp: (...args) => css `
    @media ${breakpointQueries.bigDesktopOnly} {
      ${css(...args)};
    }
  `,
};
