import { useState } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Button } from '@la/ds-ui-components';
import { StyledHTML } from 'components/StyledHTML/StyledHTML';
import styles from './AboutSection.module.scss';

export type AboutSectionProps = {
  description: string;
};

/* AboutSection */
export function AboutSection({ description }: AboutSectionProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={styles.aboutSection}>
      <div className={`${styles.content} ${expanded ? styles.expanded : ''}`}>
        <StyledHTML content={description} />
      </div>
      <div className={styles.viewDetails}>
        <Button
          onClick={() => setExpanded((prev) => !prev)}
          rightIcon={
            expanded ? <CaretUp weight="fill" /> : <CaretDown weight="fill" />
          }
          size="large"
          variant="outline"
          width="100%"
        >
          {expanded ? 'Hide' : 'View'} details
        </Button>
      </div>
    </div>
  );
}
/* */
