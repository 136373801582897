import { sortRecordByValue } from '@la/utilities';
import baseApi from './baseApi';
export const countryApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCountriesAndAdministrativeDivisions: builder.query({
            query: () => ({
                url: `/api/countries`,
                method: 'GET',
            }),
            keepUnusedDataFor: Infinity,
            transformResponse: (countries) => {
                const provincesCAN = getSortedSubLocations('CAN', countries);
                const statesUSA = getSortedSubLocations('USA', countries);
                const filteredCountries = sortRecordByValue(countries.reduce((countries, { code, name }) => {
                    if (
                    // The data source the countries API uses can return the same country
                    // more than once.
                    !countries[code] &&
                        // Exclude any US territories listed also as a country.
                        !Object.values(statesUSA).find((stateName) => stateName === name)) {
                        countries[code] = name;
                        return countries;
                    }
                    return countries;
                }, {}));
                return { countries: filteredCountries, provincesCAN, statesUSA };
            },
        }),
    }),
});
/**
 * Gets the sublocations (e.g. states, provinces, etc.) given a country code.
 * @param countryCode Country code to search for (e.g. USA, CAN)
 * @param countries Countries that may or may not have sublocations associated with it.
 * @returns Key-value pairs of sublocation codes and names.
 */
function getSortedSubLocations(countryCode, countries) {
    var _a;
    const subLocations = ((_a = countries.find(({ code }) => code === countryCode)) === null || _a === void 0 ? void 0 : _a.admin1) || {};
    return sortRecordByValue(subLocations);
}
export const { useGetCountriesAndAdministrativeDivisionsQuery } = countryApi;
