import axios from 'axios';
import { Registration, RegistrationStatus, RegistrationType } from '@la/types';
import { getLAHostnameParts, getProperty } from '@la/utilities';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

const baseUrl = getBaseURL();

const getUserRegistrations = async ({
  programId,
  siteDomain,
}: {
  programId: number;
  siteDomain: string;
}) => {
  const endpoint = `${baseUrl}/api/member-portal/${siteDomain}/registration`;
  const token = extractAccessToken();
  const response = await axios.get<{ userRegistrations: Registration[] }>(
    endpoint,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        programId,
      },
    }
  );

  return response.data;
};

/**
 * Retrieves the existing user registration (if there is one).
 * @param registrationType Determines what criteria to check when getting the user registration.
 * @param userId Id of the user the registration should be under.
 * @param programId Id of the program the registration should be under. Used when
 * `registrationType` is `STAFF`.
 * @param teamIdOg Id of the team the registration should be under. Used when `registrationType`
 * is `PLAYER`.
 * @param roleId Id of the role the registration should be under. Used when `registrationType` is `STAFF`.
 * @returns The existing `UserRegistration` or `undefined` if the criteria is not met.
 */
const getExistingUserRegistration = async ({
  registrationType,
  userId,
  programId,
  teamIdOg,
  roleId,
}: {
  registrationType: 'PLAYER' | 'STAFF';
  userId: number;
  programId: number;
  teamIdOg: number;
  roleId?: number;
}): Promise<Registration | undefined> => {
  const { subdomain } = getLAHostnameParts();
  const { userRegistrations } = await getUserRegistrations({
    programId,
    siteDomain: subdomain,
  });

  return userRegistrations.find((userRegistration) => {
    const { properties } = userRegistration;

    const hasMatchingTypeAndStatus =
      userRegistration.registrationType === registrationType &&
      (getProperty('registeredUserId', properties)?.[0] === userId.toString() ||
        userRegistration.registeringUserId === userId) &&
      userRegistration.registrationStatus !== RegistrationStatus.Removed;

    if (hasMatchingTypeAndStatus) {
      if (registrationType === RegistrationType.Player) {
        return userRegistration.teamIdOg === teamIdOg;
      }

      return (
        getProperty('programStaffId', properties)?.[0] === roleId?.toString() &&
        userRegistration.teamIdOg === teamIdOg
      );
    }

    return false;
  });
};

export { getUserRegistrations, getExistingUserRegistration };
