var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * If a serialized version of the global store—-partially or in its
 * entirety—-has been stored in the browser's local storage this will
 * retrieve it and return a deserialized version of it as JSON.
 * @param {string} key
 * @returns {any}
 */
export function loadStateFromLocalStorage(key) {
    try {
        const serializedState = localStorage.getItem(key);
        return serializedState ? JSON.parse(serializedState) : undefined;
    }
    catch (error) {
        console.error(error);
        return undefined;
    }
}
/**
 * This method receives a key and a JSON object taken from the global store.
 * The JSON is serialized and stored in the browser's local storage. The key
 * passed to the function is used as key for the serialized JSON in local
 * storage.
 * @param {string} key
 * @param state
 * @returns {Promise<void>}
 */
export function saveStateToLocalStorage(key, state) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem(key, serializedState);
        }
        catch (error) {
            console.error(error);
        }
    });
}
/**
 * clearStateFromLocalStorage removes any data from local storage that has a
 * key matching the key passed to it as an argument. In most cases it will be
 * called just prior to a user logging out so that the data from the global
 * store does not persist in local storage beyond the session.
 * @param {string} key
 * @returns {Promise<void>}
 */
export function clearStateFromLocalStorage(key) {
    return __awaiter(this, void 0, void 0, function* () {
        localStorage.removeItem(key);
    });
}
