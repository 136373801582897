import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';

export const CheckoutBillingSummary = styled.div<{
  $summaryType: 'checkout' | 'confirmation';
}>`
  border-radius: 4px;
  color: var(--blue-grey-900);
  background-color: var(--white);
  padding: 16px 16px 24px 16px;

  > * + * {
    margin-top: 8px;
  }

  ${({ $summaryType }) => media.TabletPortraitUp`
    border: ${
      $summaryType === 'checkout' ? '1px solid var(--primary-100)' : 'none'
    };
    
    > * + * {
      margin-top: 12px;
  }
  `}
`;

export const DueTodayRow = styled.div<{
  $summaryType: 'checkout' | 'confirmation';
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font: var(--ui-large-bold);

  ${({ $summaryType }) => media.TabletPortraitUp`
      font: ${
        $summaryType === 'checkout'
          ? 'var(--headline-small)'
          : 'var(--ui-large-bold)'
      };
  `}
`;
