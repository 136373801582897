import { useState } from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import {
  Modal,
  ModalPrimaryActionButton,
  ModalTertiaryActionButton,
  Badge,
} from '@la/ds-ui-components';
import { PriceAdjustment } from '@la/types';
import {
  formatAsUSD,
  isFixedAmountPriceAdjustment,
  isPercentagePriceAdjustment,
} from '@la/utilities';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import {
  PaymentPlanSummary,
  PaymentPlanSummaryInstallment,
} from '../Checkout.types';
import styles from './PaymentPlansModal.module.scss';

export type PaymentPlansFormFields = {
  [key: string]: {
    paymentPlan: string;
    payLater?: boolean;
  };
};

export type PaymentPlansModalProps = {
  cartItemName: string;
  cartItemUuid: string;
  closeModal: () => void;
  open: boolean;
  paymentPlanPrice?: string;
  paymentPlanSummaries: PaymentPlanSummary[];
  selectedPaymentPlan?: PaymentPlanSummary;
};

/* PaymentPlansModal */
export default function PaymentPlansModal({
  cartItemName,
  cartItemUuid,
  closeModal,
  open,
  paymentPlanPrice = '$0.00',
  paymentPlanSummaries,
  selectedPaymentPlan,
}: Readonly<PaymentPlansModalProps>) {
  const { handlePaymentPlanSelection } = useConsolidatedCheckout();

  const [selectedPaymentPlanName, setSelectedPaymentPlanName] = useState<
    string | undefined
  >(selectedPaymentPlan?.name ?? paymentPlanSummaries[0]?.name);

  function onPaymentPlanSelection() {
    if (paymentPlanSummaries && selectedPaymentPlanName) {
      const selectedPaymentPlan = getPaymentPlanByName(
        paymentPlanSummaries,
        selectedPaymentPlanName
      );
      handlePaymentPlanSelection(cartItemUuid, selectedPaymentPlan);
    }
    closeModal();
  }

  return (
    <Modal
      onOpenChange={closeModal}
      open={open}
      primaryAction={
        <ModalPrimaryActionButton
          onClick={onPaymentPlanSelection}
          disabled={!selectedPaymentPlanName}
        >
          Save
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalTertiaryActionButton onClick={closeModal}>
          Cancel
        </ModalTertiaryActionButton>
      }
      size="medium"
      title="Payment plans"
    >
      <PaymentPlanModalBody
        cartItemName={cartItemName}
        paymentPlanPrice={paymentPlanPrice}
        paymentPlanSummaries={paymentPlanSummaries}
        selectedPaymentPlanName={
          getPaymentPlanByName(paymentPlanSummaries, selectedPaymentPlanName)
            .name
        }
        setSelectedPaymentPlanName={setSelectedPaymentPlanName}
      />
    </Modal>
  );
}
/* */

/* Payment Plan Modal Body */
function PaymentPlanModalBody({
  paymentPlanPrice,
  paymentPlanSummaries,
  cartItemName,
  selectedPaymentPlanName,
  setSelectedPaymentPlanName,
}: Readonly<{
  paymentPlanPrice?: string;
  paymentPlanSummaries: PaymentPlanSummary[];
  cartItemName: string;
  selectedPaymentPlanName?: string;
  setSelectedPaymentPlanName: (selectedPlanName: string) => void;
}>) {
  return (
    <div className={styles.paymentPlanModalBody}>
      <div className={styles.paymentPlanModalBodyHead}>
        <span className={styles.paymentPlanModalBodyHeadTitle}>
          {cartItemName}
        </span>
        <span className={styles.paymentPlanModalBodyHeadPrice}>
          {paymentPlanPrice}
        </span>
      </div>
      <PaymentPlans
        paymentPlanSummaries={paymentPlanSummaries}
        selectedPaymentPlanName={selectedPaymentPlanName}
        setSelectedPaymentPlanName={setSelectedPaymentPlanName}
      />
    </div>
  );
}

function PaymentPlans({
  paymentPlanSummaries,
  selectedPaymentPlanName,
  setSelectedPaymentPlanName,
}: Readonly<{
  paymentPlanSummaries: PaymentPlanSummary[];
  selectedPaymentPlanName?: string;
  setSelectedPaymentPlanName: (selectedPlanName: string) => void;
}>) {
  function generatePaymentPlans() {
    return paymentPlanSummaries.map((paymentPlanSummary, idx) => {
      return (
        <PaymentPlan
          isSelected={selectedPaymentPlanName === paymentPlanSummary.name}
          key={`${paymentPlanSummary.ngPaymentPlanId}-${idx}`}
          paymentPlanSummary={paymentPlanSummary}
          setSelectedPaymentPlanName={setSelectedPaymentPlanName}
        />
      );
    });
  }

  return <>{generatePaymentPlans()}</>;
}

function PaymentPlan({
  isSelected,
  paymentPlanSummary,
  setSelectedPaymentPlanName,
}: Readonly<{
  isSelected: boolean;
  paymentPlanSummary: PaymentPlanSummary;
  setSelectedPaymentPlanName: (paymentPlanName: string) => void;
}>) {
  const paymentPlanClass = clsx(
    styles.paymentPlanSummary,
    isSelected && styles.selectedPlan
  );
  const handlePlanSelection = () => {
    if (paymentPlanSummary.name) {
      setSelectedPaymentPlanName(paymentPlanSummary.name);
    }
  };
  return (
    <div className={paymentPlanClass}>
      <div className={styles.paymentPlanName}>
        <RadioButton
          iconColor="var(--blue-grey-600)"
          checked={isSelected}
          handleChange={handlePlanSelection}
          name={paymentPlanSummary.name}
          label={paymentPlanSummary.name}
        />
        <PriceAdjustmentBadge
          priceAdjustment={paymentPlanSummary.priceAdjustment}
        />
      </div>
      <PaymentPlanInstallments
        dueCheckout={paymentPlanSummary.dueCheckout}
        installments={paymentPlanSummary.installments}
        payLater={paymentPlanSummary.payLater}
        paymentPlanId={paymentPlanSummary.ngPaymentPlanId}
      />
    </div>
  );
}

function PriceAdjustmentBadge({
  priceAdjustment,
}: Readonly<{ priceAdjustment?: PriceAdjustment }>) {
  // Early return if no price adjustment
  if (!priceAdjustment) {
    return null;
  }

  const discountDisplayText = getDiscountDisplayText(priceAdjustment);

  // Return Badge only if there's a discount to show
  return discountDisplayText ? (
    <Badge size="medium" text={discountDisplayText} />
  ) : null;
}

export function getDiscountDisplayText(
  priceAdjustment: PriceAdjustment
): string | null {
  if (
    isFixedAmountPriceAdjustment(priceAdjustment) &&
    priceAdjustment.fixedAmount < 0
  ) {
    // Convert negative amount to positive for display and format as currency
    return `${formatAsUSD(Math.abs(priceAdjustment.fixedAmount))} Discount`;
  } else if (
    isPercentagePriceAdjustment(priceAdjustment) &&
    priceAdjustment.percentage < 0
  ) {
    // Convert negative amount to positive for display and add % symbol
    return `${Math.abs(priceAdjustment.percentage)}% Discount`;
  }
  return null;
}

function PaymentPlanInstallments({
  dueCheckout,
  installments,
  payLater,
  paymentPlanId,
}: Readonly<{
  dueCheckout: boolean;
  installments: PaymentPlanSummaryInstallment[];
  payLater?: boolean;
  paymentPlanId?: string;
}>) {
  function generatePaymentPlanInstallments() {
    return installments.map((installment, idx) => {
      return (
        <div
          className={styles.paymentPlanInstallment}
          key={`${installment.installmentOrder}-${idx}`}
        >
          <span className={styles.installmentAmount}>
            {installment.installmentTotal} due
          </span>
          {dueCheckout || idx > 0 ? (
            <span className={styles.installmentDueDate}>
              {formatDate(installment.installmentDate)}
            </span>
          ) : (
            <OptionWithPayToday
              installment={installment}
              payLater={payLater}
              paymentPlanId={paymentPlanId}
            />
          )}
        </div>
      );
    });
  }

  return (
    <div className={styles.paymentPlanInstallments}>
      {generatePaymentPlanInstallments()}
    </div>
  );
}

function OptionWithPayToday({
  installment,
  payLater,
  paymentPlanId,
}: Readonly<{
  installment: PaymentPlanSummaryInstallment;
  payLater?: boolean;
  paymentPlanId?: string;
}>) {
  const { handlePayLaterSelection } = useConsolidatedCheckout();
  function handlePayLaterChange(makePaymentToday: boolean) {
    if (paymentPlanId) {
      handlePayLaterSelection(paymentPlanId, makePaymentToday);
    }
  }

  return (
    <>
      <span className={styles.installmentDueDate}>
        <RadioButton
          iconColor="var(--blue-grey-600)"
          checked={!payLater}
          handleChange={() => {
            handlePayLaterChange(false);
          }}
          name="payToday"
        />
        Today
      </span>
      <span className={styles.installmentDueDate}>
        <RadioButton
          checked={payLater}
          iconColor="var(--blue-grey-600)"
          handleChange={() => {
            handlePayLaterChange(true);
          }}
          name="payLater"
        />
        {formatDate(installment.installmentDate)}
      </span>
    </>
  );
}

export function getPaymentPlanByName(
  paymentPlanSummaries: PaymentPlanSummary[],
  paymentPlanName?: string
) {
  return (
    paymentPlanSummaries.find(
      (paymentPlan) => paymentPlan.name === paymentPlanName
    ) ?? paymentPlanSummaries[0]
  );
}

/**
 * Converts a date string from "YYYY-MM-DD" format to "MMM DD, YYYY" format using Luxon
 *
 * @param dateString - Date string in "YYYY-MM-DD" format (e.g., "2025-01-22")
 * @returns Formatted date string in "MMM DD, YYYY" format (e.g., "Jan 22, 2025")
 */
function formatDate(dateString: string): string {
  // Parse the input date string using Luxon
  const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd');

  // Check if the date is valid
  if (!date.isValid) {
    throw new Error(
      `Invalid date format: ${dateString}. Expected format: YYYY-MM-DD`
    );
  }

  // Format the date to "MMM DD, YYYY" format
  return date.toFormat('MMM dd, yyyy');
}
