import styled from 'styled-components';
import { CardBody, ModalPrimaryActionButton, } from '@la/ds-ui-components';
export const DivisionCard = styled.div ``;
export const DivisionCardSection = styled(CardBody) `
  &:nth-child(odd) {
    background-color: var(--primary-35);
  }
`;
export const ConfirmationSentence = styled.div `
  margin: 16px 0 0;
  font: var(--ui-large-bold);
`;
export const UnavailableInfo = styled.div `
  color: var(--blue-grey-600);
`;
export const UnavailableDivisionCard = styled(DivisionCard) `
  > div {
    color: ${({ theme }) => theme.palette.foundational.blueGrey[800]};

    > div {
      border: ${({ theme }) => `1px solid ${theme.palette.foundational.blueGrey[400]}`};
    }

    > div > div:first-child {
      background-color: ${({ theme }) => theme.palette.foundational.blueGrey[35]};
    }
  }
`;
export const CreateTeamFormSubmitButton = styled(ModalPrimaryActionButton) `
  min-width: 132px;
`;
