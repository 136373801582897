/*
  if authentication isn't verified this redirects to a login page
*/
export function verifyAuth() {
    const isAuthenticated = getUserIsAuthenticated();
    if (!isAuthenticated) {
        requestLIPToken();
        return false;
    }
    return true;
}
export function getUserIsAuthenticated() {
    return document.cookie.includes('lip.access-token');
}
export function requestLIPToken() {
    const { host, pathname, search } = window.location;
    const hostParts = host.split('.');
    let requestDomain, requestSubDomain;
    if (hostParts[0].indexOf('la-member-portal') > -1) {
        requestDomain = 'lapps-dev1';
        requestSubDomain = 'svssc';
    }
    else {
        requestDomain = hostParts[hostParts.length - 2];
        requestSubDomain = hostParts[0];
    }
    const redirectToLIPUrl = `https://${requestSubDomain}.${requestDomain}.com/login?redirect_uri=${pathname}${search}`;
    window.location.assign(redirectToLIPUrl);
}
/*
    Effectively deletes a cookie by setting its expiration to 1/1/1970
   */
export function deleteCookie(name, path, requestDomain) {
    if (hasCookie(name)) {
        document.cookie =
            name +
                '=' +
                (path ? ';path=' + path : '') +
                (requestDomain ? ';domain=' + requestDomain : '') +
                ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
}
/*
    Extracts an individual cookie by name from all site cookies and returns it
   */
export function getCookie(name) {
    if (!hasCookie(name)) {
        return null;
    }
    const c = document.cookie.split(';');
    const idx = c.filter((ck) => {
        return ck.trim().startsWith(name + '=');
    });
    return idx[0];
}
/*
    Verifies that the cookie being looked for exists for this domain
   */
export function hasCookie(name) {
    return document.cookie.split(';').some((c) => {
        return c.trim().startsWith(name + '=');
    });
}
/**
 * Extracts an access token from the LIP Cookie
 * If the cookie is not present it returns null
 * @returns {string | null}
 */
export function extractAccessToken() {
    const LIPCookie = getCookie('lip.access-token');
    return LIPCookie ? LIPCookie.substring(LIPCookie.indexOf('=') + 1) : null;
}
/*
    Checks for authentication.
    If not authenticated it redirects to a login page and returns null.
    If authenticated it extracts and returns an access token from the LIP cookie.
   */
export function getAccessToken() {
    return verifyAuth() ? extractAccessToken() : null;
}
