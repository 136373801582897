import parse, {
  HTMLReactParserOptions,
  domToReact,
  Element,
  DOMNode,
} from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components/macro';
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  ThemedComponent,
  Typography,
} from '@la/ds-ui-components';
import { media } from 'lib/media-queries/mixins';

export type StyledHTMLProps = {
  content?: string;
};

function isTag(node: DOMNode): node is Element {
  return node.type === 'tag';
}

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (isTag(domNode) && domNode.children) {
      const children = domToReact(domNode.children, options);
      switch (domNode.name) {
        case 'h1':
          return (
            <Typography customTag="h1" variant="headline" size="xl">
              {children}
            </Typography>
          );
        case 'h2':
          return (
            <Typography customTag="h2" variant="headline" size="large">
              {children}
            </Typography>
          );
        case 'h3':
          return (
            <Typography customTag="h3" variant="headline" size="medium">
              {children}
            </Typography>
          );
        case 'h4':
          return (
            <Typography customTag="h4" variant="headline" size="small">
              {children}
            </Typography>
          );
        case 'h5':
          return (
            <Typography customTag="h5" variant="headline" size="xs">
              {children}
            </Typography>
          );
        case 'h6':
          return (
            <Typography customTag="h6" variant="headline" size="xs">
              {children}
            </Typography>
          );
        case 'p':
          return (
            <Typography variant="ui" size="large">
              {children}
            </Typography>
          );
        // Disabling usage of `Link` from DS until it is fixed
        // case 'a':
        //   console.log(domNode);
        //   return (
        //     <Link {...domNode.attribs} variant="text" hasPadding={false}>
        //       {children}
        //     </Link>
        //   );
        case 'table':
          return <Table>{children}</Table>;
        case 'tr':
          return <TableRow>{children}</TableRow>;
        case 'th':
          // TableHeader children prop must be type string
          if (typeof children === 'string') {
            return <TableHeader>{children}</TableHeader>;
          }
          console.warn(
            '`th` element has a non-string child. This may cause unexpected rendering behavior.'
          );
          return <TableHeader>{children as unknown as string}</TableHeader>;
        case 'td':
          return <TableCell>{children}</TableCell>;
      }
    }
    return domNode;
  },
};

const StylesContainer = styled.div`
  // This margin can be removed after we transition from the local Card component
  // to the DS Card component.
  margin-right: 16px;

  & > :first-child {
    margin-top: 0;
  }

  // The margins used here are still be decided by the design team.
  // The values used below will be updated once the design team has finalized their decision.
  & h1 {
    margin-top: 2.5rem;
  }

  & h2 {
    margin-top: 2rem;
  }

  & h3,
  & h4 {
    margin-top: 1.5rem;
  }

  & h5,
  & h6 {
    margin-top: 1.25rem;
  }

  & p {
    margin-top: 1rem;
  }

  & ol,
  & ul {
    margin-top: 0.5rem;
  }

  & li {
    margin-top: 0.25rem;
  }

  & table {
    margin-top: 1rem;
    th,
    td {
      white-space: normal;
      overflow-wrap: anywhere;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    max-width: 800px;
  }

  & img {
    max-width: 100%;
  }

  & blockquote {
    ${({ theme }: ThemedComponent) => `
      border-left: 4px solid ${theme.palette.foundational.blueGrey[100]};
    `}
    padding: 12px;
    margin: 0.5rem 0;

    // The OG WYSIWYG editor is inserting <br> elements into <blockquote> elements.
    // We need to hide these in order to style the blockquote correctly.
    & br {
      display: none;
    }
  }

  // For video embeds
  & iframe {
    max-width: 100%;
    max-height: 250px;
  }

  ${media.TabletPortraitUp`
    & iframe {
      max-height: 400px;
    }
  `}

  ${media.DesktopUp`
    & iframe {
      max-height: 500px;
    }
  `}
`;

export function StyledHTML({ content }: StyledHTMLProps) {
  if (!content) {
    return null;
  }

  const sanitizedContent = sanitizeHtml(content, {
    allowedTags: [
      'a',
      'b',
      'blockquote',
      'br',
      // Omitting `code` tag on purpose
      // The 'code' element is an option in the OG WYSIWYG but we are not supporting it here
      'div',
      'figcaption',
      'figure',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'i',
      // Needed for embedded videos
      'iframe',
      'img',
      'li',
      'ol',
      'p',
      'span',
      'table',
      'tbody',
      'td',
      'th',
      'thead',
      'tr',
      'u',
      'ul',
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      iframe: [
        'src',
        'width',
        'height',
        'title',
        'allow',
        'frameborder',
        'allowfullscreen',
      ],
    },
    // Using this to completely remove unsupported tags AND their children
    // Otherwise nest text nodes will be kept
    // See: https://www.npmjs.com/package/sanitize-html#discarding-the-entire-contents-of-a-disallowed-tag
    nonTextTags: [
      'style',
      'script',
      'noscript',
      'abbr',
      'address',
      'audio',
      'base',
      'button',
      'canvas',
      'cite',
      'code',
      'data',
      'datalist',
      'dd',
      'del',
      'details',
      'dfn',
      'dialog',
      'dl',
      'dt',
      'em',
      'embed',
      'fieldset',
      'form',
      'hr',
      'input',
      'ins',
      'kbd',
      'keygen',
      'label',
      'legend',
      'mark',
      'menu',
      'menuitem',
      'meta',
      'meter',
      'option',
      'output',
      'param',
      'pre',
      'progress',
      'q',
      's',
      'samp',
      'select',
      'small',
      'source',
      'strong',
      'sub',
      'summary',
      'sup',
      'template',
      'textarea',
      'time',
      'title',
      'track',
      'var',
      'video',
    ],
  });
  const parsedContent = parse(sanitizedContent, options);

  return <StylesContainer>{parsedContent}</StylesContainer>;
}
