var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { uploadFile } from './uploadFile';
export const uploadCustomFileField = ({ fields, siteId, userId, }) => __awaiter(void 0, void 0, void 0, function* () {
    const formattedFields = [];
    for (const field of fields) {
        if (field.type !== 'FILE_UPLOAD') {
            formattedFields.push(field);
            continue;
        }
        if (!siteId || !field.value || !siteId || !userId) {
            formattedFields.push(field);
            continue;
        }
        const { uuid } = yield uploadFile({
            siteId: siteId.toString(),
            file: field.value.file,
            filename: field.value.name,
            propertyDefinitionId: field.propertyDefinitionId.toString(),
            userId: userId.toString(),
        });
        const fieldWithFile = Object.assign(Object.assign({}, field), { value: Object.assign(Object.assign({}, field.value), { uuid }) });
        formattedFields.push(fieldWithFile);
    }
    return formattedFields;
});
