import axios from 'axios';
import type {
  FormFieldParameters,
  FormField,
  NonFileUploadFormField,
  FileUploadFormField,
  UserType,
} from '@la/types';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

const baseUrl = getBaseURL();

const getFormFields = async ({
  siteId,
  programId,
  playerRoles,
  staffRoles,
  includeDeleted,
}: FormFieldParameters) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/programs/${programId}/formFields`;
  const token = extractAccessToken();
  const response = await axios.get<FormField[]>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      playerRoles: !playerRoles ? undefined : playerRoles.join(','),
      staffRoles: !staffRoles ? undefined : staffRoles.join(','),
      userType: 'adult',
      includeDeleted,
    },
  });

  const fields = response.data.filter(
    (field) => field.category !== 'TEAM' && field.visibility === 'public'
  );
  const sortedFields = fields.sort((a, b) => a.order - b.order);

  return {
    nonFileUploadFormFields: sortedFields.filter(
      (field): field is NonFileUploadFormField => field.type !== 'FILE_UPLOAD'
    ),
    fileUploadFormFields: sortedFields.filter(
      (field): field is FileUploadFormField => field.type === 'FILE_UPLOAD'
    ),
  };
};

const getMemberFormFields = async ({
  siteId,
  userType,
  playerRoles,
  staffRoles,
}: {
  siteId: string;
  userType: UserType;
  playerRoles?: string[];
  staffRoles?: number[];
}) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/formFields/memberProfile`;
  const token = extractAccessToken();
  const response = await axios.get<FormField[]>(endpoint, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      userType: userType !== 'any' ? userType : '',
      playerRoles: playerRoles ? playerRoles.join(',') : undefined,
      staffRoles: staffRoles ? staffRoles.join(',') : undefined,
    },
  });

  const fields = response.data.filter((field) => field.category !== 'TEAM');

  return fields.sort((a, b) => a.order - b.order);
};

const updateFormField = async ({
  siteId,
  formFieldId,
  values,
}: {
  siteId: string;
  formFieldId: string;
  values: {
    registrationId?: string;
    userId?: string;
    value: string | number;
  }[];
}) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/formFields/${formFieldId}`;
  const token = extractAccessToken();
  let formattedValues = values.map(({ registrationId, userId, value }) => ({
    registrationId: registrationId ? parseInt(registrationId) : undefined,
    userId: userId ? parseInt(userId) : undefined,
    value,
  }));
  const response = await axios.post(endpoint, formattedValues, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response;
};

export { getFormFields, getMemberFormFields, updateFormField };
