import {
  Checkbox,
  CloseCircleIcon,
  IconButton,
  Typography,
} from '@la/ds-ui-components';
import { FacadeRegistrant } from '@la/types';
import { isPlayer } from '@la/utilities';
import { RolloverTeamType } from '../../../../utils/team';
import { RegistrantState } from '../types/member';
import * as S from './MemberTile.styles';

export const REMOVE_BUTTON_LABEL = 'remove member';

export type MemberTileProps = RegistrantState & {
  /**
   * Whether or not the tile should be disabled. Prevents selection
   * and dragging.
   */
  disabled?: boolean;
  /**
   * Whether or not the tile should appear is actively being dragged.
   */
  active?: boolean;
  /**
   * Triggered when the remove button is clicked.
   * @param member The player/staff to remove.
   */
  onRemoveClick?: (member: FacadeRegistrant) => void;
  /**
   * Triggered when the select state of the tile is changed.
   * @param selected Whether or not the checkbox is checked.
   * @param member The player/staff whose seleciton state has changed.
   * @returns
   */
  onSelectChange?: (selected: boolean, member: FacadeRegistrant) => void;
  /**
   * Whether or not the checkbox should be selected.
   */
  selected?: boolean;
  /**
   * The type of team (ORIGIN or DESTINATION). Determines the ids generated for
   * member tile.
   */
  teamType: RolloverTeamType;
};

export function MemberTile(props: Readonly<MemberTileProps>) {
  const {
    active = false,
    ageGroup,
    disabled,
    name,
    onRemoveClick,
    onSelectChange,
    selected,
    teamType,
  } = props;

  const roleType = getRoleType(props);

  const onCheckedChange = (checked: boolean): void => {
    if (onSelectChange) {
      onSelectChange(checked, props);
    }
  };

  return (
    <S.MemberTileBorder
      $active={active}
      $removeable={!!onRemoveClick}
      $selected={selected}
      $selectable={!!onSelectChange && !disabled}
      $disabled={disabled}
    >
      <S.MemberTile
        $active={active}
        $selectable={!!onSelectChange && !disabled}
        $selected={selected}
        $disabled={disabled}
      >
        {!disabled ? (
          <Checkbox
            ariaLabel={getMemberTileLabel(ageGroup, props)}
            checked={selected}
            id={getMemberTileId(props.registeredUserId, teamType, roleType)}
            onCheckedChange={onCheckedChange}
            size="large"
          />
        ) : null}
        <S.MemberTileDetails
          $active={active}
          $disabled={disabled}
          $selectable={!!onSelectChange && !disabled}
          $selected={selected}
          onClick={() => onCheckedChange(!selected)}
        >
          <Typography size="large" variant="ui">
            {name}
          </Typography>
          <Typography size="small" variant="ui">
            {isPlayer(props)
              ? getPlayerAgeGroupText(props.ageGroup)
              : props.roles.map((r) => r.name).join(', ')}
          </Typography>
        </S.MemberTileDetails>
        <S.RemoveIcon>
          <IconButton
            ariaLabel={REMOVE_BUTTON_LABEL}
            icon={<CloseCircleIcon fill="var(--red)" variant="filled" />}
            onClick={() => onRemoveClick?.(props)}
            variant="text"
          />
        </S.RemoveIcon>
      </S.MemberTile>
    </S.MemberTileBorder>
  );
}

export function getRoleType(registrant: FacadeRegistrant): string {
  return isPlayer(registrant) ? 'PLAYER' : 'STAFF';
}

export function getMemberTileId(
  userId: number,
  teamType: RolloverTeamType,
  roleType?: string
): string {
  const teamTypeText =
    teamType === RolloverTeamType.Destination ? 'destination' : 'origin';
  if (roleType === undefined) {
    return `${teamTypeText}-member-${userId}`;
  }
  return `${teamTypeText}-${roleType === 'PLAYER' ? 'player' : 'staff'}-${userId}`;
}

export function getPlayerAgeGroupText(ageGroup?: number | null): string {
  return ageGroup ? `${ageGroup}u` : '';
}

export function getMemberTileLabel(
  ageGroup: number | undefined | null,
  registrant: FacadeRegistrant
): string {
  const roleType = getRoleType(registrant);
  if (isPlayer(registrant)) {
    const ageGroupText = getPlayerAgeGroupText(ageGroup);
    return `${roleType} ${registrant.name} ${ageGroupText}`;
  }

  return `${roleType} ${registrant.name} ${registrant.roles.map((r) => r.name).join()}`;
}
