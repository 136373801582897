/**
 * Maps properties from custom form fields to the format that the
 * registration workflow expects
 * @param formField Custom form field
 * @returns The format that the registration workflow expects
 */
export const mapFormFieldToWorkflow = (formField) => {
    var _a, _b;
    let values;
    if (!formField.value) {
        values = [];
    }
    else if (formField.type === 'FILE_UPLOAD') {
        values = [(_a = formField.value) === null || _a === void 0 ? void 0 : _a.name, (_b = formField.value) === null || _b === void 0 ? void 0 : _b.uuid];
    }
    else if (formField.type === 'MULTIPLE_CHECKBOXES') {
        values = formField.value;
    }
    else {
        values = [formField.value];
    }
    return { values, formFieldId: formField.propertyDefinitionId };
};
/**
 * Filters array of form fields with their values uniquely. The first occurrence
 * of a `formFieldId` will be kept.
 * @param formFields Array of form fields that may or may not have reoccurring `formFieldId`s.
 * @returns Set of form fields where each `formFieldId` only occurs once.
 */
export const removeDuplicateFormFields = (formFields) => {
    return formFields.reduce((fields, field) => {
        const existingField = fields.find(({ formFieldId }) => formFieldId === field.formFieldId);
        return existingField ? fields : fields.concat(field);
    }, []);
};
