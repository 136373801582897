import { DateTime, Interval } from 'luxon';
export const INVALID_TIMEZONE_ERROR_MESSAGE = 'Timezone provided was not valid.';
export const START_DATE_BEFORE_END_DATE_ERROR_MESSAGE = 'The start date cannot come before the end date.';
export const INVALID_MULTIPLE_DATE_ERROR_MESSAGE = 'At least one date provided was not valid.';
export const INVALID_DATE_ERROR_MESSAGE = 'The date provided was not valid.';
/**
 * Sets the timezone for a provided date. This does not change the actual time,
 * rather it adjusts the offset based on the provided timezone.
 *
 * Example:
 *
 * Before: America/New_York
 * 2023-04-04T00:00:00.000-05:00
 *
 * After: America/Los_Angeles
 * 2023-04-04T00:00:00.000-08:00
 *
 * @param dates ISO formatted date strings.
 * @param timezone Timezone for the dates to converted into.
 * @returns `DateTime` array of all dates with their origin timezone set
 * as the provided timezone.
 */
export const setDatesTimezone = (dates, timezone) => {
    const isTimezoneValid = DateTime.now().setZone(timezone).isValid;
    if (!isTimezoneValid) {
        throw new Error(INVALID_TIMEZONE_ERROR_MESSAGE);
    }
    return dates.map((date) => {
        /**
         * When utilizing the second parameter rather than using the setZone()
         * method, it will create the DateTime object in the context of that timezone
         * such that only the offset is affected and not the actual time.
         */
        const dateObject = DateTime.fromISO(date, {
            zone: timezone,
            setZone: true,
        });
        if (!dateObject.isValid) {
            throw new Error(INVALID_MULTIPLE_DATE_ERROR_MESSAGE);
        }
        return dateObject;
    });
};
/**
 * Checks whether or not the current date is within a given date range.
 * @param start Start date of the range in ISO format.
 * @param end End date of the range in ISO format.
 * @param timezone Timezone to take into account.
 * @returns True if the current date falls with the start and end date
 * range, false otherwise.
 */
export const isWithinDateRange = (start, end, timezone) => {
    let [startDate, endDate] = setDatesTimezone([start, end], timezone);
    startDate = startDate.startOf('day');
    endDate = endDate.endOf('day');
    if (startDate > endDate) {
        throw new Error(START_DATE_BEFORE_END_DATE_ERROR_MESSAGE);
    }
    const currentDate = DateTime.now().setZone(timezone);
    return Interval.fromDateTimes(startDate, endDate).contains(currentDate);
};
const SHORTENED_MONTHS = [
    'January',
    'February',
    'August',
    'September',
    'October',
    'November',
    'December',
];
/**
 * Shortnes long month names. If month is before March or after July, the
 * month is shortened.
 * @param month The month name. May be null due to month names being
 * a locale-based property of dates.
 * @returns Month name or "Invalid Month" if `month` is `null`.
 */
export const shortenLongMonthNames = (month) => {
    if (!month) {
        return 'Invalid Month';
    }
    if (SHORTENED_MONTHS.includes(month)) {
        // Months before March and after July are shortened.
        return `${month.substring(0, 3)}.`;
    }
    return month;
};
export function formatDateUsingShortMonthName(date) {
    if (!date.isValid) {
        throw new Error(INVALID_DATE_ERROR_MESSAGE);
    }
    const dayYearFormat = 'd, yyyy';
    const { monthLong } = date;
    const formattedMonth = shortenLongMonthNames(monthLong);
    return `${formattedMonth} ${date.toFormat(dayYearFormat)}`;
}
/**
 * Formats given start and end dates into a date range, depending on
 * whether the two dates have the same year, month, and or day.
 * @param start Beginning date
 * @param end Ending date
 * @returns Date range joining the `start` and `end` dates. Or, if either
 * of the dates are invalid, returns "Invalid Date Range".
 * - Different years: `month day, year - month day, year`
 * - Different months: `month day - month day, year`
 * - Different days: `month day-day, year`
 * - Same month, day, and year: `month day, year`
 */
export const formatDateRange = (start, end) => {
    if (!start.isValid || (end && !end.isValid)) {
        throw new Error(INVALID_MULTIPLE_DATE_ERROR_MESSAGE);
    }
    const dayYearFormat = 'd, yyyy';
    const { day: startDay, monthLong: startMonth, year } = start;
    const formattedStartMonth = shortenLongMonthNames(startMonth);
    if (!end) {
        return `Starts on ${startMonth} ${start.toFormat(dayYearFormat)}`;
    }
    const { day: endDay, monthLong: endMonth } = end;
    const formattedEndMonth = shortenLongMonthNames(endMonth);
    if (start.year !== end.year) {
        // month day, year-month day, year
        return (`${formattedStartMonth} ${start.toFormat(dayYearFormat)} - ` +
            `${formattedEndMonth} ${end.toFormat(dayYearFormat)}`);
    }
    if (startMonth !== endMonth) {
        // month day-month day, year
        return (`${formattedStartMonth} ${startDay} - ` +
            `${formattedEndMonth} ${endDay}, ${year}`);
    }
    if (startDay !== endDay) {
        // month day-day, year
        return `${formattedStartMonth} ${startDay}-${endDay}, ${year}`;
    }
    // month day, year
    return `${formattedStartMonth} ${start.toFormat(dayYearFormat)}`;
};
export const formatToISO = (year, month) => `${DateTime.fromObject({ year: year, month: month }).toISO()}`;
