var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef, useState, } from 'react';
import { Button, CloseIcon, Typography, } from '@la/ds-ui-components';
import { detectOutsideEvent } from '@la/utilities';
import * as S from './SlideOutMenu.styles';
export const SlideOutMenuPrimaryActionButton = forwardRef((_a, ref) => {
    var { size, disabled, loading } = _a, props = __rest(_a, ["size", "disabled", "loading"]);
    return (_jsx(Button, Object.assign({}, props, { disabled: disabled, loading: loading, size: "large", variant: "primary", width: "100%", ref: ref })));
});
export function SlideOutMenu({ children, onClickOutside, onClose, primaryAction, side = 'left', title, open, }) {
    const [showMenu, setShowMenu] = useState(open);
    const menuContainerRef = useRef(null);
    const onAnimationEnd = () => {
        if (!open) {
            setShowMenu(false);
        }
    };
    useEffect(() => {
        if (open) {
            setShowMenu(true);
        }
    }, [open]);
    useEffect(() => {
        const outsideClickHandler = (e) => {
            if (!!menuContainerRef.current) {
                detectOutsideEvent(e, [menuContainerRef.current], () => {
                    if (onClickOutside) {
                        return onClickOutside();
                    }
                    return onClose();
                });
            }
        };
        window.addEventListener('pointerdown', outsideClickHandler);
        return () => {
            window.removeEventListener('pointerdown', outsideClickHandler);
        };
    }, [onClickOutside, onClose]);
    const primaryActionButton = primaryAction ? (_jsx(S.MenuActionsFooter, { children: primaryAction })) : null;
    return showMenu ? (_jsx(S.MenuBackdrop, Object.assign({ "$transparent": false, "$open": open, onAnimationEnd: onAnimationEnd }, { children: _jsxs(S.MenuContainer, Object.assign({ "aria-hidden": !open, ref: menuContainerRef, "$open": open, "$side": side, onAnimationEnd: onAnimationEnd }, { children: [_jsxs(S.MenuHeader, { children: [_jsx(Typography, Object.assign({ variant: "headline", size: "small" }, { children: title })), _jsx(S.HeaderCloseIcon, { ariaLabel: "Close", icon: _jsx(CloseIcon, { fill: "white" }), onClick: onClose, variant: "text" })] }), _jsx(S.MenuBody, { children: children }), primaryActionButton] })) }))) : null;
}
