import { Button } from '@la/ds-ui-components';
import { Roster } from '@la/types';
import { RolloverTeamType } from '../../utils/team';
import { RolloverTeam, RolloverTeamState } from './RolloverTeam/RolloverTeam';
import { RegistrantState } from './RolloverTeam/types/member';
import * as S from './RolloverDropzone.styles';

/* OriginRolloverDropzone */
export const NO_TEAM_SELECTED_LABEL = 'Select a team';

export type OriginRolloverDropzoneProps = {
  /**
   * Triggered when a member is no longer being dragged from the dropzone.
   */
  onDragEnd: () => void;
  /**
   * Triggered when a member starts being dragged from the dropzone.
   */
  onDragStart: () => void;
  /**
   * Triggered when players' and/or staff's selection state is changed.
   * @param state The changed selection state.
   */
  onSelectedStateChange?: (state: RolloverTeamState) => void;
  /**
   * When the user clicks a trigger for the team selection modal.
   */
  onTeamSelectionClick: () => void;
  /**
   * Current members state (e.g. selected, disabled state).
   */
  rolloverMembersState: RolloverTeamState | null;
  /**
   * Members selected (but not necessarily rolled over) in the origin team roster.
   */
  selectedMembers: RegistrantState[];
  /**
   * Team roster the user is rolling members from. Can be undefined.
   */
  teamRoster?: Roster;
};

export function OriginRolloverDropzone({
  onDragEnd,
  onDragStart,
  onSelectedStateChange,
  onTeamSelectionClick,
  rolloverMembersState,
  selectedMembers,
  teamRoster,
}: Readonly<OriginRolloverDropzoneProps>) {
  return (
    <S.Dropzone
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      $hasSelectedTeam={!!teamRoster}
    >
      {teamRoster ? (
        <RolloverTeam
          onSelectedStateChange={onSelectedStateChange}
          onTeamSelectionClick={onTeamSelectionClick}
          rolloverMembersState={rolloverMembersState}
          selectedMembers={selectedMembers}
          teamRoster={teamRoster}
          type={RolloverTeamType.Origin}
        />
      ) : (
        <Button onClick={onTeamSelectionClick} size="large" variant="primary">
          {NO_TEAM_SELECTED_LABEL}
        </Button>
      )}
    </S.Dropzone>
  );
}
/* */
