import styled from 'styled-components';
export const EditTeamsContainer = styled.div({
    display: 'flex',
});
export const TeamsLeftPanel = styled.div({
    position: 'fixed',
    top: 48,
    left: 0,
    width: 220,
    height: 'calc(100% - 106px)',
});
export const TeamLeftPanelInnerContainer = styled.div({
    marginLeft: 24,
    marginTop: 16,
    maxHeight: 'calc(100% - 16px)',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
        width: 0,
        backgroundColor: 'transparent',
    },
});
export const EditTeamsContent = styled.div(({ $isTabletPortraitUp }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: $isTabletPortraitUp ? 220 : 0,
        overflowX: 'hidden',
    };
});
export const TeamItemContainer = styled.div(({ theme, $isSelected }) => {
    const { foundational: { blueGrey, green, white }, semantic, } = theme;
    const borderColor = semantic ? semantic.primary[600] : green[600];
    const backgroundColor = semantic ? semantic.primary[35] : green[35];
    return {
        padding: 8,
        cursor: 'pointer',
        borderRight: $isSelected ? `4px solid ${borderColor}` : 'none',
        backgroundColor: $isSelected ? backgroundColor : white,
        borderRadius: 4,
        '& p': {
            color: $isSelected ? blueGrey[600] : blueGrey[900],
        },
        '&:hover': {
            backgroundColor,
        },
    };
});
export const FormFieldsContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
});
export const TeamContainer = styled.div(({ theme }) => {
    const { foundational: { green }, semantic, } = theme;
    const borderColor = semantic ? semantic.primary[100] : green[100];
    return {
        width: '100%',
        padding: '32px 0',
        borderBottom: `1px solid ${borderColor}`,
    };
});
export const TeamFieldsContainer = styled.div(({ $isTabletPortraitUp }) => {
    return {
        width: '100%',
        display: 'flex',
        flexDirection: $isTabletPortraitUp ? 'row' : 'column',
        alignItems: $isTabletPortraitUp ? 'flex-end' : 'flex-start',
        gap: 8,
    };
});
export const CustomField = styled.div({
    width: '99%',
    '& label': {
        whiteSpace: 'break-spaces',
        textAlign: 'left',
    },
});
