import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import {
  Modal,
  Button,
  TextInput,
  Select,
  SelectOption,
  CalendarIcon,
  Form,
} from '@la/ds-ui-components';
import { createValidationSchema } from '@la/shared-components';
import { FormField } from '@la/types';
import {
  getMaxZipCodeHandlers,
  isValidEmail,
  VALID_EMAIL_ADDRESS_ERROR,
  ZIP_CODE_MAX_LENGTH,
} from '@la/utilities';
import { DatePicker } from 'components/DatePicker/DatePicker';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { Address, User } from 'lib/apis/player';
import { useRegistration } from 'lib/context/RegistrationContext/RegistrationContext';
import { getSelectOptions } from 'lib/utils/select';
import { useGetCountriesAndAdministrativeDivisionsQuery } from 'redux/services/countryApi';
import {
  CustomFields,
  CustomFieldsFormFields,
} from './CustomFieldsForm/CustomFieldsForm';
import {
  extractRegistrationOptionValue,
  isWithinAgeRange,
} from './RegistrationInfoCard';
import * as S from './RegistrationInfoCard.styles';

export const PLAYER_DETAILS_FORM_ID = 'player-details-form';

export const EDIT_PLAYER_HEADER = 'Player details';
export const EDIT_PLAYER_FORM_DESCRIPTION = 'Enter details about your player';
export const EDIT_PLAYER_BUTTON_LABEL = 'Save player';
export const CREATE_PLAYER_HEADER = 'Create new player';
export const CREATE_PLAYER_FORM_DESCRIPTION =
  'Provide more information on the new player you are creating.';
export const CREATE_PLAYER_BUTTON_LABEL = 'Create player';

export const FIELD_REQUIRED_ERROR = 'This field is required.';

export const MIN_PLAYER_EMAIL_AGE = 13;
export const PLAYER_AGE_ERROR =
  "This player's age falls outside of the tournament requirements";

export type PlayerDetailsFormFields = User & Omit<Address, 'address2'>;

export const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

export type PlayerDetailsModalProps = {
  /**
   * Whether or not a child's birthday is required when creating a user.
   */
  childBirthDateRequired: boolean;
  /**
   * Whether or not a child's email can be collected.
   */
  childEmailEnabled: boolean;
  /**
   * Default values for the player details form fields.
   */
  defaultValues?: PlayerDetailsFormFields;
  /**
   * Error message to display.
   */
  error?: string;
  /**
   * Determines the text to be rendered when the modal is rendered.
   */
  isEditing?: boolean;
  /**
   * Whether or not the modal should show loading states.
   */
  isLoading?: boolean;
  memberFormFields: FormField[];
  memberFormFieldsName: string;
  onOpenChange: (open: boolean) => void;
  /**
   * Called when the form is submitted.
   * @param playerDetails The player details form values.
   */
  onSubmit: (
    playerDetails: PlayerDetailsFormFields & CustomFieldsFormFields
  ) => void;
  /**
   * True if the modal should be open, false otherwise.
   */
  open?: boolean;
  structuredStateEnabled: boolean;
};

const PlayerDetailsModal = ({
  childBirthDateRequired,
  childEmailEnabled,
  defaultValues,
  error,
  isEditing,
  isLoading,
  memberFormFields,
  memberFormFieldsName,
  onOpenChange,
  onSubmit,
  open,
  structuredStateEnabled,
}: PlayerDetailsModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { data: countriesAndAdministrativeDivisions } =
    useGetCountriesAndAdministrativeDivisionsQuery();
  const statesUSA = countriesAndAdministrativeDivisions?.statesUSA ?? {};
  const statesUSAOptions: SelectOption[] = getSelectOptions(statesUSA);

  const { registrationOptions } = useRegistration();

  const playerDetailsFormValidation = yup
    .object({
      firstName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
      lastName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
      gender: yup.string(),
      birthdate: yup
        .string()
        .test('is-required', FIELD_REQUIRED_ERROR, (value) => {
          if (childBirthDateRequired && !value) {
            return false;
          }

          return true;
        })
        .test('is-within-rage', PLAYER_AGE_ERROR, (value) => {
          const effectiveDate = extractRegistrationOptionValue(
            registrationOptions,
            'effective_date'
          );
          const maximumAge = extractRegistrationOptionValue(
            registrationOptions,
            'maximum_age'
          );

          return isWithinAgeRange(
            value,
            effectiveDate as string,
            maximumAge as number
          );
        }),
      email: yup
        .string()
        .trim()
        .test('is-valid-email', VALID_EMAIL_ADDRESS_ERROR, (email, context) => {
          const birthdate = context.parent.birthdate;
          if (childEmailEnabled && shouldAllowChildEmail(birthdate)) {
            return !email || isValidEmail(email);
          }
          return true;
        }),
      address1: yup.string().trim().required(FIELD_REQUIRED_ERROR),
      city: yup.string().trim().required(FIELD_REQUIRED_ERROR),
      state: yup.string().required(FIELD_REQUIRED_ERROR),
      zipCode: yup.string().trim().required(FIELD_REQUIRED_ERROR),
    })
    .concat(createValidationSchema(memberFormFieldsName));

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      [memberFormFieldsName]: memberFormFields,
    },
    resolver: yupResolver(playerDetailsFormValidation),
  });

  const {
    formState: { errors, isSubmitting, submitCount },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = methods;

  const birthdate = watch('birthdate');
  const showChildEmail = childEmailEnabled
    ? shouldAllowChildEmail(birthdate)
    : false;

  useEffect(() => {
    if (!open) {
      reset({
        ...defaultValues,
        [memberFormFieldsName]: memberFormFields,
      });
    }
  }, [defaultValues, memberFormFields, memberFormFieldsName, open, reset]);

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      title={isEditing ? EDIT_PLAYER_HEADER : CREATE_PLAYER_HEADER}
      primaryAction={
        <Button form={PLAYER_DETAILS_FORM_ID} type="submit" loading={isLoading}>
          {isEditing ? EDIT_PLAYER_BUTTON_LABEL : CREATE_PLAYER_BUTTON_LABEL}
        </Button>
      }
      tertiaryAction={
        <Button variant="text" onClick={() => onOpenChange(false)}>
          Cancel
        </Button>
      }
    >
      {error ? <ErrorCard message={error} /> : null}
      <S.PlayerDetailsFormDescription>
        {isEditing
          ? EDIT_PLAYER_FORM_DESCRIPTION
          : CREATE_PLAYER_FORM_DESCRIPTION}
      </S.PlayerDetailsFormDescription>
      <FormProvider {...methods}>
        <Form
          id={PLAYER_DETAILS_FORM_ID}
          noValidate
          onSubmit={handleSubmit((originalValues) => {
            const { email: _, ...restValues } = originalValues;
            const values = showChildEmail ? originalValues : restValues;
            containerRef.current?.scrollIntoView();
            if (!isSubmitting) {
              onSubmit(values as any);
            }
          })}
        >
          <S.InputGroupContainer ref={containerRef}>
            <S.InputContainer>
              <TextInput
                {...register('firstName')}
                errorMessage={errors.firstName?.message}
                hasError={!!errors.firstName}
                id="firstName"
                label="Player first name"
                required
                size="medium"
              />
            </S.InputContainer>
            <S.InputContainer>
              <TextInput
                {...register('lastName')}
                errorMessage={errors.lastName?.message}
                hasError={!!errors.lastName}
                id="lastName"
                label="Player last name"
                required
                size="medium"
              />
            </S.InputContainer>
          </S.InputGroupContainer>
          <S.InputGroupContainer>
            <S.InputContainer>
              <Select
                {...register('gender')}
                errorMessage={errors.gender?.message}
                hasError={!!errors.gender}
                id="gender"
                label="Gender"
                options={genderOptions}
                onChange={(gender) => {
                  if (
                    gender === undefined ||
                    gender === 'Male' ||
                    gender === 'Female'
                  ) {
                    setValue('gender', gender);
                  }
                }}
                placeholder="Select a gender"
                size="medium"
              />
            </S.InputContainer>
            <S.TextInputContainer>
              <DatePicker
                date={watch('birthdate') ?? ''}
                format="M/d/yyyy"
                fullWidth
                input={(
                  formattedDate,
                  { isCalendarOpen, toggleCalendar, ...props }
                ) => (
                  <TextInput
                    {...props}
                    endAdornment={<CalendarIcon />}
                    errorMessage={errors.birthdate?.message}
                    hasError={!!errors.birthdate}
                    id="birthdate"
                    label="Birth date"
                    name="birthdate"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleCalendar();
                    }}
                    placeholder="mm/dd/yyyy"
                    required={childBirthDateRequired}
                    size="medium"
                    value={formattedDate}
                  />
                )}
                onDateSelect={(date) =>
                  setValue('birthdate', date?.toISODate())
                }
              />
            </S.TextInputContainer>
          </S.InputGroupContainer>
          {showChildEmail ? (
            <S.InputGroupContainer>
              <S.TextInputContainer $columnSpan={2}>
                <TextInput
                  {...register('email')}
                  errorMessage={errors.email?.message}
                  hasError={!!errors.email}
                  id="email"
                  label="Email"
                  size="medium"
                />
              </S.TextInputContainer>
            </S.InputGroupContainer>
          ) : null}

          <S.InputGroupContainer>
            <S.TextInputContainer $columnSpan={2}>
              <TextInput
                {...register('address1')}
                errorMessage={errors.address1?.message}
                hasError={!!errors.address1}
                id="address1"
                label="Street address"
                required
                size="medium"
              />
            </S.TextInputContainer>
          </S.InputGroupContainer>
          <S.AddressDetailsInputGroupContainer>
            <S.TextInputContainer>
              <TextInput
                {...register('city')}
                errorMessage={errors.city?.message}
                hasError={!!errors.city}
                id="city"
                label="City"
                required
                size="medium"
              />
            </S.TextInputContainer>
            <S.InputContainer>
              {!structuredStateEnabled ? (
                <TextInput
                  {...register('state')}
                  errorMessage={errors.state?.message}
                  hasError={!!errors.state}
                  id="state"
                  label="State"
                  maxLength={2}
                  required
                  showCharacterCounter={false}
                  size="medium"
                />
              ) : (
                <Select
                  {...register('state')}
                  errorMessage={errors.state?.message}
                  hasError={!!errors.state}
                  id="state"
                  label="State"
                  onChange={(state) => {
                    setValue('state', state, {
                      shouldValidate: submitCount > 0,
                    });
                  }}
                  options={statesUSAOptions}
                  placeholder="Select a state"
                  required
                  size="medium"
                />
              )}
            </S.InputContainer>
            <S.TextInputContainer>
              <TextInput
                {...register('zipCode')}
                {...getMaxZipCodeHandlers((value?: string) =>
                  setValue('zipCode', value)
                )}
                errorMessage={errors.zipCode?.message}
                hasError={!!errors.zipCode}
                id="zipCode"
                label="Zip code"
                maxLength={ZIP_CODE_MAX_LENGTH}
                required
                showCharacterCounter={false}
                size="medium"
              />
            </S.TextInputContainer>
          </S.AddressDetailsInputGroupContainer>
          {memberFormFields.length ? (
            <CustomFields columns={1} name={memberFormFieldsName} />
          ) : null}
        </Form>
      </FormProvider>
    </Modal>
  );
};

/**
 * Returns whether not child email should be taken into account in validation
 * and rendering. If
 * @param birthdate The birthdate of the child.
 * @returns True if child is at least MIN_PLAYER_EMAIL_AGE years of age. If birthdate
 * does not resolve to a valid date, returns true.
 */
function shouldAllowChildEmail(birthdate?: string | null): boolean {
  const date = DateTime.fromISO(birthdate ?? '');
  return (
    !date.isValid ||
    Math.abs(date.diffNow('years').years) >= MIN_PLAYER_EMAIL_AGE
  );
}

export { PlayerDetailsModal };
