import styled from 'styled-components';
import { AlertContainer } from '../Alert/Alert.styles';
export const RegistrationContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 920px;
  padding: 24px;

  & > h4 {
    margin-bottom: 16px;
  }

  ${AlertContainer} {
    margin: 0 0 16px;
  }
`;
export const StepContainer = styled.div `
  align-self: flex-start;
  margin-bottom: 16px;
`;
