// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ec40QqhD39_v1IV9sjNF{display:flex;flex-direction:column;gap:12px}.ZpdcuIEfkzrztG7YTqlQ{display:flex;align-items:center;justify-content:space-between}.yv7CLX0n0QLqpfYEBiEd{font:var(--ui-large-regular)}.XK9Bnd1nHuCYTV_574pM{display:flex;align-items:center;gap:8px;font:var(--ui-large-bold)}`, "",{"version":3,"sources":["webpack://./src/domains/Checkout/PaymentOptionsCard/PaymentOptionsCard.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,sBACE,4BAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentOptionsBody": `Ec40QqhD39_v1IV9sjNF`,
	"paymentOptionsItem": `ZpdcuIEfkzrztG7YTqlQ`,
	"tournamentName": `yv7CLX0n0QLqpfYEBiEd`,
	"paymentDetail": `XK9Bnd1nHuCYTV_574pM`
};
export default ___CSS_LOADER_EXPORT___;
