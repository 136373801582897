import ErrorCard from 'components/ErrorCard/ErrorCard';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import * as S from './CheckoutPageAlerts.styles';

/* CheckoutPageAlert */
export default function CheckoutPageAlerts() {
  const { submissionErrors, submissionErrorsAreVisible } = useCheckoutInfo();

  function generateSubmissionErrors() {
    return submissionErrors.map((errorMessage, idx) => (
      <S.CheckoutPageAlert key={`error-message-${idx}`}>
        <ErrorCard message={errorMessage} />
      </S.CheckoutPageAlert>
    ));
  }

  return submissionErrorsAreVisible ? <>{generateSubmissionErrors()}</> : null;
}
/* */
