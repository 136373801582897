import { http, HttpResponse } from 'msw';
import { mockRoster } from 'domains/RosterManagement/RosterRollover/__dev__/mockRoster';
import mockInvitesData from 'domains/RosterManagement/__dev__/mockInvites.json';
import mockPlayersData from 'domains/RosterManagement/__dev__/mockPlayersData.json';
import mockStaffData from 'domains/RosterManagement/__dev__/mockStaffData.json';
import mockTeamsData from 'domains/RosterManagement/__dev__/mockTeamsData.json';

const mockRegistrantsData = { players: mockPlayersData, staff: mockStaffData };

export const rosterManagementHandlers = [
  // Download Team Roster CSV
  http.post('*/*/*/teamRoster', () => {
    return new HttpResponse(null, { status: 200 });
  }),
  // Get Registrant Data (Facade API)
  http.get(`*/api/member-portal/*/roster`, () => {
    return HttpResponse.json(mockRoster, { status: 200 });
  }),
  // Get Registrant Data
  http.get(`*/api/member-portal/*/rosters`, () => {
    return HttpResponse.json(mockRegistrantsData, { status: 204 });
  }),
  // Edit Registrant Data
  // rest.post(
  //   `*/api/cart/sites/*/users/*/cart/items/*/delete`,
  //   (req, res, ctx) => {
  //     return res(ctx.status(204), ctx.json({}));
  //   }
  // ),
  // Delete Registrant Data
  // rest.delete(
  //   `*/api/cart/sites/*/users/*/cart/items/*/delete`,
  //   (req, res, ctx) => {
  //     return res(ctx.status(204), ctx.json({}));
  //   }
  // ),
  // Get Player & Staff invite links
  http.get('*/api/member-portal/*/rosters/inviteUrls', () => {
    return HttpResponse.json(mockInvitesData, { status: 204 });
  }),
  // Get Team information for View a different team modal
  http.get('*/api/member-portal/*/rosters/teams', () => {
    return HttpResponse.json(mockTeamsData, { status: 204 });
  }),
  // Delete player from roster
  http.post('*/api/member-portal/*/rosters/removePlayer', () => {
    return HttpResponse.json({}, { status: 204 });
  }),
];
