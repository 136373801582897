import styled from 'styled-components';
import { CardBody, CardHeader, CaretDownIcon } from '@la/ds-ui-components';
export const DivisionSummaryCardHeader = styled(CardHeader) `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
export const DivisionSummaryCardHeaderName = styled.div `
  padding-right: 16px;
  overflow-wrap: anywhere;
`;
export const DivisionSummaryCardMainSection = styled(CardBody) `
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const ShowAndHideButtonIcon = styled(CaretDownIcon) `
  transition: transform 0.2s ease;

  ${({ $isRotated }) => $isRotated
    ? `
      transform: rotate(180deg);
    `
    : null}
`;
/*
 * The table and table container classes were copied from the checkout summary and tweaked to
 * follow the newest style guidelines where the table header has a colored background.
 */
export const DivisionSummaryCardTeamsTableContainer = styled.div `
  padding: 16px 24px;
  background-color: var(--primary-35);
  border-radius: 0px 0px 4px 4px;
`;
export const DivisionSummaryCardTeamsTable = styled.table `
  width: 100%;
  background: var(--white);
  border: 1px solid var(--primary-600);
  border-spacing: 0px;
  border-radius: 4px;
  font: var(--ui-medium-medium);
  color: var(--blue-grey-900);

  thead {
    color: var(--primary-600);
    background-color: var(--primary-25);
    border: 1px solid transparent;
    tr:first-child {
      th,
      td {
        border-bottom: 1px solid var(--primary-300);
      }
    }
  }

  tbody tr td:first-child {
    width: 35%;
  }

  th,
  td {
    padding: 7px 0px 7px 12px;
    text-align: left;
  }

  th:first-child {
    border-top-left-radius: 4px;
  }

  th:last-child {
    border-top-right-radius: 4px;
  }

  th:last-child,
  td:last-child {
    padding: 7px 12px;
  }
`;
