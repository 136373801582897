import styled from 'styled-components';
import { ThemedComponent } from '@la/ds-ui-components';

type FilledSegmentProps = ThemedComponent<{
  $filled: boolean;
  $percentage: number;
}>;

export const FilledSegment = styled.span<FilledSegmentProps>(
  ({ $filled, $percentage, theme }: FilledSegmentProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    maxWidth: 320,
    width: `${$percentage}%`,
    backgroundColor: theme.foundational.green[600],
    borderRadius: $filled ? '4px' : '4px 0 0 4px',

    [theme.mediaQueries.mobile]: {
      maxWidth: 240,
    },
  })
);

type PendingSegmentProps = ThemedComponent<{
  $filled: boolean;
  $hasMin: boolean;
  $offset: number;
  $percentage: number;
}>;

export const PendingSegment = styled.span<PendingSegmentProps>(
  ({ $filled, $hasMin, $offset, $percentage, theme }: PendingSegmentProps) => {
    let borderRadius;
    if (!$hasMin && $filled) {
      borderRadius = '4px';
    } else if ($hasMin && $filled) {
      borderRadius = '0 4px 4px 0';
    }

    return {
      position: 'absolute',
      top: 0,
      left: `${$offset}%`,
      height: '100%',
      width: `${$percentage}%`,
      backgroundColor: theme.foundational.blueGrey[400],
      borderRadius,
    };
  }
);
