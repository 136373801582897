import styled from 'styled-components';

export const StaffRosterContainer = styled.div({
  width: '100%',
  marginTop: 24,
});

export const StaffContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const StaffSelectContainer = styled.div({
  width: 220,
});
