import styled from 'styled-components/macro';
import { ThemedComponent } from '@la/ds-ui-components';

export const SelectedMembers = styled.div<ThemedComponent>(
  ({ theme }: ThemedComponent) => ({
    background: theme.foundational.white,
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  })
);
