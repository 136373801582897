import { ReactNode } from 'react';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { Link, Typography } from '@la/ds-ui-components';
import { Gender } from '@la/types';
import { genderNameMap } from '@la/utilities';
import styles from './DetailsCard.module.scss';
import { getAgeGroups } from './utils/ageGroup';

export const AGE_GROUPS_LABEL = 'Age Groups';
export const GENDER_LABEL = 'Gender';
export const LODGING_LABEL = 'Lodging';

type LabelAndValue = {
  /**
   * A unique ID used for the `key` and for testing purposes
   */
  id: string;
  /**
   * The text for the label
   */
  label: string;
  /**
   * The text for the value
   */
  value: string | string[] | ReactNode;
};

export type DetailsCardProps = {
  /**
   * Age groups of all divisions in tournment
   */
  ageGroups?: number[];
  /**
   * Required value set during program creation, will be displayed capitalized
   */
  gender?: Gender;
  /**
   * Hotel link set at the tournament level. Renders a link to a new tab.
   */
  hotelLink?: string;
  /**
   * Level of the tournament. Label is customizable by partner.
   */
  level?: {
    label: string;
    value: string;
  };
  /**
   * Season of the tournament. Label is customizable by partner.
   */
  season?: {
    label: string;
    value: string;
  };
  /**
   * Required value set at the parent program level, will be displayed as is
   */
  sport: string;
};

/* DetailsCard */
export function DetailsCard({
  ageGroups,
  gender,
  hotelLink,
  level,
  season,
  sport,
}: DetailsCardProps) {
  const details: LabelAndValue[] = [];

  if (ageGroups && ageGroups.length > 0) {
    details.push({
      id: 'tournament-details-age-groups',
      label: AGE_GROUPS_LABEL,
      value: getAgeGroups(ageGroups),
    });
  }
  if (season) {
    details.push({
      id: 'tournament-details-season',
      label: season.label,
      value: season.value,
    });
  }
  details.push({
    id: 'tournament-details-sport',
    label: 'Sport',
    value: sport,
  });
  if (gender) {
    details.push({
      id: 'tournament-details-gender',
      label: GENDER_LABEL,
      value: genderNameMap[gender],
    });
  }
  if (level) {
    details.push({
      id: 'tournament-details-level',
      label: level.label,
      value: level.value,
    });
  }
  if (hotelLink) {
    details.push({
      id: 'tournament-details-hotel-link',
      label: LODGING_LABEL,
      value: (
        <div className={styles.link}>
          <Link href={hotelLink} isBold size="large" target="_blank">
            Learn{' '}
            <span>
              more <ArrowSquareOut weight="bold" />
            </span>
          </Link>
        </div>
      ),
    });
  }

  return (
    <div className={styles.detailsCard}>
      <ul className={styles.details}>
        {details.map((detail) => (
          <li className={styles.detail} key={detail.id}>
            <Typography size="small" uppercase variant="ui">
              {detail.label}
            </Typography>
            <Typography size="large" variant="ui" weight="bold">
              {detail.value}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
}
/* */
