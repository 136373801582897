import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { getSummaryData } from 'redux/checkoutSlice';
import { useAppSelector } from 'redux/store';
import CheckoutBillingSummary from '../CheckoutBillingSummary/CheckoutBillingSummary';
import { CheckoutCardHeader } from '../CheckoutCard/CheckoutCard';
import * as S from './ConfirmationRollup.styles';

/* ConfirmationRollup */
export default function ConfirmationRollup() {
  const { mcShowDiscountCodes } = useFlags();
  const { billingSummary: billingSummaryContext } = useCheckoutInfo();
  const billingSummaryRedux = useAppSelector(getSummaryData);

  const billingSummary = mcShowDiscountCodes
    ? billingSummaryContext
    : billingSummaryRedux;

  return (
    <S.ConfirmationRollup>
      <CheckoutCardHeader name="Your order" />
      {billingSummary ? (
        <CheckoutBillingSummary
          billingSummaryData={billingSummary}
          showHotelLinks
          summaryType="confirmation"
        />
      ) : null}
    </S.ConfirmationRollup>
  );
}
/* */
