import { ExternalLinkIcon, formatAsCurrency, Link } from '@la/ds-ui-components';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import { getHotelLinks } from 'redux/checkoutSlice';
import { useAppSelector } from 'redux/store';
import {
  BillingSummaryItemLineItem,
  CartItemHotelLink,
  CombinedCartItemData,
  DiscountCode,
} from '../Checkout.types';
import styles from './CheckoutBillingSummary.module.scss';
import * as S from './CheckoutBillingSummary.styles';

export type CheckoutBillingSummaryProps = {
  showHotelLinks?: boolean;
  summaryType: 'checkout' | 'confirmation';
};

export const DUE_TODAY = 'Due today:';
export const PAID_TODAY = 'Paid today:';

/* CheckoutBillingSummary */
export default function CheckoutBillingSummary({
  showHotelLinks = false,
  summaryType,
}: CheckoutBillingSummaryProps) {
  const { cartItems } = useConsolidatedCheckout();

  const hotelLinks = useAppSelector(getHotelLinks);

  const hasPaymentOption = cartItems?.some(
    (cartItem) => cartItem.paymentTerm !== 'FULL'
  );
  return (
    <S.CheckoutBillingSummary $summaryType={summaryType}>
      <CheckoutBillingSummaryDetail
        hotelLinks={showHotelLinks && hotelLinks.length > 1 ? hotelLinks : []}
      />
      <TransactionFees />
      <CheckoutBillingSummaryHeader
        summaryType={summaryType}
        hasPaymentOption={hasPaymentOption}
      />
      <CheckoutBillingSummaryMobileFooter
        summaryType={summaryType}
        hasPaymentOption={hasPaymentOption}
      />
    </S.CheckoutBillingSummary>
  );
}
/* */

/*CheckoutBillingSummaryHeader */
function CheckoutBillingSummaryHeader({
  summaryType,
  hasPaymentOption,
}: {
  summaryType: 'checkout' | 'confirmation';
  hasPaymentOption?: boolean;
}) {
  const { dueToday, totalAmount } = useConsolidatedCheckout();
  const rowLabel =
    summaryType === 'checkout'
      ? hasPaymentOption
        ? DUE_TODAY
        : 'Total due:'
      : PAID_TODAY;

  return (
    <div className={styles.checkoutBillingSummaryHeader}>
      <S.DueTodayRow $summaryType={summaryType}>
        <span>{rowLabel}</span>
        <span>{formatAsCurrency(Number(dueToday))}</span>
      </S.DueTodayRow>
      {hasPaymentOption ? (
        <div className={styles.totalPriceRow}>
          <span>Total price:</span>
          <span className={styles.totalPrice}>
            {formatAsCurrency(Number(totalAmount))}
          </span>
        </div>
      ) : null}
    </div>
  );
}
/* */

/*CheckoutBillingSummaryMobileFooter */
function CheckoutBillingSummaryMobileFooter({
  summaryType,
  hasPaymentOption,
}: {
  summaryType: 'checkout' | 'confirmation';
  hasPaymentOption?: boolean;
}) {
  const { dueToday, totalAmount } = useConsolidatedCheckout();
  const rowLabel =
    summaryType === 'checkout'
      ? hasPaymentOption
        ? DUE_TODAY
        : 'Total due:'
      : PAID_TODAY;

  return (
    <div className={styles.checkoutBillingSummaryMobileFooter}>
      {hasPaymentOption ? (
        <div className={styles.totalPriceRow}>
          <span>Total price:</span>
          <span className={styles.totalPrice}>
            {formatAsCurrency(Number(totalAmount))}
          </span>
        </div>
      ) : null}
      <S.DueTodayRow $summaryType={summaryType}>
        <span>{rowLabel}</span>
        {/*TODO: This should maybe be formatted in the BFF?*/}
        <span>{formatAsCurrency(Number(dueToday))}</span>
      </S.DueTodayRow>
    </div>
  );
}
/* */

/*CheckoutBillingSummaryDetail */
function CheckoutBillingSummaryDetail({
  hotelLinks,
}: {
  hotelLinks: CartItemHotelLink[];
}) {
  const { cartItems } = useConsolidatedCheckout();
  function renderSummaryItems() {
    return cartItems?.map((cartItem: CombinedCartItemData, idx: number) => {
      return (
        <SummaryItem
          {...cartItem}
          key={`${cartItem.cartItemName}-${idx}`}
          hotelLink={hotelLinks[idx]?.hotelLink}
        />
      );
    });
  }
  return <div className={styles.summaryItems}>{renderSummaryItems()}</div>;
}
/* */

function SummaryItem({
  cartItemName,
  description,
  dueToday,
  hotelLink,
  lineItems,
  paymentTerm,
  selectedPaymentPlan,
  totalAmount,
}: CombinedCartItemData & { hotelLink?: string }) {
  function formatDiscountValue(discount: DiscountCode): string {
    return 'percentage' in discount && discount.percentage != null
      ? `${discount.percentage}%`
      : formatAsCurrency(Number(discount.fixedAmount));
  }

  function extractDiscountCodesFromLineItems(
    lineItems?: BillingSummaryItemLineItem[]
  ): string | null {
    // Early returns for invalid inputs
    if (!lineItems?.length || !lineItems[0]?.discountCodes?.length) {
      return null;
    }

    try {
      const firstDiscount = lineItems[0].discountCodes[0];
      const codeName = firstDiscount.code;
      const value = formatDiscountValue(firstDiscount);

      return `"${codeName}" - ${value} discount`;
    } catch (error) {
      console.error('Error processing discount code:', error);
      return null;
    }
  }

  const discountCodeLineItem = extractDiscountCodesFromLineItems(lineItems);

  return (
    <div className={styles.summaryItem}>
      <div className={styles.checkoutBillingSummaryPrimaryRow}>
        <span>{cartItemName}:</span>
        <PaymentsLineItem
          dueToday={dueToday}
          paymentTerm={paymentTerm}
          totalAmount={totalAmount}
        />
      </div>
      <div className={styles.checkoutBillingSummarySecondaryRow}>
        <span>{description}</span>
        <span>{selectedPaymentPlan.name}</span>
      </div>
      {discountCodeLineItem ? (
        <div className={styles.checkoutBillingSummarySecondaryRow}>
          <span></span>
          <span>{discountCodeLineItem}</span>
        </div>
      ) : null}
      {hotelLink ? (
        <div className={styles.checkoutBillingSummaryTertiaryRow}>
          <Link href={hotelLink} isBold variant="text" target="_blank">
            Manage accommodations
            <ExternalLinkIcon
              fill="var(--secondary-600)"
              variant="bold"
              size="large"
            />
          </Link>
        </div>
      ) : null}
    </div>
  );
}

function PaymentsLineItem({
  dueToday,
  paymentTerm,
  totalAmount,
}: Readonly<{
  dueToday: string;
  paymentTerm?: string;
  totalAmount: string;
}>) {
  const dueTodayAmount =
    paymentTerm === 'PAYMENT_PLAN' && dueToday !== totalAmount ? (
      <>{formatAsCurrency(Number(dueToday))}/</>
    ) : null;
  return (
    <span>
      {dueTodayAmount}
      <span className={styles.totalCost}>
        {formatAsCurrency(Number(totalAmount))}
      </span>
    </span>
  );
}

function TransactionFees() {
  const { transactionFees } = useConsolidatedCheckout();
  //TODO: This should maybe be formatted in the BFF?
  const feesAsCurrency = formatAsCurrency(parseFloat(transactionFees));
  return (
    <div className={styles.transactionFee}>
      <span> Transaction fees:</span>
      <span>{feesAsCurrency}</span>
    </div>
  );
}
