import { Table, TableCell, TableHeader, TableRow } from '@la/ds-ui-components';
import { FacadeRegistrant } from '@la/types';
import { isPlayer } from '@la/utilities';
import * as S from './RolloverSummary.styles';

export type RolloverSummaryProps = {
  /**
   * Members (players and/or staff) being rolled over.
   */
  members: FacadeRegistrant[];
};

const TABLE_HEADERS = ['Rolled over member', 'Role', 'Parent', 'Email address'];

/* RolloverSummary */
export function RolloverSummary({ members }: Readonly<RolloverSummaryProps>) {
  return (
    <S.RolloverSummary>
      <Table>
        <thead>
          <TableRow>
            {TABLE_HEADERS.map((header, index) => (
              <TableHeader key={`rollover-summary-header-${index}`}>
                {header}
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <TableRow key={`rollover-summary-member-${index}`}>
              <TableCell>{member.name}</TableCell>
              <TableCell>
                {isPlayer(member) ? 'Player' : (member.roles[0]?.name ?? '')}
              </TableCell>
              <TableCell>
                {isPlayer(member) ? (member.parent[0]?.name ?? '') : null}
              </TableCell>
              <TableCell>
                {isPlayer(member)
                  ? (member.parent[0]?.email ?? '')
                  : member.email}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </S.RolloverSummary>
  );
}
/* */
