import styled from 'styled-components';
export const PaymentOptionsContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    '& > p': {
        marginBottom: '16px',
    },
});
export const PaymentOptionsInnerContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});
export const PaymentOption = styled.div(({ theme }) => {
    const { palette } = theme;
    return {
        border: `1px solid ${palette.foundational.blueGrey[400]}`,
        borderRadius: '4px',
        width: '100%',
        backgroundColor: palette.foundational.white,
    };
});
export const PaymentOptionHeader = styled.div(({ theme }) => {
    return {
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        '& > label': Object.assign(Object.assign({}, theme.typography.ui.large), { flexGrow: 1, cursor: 'pointer', marginTop: 4 }),
    };
});
export const RadioButton = styled.input({
    height: '24px',
    width: '24px',
});
export const ExpandOptionDetails = styled.div({
    display: 'flex',
    alignSelf: 'center',
});
export const ExpandButton = styled.button(({ theme, $isOpen }) => {
    return Object.assign(Object.assign({ all: 'unset' }, theme.typography.ui.small), { cursor: 'pointer', textDecoration: 'underline', '& > svg': {
            marginBottom: '-4px',
            marginLeft: '2px',
            fill: '#CC0000',
            transform: $isOpen ? 'rotate(180deg)' : 'rotate(0)',
            transition: '180ms linear',
            willChange: 'transform',
        } });
});
export const PaymentOptionInstallments = styled.div({
    marginLeft: '48px',
    marginBottom: '8px',
    marginTop: '-4px',
});
export const PaymentOptionBody = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '12px',
    marginLeft: '36px',
});
