import styled from 'styled-components';
export const InputGroupContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  margin-bottom: 12px;

  // TODO: Remove after release of NG tournaments
  @media (min-width: 900px) {
    flex-direction: row;
    margin-bottom: ${({ $gap }) => ($gap ? `${$gap}px` : '12px')}
    align-items: flex-start;
  }
`;
export const InputContainer = styled.div `
  grid-column: 1 / span 2;

  // TODO: Replace after release of NG tournaments
  @media (min-width: 900px) {
    grid-column: ${({ $columnSpan }) => $columnSpan === 2 ? '1 / span 2' : 'auto'};
  }
`;
export const TextInputContainer = styled.div `
  grid-column: 1 / span 2;
  width: 100%;
  margin-bottom: 0;

  & label {
    word-break: break-word;
    white-space: normal;
    text-align: left;
  }

  // TODO: Remove after release of NG tournaments
  @media (min-width: 900px) {
    grid-column: ${({ $columnSpan }) => $columnSpan === 2 ? '1 / span 2' : 'auto'};
    padding-top: 4px;
    margin-bottom: 4px;
  }
`;
