import * as Popover from '@radix-ui/react-popover';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import styled from 'styled-components';
import { generateInputAdornmentDynamicStyles, generateInputBaseDynamicStyles } from 'theming/tokens/components/input';
import { Typography } from 'components/Typography/Typography';
export const MultiSelectWrapper = styled.div.withConfig({
  componentId: "sc-12nwnco-0"
})(({
  $isInteractive
}) => {
  const cursor = $isInteractive ? 'pointer' : 'not-allowed';
  return {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    cursor,
    '& > label': {
      pointerEvents: $isInteractive ? 'auto' : 'none'
    }
  };
});
export const MultiSelectTrigger = styled.button.withConfig({
  componentId: "sc-12nwnco-1"
})(({
  theme,
  $state,
  $isInteractive,
  $size
}) => {
  const {
    spacing
  } = theme;
  const styles = generateInputBaseDynamicStyles(theme, $state);
  return {
    width: '100%',
    border: '1px solid',
    borderColor: styles.borderColor,
    borderRadius: spacing.xxxs,
    height: $size === 'medium' ? '32px' : '40px',
    backgroundColor: styles.backgroundColor,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${spacing.xxxs} ${spacing.xxs}`,
    pointerEvents: $isInteractive ? 'auto' : 'none',
    '&:focus': {
      outline: $isInteractive ? '2px solid' : 'none',
      outlineOffset: '2px',
      outlineColor: styles.borderColor
    }
  };
});
export const MultiSelectContent = styled(Popover.Content).withConfig({
  componentId: "sc-12nwnco-2"
})(({
  theme
}) => {
  const {
    foundational: {
      blueGrey,
      white
    },
    spacing
  } = theme;
  return {
    width: 'var(--radix-popover-trigger-width)',
    borderRadius: spacing.xxxs,
    backgroundColor: white,
    border: '1px solid',
    borderColor: blueGrey[400],
    overflow: 'hidden',
    // Hardcoded until we add zIndexes to theme
    zIndex: 1100,
    boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
    '&:focus': {
      outline: 'none'
    }
  };
});
export const MultiSelectOption = styled.div.withConfig({
  componentId: "sc-12nwnco-3"
})(({
  theme,
  $size = 'large',
  $isSelectAllOption = false,
  $isBottomAction = false,
  $disabled = false
}) => {
  const {
    foundational: {
      blueGrey
    }
  } = theme;
  const pseudoMargin = $size === 'medium' ? '32px' : '44px';
  const pseudoElement = {
    top: $isBottomAction ? -4 : pseudoMargin,
    left: 16,
    content: "' '",
    height: 1,
    width: 'calc(100% - 32px)',
    position: 'absolute',
    backgroundColor: theme.semantic ? theme.semantic.primary[100] : blueGrey[100]
  };
  const optionSize = $size === 'medium' ? '28px' : '40px';
  return {
    minHeight: $isBottomAction ? 44 : optionSize,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    columnGap: theme.spacing.xxs,
    marginBottom: $isSelectAllOption || $isBottomAction ? 4 : 0,
    marginTop: $isBottomAction ? 4 : 0,
    cursor: 'pointer',
    padding: `10px 16px`,
    pointerEvents: $disabled ? 'none' : 'auto',
    color: $disabled ? blueGrey[250] : blueGrey[900],
    '&:hover': {
      backgroundColor: blueGrey[50]
    },
    '&:focus': {
      backgroundColor: blueGrey[50],
      outline: 'none'
    },
    '&::after': $isSelectAllOption || $isBottomAction ? pseudoElement : undefined,
    [theme.mediaQueries.mobile]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  };
});
export const MultiSelectValueContainer = styled.div.withConfig({
  componentId: "sc-12nwnco-4"
})({});
export const MultiSelectPlaceholder = styled.span.withConfig({
  componentId: "sc-12nwnco-5"
})(({
  theme,
  $size = 'large'
}) => {
  return {
    ...($size === 'medium' ? theme.typography.ui.medium : theme.typography.ui.large),
    fontStyle: 'italic',
    color: theme.foundational.blueGrey[400]
  };
});
export const MultiSelectChevronContainer = styled.div.withConfig({
  componentId: "sc-12nwnco-6"
})(({
  theme,
  $state
}) => {
  const {
    fill
  } = generateInputAdornmentDynamicStyles(theme, $state);
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 0 4px 0',
    fill: fill,
    stroke: fill
  };
});
export const MultiSelectHeader = styled.div.withConfig({
  componentId: "sc-12nwnco-7"
})({
  display: 'flex',
  justifyContent: 'space-between'
});
export const ScrollAreaRoot = styled(ScrollArea.Root).withConfig({
  componentId: "sc-12nwnco-8"
})({});
export const ScrollAreaViewport = styled(ScrollArea.Viewport).withConfig({
  componentId: "sc-12nwnco-9"
})(({
  theme
}) => ({
  maxHeight: 'min(376px, var(--radix-popper-available-height))',
  padding: `${theme.spacing.xxs} 0`
}));
export const Scrollbar = styled(ScrollArea.Scrollbar).withConfig({
  componentId: "sc-12nwnco-10"
})(({
  theme
}) => {
  return {
    width: '14px',
    padding: theme.spacing.xxxs,
    backgroundColor: theme.palette.foundational.white
  };
});
export const ScrollbarThumb = styled(ScrollArea.Thumb).withConfig({
  componentId: "sc-12nwnco-11"
})(({
  theme
}) => {
  return {
    backgroundColor: theme.palette.foundational.blueGrey[200],
    borderRadius: theme.spacing.xxxs
  };
});
export const MultiSelectOptionContainer = styled.div.withConfig({
  componentId: "sc-12nwnco-12"
})(({
  theme
}) => {
  return {
    display: 'flex',
    columnGap: theme.spacing.xxs,
    alignItems: 'center'
  };
});
export const RemainingSelected = styled(Typography).attrs({
  variant: 'ui',
  size: 'medium',
  weight: 'bold'
}).withConfig({
  componentId: "sc-12nwnco-13"
})(({
  theme
}) => {
  return {
    color: theme.foundational.blueGrey[600]
  };
});
export const MultiSelectOptionName = styled(Typography).withConfig({
  componentId: "sc-12nwnco-14"
})(({
  theme,
  $disabled
}) => {
  const {
    foundational: {
      blueGrey
    },
    spacing
  } = theme;
  return {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacing.xxxs,
    color: $disabled ? blueGrey[250] : blueGrey[900],
    marginTop: -2,
    wordBreak: 'break-all'
  };
});
export const HelperText = styled.div.withConfig({
  componentId: "sc-12nwnco-15"
})(({
  theme
}) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.foundational.blue[500],
  padding: '12px 16px 0',
  ...theme.typography.ui.medium,
  svg: {
    stroke: theme.foundational.blue[500],
    marginRight: '4px'
  }
}));
export const OptionSubtitleContainer = styled.div.withConfig({
  componentId: "sc-12nwnco-16"
})(({
  theme
}) => ({
  alignSelf: 'flex-start',
  marginLeft: 26,
  [theme.mediaQueries.mobile]: {
    alignSelf: 'flex-end',
    marginLeft: 0
  }
}));
export const SubtitleContainer = styled.div.withConfig({
  componentId: "sc-12nwnco-17"
})(["display:flex;justify-content:space-between;padding:10px 16px;"]);
export const OptionInnerContainer = styled.div.withConfig({
  componentId: "sc-12nwnco-18"
})({
  display: 'flex',
  alignItems: 'start',
  gap: 8
});