var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { extractAccessToken, getBaseURL } from '@la/utilities';
const baseUrl = getBaseURL();
const getProgramPaymentPlans = ({ programId, siteId, }) => __awaiter(void 0, void 0, void 0, function* () {
    const endpoint = `${baseUrl}/api/payment-option/sites/${siteId}/programs/${programId}/paymentPlans`;
    const token = extractAccessToken();
    const response = yield axios.get(endpoint, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
});
export { getProgramPaymentPlans };
