import styled from 'styled-components';
import { media } from '../../../lib/media-queries/mixins';
import { MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH } from '../MainContentHeaderBackButton/MainContentHeaderBackButton.styles';
// This is exported so other components can calculate their height or positioning
// based on this.
export const MAIN_CONTENT_HEADER_HEIGHT = 90;
export const MainContentHeader = styled.div `
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  // 16px = 8px on left and right
  padding: 16px 8px 8px calc(16px + ${MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH}px);
  background-color: var(--primary, var(--green-600));
  color: var(--white);
  width: 100%;

  ${media.TabletLandscapeUp `    
    justify-content: flex-start;
    height: ${MAIN_CONTENT_HEADER_HEIGHT}px;
    padding: 32px 16px 16px;
  `}
`;
