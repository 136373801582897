import styled from 'styled-components';
import { IconButton, Typography } from '@la/ds-ui-components';
import { media } from '../../../../../../lib/media-queries/mixins';
const themedIconButton = `
  & svg {
    fill: ${({ theme }) => { var _a; return (_a = theme.semantic) === null || _a === void 0 ? void 0 : _a.secondary[500]; }};
  }

  &:hover {
    & svg {
      fill: ${({ theme }) => { var _a; return (_a = theme.semantic) === null || _a === void 0 ? void 0 : _a.secondary[600]; }};
    }
  }

  &:active {
    & svg {
      fill: ${({ theme }) => { var _a; return (_a = theme.semantic) === null || _a === void 0 ? void 0 : _a.secondary[800]; }};
    }
  }
`;
export const DivisionCardNotSelectedSectionRight = styled.div `
  display: flex;
  align-items: center;
  align-self: flex-end;
  flex-direction: row;
  position: relative;
  right: -16px;
  bottom: -4px;
`;
export const DivisionCardAddDivisionButton = styled(IconButton) `
  padding: 4px 16px;

  ${themedIconButton}
`;
export const DivisionCardEditDivisionButton = styled(IconButton) `
  padding: 4px 16px;
`;
export const DivisionCardTeamsText = styled(Typography).attrs({
    variant: 'ui',
    size: 'large',
}) `
  white-space: nowrap;
`;
export const DivisionCardInfoSectionRight = styled.div `
  align-self: flex-end;
`;
export const DivisionCardSavedInfoSectionRight = styled(DivisionCardInfoSectionRight) `
  display: flex;
  align-items: center;
  position: relative;
  right: -12px;
  bottom: -8px;

  ${media.TabletPortraitUp `
    right: -16px;
    bottom: -12px;
  `}
`;
export const UnavailableMessage = styled(Typography).attrs({
    variant: 'ui',
    size: 'large',
    weight: 'bold',
}) `
  align-self: flex-end;
  padding: 4px;
  text-align: right;
  color: ${({ theme }) => theme.palette.foundational.blueGrey[600]};
`;
export const RegistrationStartDate = styled.span `
  white-space: nowrap;
`;
