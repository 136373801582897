import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PencilSquareIcon, PlusCircleIcon } from '@la/ds-ui-components';
import * as S from './DivisionCardInfoSectionRight.styles';
export function DivisionCardInfoRightSection({ cardState, onEditDivision, onSelectDivision, }) {
    if (cardState.value === 'unavailable') {
        return null;
    }
    if (cardState.value === 'not-selected') {
        return (_jsx(S.DivisionCardNotSelectedSectionRight, { children: _jsx(S.DivisionCardAddDivisionButton, { ariaLabel: "Add division", icon: _jsx(PlusCircleIcon, { variant: "filled", size: "large" }), onClick: onSelectDivision, size: "xl", variant: "text" }) }));
    }
    else if (cardState.value === 'not-saved' ||
        cardState.value === 'saved-and-editing') {
        const numberOfTeamsAdded = cardState.teamSelections.filter((teamSelection) => teamSelection.teamId !== '').length;
        let numberOfTeamsAddedText;
        if (numberOfTeamsAdded === 1) {
            numberOfTeamsAddedText = '1 team added';
        }
        else if (numberOfTeamsAdded > 1) {
            numberOfTeamsAddedText = `${numberOfTeamsAdded} teams added`;
        }
        return (_jsx(S.DivisionCardInfoSectionRight, { children: _jsx(S.DivisionCardTeamsText, { children: numberOfTeamsAddedText }) }));
    }
    else {
        const numberOfTeamsSaved = cardState.savedTeams.length;
        let teamsSavedString = '';
        if (numberOfTeamsSaved > 1) {
            teamsSavedString = `${numberOfTeamsSaved} teams`;
        }
        if (numberOfTeamsSaved === 1) {
            teamsSavedString = '1 team';
        }
        return (_jsxs(S.DivisionCardSavedInfoSectionRight, { children: [_jsx(S.DivisionCardTeamsText, { children: teamsSavedString }), _jsx("span", Object.assign({ style: { cursor: 'pointer' }, onClick: onEditDivision }, { children: _jsx(PencilSquareIcon, { fill: "var(--green-600)", variant: "bold", size: "xl" }) }))] }));
    }
}
