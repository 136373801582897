import { AccountInfo } from '../apis/getAccountInfo';

export type RegistrationAnalyticsData = {
  programFullName?: string;
  programId?: number;
  programSport?: string;
  programType?: string;
  registrationUserId?: string;
  registrationId?: string;
};

/**
 * Injects the registration analytics data into the document at the beginning of the
 * <body> tag so that any scripts that are injected after will have access to these variables.
 * @param data registration-related data
 */
export const injectRegistrationAnalyticsData = (
  data: RegistrationAnalyticsData
): void => {
  const registrationAnalyticsData = parseHTML(`
    <div itemscope="program-${data.programId ?? ''}" itemtype="http://www.leagueapps.com/registration">
      <span id="programFullName" itemprop="programFullName" hidden="true">${data.programFullName ?? ''}</span>
      <span id="programId" itemprop="programId" hidden="true">${data.programId ?? ''}</span>
      <span id="programType" itemprop="programType" hidden="true">${data.programType?.toUpperCase() ?? ''}</span>
      <span id="programSport" itemprop="programSport" hidden="true">${data.programSport ?? ''}</span>
      <span id="registrationUserId" itemprop="registrationUserId" hidden="true">${data.registrationUserId ?? ''}</span>
      <span id="registrationId" itemprop="registrationId" hidden="true">${data.registrationId ?? ''}</span>
    </div>
  `);

  document.body.prepend(registrationAnalyticsData);
};

/**
 * Injects a script into the page for use by Heap RUM tracking
 * @param accountInfo
 */
export function injectHeapScript(accountInfo: AccountInfo) {
  // In development environments this script will not be run
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const { hostname, pathname } = window.location;
  const heapScript = document.createElement('script');
  heapScript.setAttribute('id', 'heapScript');
  heapScript.setAttribute('type', 'text/javascript');
  const skip = pathname.match(/^\/console\/sites\/\d+\/teams/g) != null;
  if (accountInfo && !skip) {
    const inlineScript = document.createTextNode(`
        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load("${hostname.indexOf('leagueapps.com') > -1 ? '3732699383' : '2991539573'}");
        heap.addUserProperties({"orgAccountId" : "${accountInfo.user.id ?? ''}"});
        heap.addUserProperties({"siteId" : "${accountInfo.site.id}"});
        heap.addUserProperties({"orgAccountName" : "${accountInfo.site.name}"});
        heap.addUserProperties({"siteName" : "${accountInfo.site.name}"});
        heap.addUserProperties({"orgAccountStatus" : "${accountInfo.orgAccount.status?.toUpperCase() ?? ''}"});
        heap.addUserProperties({"siteMode" : "${accountInfo.site.mode?.toUpperCase() ?? ''}"}); 
        heap.addUserProperties({"userEmail" : "${accountInfo.user.email}"});
        heap.addUserProperties({"userName" : "${accountInfo.user.firstName} ${accountInfo.user.lastName}"});
        heap.addUserProperties({"orgAccountRole" : "${accountInfo.user.orgAccountRole.toUpperCase()}"});
      `);
    heapScript.appendChild(inlineScript);
    document.head.appendChild(heapScript);
  }
}

export function removeHeapScript() {
  const heapScript = document.querySelector('script#heapScript');
  if (heapScript) {
    document.head.removeChild(heapScript);
  }
}

/**
 * Parses an HTML string for rendering inside the HTMl docuemnt.
 */
export const parseHTML = Range.prototype.createContextualFragment.bind(
  document.createRange()
);
