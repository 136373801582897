import { PropsWithChildren, useState } from 'react';
import {
  CheckCircle,
  Info,
  Warning,
  WarningOctagon,
  X,
} from '@phosphor-icons/react';
import clsx from 'clsx';
import styles from './PageBanner.module.scss';

export type PageBannerData = {
  bannerType: 'error' | 'informative' | 'success' | 'warning';
};

export function PageBanner({
  bannerType,
  children,
}: PropsWithChildren<PageBannerData>) {
  const [isRendered, setIsRendered] = useState(true);

  function getTypeIcon(bannerType: string) {
    switch (bannerType) {
      case 'error':
        return (
          <WarningOctagon
            size={24}
            weight="bold"
            color="#D90606"
            className={styles.typeIcon}
          />
        );
      case 'success':
        return (
          <CheckCircle
            size={24}
            weight="bold"
            color="#009500"
            className={styles.typeIcon}
          />
        );
      case 'warning':
        return (
          <Warning
            size={24}
            weight="bold"
            color="#DF6C06"
            className={styles.typeIcon}
          />
        );
      case 'informative':
      default:
        return (
          <Info
            size={24}
            weight="bold"
            color="#3679FA"
            className={styles.typeIcon}
          />
        );
    }
  }

  return isRendered ? (
    <div className={clsx(styles.pageBanner, styles[bannerType])}>
      {getTypeIcon(bannerType)}
      <span className={styles.textContent}>{children}</span>
      {bannerType !== 'error' ? (
        <X
          size={16}
          onClick={() => setIsRendered(false)}
          color="#000000"
          className={styles.closeIcon}
        />
      ) : null}
    </div>
  ) : null;
}
