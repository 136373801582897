/**
 * Returns a subset of length `n` from a larger array.
 * @param array The array to be splitted
 * @param chunkSize The maximum size each inner array should have
 * @returns A 1xN matrix where n <= chunkSize
 */
export function chunkArray(array, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
}
