import { useEffect, useState } from 'react';
// Adapted from https://usehooks-ts.com/react-hook/use-media-query
export function useMediaQuery(query) {
    const getMatches = (query) => {
        // Prevents SSR issues
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches;
        }
        return false;
    };
    const [matches, setMatches] = useState(getMatches(query));
    useEffect(() => {
        function handleChange() {
            setMatches(getMatches(query));
        }
        const matchMedia = window.matchMedia(query);
        // Triggered at the first client-side load and if query changes
        handleChange();
        /*
         * Before Safari 14, MediaQueryList is based on EventTarget and only supports
         * addListener/removeListener for media queries.  Read more about this on MDN:
         * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
         */
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        }
        else {
            matchMedia.addEventListener('change', handleChange);
        }
        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            }
            else {
                matchMedia.removeEventListener('change', handleChange);
            }
        };
    }, [query]);
    return matches;
}
