export var ProgramRole;
(function (ProgramRole) {
    ProgramRole["Player"] = "PLAYER";
    ProgramRole["FreeAgent"] = "FREE_AGENT";
})(ProgramRole || (ProgramRole = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["Unpaid"] = "UNPAID";
    PaymentStatus["Paid"] = "PAID";
    PaymentStatus["Refund"] = "REFUND";
    PaymentStatus["Partial"] = "PARTIAL";
    PaymentStatus["Void"] = "VOID";
    PaymentStatus["NATeamPays"] = "NA_TEAM_PAYS";
    PaymentStatus["NAFree"] = "NA_FREE";
    PaymentStatus["NAWaitlist"] = "NA_WAITLIST";
})(PaymentStatus || (PaymentStatus = {}));
export var RegistrationStatus;
(function (RegistrationStatus) {
    RegistrationStatus["Created"] = "CREATED";
    RegistrationStatus["Processing"] = "PROCESSING";
    RegistrationStatus["InError"] = "IN_ERROR";
    RegistrationStatus["Registered"] = "REGISTERED";
    RegistrationStatus["Removed"] = "REMOVED";
    RegistrationStatus["PendingInvite"] = "PENDING_INVITE";
})(RegistrationStatus || (RegistrationStatus = {}));
export var RegistrationType;
(function (RegistrationType) {
    RegistrationType["Team"] = "TEAM";
    RegistrationType["Staff"] = "STAFF";
    RegistrationType["Player"] = "PLAYER";
    RegistrationType["InvitePlayer"] = "INVITE_PLAYER";
    RegistrationType["InviteStaff"] = "INVITE_STAFF";
    RegistrationType["StaffFreeAgent"] = "STAFF_FREE_AGENT";
    RegistrationType["PlayerFreeAgent"] = "PLAYER_FREE_AGENT";
})(RegistrationType || (RegistrationType = {}));
