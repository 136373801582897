import {
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UploadedFile } from '@la/ds-ui-components';
import {
  BFFMember,
  FormField,
  FormFieldResponse,
  BFFGroupAccount,
  GroupAccountUser,
  Registration,
  RegistrationWorkflowWaiver,
  RegistrationStatus,
  RegistrationType,
  ProgramRole,
} from '@la/types';
import { formatFormFieldsForWorkflow } from '@la/utilities';
import { updateFormField } from 'lib/apis/getFormFields';
import { useGetRegistrationInfoFromFacade } from 'lib/apis/getRegistrantInfoFromFacade';
import { getStaffRoles } from 'lib/apis/getStaffRoles';
import {
  getUserRegistrations,
  getExistingUserRegistration,
} from 'lib/apis/getUserRegistrations';
import { updateWorkflowRegistration } from 'lib/apis/updateWorkflowRegistration';
import { uploadFile as uploadFileApi } from 'lib/apis/uploadFile';
import { DecodedTokenData } from 'lib/apis/verifyUrlSignature';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useGetExposeNGBMembershipFieldsQuery } from 'redux/services/registrationApi';
import { useGetSiteSettingsQuery } from 'redux/services/siteInfo';
import { RegistrationOption } from 'redux/services/tournamentApi';
import { WaiverData } from 'domains/Checkout/Checkout.types';
import {
  RegistrantState,
  RegistrantStateActions,
  registrantInitialState,
  registrantReducer,
} from './reducer';
import { useRegistrantSteps } from './steps';

export type FieldState = {
  value: string;
  required: boolean;
  error?: string;
};

export type FormFieldUpdateData = {
  customFields: FormField[];
  registrationId?: string;
  userId?: string;
};

export type FileUploadData = {
  file: UploadedFile;
  propertyDefinitionId: string;
  userId: string;
};

type MemberProfileSettings = {
  childBirthDateRequired: boolean;
  childEmailEnabled: boolean;
};

export type RegistrationContextProps = RegistrantState & {
  dispatch: React.Dispatch<RegistrantStateActions>;
  decodedData: DecodedTokenData | null;
  formFields: FormFieldResponse;
  groupAccount: BFFGroupAccount | null;
  loggedInUserId: number;
  loggedInUserName: string;
  masterProgramName?: string;
  memberFormFields: FormField[];
  memberProfileSettings: MemberProfileSettings;
  ngbMembershipType?: string | null;
  registrationId?: string;
  structuredStateEnabled: boolean;
  teamName: string;
  tournamentId: string;
  hotelLink?: string;
  waivers: WaiverData[] | null;
  touched: boolean;
  setTouched: React.Dispatch<React.SetStateAction<boolean>>;
  waiversTouched: boolean;
  setWaiversTouched: React.Dispatch<React.SetStateAction<boolean>>;
  hasErrors: boolean;
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>;
  updateFormFields: (data: FormFieldUpdateData) => Promise<{}[]>;
  uploadFile: (data: FileUploadData) => Promise<{ uuid: string }>;
  updateWithExistingRegistration: (userId: string) => Promise<void>;
  roleName?: string;
  userRegistrations?: Registration[];
  registrationOptions: RegistrationOption[] | null;
  setRegistrationId: (registrationId: string) => void;
  setRegistrationOptions: (options: RegistrationOption[]) => void;
  subdomain: string;
};

const initialValues: RegistrationContextProps = {
  ...registrantInitialState,
  waivers: null,
  formFields: null,
  groupAccount: null,
  loggedInUserId: 0,
  loggedInUserName: '',
  memberFormFields: [],
  structuredStateEnabled: true,
  decodedData: null,
  tournamentId: '',
  masterProgramName: '',
  memberProfileSettings: {
    childBirthDateRequired: false,
    childEmailEnabled: false,
  },
  ngbMembershipType: undefined,
  registrationId: undefined,
  teamName: '',
  touched: false,
  setTouched: () => {},
  hasErrors: false,
  setHasErrors: () => {},
  dispatch: () => {},
  updateFormFields: async () => [],
  uploadFile: async () => ({ uuid: '' }),
  waiversTouched: false,
  setWaiversTouched: () => {},
  updateWithExistingRegistration: async () => {},
  registrationOptions: null,
  setRegistrationId: () => {},
  setRegistrationOptions: () => {},
  subdomain: '',
};

export const RegistrationContext =
  createContext<RegistrationContextProps>(initialValues);

export const RegistrationProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, dispatch] = useReducer(
    registrantReducer,
    registrantInitialState
  );

  const [touched, setTouched] = useState(false);
  const [waiversTouched, setWaiversTouched] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [roleName, setRoleName] = useState<string | undefined>();
  const [registrationId, setRegistrationId] = useState<string>();
  const [registrationOptions, setRegistrationOptions] = useState<
    RegistrationOption[] | null
  >(null);
  const [userRegistrations, setUserRegistrations] = useState<Registration[]>(
    []
  );

  let encodedToken = window.location.search.substring(1);
  if (encodedToken) {
    localStorage.setItem('lip.invite-token', encodedToken);
  } else {
    encodedToken = localStorage.getItem('lip.invite-token') ?? '';
  }
  const { subdomain } = getLAHostnameParts();
  const {
    data,
    loading,
    error: registrationError,
  } = useGetRegistrationInfoFromFacade(encodedToken);
  const siteId = data?.decodedData.site;

  const { data: ngbMembershipType, isLoading: ngbMembershipTypeIsLoading } =
    useGetExposeNGBMembershipFieldsQuery(
      {
        programId: data?.decodedData?.prid,
        subdomain: subdomain,
      },
      { skip: !data?.decodedData?.prid || !subdomain }
    );

  const { data: siteSettings, isLoading: siteSettingsIsLoading } =
    useGetSiteSettingsQuery(siteId ? parseInt(siteId) : 0, { skip: !siteId });

  useEffect(() => {
    if (data?.masterProgram?.id && subdomain) {
      getUserRegistrations({
        programId: data?.masterProgram?.id,
        siteDomain: subdomain,
      }).then((response) => {
        setUserRegistrations(response.userRegistrations);
      });
    }
  }, [data, subdomain]);

  useEffect(() => {
    dispatch({
      type: 'SET_GROUP_ACCOUNT',
      payload: data?.groupAccount ?? null,
    });
  }, [data]);

  useEffect(() => {
    if (data?.decodedData && !isNaN(parseInt(data?.decodedData.role))) {
      getStaffRoles({ siteId: data.decodedData.site })
        .then((staffRoles) => {
          const programStaffRole = staffRoles.find(
            (staffRole) => staffRole.id === parseInt(data.decodedData.role)
          )?.role;
          setRoleName(programStaffRole);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [data]);

  if (registrationError) {
    // This error message will be treated in another ticket
    throw new Error('Unable to fetch registrant data');
  }

  if (!data || loading || siteSettingsIsLoading || ngbMembershipTypeIsLoading) {
    return (
      <RegistrationContext.Provider value={initialValues}>
        {!data || siteSettingsIsLoading || ngbMembershipTypeIsLoading
          ? null
          : children}
      </RegistrationContext.Provider>
    );
  }

  const {
    decodedData,
    formFields,
    loggedInUserId,
    loggedInUserName,
    masterProgram,
    memberFormFields,
    structuredStateEnabled,
    team,
    waivers,
  } = data;

  const updateWithExistingRegistration = async (userId: string) => {
    return getExistingUserRegistration({
      registrationType:
        decodedData.type === 'player'
          ? RegistrationType.Player
          : RegistrationType.Staff,
      userId: parseInt(userId),
      programId: parseInt(decodedData.prid),
      teamIdOg: parseInt(decodedData.team),
      roleId: !isNaN(parseInt(decodedData.role))
        ? parseInt(decodedData.role)
        : undefined,
    }).then((existingRegistration) => {
      if (existingRegistration) {
        if (
          existingRegistration.registrationStatus ===
          RegistrationStatus.Registered
        ) {
          const message =
            existingRegistration.registrationType === RegistrationType.Player
              ? 'This player already has a completed registration. Please select a different player.'
              : 'You have already registered for this tournament.';
          dispatch({
            type: 'SET_EXISTING_REGISTRATION_ERROR',
            payload: message,
          });

          return;
        }
        dispatch({ type: 'SET_EXISTING_REGISTRATION_ERROR', payload: '' });

        const { formFields: existingFormFields, waivers: existingWaivers } =
          existingRegistration;

        if (existingFormFields && existingFormFields.length) {
          existingFormFields.forEach((formField) => {
            const { formFieldId, values } = formField;
            const allFormFields: FormField[] = [
              ...(data.formFields?.nonFileUploadFormFields ?? []),
              ...(data.formFields?.fileUploadFormFields ?? []),
            ];
            const type = allFormFields.find(
              (field) => field.propertyDefinitionId === formFieldId
            )?.type;

            if (type) {
              if (type === 'FILE_UPLOAD') {
                const currentFormField =
                  state.fileUploadFormFields[formFieldId];
                if (currentFormField) {
                  dispatch({
                    type: 'SET_FILE_UPLOAD_FORM_FIELD',
                    payload: {
                      id: formFieldId,
                      field: {
                        ...currentFormField,
                        value: {
                          file: null,
                          name: values[0],
                          uuid: values[1],
                        },
                      },
                    },
                  });
                }
              } else {
                const currentFormField =
                  state.nonFileUploadFormFields[formFieldId];
                if (currentFormField) {
                  dispatch({
                    type: 'SET_NON_FILE_UPLOAD_FORM_FIELD',
                    payload: {
                      id: formFieldId,
                      field: {
                        ...currentFormField,
                        value:
                          type === 'MULTIPLE_CHECKBOXES' ? values : values[0],
                      } as any,
                    },
                  });
                }
              }
            }
          });
        }

        if (existingWaivers && existingWaivers.length) {
          existingWaivers.forEach((waiver) => {
            if (waivers) {
              const currentWaiver = waivers[waiver.waiverId];
              if (currentWaiver) {
                dispatch({
                  type: 'SET_SIGNED_WAIVERS',
                  payload: {
                    id: waiver.waiverId.toString(),
                    signed: true,
                  },
                });
              }
            }
          });
        }
      }
    });
  };

  const uploadFile = ({
    file,
    propertyDefinitionId,
    userId,
  }: FileUploadData): Promise<{ uuid: string }> => {
    return uploadFileApi({
      siteId: decodedData?.site.toString() ?? '',
      propertyDefinitionId,
      userId,
      file: file.file as ArrayBuffer,
      filename: file.name,
    });
  };

  const updateFormFields = ({
    customFields,
    registrationId,
    userId,
  }: FormFieldUpdateData): Promise<{}[]> => {
    return Promise.all(
      customFields.map((field) => {
        const { propertyDefinitionId, type, value } = field;
        // Do not make call to update form field if there is no value
        if (!value) {
          return {};
        }

        let values;
        switch (type) {
          case 'FILE_UPLOAD':
            let uuid = value.uuid;
            if (uuid) {
              values = [
                {
                  registrationId,
                  userId,
                  value: uuid,
                },
              ];
            } else {
              // If the form is not uploaded yet (`uuid` is undefined), upload
              // the file first and then set `values` accordingly.
              if (userId) {
                return uploadFile({
                  file: { file: value.file, name: value.name },
                  propertyDefinitionId: propertyDefinitionId.toString(),
                  userId: userId,
                }).then(({ uuid }) => {
                  let id = registrationId ? { registrationId } : { userId };

                  return updateFormField({
                    siteId: decodedData?.site,
                    formFieldId: propertyDefinitionId.toString(),
                    values: [
                      {
                        ...id,
                        value: uuid,
                      },
                    ],
                  });
                });
              }

              throw new Error('A user id must be supplied to upload a file.');
            }
            break;
          case 'MULTIPLE_CHECKBOXES':
            values = value.map((itemId) => ({
              registrationId,
              userId,
              value: itemId,
            }));
            break;
          default:
            values = [
              {
                registrationId,
                userId,
                value,
              },
            ];
            break;
        }

        return updateFormField({
          siteId: decodedData?.site,
          formFieldId: propertyDefinitionId.toString(),
          values,
        });
      })
    );
  };

  return (
    <RegistrationContext.Provider
      value={{
        ...state,
        dispatch,
        waivers,
        formFields,
        decodedData,
        loggedInUserId,
        loggedInUserName,
        memberFormFields,
        memberProfileSettings: {
          childBirthDateRequired: !!siteSettings?.childBirthDateRequired,
          childEmailEnabled: !!siteSettings?.childEmailEnabled,
        },
        structuredStateEnabled,
        tournamentId: decodedData.prid,
        masterProgramName: masterProgram.name,
        ngbMembershipType,
        hotelLink: masterProgram.details.hotelLinks,
        registrationId,
        teamName: team.name,
        touched,
        setTouched,
        updateFormFields,
        uploadFile,
        hasErrors,
        setHasErrors,
        waiversTouched,
        setWaiversTouched,
        updateWithExistingRegistration,
        roleName,
        registrationOptions,
        setRegistrationId,
        setRegistrationOptions,
        subdomain,
        userRegistrations: userRegistrations ?? [],
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export const getProgramRole = (type: string) => {
  if (type.toLocaleUpperCase() === RegistrationType.Player) {
    return ProgramRole.Player;
  }

  if (type.toLocaleUpperCase() === RegistrationType.PlayerFreeAgent) {
    return ProgramRole.FreeAgent;
  }

  return undefined;
};

export const useRegistration = () => {
  const context = useContext(RegistrationContext);
  const {
    decodedData,
    groupAccount,
    nonFileUploadFormFields,
    fileUploadFormFields,
    loggedInUserId,
    loggedInUserName,
    ngbMembershipType,
    selectedPlayer,
    setHasErrors,
    setRegistrationId,
    setWaiversTouched,
    signedWaivers,
    subdomain,
    waivers,
    userRegistrations,
  } = context;

  const {
    ngTournamentNgbVerification,
    tournamentTeamRegistrationWizardVersion,
  } = useFlags();

  const {
    currentStep,
    formSteps,
    stepNumber,
    numberOfTotalSteps,
    getNextStep,
    getPreviousStep,
    onNextClick,
    onBackClick,
    steps,
  } = useRegistrantSteps(
    decodedData?.type,
    context.formFields,
    ngTournamentNgbVerification && ngbMembershipType
  );

  const childPlayers: GroupAccountUser[] = (groupAccount?.members ?? [])
    .filter((member: BFFMember) => member.user.type === 'child')
    .map((member: BFFMember) => member.user);

  const adultEmails: string[] = (groupAccount?.members ?? [])
    .filter((member: BFFMember) => member.user.type === 'adult')
    .map((member: BFFMember) => member.user.email);

  const pendingInvitationPlayers: Registration[] = (
    userRegistrations || []
  ).filter((reg) => reg.registrationStatus === 'PENDING_INVITE');

  let name: string;
  if (decodedData?.type === 'player') {
    const player = childPlayers.find((p) => p.id.toString() === selectedPlayer);
    name = `${player?.firstName} ${player?.lastName}`;
  } else {
    name = loggedInUserName;
  }

  const onWaiversSubmit = () => {
    if (!waivers) {
      return null;
    }

    setWaiversTouched(true);
    let hasError = false;

    for (const waiver of waivers) {
      if (!signedWaivers[waiver.waiverId]) {
        hasError = true;
      }
    }

    setHasErrors(hasError);

    if (!hasError && decodedData) {
      const registrationFormFields = formatFormFieldsForWorkflow(
        [
          Object.values(nonFileUploadFormFields),
          Object.values(fileUploadFormFields),
        ].flat()
      );

      const registrationWaivers: RegistrationWorkflowWaiver[] = Object.keys(
        signedWaivers
      ).map((waiverId) => ({
        waiverId: parseInt(waiverId),
        waiverType: 'REGISTRATION' as 'REGISTRATION',
      }));

      updateWorkflowRegistration(
        {
          formFields: registrationFormFields,
          name,
          programId: parseInt(decodedData.prid),
          programRole: getProgramRole(decodedData.type),
          programStaffId:
            decodedData.type !== 'player'
              ? parseInt(decodedData.role)
              : undefined,
          registeredUserId:
            decodedData.type === 'player'
              ? parseInt(selectedPlayer)
              : loggedInUserId,
          registeringUserId: loggedInUserId,
          siteId: parseInt(decodedData.site),
          teamIdOg: parseInt(decodedData.team),
          waivers: registrationWaivers,
          workflowVersion: Math.trunc(tournamentTeamRegistrationWizardVersion),
        } as any,
        subdomain
      ).then((response) => {
        const registrationId = Object.keys(response.data.outputs.results)[0];
        setRegistrationId(registrationId);
        onNextClick();
      });
    }
  };

  const submitRegistration = () => {
    if (decodedData) {
      const registrationFormFields = formatFormFieldsForWorkflow(
        [
          Object.values(nonFileUploadFormFields),
          Object.values(fileUploadFormFields),
        ].flat()
      );

      const registrationWaivers: RegistrationWorkflowWaiver[] = Object.keys(
        signedWaivers
      ).map((waiverId) => ({
        waiverId: parseInt(waiverId),
        waiverType: 'REGISTRATION' as 'REGISTRATION',
      }));

      updateWorkflowRegistration(
        {
          finalize: true,
          formFields: registrationFormFields,
          name,
          registeredUserId:
            decodedData.type === 'player'
              ? parseInt(selectedPlayer)
              : loggedInUserId,
          registeringUserId: loggedInUserId,
          programId: parseInt(decodedData.prid),
          programRole: getProgramRole(decodedData.type),
          programStaffId:
            decodedData.type !== 'player'
              ? parseInt(decodedData.role)
              : undefined,
          siteId: parseInt(decodedData.site),
          teamIdOg: parseInt(decodedData.team),
          waivers: registrationWaivers,
          workflowVersion: Math.trunc(tournamentTeamRegistrationWizardVersion),
        } as any,
        subdomain
      ).then(onNextClick);
    }
  };

  return {
    ...context,
    tournamentId: decodedData?.prid,
    siteId: decodedData?.site,
    childPlayers,
    adultEmails,
    pendingInvitationPlayers,
    currentStep,
    formSteps,
    stepNumber,
    ngbMembershipType,
    numberOfTotalSteps,
    getNextStep,
    getPreviousStep,
    steps,
    name,
    onNextClick,
    onBackClick,
    onWaiversSubmit,
    showNGBVerification: !!ngTournamentNgbVerification,
    submitRegistration,
  };
};
