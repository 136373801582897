import baseApi from './baseApi';
export const registrationApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        updateRegistrations: builder.mutation({
            query: ({ siteDomain, programId, editURL, registrations, payerId, siteId, includeRegistrationTriageFix, }) => {
                return {
                    url: `/api/member-portal/${siteDomain}/registration`,
                    method: 'POST',
                    body: {
                        programId,
                        editURL,
                        registrations,
                        payerId,
                        includeRegistrationTriageFix,
                    },
                    params: {
                        siteId,
                    },
                };
            },
        }),
    }),
});
export const { useUpdateRegistrationsMutation } = registrationApi;
