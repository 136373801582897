import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DateTime } from 'luxon';
import { formatAsCurrency } from '@la/ds-ui-components';
import { formatDateUsingShortMonthName } from '@la/utilities';
import * as S from './DivisionCardHeader.styles';
export const NUMBER_OF_SPOTS_WARNING_AMOUNT = 5;
export function DivisionCardHeader({ cardLabelId, cardState, cost, name, numberOfSpotsLeft, registrationStartDate, registrationEndDate, variant, }) {
    let cardHeaderRightSection;
    if (cardState.value === 'unavailable') {
        cardHeaderRightSection = (_jsx(S.DivisionCardHeaderUnavailable, { children: getUnavailableMessage({
                reasonUnavailable: cardState.reasonUnavailable,
                registrationDates: {
                    start: registrationStartDate,
                    end: registrationEndDate,
                },
            }) }));
    }
    else {
        cardHeaderRightSection = (_jsx("div", { children: formatAsCurrency(cost ? Number(cost) : 0) }));
    }
    return (_jsxs(S.DivisionCardHeader, Object.assign({ "$variant": variant }, { children: [_jsxs(S.TitleAndSpotsLeft, { children: [_jsx("div", Object.assign({ id: cardLabelId }, { children: name })), _jsx(DivisionSpotsLeftMessage, { numberOfSpotsLeft: numberOfSpotsLeft })] }), cardHeaderRightSection] })));
}
function DivisionSpotsLeftMessage({ numberOfSpotsLeft, }) {
    if (numberOfSpotsLeft) {
        if (numberOfSpotsLeft < 0) {
            numberOfSpotsLeft = 0;
        }
        const showWarning = numberOfSpotsLeft <= 5 && numberOfSpotsLeft !== 0;
        const spotsLeftText = `${numberOfSpotsLeft === 1 ? '1 spot' : `${numberOfSpotsLeft} spots`} left`;
        if (showWarning) {
            return (_jsxs(S.SpotsLeftWarningMessage, { children: [spotsLeftText, "!"] }));
        }
        else {
            return _jsx(S.SpotsLeftMessage, { children: spotsLeftText });
        }
    }
    return null;
}
function getUnavailableMessage({ reasonUnavailable, registrationDates, }) {
    switch (reasonUnavailable) {
        case 'before-registration-period-opens':
            return (_jsxs(_Fragment, { children: ["Registration opens", ' ', _jsx(S.RegistrationStartDate, { children: formatDateUsingShortMonthName(DateTime.fromISO(registrationDates.start)) })] }));
        case 'after-registration-period-ends':
            return 'Registration closed';
        case 'at-capacity':
            return 'At capacity';
    }
    return null;
}
