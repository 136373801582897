import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { extractAccessToken, getBaseURL } from '@la/utilities';
const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseURL(),
        prepareHeaders: (headers) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = extractAccessToken();
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});
export default baseApi;
