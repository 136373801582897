import styled from 'styled-components';
import { Typography } from '@la/ds-ui-components';
import { media } from '../../../../../lib/media-queries/mixins';
export const DivisionCards = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${media.TabletPortraitUp `
    gap: 16px;
  `}
`;
export const DivisionCardsSectionTitle = styled(Typography).attrs({
    size: 'xl',
    variant: 'ui',
}) `
  margin-top: 12px;

  ${media.TabletPortraitUp `
    margin-top: 16px;
  `}
`;
