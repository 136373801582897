// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jyAS2lMrQI_bWfHMY79A .KwEVYNY0qfEisVZEXCYs{color:var(--blue-grey-600);text-align:center}.jyAS2lMrQI_bWfHMY79A .Fw1c5bXmjrfH3JgyS9an{display:flex;flex-direction:column}.jyAS2lMrQI_bWfHMY79A .Fw1c5bXmjrfH3JgyS9an:not(:last-child){margin-bottom:16px}@media(min-width: 1200px){.jyAS2lMrQI_bWfHMY79A .Fw1c5bXmjrfH3JgyS9an{flex-direction:row;justify-content:space-between}}`, "",{"version":3,"sources":["webpack://./src/domains/Tournaments/TournamentDetailsPage/FeesCard/FeesCard.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAGE,4CACE,0BAAA,CACA,iBAAA,CAEF,4CACE,YAAA,CACA,qBAAA,CACA,6DACE,kBAAA,CCKJ,0BDTA,4CAOI,kBAAA,CACA,6BAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feesCard": `jyAS2lMrQI_bWfHMY79A`,
	"noFees": `KwEVYNY0qfEisVZEXCYs`,
	"fee": `Fw1c5bXmjrfH3JgyS9an`
};
export default ___CSS_LOADER_EXPORT___;
