import * as RadixTooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';
import { InfoIcon } from 'components/Icon';
export const TooltipButton = styled.button.withConfig({
  componentId: "sc-1uugfav-0"
})(({
  theme
}) => {
  const {
    foundational: {
      blueGrey
    },
    spacing
  } = theme;
  return {
    padding: 0,
    margin: 0,
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'flex-end',
    borderRadius: spacing.xxxs,
    '&:focus': {
      outline: '2px solid',
      outlineOffset: '1px',
      outlineColor: blueGrey[700]
    }
  };
});
export const TooltipArrow = styled(RadixTooltip.Arrow).attrs({
  width: 20,
  height: 8
}).withConfig({
  componentId: "sc-1uugfav-1"
})(({
  theme
}) => {
  return {
    fill: theme.foundational.blueGrey[700]
  };
});
export const TooltipContent = styled(RadixTooltip.Content).withConfig({
  componentId: "sc-1uugfav-2"
})(({
  theme,
  $translationThreshold = 0
}) => {
  const {
    foundational,
    spacing,
    typography
  } = theme;
  return {
    maxWidth: '244px',
    backgroundColor: foundational.blueGrey[700],
    padding: '10px',
    borderRadius: '2px',
    ...typography.ui.medium,
    lineHeight: spacing.medium,
    color: foundational.white,
    zIndex: 'var(--tooltip-z)',
    transform: `translateX(${$translationThreshold}%)`
  };
});
export const StyledInfoIcon = styled(InfoIcon).attrs(({
  theme: {
    foundational
  }
}) => ({
  fill: foundational.blueGrey[600]
})).withConfig({
  componentId: "sc-1uugfav-3"
})({});