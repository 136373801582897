import { ReactNode } from 'react';
import { X } from '@phosphor-icons/react';
import styles from './Tag.module.scss';

export default function Tag({
  icon,
  label,
  closeHandler,
}: {
  icon?: ReactNode;
  label: string;
  closeHandler?: () => void;
}) {
  return (
    <div className={styles.tag}>
      {icon}
      <span className={styles.tagLabel}>{label}</span>
      <button className={styles.closeButton} onClick={closeHandler}>
        <X width="12px" height="12px" />
      </button>
    </div>
  );
}
