import styled from 'styled-components';
import { ExclamationCircleIcon, CloseIcon as DSCloseIcon, } from '@la/ds-ui-components';
export const ErrorCard = styled.div `
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 0 0 16px 0;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--red);
  background-color: #ffeeee;
`;
export const ErrorIcon = styled(ExclamationCircleIcon) `
  flex-basis: 18px;
  width: 18px;
  margin: 2px 0 0 0;
  fill: var(--red);
`;
export const ErrorMessage = styled.span `
  flex: 1;
  font: var(--ui-large-medium);
  color: var(--blue-grey-900);
`;
export const CloseIcon = styled(DSCloseIcon) `
  flex-basis: 11px;
  margin: 2px 0 0 0;
  fill: var(--blue-grey-900);
  cursor: pointer;
`;
