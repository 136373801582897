// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fRhRpkdI11eVB1uXoJhr{max-width:1440px;width:100%;margin:0 auto}.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .OMsOS66At2oeEv1hm1Cz{display:flex;flex-direction:column;gap:4px 16px;margin:0;padding:8px 0 16px;border-bottom:1px solid var(--primary-100)}@media(min-width: 600px){.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .OMsOS66At2oeEv1hm1Cz{align-items:flex-end;flex-direction:row;flex-wrap:wrap;margin-bottom:-16px;padding-top:12px}}.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .P4PzC50WCLhjgVlEafJn{margin-bottom:16px}@media(min-width: 600px){.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .P4PzC50WCLhjgVlEafJn{margin-bottom:0}}.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .n5SzEHaKGO9spyt8FItq{margin-bottom:8px}.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .Daqs3fohoz0_cL2C230g{margin-top:8px}@media(min-width: 600px){.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .Daqs3fohoz0_cL2C230g{margin-top:32px}}.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .Daqs3fohoz0_cL2C230g .ttueDuKDTeSx2pyqvt7g{color:var(--blue-grey-600);margin-bottom:4px}.fRhRpkdI11eVB1uXoJhr .Nr4uKPFqcyJ6ZKaoYUke .Daqs3fohoz0_cL2C230g .EfB3zTvbyizRAUmrksBK{margin-top:16px}`, "",{"version":3,"sources":["webpack://./src/domains/Tournaments/TournamentDetailsPage/TournamentDetailsPage.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,gBAAA,CACA,UAAA,CACA,aAAA,CAEE,kEACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CACA,0CAAA,CCPJ,yBDCE,kEAQI,oBAAA,CACA,kBAAA,CACA,cAAA,CACA,mBAAA,CACA,gBAAA,CAAA,CAGJ,kEACE,kBAAA,CCjBJ,yBDgBE,kEAGI,eAAA,CAAA,CAGJ,kEACE,iBAAA,CAEF,kEACE,cAAA,CC1BJ,yBDyBE,kEAGI,eAAA,CAAA,CAEF,wFACE,0BAAA,CACA,iBAAA,CAEF,wFACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tournamentDetailsPage": `fRhRpkdI11eVB1uXoJhr`,
	"tournamentDetails": `Nr4uKPFqcyJ6ZKaoYUke`,
	"header": `OMsOS66At2oeEv1hm1Cz`,
	"registrationWindowCard": `P4PzC50WCLhjgVlEafJn`,
	"locationCard": `n5SzEHaKGO9spyt8FItq`,
	"tournamentDetail": `Daqs3fohoz0_cL2C230g`,
	"label": `ttueDuKDTeSx2pyqvt7g`,
	"action": `EfB3zTvbyizRAUmrksBK`
};
export default ___CSS_LOADER_EXPORT___;
