import styled from 'styled-components';
export const AlertContainer = styled.div `
  width: 100%;
  height: 56px;
  padding: 16px;
  background-color: ${({ theme }) => theme.foundational.red[25]};
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.warning.dark};
  margin-top: 24px;
  grid-column-start: 1;
  grid-column-end: -1;

  & p {
    margin-left: 12px;
    flex: 1;
  }
  & svg {
    fill: ${({ theme }) => theme.foundational.red[500]};
  }
`;
export const CloseIconContainer = styled.span `
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    cursor: pointer;
  }
`;
