/**
 * Formats age group numbers with standard "u" text.
 * @param ageGroup Age group to be formatted.
 */
const formatAgeGroup = (ageGroup: number): string => `${ageGroup}u`;

/**
 * Formats the age groups, hyphenating sequential values together and
 * joining those groups with commas.
 * @param ageGroups Collection of age groups that may or may not be sequential.
 */
export const getAgeGroups = (ageGroups: number[]): string => {
  if (!ageGroups || ageGroups.length === 0) {
    return '';
  }

  const sortedAgeGroups = Array.from(
    new Set([...ageGroups].sort((a, b) => a - b))
  );
  const groupedAgeGroups = sortedAgeGroups.reduce(
    (groupedAgeGroups: number[][], ageGroup: number) => {
      if (groupedAgeGroups.length > 0) {
        const lastAgeGroup = groupedAgeGroups[groupedAgeGroups.length - 1];
        if (lastAgeGroup[lastAgeGroup.length - 1] + 1 === ageGroup) {
          groupedAgeGroups[groupedAgeGroups.length - 1].push(ageGroup);
          return groupedAgeGroups;
        }
      }

      return [...groupedAgeGroups, [ageGroup]];
    },
    []
  );

  const ageGroupText = groupedAgeGroups
    .map((ageGroup: number[]) => {
      const min = formatAgeGroup(ageGroup[0]);
      if (ageGroup.length > 1) {
        return `${min}-${formatAgeGroup(ageGroup[ageGroup.length - 1])}`;
      }
      return min;
    })
    .join(', ');

  return ageGroupText;
};
