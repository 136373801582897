import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import {
  Registration,
  RegistrationStatus,
  RegistrationType,
  StaffRole,
} from '@la/types';
import { getProperty } from '@la/utilities';
import ColumnGrid from 'components/ColumnGrid/ColumnGrid';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetRegistrationSettingsQuery } from 'redux/services/registrationApi';
import {
  useGetInvitesQuery,
  useGetTeamRosterQuery,
} from 'redux/services/rosterManagementApi';
import { useGetStaffRolesQuery } from 'redux/services/staffRoleApi';
import { useGetUserRegistrationsQuery } from 'redux/services/tournamentApi';
import { useAppSelector } from 'redux/store';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import { AddPlayerModal } from './AddPlayerModal/AddPlayerModal';
import CSVDownloadModal from './CSVDownloadModal';
import InvitePlayerStaffModals from './InvitePlayerStaffModals';
import { RosterActionsModal } from './RosterActionsModal/RosterActionsModal';
import { InviteModal } from './RosterRollover/InviteModal/InviteModal';
import RosterSection from './RosterSection';
import RosterSideNav from './RosterSideNav';
import { Registrant } from './RosterTable';
import RosterUtilities from './RosterUtilities';
import { getFormDataBodyFromValues } from './utilities/csv';
import * as S from './RosterManagement.styles';

export type StaffInvitation = {
  link: string;
  role: string;
  id?: string;
};

export type InvitationLinks = {
  playerInviteLink: string;
  staffInviteLinks: StaffInvitation[];
};

export type RolloverData = {
  totalPlayers: number;
  totalStaff: number;
  invites: InvitationLinks;
};

const mergeTeamRegistrants = (
  finished?: Registrant[],
  pending?: Registration[]
): Registrant[] => {
  const players: Registrant[] = [
    ...(finished ?? []),
    ...(pending ?? [])
      .filter(
        (reg) =>
          reg.registrationStatus === RegistrationStatus.PendingInvite &&
          [RegistrationType.InvitePlayer, RegistrationType.Player].includes(
            reg.registrationType
          )
      )
      .map<Registrant>((reg: Registration) => {
        return {
          avatarUrl: '',
          name: getProperty('playerName', reg.properties)?.[0] ?? '',
          userId: parseInt(reg.registeringUserId.toString()),
          registrationStatus: RegistrationStatus.PendingInvite,
          registrationId: reg.id?.toString(),
        };
      }),
  ];

  return players;
};

const mergeStaffRegistrants = (
  finished?: Registrant[],
  pending?: Registration[],
  staffRoles?: StaffRole[]
): Registrant[] => {
  const staff: Registrant[] = [
    ...(finished ?? []),
    ...(pending ?? [])
      .filter(
        (reg) =>
          reg.registrationStatus === RegistrationStatus.PendingInvite &&
          [RegistrationType.InviteStaff, RegistrationType.Staff].includes(
            reg.registrationType
          )
      )
      .map<Registrant>((reg: Registration) => {
        let role: string | undefined;
        let isPrimaryStaff: boolean;
        if ((reg as any).role) {
          role = (reg as any).role;
          isPrimaryStaff = !!staffRoles?.find(
            (staffRole) => staffRole.role.toLowerCase() === role?.toLowerCase()
          )?.isClubDirector;
        } else {
          const staffRole = staffRoles?.find(
            (staffRole) =>
              staffRole.id.toString() ===
              getProperty('programStaffId', reg.properties)?.[0]
          );
          role = staffRole?.role;
          isPrimaryStaff = !!staffRole?.isClubDirector;
        }

        return {
          avatarUrl: '',
          name: getProperty('staffName', reg.properties)?.[0] ?? '',
          userId: parseInt(reg.registeringUserId.toString()),
          registrationStatus: RegistrationStatus.PendingInvite,
          isPrimaryStaff,
          role,
          registrationId: reg.id?.toString(),
        };
      }),
  ];

  return staff;
};

/* Roster Management Page */
export default function RosterManagement() {
  const { playerAndStaffMaxCapacity } = useFlags();
  const navigate = useNavigate();

  const { siteId, siteName } = useAppSelector(getSiteIdentityData);
  const { programId, programType, teamId } = useParams();
  const [searchParams] = useSearchParams();

  const rolloverEncodedData = searchParams.get('rolloverData');
  const siteSubdomain = getLAHostnameParts().subdomain;

  const [openAddModal, setOpenAddModal] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [rolloverData, setRolloverData] = useState<RolloverData | null>(null);
  const [isRolloverModalOpen, setIsRolloverModalOpen] = useState(false);
  const isTabletPortraitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const {
    data,
    isLoading: teamDataIsLoading,
    error: teamDataError /*isError, isLoading */,
  } = useGetTeamRosterQuery(
    {
      siteSubdomain,
      teamId,
    },
    { skip: !teamId }
  );
  const { data: staffRoles } = useGetStaffRolesQuery({
    siteId: siteId?.toString() ?? '',
  });
  const { data: invitesData, isLoading: invitesDataIsLoading } =
    useGetInvitesQuery(
      {
        programId,
        siteSubdomain,
        teamId,
      },
      { skip: !programId }
    );

  const {
    data: registrationsData,
    isLoading: registrationsDataIsLoading,
    isUninitialized: isUserRegistrationQueryUninitialized,
    error: registrationsDataError,
    refetch: userRegistrationQueryRefetch,
  } = useGetUserRegistrationsQuery(
    {
      programId: programId || '',
      siteDomain: siteSubdomain,
    },
    {
      skip: !programId && !openAddModal,
    }
  );

  const { data: registrationSettings } = useGetRegistrationSettingsQuery(
    {
      programId: programId ?? '',
      subdomain: siteSubdomain,
    },
    {
      skip: !programId,
    }
  );

  const minimumNumberOfPlayers = useMemo(() => {
    return registrationSettings?.settings.find(
      ({ settingName }) => settingName === 'minimum_number_of_team_players'
    );
  }, [registrationSettings]);

  const maximumNumberOfPlayers = useMemo(() => {
    return registrationSettings?.settings.find(
      ({ settingName }) => settingName === 'maximum_number_of_team_players'
    );
  }, [registrationSettings]);

  const minimumNumberOfStaff = useMemo(() => {
    return registrationSettings?.settings.find(
      ({ settingName }) => settingName === 'minimum_number_of_team_staff'
    );
  }, [registrationSettings]);

  const maximumNumberOfStaff = useMemo(() => {
    return registrationSettings?.settings.find(
      ({ settingName }) => settingName === 'maximum_number_of_team_staff'
    );
  }, [registrationSettings]);

  const filteredRegistrations = (
    registrationsData?.userRegistrations as any
  )?.filter(
    (reg: Registration) => reg.teamIdOg.toString() === teamId?.toString()
  );

  useEffect(() => {
    if (!openAddModal && !isUserRegistrationQueryUninitialized) {
      userRegistrationQueryRefetch();
    }
  }, [
    openAddModal,
    userRegistrationQueryRefetch,
    isUserRegistrationQueryUninitialized,
  ]);

  /**
   * Serializes the encoded data into local state, cleans the URL and opens the invite modal for
   * rollovered members.
   */
  useEffect(() => {
    if (rolloverEncodedData) {
      const decodedData: RolloverData = JSON.parse(
        window.atob(rolloverEncodedData)
      );
      setRolloverData(decodedData);
      setIsRolloverModalOpen(true);
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, [rolloverEncodedData, searchParams]);

  const { phoneOnly, tabletPortraitUp, tabletLandscapeUp } = breakpointQueries;
  const isPhone = useMediaQuery(phoneOnly);
  const isTabletPortrait = useMediaQuery(tabletPortraitUp);
  const isTabletLandscape = useMediaQuery(tabletLandscapeUp);

  let teamNameSize: 'large' /* | 'large-mobile' */ = 'large',
    tableTitleSize: 'medium' /* | 'medium-mobile' */ = 'medium';

  if (isPhone) {
    teamNameSize = 'large'; /* 'large-mobile' once this size is added */
    tableTitleSize = 'medium' /* 'medium-mobile' once this size is added */;
  } else if (isTabletPortrait) {
    teamNameSize = 'large';
  } else if (isTabletLandscape) {
    teamNameSize = 'large'; /* 'large-mobile' once this size is added */
  }

  const formDataBody = getFormDataBodyFromValues(teamId!, 'ExportToCSV');

  const teamRoster = mergeTeamRegistrants(
    data?.players,
    filteredRegistrations as Registration[]
  );

  const staffRoster = mergeStaffRegistrants(
    data?.staff,
    filteredRegistrations as Registration[],
    staffRoles
  ).map((staff) => {
    return {
      ...staff,
      role: invitesData?.staffInviteLinks.find(
        (role) => role.role === staff.role
      )?.role,
    };
  });

  // We need to refactor all this merging asap, this is just not optimal...
  staffRoster.forEach((staff) => {
    if (staff.role) {
      return;
    }
    const regRole = data?.staff.find(
      (staffReg) => staffReg.name === staff.name
    )?.role;
    staff.role = regRole;
  });

  useEffect(() => {
    document.title = siteName
      ? `${siteName}: Roster Management`
      : 'Roster Management';
  }, [siteName]);

  function handlePrinterRosterButtonClick(): void {
    window.open(
      `/printable?format=HTML&type=TEAM_ROSTER&issuedByRole=Captain&teamId=${teamId}&programId=${programId}`
    );
  }

  function handleRolloverRosterClick(): void {
    navigate('rollover');
  }

  function getCompletedRegistrationsCount(registrants: Registrant[]): number {
    return registrants.filter(
      (roster) => roster.registrationStatus === 'SPOT_RESERVED'
    ).length;
  }

  const completedPlayerCount = getCompletedRegistrationsCount(teamRoster);
  let playerSpotsRemaining =
    playerAndStaffMaxCapacity && maximumNumberOfPlayers?.value
      ? maximumNumberOfPlayers.value - completedPlayerCount
      : undefined;
  if (playerSpotsRemaining !== undefined && playerSpotsRemaining < 0) {
    playerSpotsRemaining = 0;
  }

  const completedStaffCount = getCompletedRegistrationsCount(staffRoster);
  let staffSpotsRemaining =
    playerAndStaffMaxCapacity && maximumNumberOfStaff?.value
      ? maximumNumberOfStaff.value - completedStaffCount
      : undefined;
  if (staffSpotsRemaining !== undefined && staffSpotsRemaining < 0) {
    staffSpotsRemaining = 0;
  }

  const shouldRenderAddPlayerStaffModal: boolean =
    !teamDataIsLoading &&
    !teamDataError &&
    !registrationsDataIsLoading &&
    !registrationsDataError;

  return (
    <S.RosterManagementPage>
      <PageTitle>Manage your team roster</PageTitle>
      <ColumnGrid>
        <S.MinorColumn>
          <RosterSideNav
            programId={programId!}
            programType={programType!}
            teamId={teamId!}
            teamNameSize={teamNameSize}
          />
        </S.MinorColumn>
        <S.MajorColumn>
          {isTabletPortraitUp ? (
            <RosterUtilities
              onDownloadRosterCSVClick={() => setOpenModal('csvDownload')}
              onPrintRosterClick={handlePrinterRosterButtonClick}
              onRosterRolloverClick={handleRolloverRosterClick}
            />
          ) : (
            <RosterActionsModal
              disableInvitePlayerAction={
                playerAndStaffMaxCapacity && maximumNumberOfPlayers?.value
                  ? !playerSpotsRemaining
                  : false
              }
              disableInviteStaffAction={
                playerAndStaffMaxCapacity && maximumNumberOfStaff?.value
                  ? !staffSpotsRemaining
                  : false
              }
              onAddPlayerClick={() => setOpenAddModal('addPlayers')}
              onDownloadRosterCSVClick={() => setOpenModal('csvDownload')}
              onInvitePlayerClick={() => setOpenModal('invitePlayers')}
              onInviteStaffClick={() => setOpenModal('inviteStaff')}
              onPrintRosterClick={handlePrinterRosterButtonClick}
              onRosterRolloverClick={handleRolloverRosterClick}
            />
          )}
          {data?.players ? (
            <RosterSection
              completedRegistrationsCount={completedPlayerCount}
              minimumCapacity={minimumNumberOfPlayers?.value}
              maximumCapacity={
                playerAndStaffMaxCapacity
                  ? maximumNumberOfPlayers?.value
                  : undefined
              }
              openAddModal={() => setOpenAddModal('addPlayers')}
              openModal={() => setOpenModal('invitePlayers')}
              pendingRegistrations={registrationsData?.userRegistrations ?? []}
              programId={programId!}
              rosterData={teamRoster}
              rosterTitle="Players"
              rosterType="players"
              spotsRemaining={
                playerAndStaffMaxCapacity ? playerSpotsRemaining : undefined
              }
              tableTitleSize={tableTitleSize}
              teamId={teamId!}
            />
          ) : null}
          {data?.staff ? (
            <RosterSection
              completedRegistrationsCount={completedStaffCount}
              minimumCapacity={minimumNumberOfStaff?.value}
              maximumCapacity={
                playerAndStaffMaxCapacity
                  ? maximumNumberOfStaff?.value
                  : undefined
              }
              openModal={() => setOpenModal('inviteStaff')}
              pendingRegistrations={registrationsData?.userRegistrations ?? []}
              programId={programId!}
              rosterData={staffRoster}
              rosterTitle="Staff"
              rosterType="staff"
              tableTitleSize={tableTitleSize}
              teamId={teamId!}
              spotsRemaining={
                playerAndStaffMaxCapacity ? staffSpotsRemaining : undefined
              }
              staffInviteLinks={invitesData?.staffInviteLinks}
              userRegistrationQueryRefetch={userRegistrationQueryRefetch}
            />
          ) : null}
        </S.MajorColumn>
      </ColumnGrid>
      {shouldRenderAddPlayerStaffModal ? (
        <AddPlayerModal
          closeModal={() => setOpenAddModal(null)}
          openModalName={openAddModal}
          playerInviteLink={invitesData?.playerInviteLink}
        />
      ) : null}
      {!invitesDataIsLoading ? (
        <InvitePlayerStaffModals
          closeModalMethod={() => setOpenModal(null)}
          invitesData={invitesData}
          openModalName={openModal}
        />
      ) : null}
      {openModal === 'csvDownload' ? (
        <CSVDownloadModal
          body={formDataBody}
          closeModal={() => setOpenModal(null)}
          programId={programId!}
          programType={programType!}
        />
      ) : null}
      {rolloverData ? (
        <InviteModal
          invitationLinks={rolloverData.invites}
          onOpenChange={() => setIsRolloverModalOpen(false)}
          open={isRolloverModalOpen}
          totalPlayers={rolloverData.totalPlayers}
          totalStaff={rolloverData.totalStaff}
        />
      ) : null}
    </S.RosterManagementPage>
  );
}
/* */
