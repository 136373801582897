import { useEffect, useMemo, useState } from 'react';
import { ArrowCircleDown, ArrowCircleRight } from '@phosphor-icons/react';
import { breakpointQueries, useMediaQuery } from '@la/shared-components';
import { FacadeRegistrant, Player, Roster, Staff, StaffRole } from '@la/types';
import { isPlayer, isStaff } from '@la/utilities';
import { AssignStaffRolesModal } from './AssignStaffRolesModal/AssignStaffRolesModal';
import { DestinationRolloverDropzone } from './RolloverDropzone/DestinationRolloverDropzone';
import { OriginRolloverDropzone } from './RolloverDropzone/OriginRolloverDropzone';
import {
  RolloverTeamState,
  updateMemberSelectionState,
} from './RolloverDropzone/RolloverTeam/RolloverTeam';
import { RegistrantState } from './RolloverDropzone/RolloverTeam/types/member';
import * as S from './RolloverWizard.styles';

export type RolloverWizardProps = {
  /**
   * The team the players and/or staff will be rolled to.
   */
  destinationTeam: Roster;
  /**
   * Players and/or staff currently included in the destination team roster.
   */
  members: FacadeRegistrant[];
  /**
   * Triggered when a player or staff member is being removed
   * from the destination roster.
   * @param member The player or staff being removed.
   */
  onRemove: (member: FacadeRegistrant) => void;
  /**
   * Triggered when players and/or staff are being rolled over.
   * @param members The players and/or staff being rolled over.
   */
  onRollover: (members: FacadeRegistrant[]) => void;
  /**
   * When the user clicks a trigger for the team selection modal.
   */
  onTeamSelectionClick: () => void;
  /**
   * Team roster the user is rolling members from.
   */
  selectedTeamRoster?: Roster;
  /**
   * Available staff roles to the new rolling staff members.
   */
  staffRoles: StaffRole[];
};

function getMembersState(
  teamRoster: Roster | undefined,
  rolledOverMembers: FacadeRegistrant[],
  destinationTeam: Roster
): RolloverTeamState | null {
  if (!teamRoster) {
    return null;
  }

  const isRolledOver = (member: FacadeRegistrant): boolean => {
    return !!rolledOverMembers.find(
      (m) => m.registeredUserId === member.registeredUserId
    );
  };

  const playersState: RegistrantState[] =
    teamRoster.players
      ?.map((player) => ({
        ...player,
        ageGroup: teamRoster.teamInfo.ageGroup,
        disabled: isRolledOver(player),
        selected: false,
      }))
      .filter(
        (player) =>
          !destinationTeam.players?.find(
            (p) => p.registeredUserId === player.registeredUserId
          )
      ) ?? [];
  const staffState: RegistrantState[] =
    teamRoster.staff
      ?.map((staff) => ({
        ...staff,
        disabled: isRolledOver(staff),
        selected: false,
      }))
      .filter(
        (staff) =>
          !destinationTeam.staff?.find(
            (s) => s.registeredUserId === staff.registeredUserId
          )
      ) ?? [];

  return { players: playersState, staff: staffState };
}

export function RolloverWizard({
  destinationTeam,
  members,
  onRemove,
  onRollover,
  onTeamSelectionClick,
  selectedTeamRoster,
  staffRoles,
}: Readonly<RolloverWizardProps>) {
  const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);

  const [destinationDropzoneIsHovered, setDestinationDropzoneIsHovered] =
    useState<boolean>(false);

  const [isAssignStaffRolesModalOpen, setIsAssignStaffRolesModalOpen] =
    useState(false);

  const [rolloverMembersState, setRolloverMembersState] =
    useState<RolloverTeamState | null>(
      getMembersState(selectedTeamRoster, members, destinationTeam)
    );
  const selectedMembers: RegistrantState[] = useMemo(() => {
    if (!rolloverMembersState) {
      return [];
    }

    return Object.values(rolloverMembersState)
      .flat()
      .filter((member) => member.selected && !member.disabled);
  }, [rolloverMembersState]);

  const players: Player[] = useMemo(() => {
    return members.filter(isPlayer).concat(destinationTeam.players ?? []);
  }, [destinationTeam, members]);
  const staff: Staff[] = useMemo(() => {
    return members.filter(isStaff).concat(destinationTeam.staff ?? []);
  }, [destinationTeam, members]);

  useEffect(() => {
    setRolloverMembersState(
      getMembersState(selectedTeamRoster, members, destinationTeam)
    );
  }, [destinationTeam, selectedTeamRoster, members]);

  const onOriginDragEnd = (): void => {
    setDestinationDropzoneIsHovered(false);
  };

  const onOriginDragStart = (): void => {
    setDestinationDropzoneIsHovered(true);
  };

  const onDragMembers = (updatedMembers: RegistrantState[]): void => {
    if (updatedMembers.some(isStaff)) {
      const unselectedMember = updatedMembers.find((m) => !m.selected);
      if (unselectedMember) {
        updateMemberSelectionState(
          true,
          unselectedMember,
          rolloverMembersState,
          setRolloverMembersState
        );
      }
      setIsAssignStaffRolesModalOpen(true);
    } else {
      onRollover(updatedMembers);
    }
  };

  const onAssignStaffRolesCancel = (): void => {
    setIsAssignStaffRolesModalOpen(false);
  };

  const onAssignStaffRolesSave = (staff: Staff[]): void => {
    onRollover([...selectedMembers.filter(isPlayer), ...staff]);
    setIsAssignStaffRolesModalOpen(false);
  };

  const onRolloverClick = (): void => {
    if (selectedMembers.some(isStaff)) {
      setIsAssignStaffRolesModalOpen(true);
    } else {
      onRollover(selectedMembers);
    }
  };

  const isRolloverButtonDisabled =
    !selectedTeamRoster || selectedMembers.length === 0;

  return (
    <S.RolloverWizard>
      <OriginRolloverDropzone
        onDragEnd={onOriginDragEnd}
        onDragStart={onOriginDragStart}
        onSelectedStateChange={setRolloverMembersState}
        onTeamSelectionClick={onTeamSelectionClick}
        rolloverMembersState={rolloverMembersState}
        selectedMembers={selectedMembers}
        teamRoster={selectedTeamRoster}
      />
      <S.RolloverButton
        $disabled={isRolloverButtonDisabled}
        leftIcon={
          isTabletPortaitUp ? (
            <ArrowCircleRight fill="var(--secondary-600)" weight="bold" />
          ) : (
            <ArrowCircleDown fill="var(--secondary-600)" weight="bold" />
          )
        }
        size="xl"
        variant="outline"
        width={isTabletPortaitUp ? 'auto' : '100%'}
        onClick={onRolloverClick}
        disabled={isRolloverButtonDisabled}
      >
        Rollover
      </S.RolloverButton>
      <DestinationRolloverDropzone
        addedMembers={members}
        onRemove={onRemove}
        onRollover={onDragMembers}
        showHoveredState={destinationDropzoneIsHovered}
        teamRoster={{ ...destinationTeam, players, staff }}
      />
      {isAssignStaffRolesModalOpen ? (
        <AssignStaffRolesModal
          hasSameSiteRoles={
            selectedTeamRoster?.siteId === destinationTeam.siteId
          }
          onCancel={onAssignStaffRolesCancel}
          onOpenChange={() => setIsAssignStaffRolesModalOpen(false)}
          onStaffRollover={onAssignStaffRolesSave}
          open={isAssignStaffRolesModalOpen}
          staffRoster={selectedMembers.filter(isStaff)}
          staffRoles={staffRoles ?? []}
        />
      ) : null}
    </S.RolloverWizard>
  );
}
