import { useEffect } from 'react';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import PageTitle from 'domains/Shell/PageTitle/PageTitle';
import CheckoutPageContent from './CheckoutPageContent/CheckoutPageContent';
import * as S from './Checkout.styles';

/* Checkout */
export default function ConsolidatedCheckout() {
  const { siteId, siteName } = useAppSelector(getSiteIdentityData);
  const { data: userId } = useGetUserIdQuery(siteId);

  useEffect(() => {
    document.title = siteName ? `${siteName}: Checkout` : 'Checkout';
  }, [siteName]);

  return (
    <S.Checkout id="checkout-page">
      <PageTitle>Checkout</PageTitle>
      <CheckoutPageContent siteId={siteId} userId={userId} />
    </S.Checkout>
  );
}
/* */
