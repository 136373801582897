import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Typography } from '@la/ds-ui-components';
import { getSiteIdentityData } from 'redux/coreSlice';
import { useGetActivityQuery } from 'redux/services/activity';
import { useAppSelector } from 'redux/store';
import Header from 'domains/Shell/Header/Header';
import {
  ActivityFormProvider,
  ActivityFormWrapperScreens,
  useActivityForm,
} from './ActivityContext';
import { ActivityForm } from './ActivityForm';
import { ActivityFormConfirmation } from './ActivityFormConfirmation/ActivityFormConfirmation';
import { CancelActivity } from './CancelActivity/CancelActivity';
import { UncancelActivity } from './CancelActivity/UncancelActivity';
import { DeleteActivity } from './DeleteActivity/DeleteActivity';
import { EditRecurringActivity } from './EditRecurringActivity/EditRecurringAcitivity';
import { NotificationEditActivity } from './NotificationEditActivity/NotificationEditActivity';
import * as S from './ActivityFormWrapper.styles';

export function ActivityFormWrapper() {
  return (
    <S.ActivityFormWrapperContainer>
      <ActivityFormProvider>
        <Header />
        <ActivityFormScreens />
      </ActivityFormProvider>
    </S.ActivityFormWrapperContainer>
  );
}

function ActivityFormScreens() {
  const {
    activityFormTitle,
    currentScreen,
    handleSetActivityFormTitle,
    handleSetCurrentScreen,
  } = useActivityForm();

  const { siteId } = useAppSelector(getSiteIdentityData);
  const { activityId } = useParams();
  const { teamId } = useParams();
  const { subProgramId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const uncanceled = urlParams.get('status') === 'uncancel';
  const originRoute = ['clubteams', subProgramId, 'teams', teamId]
    .filter((s) => s?.toString().length)
    .join('/');

  const { data: editActivity, isFetching } = useGetActivityQuery(
    { siteId: Number(siteId), activityId: Number(activityId) },
    { skip: !activityId }
  );

  useEffect(() => {
    const isRecurring =
      !!editActivity &&
      editActivity.origin === 'generated' &&
      !!editActivity.seqToken;

    if (!isFetching) {
      if (uncanceled) {
        handleSetCurrentScreen(ActivityFormWrapperScreens.UncancelActivity);
      } else if (isRecurring) {
        handleSetCurrentScreen(
          ActivityFormWrapperScreens.EditRecurringActivity
        );
      } else {
        handleSetCurrentScreen(ActivityFormWrapperScreens.ActivityForm);
      }
    }
    // adding handleSetCurrentScreen to the dependencies causes this useEffect to run more often than we need;
    // not using useEffect causes render loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editActivity, isFetching]);

  const screens = {
    [ActivityFormWrapperScreens.ActivityForm]: (
      <ActivityForm
        editActivity={editActivity}
        formId="activity-form"
        teamId={teamId}
        subProgramId={subProgramId}
        originRoute={originRoute}
        handleSetCurrentScreen={handleSetCurrentScreen}
        handleSetActivityFormTitle={handleSetActivityFormTitle}
      />
    ),
    [ActivityFormWrapperScreens.CancelActivity]: (
      <CancelActivity
        cancelActivity={editActivity}
        handleSetCurrentScreen={handleSetCurrentScreen}
        handleSetActivityFormTitle={handleSetActivityFormTitle}
        subProgramId={subProgramId}
      />
    ),
    [ActivityFormWrapperScreens.DeleteActivity]: (
      <DeleteActivity
        deleteActivity={editActivity}
        handleSetCurrentScreen={handleSetCurrentScreen}
        handleSetActivityFormTitle={handleSetActivityFormTitle}
      />
    ),
    [ActivityFormWrapperScreens.EditRecurringActivity]: (
      <EditRecurringActivity
        handleSetCurrentScreen={handleSetCurrentScreen}
        handleSetActivityFormTitle={handleSetActivityFormTitle}
        originRoute={originRoute}
      />
    ),
    [ActivityFormWrapperScreens.Loading]: (
      <Loader loading isFullscreen={true} />
    ),
    [ActivityFormWrapperScreens.NotificationEditActivity]: (
      <NotificationEditActivity
        handleSetCurrentScreen={handleSetCurrentScreen}
        originRoute={originRoute}
      />
    ),
    [ActivityFormWrapperScreens.Submitted]: (
      <ActivityFormConfirmation originRoute={originRoute} />
    ),
    [ActivityFormWrapperScreens.UncancelActivity]: (
      <UncancelActivity
        uncancelActivity={editActivity}
        handleSetCurrentScreen={handleSetCurrentScreen}
        handleSetActivityFormTitle={handleSetActivityFormTitle}
      />
    ),
  };

  return (
    <ActivityFormProvider>
      <S.ActivityFormContainer>
        <Typography variant="headline" size="large" weight="bold">
          {activityFormTitle}
        </Typography>
        {screens[currentScreen]}
      </S.ActivityFormContainer>
    </ActivityFormProvider>
  );
}
