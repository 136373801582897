// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gIVieGEJy7srEg5UMiYJ{grid-column-start:1;grid-column-end:5;display:flex;flex-direction:column;gap:16px}@media(min-width: 600px){.gIVieGEJy7srEg5UMiYJ{grid-column-end:9}}@media(min-width: 900px){.gIVieGEJy7srEg5UMiYJ{grid-column-end:12}}.mxhSASN19NXlz_LeweeN{grid-column-start:1;grid-column-end:5}@media(min-width: 600px){.mxhSASN19NXlz_LeweeN{grid-column-start:1;grid-column-end:9}}@media(min-width: 900px){.mxhSASN19NXlz_LeweeN{grid-column-start:12;grid-column-end:17}}.mOjmbkVdifwQVKPykysK{grid-column:1/17;font:var(--headline-medium)}.CccXelV8BAWRga_q0JxW{display:flex;flex-direction:column;gap:4px;align-items:center;padding:24px}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/Checkout.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAEA,sBACE,mBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CCDA,yBDJF,sBAQI,iBAAA,CAAA,CCCF,yBDTF,sBAYI,kBAAA,CAAA,CAIJ,sBACE,mBAAA,CACA,iBAAA,CCdA,yBDYF,sBAKI,mBAAA,CACA,iBAAA,CAAA,CCbF,yBDOF,sBAUI,oBAAA,CACA,kBAAA,CAAA,CAIJ,sBACE,gBAAA,CACA,2BAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,kBAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"majorColumn": `gIVieGEJy7srEg5UMiYJ`,
	"minorColumn": `mxhSASN19NXlz_LeweeN`,
	"sectionHead": `mOjmbkVdifwQVKPykysK`,
	"emptyCart": `CccXelV8BAWRga_q0JxW`
};
export default ___CSS_LOADER_EXPORT___;
