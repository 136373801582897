import clsx from 'clsx';
import { Button, Typography } from '@la/ds-ui-components';
import styles from '../TournamentDetailsPage.module.scss';

export const NO_LOCATION_TEXT = 'No location set';
export const GET_DIRECTIONS_LABEL = 'Get directions';

export type LocationCardProps = {
  /**
   * Link for location page to get directions.
   */
  href?: string;
  /**
   * Name of location
   */
  name?: string;
};

/* LocationCard */
export function LocationCard({ href, name }: LocationCardProps) {
  const navigateToLocation = (): void => {
    if (href) {
      window.location.href = href;
    }
  };

  return (
    <div className={clsx(styles.tournamentDetail, styles.locationCard)}>
      <Typography
        className={styles.label}
        size="small"
        uppercase
        variant="ui"
        weight="bold"
      >
        Location
      </Typography>
      <Typography size="xl" variant="ui">
        {name || NO_LOCATION_TEXT}
      </Typography>
      {href ? (
        <Button
          className={styles.action}
          onClick={navigateToLocation}
          variant="outline"
        >
          {GET_DIRECTIONS_LABEL}
        </Button>
      ) : null}
    </div>
  );
}
/* */
