import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Loader, formatAsCurrency } from '@la/ds-ui-components';
import { useLazyGetTournamentQuery } from '@la/services';
import { LoadingRipple } from 'components/LoadingRipple/LoadingRipple';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { setHotelLinks } from 'redux/checkoutSlice';
import { getSiteId } from 'redux/coreSlice';
import {
  useGetPaymentWaiverQuery,
  useProcessCartMutation,
} from 'redux/services/checkoutApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { RollupButtonContainer } from '../CartSummaryRollup/CartSummaryRollup';
import {
  BillingSummaryRequestItems,
  CheckoutError,
  PaymentTerm,
  ProcessPaymentBody,
} from '../Checkout.types';
import CheckoutBillingSummary from '../CheckoutBillingSummary/CheckoutBillingSummary';
import { CHECKOUT_ERRORS } from '../CheckoutErrors';
import DiscountCodesCard from '../DiscountCodesCard/DiscountCodesCard';
import SecureCheckoutBadge from '../SecureCheckoutBadge/SecureCheckoutBadge';
import styles from './CheckoutRollup.module.scss';
import * as S from './CheckoutRollup.styles';

export type MakePaymentButtonProps = {
  clickHandler: () => void;
  price: string;
};

/* CheckoutRollup */
export default function CheckoutRollup() {
  const { mcShowDiscountCodes } = useFlags();
  const dispatch = useAppDispatch();
  const { subdomain } = getLAHostnameParts();
  const siteId = useAppSelector(getSiteId);
  const { data: userId } = useGetUserIdQuery(siteId);
  const {
    discountCodeNames,
    updateCheckoutErrorsAreVisible,
    updateErrorStates,
    updateSubmissionErrors,
    updateSubmissionErrorsAreVisible,
    validateCheckout,
    selectedPaymentMethod,
    selectedPaymentMethodType,
    selectedPaymentOptions,
  } = useCheckoutInfo();

  const { cartItems, dueToday } = useConsolidatedCheckout();

  const [getTournament] = useLazyGetTournamentQuery();
  const { data: paymentWaiver } = useGetPaymentWaiverQuery({
    siteId,
  });
  const [processCart] = useProcessCartMutation();
  const [isProcessingPayment, setIsProcessingPayment] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (cartItems) {
      const tournamentIds = cartItems.map((item) => item.programId);
      const allTournaments = tournamentIds.map((tournamentId) =>
        getTournament({
          siteDomain: subdomain,
          tournamentId: tournamentId.toString(),
        }).unwrap()
      );

      Promise.all(allTournaments).then((tournaments) => {
        const hotelLinks = tournaments.map((tournament) => ({
          id: tournament.id,
          hotelLink: tournament.hotelLinks,
        }));
        dispatch(setHotelLinks(hotelLinks));
      });
    }
  }, [dispatch, cartItems, getTournament, subdomain]);

  function getPaymentMethodId() {
    let paymentId = null,
      tokenId = null;
    if (selectedPaymentMethod) {
      if ('tokenId' in selectedPaymentMethod) {
        tokenId = selectedPaymentMethod.tokenId;
      } else {
        paymentId = selectedPaymentMethod.paymentMethodId;
      }
    }
    return {
      tokenId: tokenId,
      storedPaymentId: paymentId,
    };
  }

  function getPaymentOptions(): BillingSummaryRequestItems[] {
    return cartItems
      ? cartItems.map(({ cartItemUuid }) => {
          const cartItemPaymentTerm = selectedPaymentOptions?.find(
            (selectedPaymentTerm) =>
              selectedPaymentTerm.cartItemUuid === cartItemUuid
          );

          const paymentTerm: PaymentTerm =
            cartItemPaymentTerm?.paymentTerm ?? 'FULL';

          return {
            cartItemUuid: cartItemUuid,
            paymentTerm,
            paymentPlanId: cartItemPaymentTerm?.paymentPlanId ?? null,
            payLater: cartItemPaymentTerm?.payLater,
          };
        })
      : [];
  }

  function getCartBody(): ProcessPaymentBody {
    const { storedPaymentId, tokenId } = getPaymentMethodId();
    const paymentOptions = getPaymentOptions();
    const paymentWaiverId = paymentWaiver
      ? paymentWaiver.waiverId.toString()
      : null;
    return {
      discountCodes: discountCodeNames,
      paymentMethod: {
        storedPaymentId: storedPaymentId,
        token: tokenId,
        type: selectedPaymentMethodType,
      },
      paymentTerms: paymentOptions,
      paymentWaiverId: paymentWaiverId,
    };
  }

  function onMakePaymentClick() {
    const { methodIsValid, waiverIsValid } = validateCheckout(dueToday);

    updateErrorStates(!methodIsValid, !waiverIsValid);
    updateCheckoutErrorsAreVisible();
    if (methodIsValid && waiverIsValid) {
      submitPayment();
    }
  }

  function submitPayment() {
    setIsProcessingPayment(true);
    const cartBody = getCartBody();
    processCart({
      body: cartBody,
      siteId,
      userId,
    })
      .unwrap()
      .then((payload) => onPaymentSuccess())
      .catch((error) => {
        onPaymentFailure(error.data);
      });
  }

  function onPaymentSuccess() {
    // if (billingSummaryData) {
    //   updateBillingSummary(billingSummaryData);
    // }
    // TODO: Update the cart in Context?
    updateSubmissionErrorsAreVisible(false);
    navigate('/confirmation');
  }

  function onPaymentFailure(error: CheckoutError) {
    setIsProcessingPayment(false);
    const parsedErrorMessages = parseErrorMessages(error);
    updateSubmissionErrors(parsedErrorMessages);
    updateSubmissionErrorsAreVisible(true);
  }

  function parseErrorMessages(errorMessages: CheckoutError) {
    if (!errorMessages?.data?.messages) {
      return [CHECKOUT_ERRORS.defaultSubmissionError];
    }

    const paymentErrorMessages = errorMessages.data.messages
      .filter((message) => message.type === 'PAYMENT')
      .map((message) => message.responses[0]);

    return paymentErrorMessages.length > 0
      ? paymentErrorMessages
      : [CHECKOUT_ERRORS.defaultSubmissionError];
  }

  function getRollupBody() {
    // if (isBillingSummaryError && billingSummaryError) {
    //   return <div className={styles.rollupBody}>{JSON.stringify(billingSummaryError)}</div>;
    // }
    //
    if (cartItems.length <= 0) {
      return (
        <div className={styles.rollupBody}>
          <LoadingRipple />
        </div>
      );
    }

    return (
      <>
        <CheckoutBillingSummary summaryType="checkout" />
        {mcShowDiscountCodes ? <DiscountCodesCard /> : null}
        <RollupButtonContainer>
          <MakePaymentButton
            clickHandler={onMakePaymentClick}
            price={dueToday}
          />
        </RollupButtonContainer>
        <SecureCheckoutBadge />
      </>
    );

    // return null;
  }

  return (
    <div className={styles.checkoutRollup}>
      {isProcessingPayment ? (
        <Loader
          loading={true}
          isFullscreen={true}
          title=""
          description="We are processing your payment. Please do not leave or refresh this page."
        />
      ) : null}
      {getRollupBody()}
    </div>
  );
}
/* */

/*MakePaymentButton */
export function MakePaymentButton({
  price,
  clickHandler,
}: MakePaymentButtonProps) {
  return (
    <S.MakePaymentButton onClick={clickHandler} width="100%">
      {`Pay ${formatAsCurrency(Number(price))} and register`}
    </S.MakePaymentButton>
  );
}
/* */
