import styled from 'styled-components';
import { Button } from '@la/ds-ui-components';
import { MAIN_CONTENT_HEADER_HEIGHT, SITE_HEADER_HEIGHT, } from '../../../../../lib/constants';
import { media } from '../../../../../lib/media-queries/mixins';
import { WIZARD_CONTENT_PADDING } from '../../Wizard.styles';
import { TOURNAMENT_INFO_HEIGHT } from '../WizardHeader/WizardHeader.styles';
// This is the total vertical space taken up by other elements that we need to know
// to calculate the correct height for this summary and submit button section.
const VERTICAL_SPACE_TOTAL = SITE_HEADER_HEIGHT +
    MAIN_CONTENT_HEADER_HEIGHT +
    TOURNAMENT_INFO_HEIGHT +
    2 * WIZARD_CONTENT_PADDING; // leave equal space on top and bottom
// The page title scrolls out of view so the sticking point is based only on the site header
// and tournament info heights.
const STICKING_POINT = SITE_HEADER_HEIGHT + TOURNAMENT_INFO_HEIGHT + WIZARD_CONTENT_PADDING;
export const SummaryAndSubmit = styled.div `
  grid-column: 1 / -1;

  ${media.TabletLandscapeUp `
    grid-column-start: 12;

    // This section must always remain in view so we set the height to make it fit initially
    // and have it stick so that it doesn't scroll out of view.
    height: calc(100vh - ${VERTICAL_SPACE_TOTAL}px);
    position: sticky;
    top: ${STICKING_POINT}px;
  `}
`;
const SUBMIT_BUTTON_HEIGHT = 51;
const SUBMIT_MARGIN_TOP = 12;
const VERTICAL_SPACE_FOR_BUTTON = SUBMIT_BUTTON_HEIGHT + SUBMIT_MARGIN_TOP;
export const SummaryPanel = styled.div `
  background-color: var(--white);
  border: 1px solid var(--primary-100);
  border-radius: 4px;
  padding: 16px;
  height: calc(100% - ${VERTICAL_SPACE_FOR_BUTTON}px);
`;
export const SubmitSection = styled.div ``;
export const SubmitButton = styled(Button) `
  width: 100%;
  margin-top: ${SUBMIT_MARGIN_TOP}px;
  height: ${SUBMIT_BUTTON_HEIGHT}px;
  font: var(--ui-xtra-large-bold);
`;
export const SubmissionErrorMessage = styled.p `
  margin: 4px;
  color: var(--red);
  font: var(--ui-small-regular);
`;
export const ActionButtons = styled.div `
  width: 100%;
  position: sticky;
  bottom: 0;
  display: grid;
  gap: 8px;
  padding: 12px 16px;
  border-top: 1px solid var(--primary-100);
  background-color: var(--white);
`;
export const ModalSummaryContent = styled.div `
  display: grid;
  gap: 12px;
`;
