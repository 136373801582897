// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nkIFHj4YwrzOzZPz0jQA{display:flex;flex-direction:column;gap:8px;margin:4px 0}@media(min-width: 600px){.nkIFHj4YwrzOzZPz0jQA{flex-direction:row}}.MtUI0DOhjM6HpLxeuBNe{display:flex;flex-direction:column;gap:16px}.MtUI0DOhjM6HpLxeuBNe .uI12McVlcPyyXNYRKfQc{font:var(--ui-large-regular)}.Jb5fnKvYlygYl4Wb_hSq{margin-left:8px;display:flex;gap:8px;align-items:center}.Jb5fnKvYlygYl4Wb_hSq svg{fill:var(--red)}.Jb5fnKvYlygYl4Wb_hSq p{font:var(--ui-small-regular);color:var(--red)}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/PaymentMethodCard/PaymentMethodCard.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,YAAA,CAAA,yBAJF,sBAOI,kBAAA,CAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,4CACE,4BAAA,CAIJ,sBACE,eAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,0BACE,eAAA,CAGF,wBACE,4BAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtons": `nkIFHj4YwrzOzZPz0jQA`,
	"paymentMethodCard": `MtUI0DOhjM6HpLxeuBNe`,
	"informationalCopy": `uI12McVlcPyyXNYRKfQc`,
	"paymentMethodError": `Jb5fnKvYlygYl4Wb_hSq`
};
export default ___CSS_LOADER_EXPORT___;
