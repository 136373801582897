import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import styled from 'styled-components/macro';
import { media } from 'lib/media-queries/mixins';
import { SITE_HEADER_HEIGHT } from 'domains/Shell/Header/Header.styles';

export const SecondaryNavigationBar = styled.div`
  position: sticky;
  top: ${SITE_HEADER_HEIGHT}px;
  width: 100%;
  padding: 0 24px;
  background-color: var(--primary-35);
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  scroll-behavior: smooth;

  ${media.TabletLandscapeUp`
    height: 64px;
  `}
`;

export const NavigationMenuList = styled(NavigationMenu.List)`
  list-style: none;
  display: flex;
  gap: 8px;
  padding: 0;
`;

export const NavigationMenuLink = styled(NavigationMenu.Link)`
  padding: 14px;
  text-decoration: none;
  color: var(--blue-grey-600);
  white-space: nowrap;
  font: var(--ui-large-bold);

  // This transparent border is used to keep the non-active links vertically aligned
  // with the active one.
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid var(--primary-300);
    color: var(--secondary-900);
  }

  ${media.TabletLandscapeUp`
    padding: 20px;
  `}
`;
