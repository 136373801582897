import axios from 'axios';
import type { BFFGroupAccount, GroupAccountParameters } from '@la/types';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';
import { ErrorResponse } from './verifyUrlSignature';

const baseUrl = getBaseURL();

const getGroupAccount = async ({
  siteId,
}: GroupAccountParameters): Promise<BFFGroupAccount | null> => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/groupAccount`;
  const token = extractAccessToken();
  try {
    const response = await axios.get<BFFGroupAccount>(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (e) {
    if ((e as ErrorResponse).response.status === 404) {
      return null;
    }
    throw e;
  }
};

export { getGroupAccount };
