import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Form,
  LinkIcon,
  Loader,
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  Snackbar,
  TextInput,
} from '@la/ds-ui-components';
import { getSiteId, getUserId, useAppSelector } from '@la/services';
import { PaymentStatus, RegistrationStatus, RegistrationType } from '@la/types';
import { isValidEmail } from '@la/utilities';
import { registerPlayer } from 'lib/apis/player';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { copyTextToClipboard } from 'lib/utils/utilities';
import * as S from './AddPlayerModal.styles';

const SNACKBAR_DURATION = 5000;

export type AddPlayerModalProps = {
  closeModal: () => void;
  openModalName: string | null;
  playerInviteLink?: string;
};

/* AddPlayerModal */
export function AddPlayerModal({
  closeModal,
  openModalName,
  playerInviteLink,
}: AddPlayerModalProps) {
  const userId = useAppSelector(getUserId);
  const siteId = useAppSelector(getSiteId);

  const [showAddErrorSnackBar, setShowAddErrorSnackBar] =
    useState<boolean>(false);
  const [showAddSuccessSnackBar, setShowAddSuccessSnackBar] =
    useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { programId, teamId } = useParams();

  let modal: JSX.Element | undefined = undefined;

  if (openModalName === 'addPlayers') {
    const savePlayer = async (
      firstName: string,
      lastName: string,
      parentEmailAddress: string,
      subdomain: string,
      onLoadChange: (value: boolean) => void
    ) => {
      onLoadChange(true);

      const teamIdOg: number = parseInt(teamId || '');

      const registration = {
        programId: Number(programId),
        content: {
          add: [
            {
              deleted: false,
              siteId: Number(siteId),
              paymentStatus: PaymentStatus.Unpaid,
              programId: programId ? parseInt(programId) : 0,
              registrationType: RegistrationType.InvitePlayer,
              registrationStatus: RegistrationStatus.PendingInvite,
              registeringUserId: userId,
              teamIdOg: teamIdOg,
              properties: [
                {
                  name: 'email',
                  values: [parentEmailAddress],
                },
                {
                  name: 'playerName',
                  values: [`${firstName} ${lastName}`],
                },
                {
                  name: 'programRole',
                  values: ['PLAYER'],
                },
              ],
            },
          ],
          remove: [],
          update: [],
        },
      };

      registerPlayer({ siteDomain: subdomain, registration })
        .then((r) => {
          setSuccessMessage(
            `Player ${firstName} ${lastName} added successfuly`
          );
          setShowAddSuccessSnackBar(true);
        })
        .catch((err) => {
          setErrorMessage(`Error adding player ${firstName} ${lastName}`);
          setShowAddErrorSnackBar(true);
        })
        .finally(() => {
          onLoadChange(false);
          closeModal();
        });
    };

    modal = (
      <>
        <AddPlayerModalContent
          closeModal={closeModal}
          playerInviteLink={playerInviteLink}
          savePlayer={savePlayer}
        />
      </>
    );
  }

  return (
    <>
      {modal}
      <Snackbar
        description={errorMessage}
        duration={SNACKBAR_DURATION}
        onOpenChange={setShowAddErrorSnackBar}
        open={showAddErrorSnackBar}
        variant="error"
      />
      <Snackbar
        description={successMessage}
        duration={SNACKBAR_DURATION}
        onOpenChange={setShowAddSuccessSnackBar}
        open={showAddSuccessSnackBar}
      />
    </>
  );
}
/* */

export const ADD_PLAYER_MODAL_TITLE = 'Add a player';

const ADD_PLAYER_FORM_ID = 'add-player-form';
export const SUBMIT_BUTTON_LABEL = 'Add player';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const FIRST_NAME_ID = 'firstName';
export const LAST_NAME_ID = 'lastName';
export const PARENT_EMAIL_ADDRESS_ID = 'parentEmailAddress';

export const FIELD_REQUIRED_ERROR = 'This field is required.';
export const VALID_EMAIL_ADDRESS_ERROR = 'Please enter a valid email address.';

type AddPlayerFormFields = {
  firstName: string;
  lastName: string;
  parentEmailAddress: string;
};

export type AddPlayerModalContentProps = {
  closeModal: () => void;
  playerInviteLink?: string;
  savePlayer: (
    firstName: string,
    lastName: string,
    parentEmailAddress: string,
    subdomain: string,
    onLoadChange: (value: boolean) => void
  ) => void;
};

const addPlayerFormValidation = yup.object({
  firstName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
  lastName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
  parentEmailAddress: yup
    .string()
    .trim()
    .required(FIELD_REQUIRED_ERROR)
    .test('is-valid-email', VALID_EMAIL_ADDRESS_ERROR, isValidEmail),
});

/* AddPlayerModalContent */
function AddPlayerModalContent({
  closeModal,
  playerInviteLink,
  savePlayer,
}: AddPlayerModalContentProps) {
  const { subdomain } = getLAHostnameParts();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    watch,
  } = useForm<AddPlayerFormFields>({
    resolver: yupResolver(addPlayerFormValidation),
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const parentEmailAddress = watch('parentEmailAddress');

  return (
    <Modal
      onOpenChange={closeModal}
      open={true}
      primaryAction={
        <ModalPrimaryActionButton
          form={ADD_PLAYER_FORM_ID}
          disabled={Object.values(errors).length > 0}
          loading={isLoading}
          type="submit"
        >
          {SUBMIT_BUTTON_LABEL}
        </ModalPrimaryActionButton>
      }
      tertiaryAction={
        <ModalClose>
          <Button variant="text">{CANCEL_BUTTON_LABEL}</Button>
        </ModalClose>
      }
      title={ADD_PLAYER_MODAL_TITLE}
    >
      {isLoading ? (
        <Loader loading description="Saving player data" />
      ) : (
        <Form
          id={ADD_PLAYER_FORM_ID}
          noValidate
          onSubmit={handleSubmit((values) => {
            if (!isSubmitting) {
              savePlayer(
                values.firstName,
                values.lastName,
                values.parentEmailAddress,
                subdomain,
                setIsLoading
              );
            }
          })}
        >
          <S.AddPlayerModalFields>
            <S.AddPlayerTextFieldsContainer>
              <S.AddPlayerTextFieldContainer>
                <TextInput
                  {...register('firstName')}
                  data-testid={FIRST_NAME_ID}
                  errorMessage={errors.firstName?.message}
                  hasError={!!errors.firstName}
                  id={FIRST_NAME_ID}
                  label="Player first name"
                  placeholder="Enter player's first name..."
                  required={true}
                  value={firstName}
                />
              </S.AddPlayerTextFieldContainer>
              <S.AddPlayerTextFieldContainer>
                <TextInput
                  {...register('lastName')}
                  data-testid={LAST_NAME_ID}
                  errorMessage={errors.lastName?.message}
                  hasError={!!errors.lastName}
                  id={LAST_NAME_ID}
                  label="Player last name"
                  placeholder="Enter player's last name..."
                  required={true}
                  value={lastName}
                />
              </S.AddPlayerTextFieldContainer>
            </S.AddPlayerTextFieldsContainer>
            <S.AddPlayerTextFieldContainer>
              <TextInput
                {...register('parentEmailAddress')}
                data-testid={PARENT_EMAIL_ADDRESS_ID}
                errorMessage={errors.parentEmailAddress?.message}
                hasError={!!errors.parentEmailAddress}
                id={PARENT_EMAIL_ADDRESS_ID}
                label="Parent email address"
                placeholder="Enter email address..."
                required={true}
                value={parentEmailAddress}
              />
            </S.AddPlayerTextFieldContainer>
            <S.CopyPlayerLinkFields>
              <TextInput
                disabled={!playerInviteLink}
                id="share-player-invite-link"
                label="Share invite link"
                value={playerInviteLink}
              />
              <Button
                disabled={!playerInviteLink}
                leftIcon={
                  <LinkIcon
                    fill="var(--secondary-400)"
                    size="xl"
                    variant="bold"
                  />
                }
                onClick={() => copyTextToClipboard(playerInviteLink!)}
                size="large"
                variant="outline"
              >
                Copy link
              </Button>
            </S.CopyPlayerLinkFields>
          </S.AddPlayerModalFields>
        </Form>
      )}
    </Modal>
  );
}
/* */
