import baseApi from './baseApi';
export const teamApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createTeam: builder.mutation({
            query: ({ siteDomain, programId, team, payerId, siteId }) => {
                return getTeamPostPayload(siteDomain, programId, team, payerId, siteId);
            },
        }),
        updateTeam: builder.mutation({
            query: ({ siteDomain, programId, team, payerId, siteId }) => {
                return getTeamPostPayload(siteDomain, programId, team, payerId, siteId);
            },
        }),
        deleteTeam: builder.mutation({
            query: ({ siteDomain, programId, editURL, teamId, siteId }) => {
                return {
                    url: `/api/member-portal/${siteDomain}/teams`,
                    method: 'DELETE',
                    body: {
                        programId,
                        editURL,
                        teamId,
                    },
                    params: {
                        siteId,
                    },
                };
            },
        }),
        getTeams: builder.query({
            query: ({ siteDomain, payerId, siteId }) => {
                return {
                    url: `/api/member-portal/${siteDomain}/teams`,
                    method: 'GET',
                    params: {
                        payerId,
                        siteId,
                    },
                };
            },
        }),
        getStaffTeams: builder.query({
            query: ({ userId }) => {
                return {
                    url: `/api/users/${userId}/teams?role=staff&include=primaryStaff`,
                    method: 'GET',
                };
            },
        }),
    }),
});
function getTeamPostPayload(siteDomain, programId, team, payerId, siteId) {
    return {
        url: `/api/member-portal/${siteDomain}/teams`,
        method: 'POST',
        body: {
            programId,
            team,
            payerId,
        },
        params: {
            siteId,
            payerId,
        },
    };
}
export const { useCreateTeamMutation, useUpdateTeamMutation, useDeleteTeamMutation, useGetTeamsQuery, useGetStaffTeamsQuery, } = teamApi;
