import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, ListIcon, Modal } from '@la/ds-ui-components';
import * as S from './RosterActionsModal.styles';

export type RosterActionsModalProps = {
  disableInvitePlayerAction: boolean;
  disableInviteStaffAction: boolean;
  onDownloadRosterCSVClick: () => void;
  onAddPlayerClick: () => void;
  onInvitePlayerClick: () => void;
  onInviteStaffClick: () => void;
  onPrintRosterClick: () => void;
  onRosterRolloverClick: () => void;
};

export function RosterActionsModal({
  disableInvitePlayerAction,
  disableInviteStaffAction,
  onAddPlayerClick,
  onDownloadRosterCSVClick,
  onInvitePlayerClick,
  onInviteStaffClick,
  onPrintRosterClick,
  onRosterRolloverClick,
}: RosterActionsModalProps) {
  const { ngRosterRollover } = useFlags();

  return (
    <Modal
      title="Roster actions"
      trigger={
        <Button
          rightIcon={
            <S.TriggerIcon>
              <ListIcon fill="var(--white)" size="xl" variant="bold" />
            </S.TriggerIcon>
          }
          size="large"
          variant="primary"
          width="100%"
        >
          Roster actions
        </Button>
      }
    >
      <S.ActionList>
        {ngRosterRollover ? (
          <S.Item>
            <S.ItemTrigger onClick={onRosterRolloverClick}>
              Rollover roster
            </S.ItemTrigger>
          </S.Item>
        ) : null}
        <S.Item>
          <S.ItemTrigger onClick={onAddPlayerClick}>Add player</S.ItemTrigger>
        </S.Item>
        <S.Item>
          <S.ItemTrigger
            $disabled={disableInvitePlayerAction}
            onClick={onInvitePlayerClick}
          >
            Invite player
          </S.ItemTrigger>
        </S.Item>
        <S.Item>
          <S.ItemTrigger
            $disabled={disableInviteStaffAction}
            onClick={onInviteStaffClick}
          >
            Invite staff member
          </S.ItemTrigger>
        </S.Item>
        <S.Item>
          <S.ItemTrigger onClick={onDownloadRosterCSVClick}>
            Download roster CSV
          </S.ItemTrigger>
        </S.Item>
        <S.Item>
          <S.ItemTrigger onClick={onPrintRosterClick}>
            Print roster
          </S.ItemTrigger>
        </S.Item>
      </S.ActionList>
    </Modal>
  );
}
