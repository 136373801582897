import { FacadeRegistrant, Roster } from '@la/types';

export const SELECT_TEAM_AND_ADD_ROSTER_MEMBERS_ERROR =
  'Please select a team and add roster members to continue.';
export const ADD_ROSTER_MEMBERS_ERROR =
  'Please add roster members to continue.';

export type Registrant = FacadeRegistrant & { ageGroup?: number | null };

export enum RosterRolloverActionType {
  UpdateStep = 'UPDATE_STEP',
  UpdateMembers = 'UPDATE_MEMBERS',
  SelectTeamRoster = 'SELECT_TEAM_ROSTER',
  ToggleShowTeamSelectionLoadMoreOption = 'TOGGLE_SHOW_TEAM_SELECTION_LOAD_MORE_OPTION',
  UpdateTeamDataPageCount = 'UPDATE_TEAM_DATA_PAGE_COUNT',
  IncrementTeamDataPage = 'INCREMENT_TEAM_DATA_PAGE',
  ToggleModal = 'TOGGLE_MODAL',
}

export enum ModalName {
  TeamSelection = 'TEAM_SELECTION',
  Invite = 'INVITE',
}

export type RosterRolloverAction =
  | { type: RosterRolloverActionType.UpdateStep; payload: number }
  | {
      type: RosterRolloverActionType.UpdateMembers;
      payload: {
        add?: Registrant[];
        update?: Registrant[];
        remove?: Registrant[];
      };
    }
  | {
      type: RosterRolloverActionType.SelectTeamRoster;
      payload: Roster;
    }
  | {
      type: RosterRolloverActionType.ToggleShowTeamSelectionLoadMoreOption;
      payload: boolean;
    }
  | {
      type: RosterRolloverActionType.UpdateTeamDataPageCount;
      payload: number;
    }
  | {
      type: RosterRolloverActionType.IncrementTeamDataPage;
    }
  | {
      type: RosterRolloverActionType.ToggleModal;
      payload?: ModalName;
    };

export type RosterRolloverState = {
  /**
   * Error at the top-level in roster rollover flow.
   */
  error?: string;
  /**
   * Current step number in roster rollover flow.
   */
  step: number;
  /**
   * Members selected to rollover.
   */
  members: Registrant[];
  /**
   * Team roster the user is rolling members from.
   */
  selectedTeamRoster?: Roster;
  /**
   * Whether or not the "Load more" option should show in the team
   * selection modal.
   */
  showTeamSelectionLoadMoreOption?: boolean;
  /**
   * Total pages of data (all results that can be returned).
   */
  teamDataPageCount: number;
  /**
   * Number of pages loaded of team data (visible to user).
   */
  teamDataPage: number;
  /**
   * The modal that should currently be displayed.
   */
  openModal?: string;
};

export const rosterRolloverReducer = (
  state: RosterRolloverState,
  action: RosterRolloverAction
): RosterRolloverState => {
  switch (action.type) {
    case RosterRolloverActionType.UpdateStep:
      let error;
      let step = action.payload;
      if (state.step === 1) {
        if (!state.members.length) {
          if (state.selectedTeamRoster) {
            error = ADD_ROSTER_MEMBERS_ERROR;
          } else {
            error = SELECT_TEAM_AND_ADD_ROSTER_MEMBERS_ERROR;
          }
          step = state.step;
        }
      }
      return { ...state, error, step };
    case RosterRolloverActionType.UpdateMembers:
      const { members } = state;

      // Add members (checks for existing as safeguard to prevent duplication)
      const newMembers =
        action.payload.add?.filter((member) => {
          const isNewMember = !members.find(
            (m) => m.registeredUserId === member.registeredUserId
          );
          return isNewMember;
        }) ?? [];
      const membersAdded = newMembers.concat(members);

      // Update members (if member not found in update array, will return
      // existing member as is)
      const membersUpdated = membersAdded.map((member) => {
        const updatedMember = action.payload.update?.find(
          (m) => m.registeredUserId === member.registeredUserId
        );
        return updatedMember ?? member;
      });

      // Remove members
      const membersRemoved = membersUpdated.filter((member) => {
        const shouldRemove = action.payload.remove?.find(
          (m) => m.registeredUserId === member.registeredUserId
        );
        return !shouldRemove;
      });

      return {
        ...state,
        error: undefined,
        members: membersRemoved,
      };
    case RosterRolloverActionType.SelectTeamRoster:
      return {
        ...state,
        error: undefined,
        selectedTeamRoster: action.payload,
        openModal: undefined,
      };
    case RosterRolloverActionType.ToggleShowTeamSelectionLoadMoreOption:
      return { ...state, showTeamSelectionLoadMoreOption: action.payload };
    case RosterRolloverActionType.UpdateTeamDataPageCount:
      return { ...state, teamDataPageCount: action.payload };
    case RosterRolloverActionType.IncrementTeamDataPage:
      return { ...state, teamDataPage: state.teamDataPage + 1 };
    case RosterRolloverActionType.ToggleModal:
      return {
        ...state,
        openModal: action.payload,
      };
  }
};
