import { forwardRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as yup from 'yup';
import {
  Checkbox,
  Fieldset,
  Form,
  InputGroup,
  Legend,
  Select,
  SelectOption,
  TextInput,
  Typography,
} from '@la/ds-ui-components';
import { getSiteId } from '@la/services';
import { createValidationSchema } from '@la/shared-components';
import { FormField, TeamFormFieldsResponse } from '@la/types';
import {
  getMaskPhoneNumberHandlers,
  PHONE_NUMBER_MAX_LENGTH,
} from '@la/utilities';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { CustomFields } from 'components/RegistrationInfoCard/CustomFieldsForm/CustomFieldsForm';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { getSelectOptions } from 'lib/utils/select';
import { isValidEmail } from 'lib/utils/stringUtils';
import { useGetCountriesAndAdministrativeDivisionsQuery } from 'redux/services/countryApi';
import { useGetTeamRepLabelQuery } from 'redux/services/staffRoleApi';
import { useAppSelector } from 'redux/store';
import { getUserEmail, getUserName } from 'redux/userSlice';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { createAgeGroupOptions } from './utils/ageGroup';
import * as S from './CreateTeamForm.styles';

export const API_ERROR_MESSAGE =
  'There was an error creating your team. Please try creating again in a few seconds.';

/**
 * TOOD: Migrate this into Wizard.types file. For now, leaving here
 * so that the PR for the field updates to this form is not too big.
 */
export type TeamLocation =
  | { country: string; state?: never; province?: never }
  | {
      country: 'USA';
      state: string;
      province?: never;
    }
  | { country: 'CAN'; state?: never; province: string };

export type CreateTeamFormFields = TeamLocation & {
  teamName: string;
  organization: string;
  ageGroup: number;
  city: string;
  repName?: string;
  repPhoneNumber?: string;
  repEmail?: string;
  customTeamFields: FormField[];
};

export const FIELD_REQUIRED_ERROR = 'This field is required.';
export const REP_EMAIL_VALID_ERROR =
  'The team rep email must be in a valid email format.';

export type CreateTeamFormProps = {
  id: string;
  defaultValues?: Partial<CreateTeamFormFields>;
  maxDivisionAgeGroup: number;
  hasSubmitError?: boolean;
  displayAgeGroupOptions?: boolean;
  onChange?: (values: CreateTeamFormFields) => void;
  onSubmit: (values: CreateTeamFormFields) => void;
  customFields: FormField[];
  existingFields?: TeamFormFieldsResponse[] | null;
};

const CUSTOM_TEAM_FIELDS_NAME = 'customTeamFields';

export const extractCustomFieldValue = (
  customFields: FormField[],
  existingFields?: TeamFormFieldsResponse[] | null
) => {
  return customFields.map((customField) => {
    const existingField = existingFields?.find(
      (filledField) =>
        filledField.formFieldId === customField.propertyDefinitionId
    );
    let value;
    if (existingField) {
      if (customField.type === 'FILE_UPLOAD') {
        value = {
          name: existingField.values[0],
          uuid: existingField.values[1],
        };
      } else if (customField.type === 'MULTIPLE_CHECKBOXES') {
        value = existingField.values.map((v) => {
          return customField.items?.find(
            (item) => item.itemId === parseInt(v as string)
          )?.itemId;
        });
      } else if (customField.type === 'PICK_LIST') {
        value = customField.items?.find(
          (item) => item.itemId === parseInt(existingField.values[0] as string)
        )?.itemId;
      } else {
        value = existingField.values[0] as any;
      }
    }
    return {
      ...customField,
      value,
    };
  });
};

export const CreateTeamForm = forwardRef<HTMLFormElement, CreateTeamFormProps>(
  (
    {
      id,
      defaultValues,
      maxDivisionAgeGroup,
      hasSubmitError = false,
      displayAgeGroupOptions = true,
      onChange,
      onSubmit,
      customFields,
      existingFields,
    }: CreateTeamFormProps,
    ref?
  ) => {
    const isMobile = useMediaQuery(breakpointQueries.phoneOnly);
    const siteId = useAppSelector(getSiteId);
    const userName = useAppSelector(getUserName);
    const userEmail = useAppSelector(getUserEmail);
    const {
      data: countriesAndAdministrativeDivisions = {
        countries: {},
        provincesCAN: {},
        statesUSA: {},
      },
    } = useGetCountriesAndAdministrativeDivisionsQuery();
    const { countries, provincesCAN, statesUSA } =
      countriesAndAdministrativeDivisions;
    const { data: teamRepLabel } = useGetTeamRepLabelQuery({
      siteId: siteId ?? '',
    });

    const [isRep, setIsRep] = useState<boolean>();

    const ageGroupOptions = createAgeGroupOptions(maxDivisionAgeGroup);

    const countryOptions: SelectOption[] = getSelectOptions(countries);
    const provinceCANOptions: SelectOption[] = getSelectOptions(provincesCAN);
    const stateUSAOptions: SelectOption[] = getSelectOptions(statesUSA);

    const createTeamFormValidation = yup
      .object({
        teamName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
        organization: yup.string().trim().required(FIELD_REQUIRED_ERROR),
        ageGroup: yup
          .number()
          .nullable()
          .transform((_, val) => (val === 0 || val ? Number(val) : null))
          .test(
            'required-age-group',
            FIELD_REQUIRED_ERROR,
            (ageGroup) => !ageGroup || ageGroup !== -1
          ),
        country: yup.string().trim().required(FIELD_REQUIRED_ERROR),
        city: yup.string().trim().required(FIELD_REQUIRED_ERROR),
        state: yup
          .string()
          .when('country', ([country], schema: yup.StringSchema) => {
            if (country === 'USA') {
              return schema.required(FIELD_REQUIRED_ERROR);
            }
            return schema.optional();
          }),
        province: yup
          .string()
          .when('country', ([country], schema: yup.StringSchema) => {
            if (country === 'CAN') {
              return schema.required(FIELD_REQUIRED_ERROR);
            }
            return schema.optional();
          }),
        repName: yup.string().trim().required(FIELD_REQUIRED_ERROR),
        repEmail: yup
          .string()
          .trim()
          .required(FIELD_REQUIRED_ERROR)
          .test('valid-email', REP_EMAIL_VALID_ERROR, isValidEmail),
        repPhoneNumber: yup.string().trim().required(FIELD_REQUIRED_ERROR),
      })
      .concat(createValidationSchema(CUSTOM_TEAM_FIELDS_NAME));

    const methods = useForm<CreateTeamFormFields>({
      defaultValues: {
        ...defaultValues,
        [CUSTOM_TEAM_FIELDS_NAME]: extractCustomFieldValue(
          customFields,
          existingFields
        ),
      },
      resolver: yupResolver(createTeamFormValidation),
    });

    const {
      formState: { errors, isSubmitting, submitCount },
      getValues,
      handleSubmit,
      register,
      setValue,
      watch,
    } = methods;

    const ageGroup = watch('ageGroup');
    const country = watch('country');

    const toggleIsRep = (checked: boolean): void => {
      if (checked) {
        setValue('repName', userName);
        setValue('repEmail', userEmail);
      }
      setIsRep(checked);
    };

    return (
      <FormProvider {...methods}>
        <Form
          id={id}
          noValidate
          onChange={() => {
            if (onChange) {
              onChange(getValues());
            }
          }}
          onSubmit={handleSubmit((values) => {
            if (!isSubmitting) {
              onSubmit(values);
            }
          })}
          ref={ref}
        >
          {hasSubmitError ? <ErrorCard message={API_ERROR_MESSAGE} /> : null}
          <Fieldset
            hasGutters={false}
            legend={
              <Legend>
                Provide more information on the new team you are creating
              </Legend>
            }
          >
            <InputGroup>
              <TextInput
                {...register('teamName')}
                errorMessage={errors.teamName?.message}
                hasError={!!errors.teamName}
                id="create-team-team-name"
                label="Team name"
                required
                value={watch('teamName')}
              />
              <S.HorizontalInputGroup $isMobile={isMobile}>
                <TextInput
                  {...register('organization')}
                  errorMessage={errors.organization?.message}
                  hasError={!!errors.organization}
                  id="create-team-organization"
                  label="Organization"
                  required
                  value={watch('organization')}
                />
                {displayAgeGroupOptions && (
                  <S.SelectTemporaryPositionContainer>
                    <Select
                      {...register('ageGroup')}
                      data-testid="create-team-age-group"
                      errorMessage={errors.ageGroup?.message}
                      hasError={!!errors.ageGroup}
                      id="create-team-age-group"
                      label="Age group"
                      options={ageGroupOptions}
                      onChange={(ageGroup: string) => {
                        if (ageGroup) {
                          setValue('ageGroup', parseInt(ageGroup), {
                            shouldValidate: submitCount > 0,
                          });
                        } else {
                          /**
                           * If placeholder option gets selected, using Number() will
                           * cause the value to be 0 because the value is undefined, which we
                           * do not want because ageGroup being 0 sets the Select to
                           * be read-only. Setting to -1 allows us to conditionally set the
                           * value to undefined if needed.
                           */
                          setValue('ageGroup', -1);
                        }
                      }}
                      placeholder="Select age group"
                      required
                      value={ageGroup >= 0 ? ageGroup.toString() : undefined}
                    />
                  </S.SelectTemporaryPositionContainer>
                )}
              </S.HorizontalInputGroup>
              <S.SelectTemporaryPositionContainer>
                <Select
                  {...register('country')}
                  hasError={!!errors.country}
                  errorMessage={errors.country?.message}
                  id="create-team-country"
                  label="Country"
                  onChange={(country) =>
                    setValue('country', country, {
                      shouldValidate: submitCount > 0,
                    })
                  }
                  options={countryOptions}
                  placeholder="Select country"
                  required
                  value={watch('country')}
                  testId="create-team-country-trigger"
                  valueTestId="create-team-country-value"
                />
              </S.SelectTemporaryPositionContainer>
              <S.HorizontalInputGroup $isMobile={isMobile}>
                <TextInput
                  {...register('city')}
                  hasError={!!errors.city}
                  errorMessage={errors.city?.message}
                  id="create-team-city"
                  label="City"
                  required
                  value={watch('city')}
                />
                <S.SelectTemporaryPositionContainer>
                  {country === 'USA' ? (
                    <Select
                      {...register('state')}
                      errorMessage={errors.state?.message}
                      hasError={!!errors.state}
                      id="create-team-state"
                      label="State"
                      options={stateUSAOptions}
                      onChange={(state) =>
                        setValue('state', state, {
                          shouldValidate: submitCount > 0,
                        })
                      }
                      placeholder="Select state"
                      required
                      value={watch('state')}
                      testId="create-team-state-trigger"
                      valueTestId="create-team-state-value"
                    />
                  ) : null}
                  {country === 'CAN' ? (
                    <Select
                      {...register('province')}
                      errorMessage={errors.province?.message}
                      hasError={!!errors.province}
                      id="create-team-province"
                      label="Province"
                      options={provinceCANOptions}
                      onChange={(province) =>
                        setValue('province', province, {
                          shouldValidate: submitCount > 0,
                        })
                      }
                      placeholder="Select province"
                      required
                      value={watch('province')}
                      testId="create-team-province-trigger"
                      valueTestId="create-team-province-value"
                    />
                  ) : null}
                </S.SelectTemporaryPositionContainer>
              </S.HorizontalInputGroup>
              {customFields.length ? (
                <CustomFields
                  inputSize="large"
                  columns={2}
                  name={CUSTOM_TEAM_FIELDS_NAME}
                />
              ) : null}
            </InputGroup>
          </Fieldset>
          <Fieldset
            data-testid="rep-fields"
            hasGutters={false}
            legend={
              <Legend>
                <Typography size="large" variant="ui" weight="bold">
                  <S.Title>
                    {teamRepLabel} info{' '}
                    {teamRepLabel ? (
                      <TeamRepInfo teamRepLabel={teamRepLabel} />
                    ) : null}
                  </S.Title>
                </Typography>
                The {teamRepLabel} is responsible for managing team info,
                waivers, rosters and more.
              </Legend>
            }
          >
            <InputGroup>
              <TextInput
                {...register('repName')}
                errorMessage={errors.repName?.message}
                hasError={!!errors.repName}
                id="create-team-rep-name"
                label={`${teamRepLabel} name`}
                readOnly={isRep}
                required
                value={watch('repName')}
              />
              <S.HorizontalInputGroup $isMobile={isMobile}>
                <TextInput
                  {...register('repPhoneNumber')}
                  {...getMaskPhoneNumberHandlers((value?: string) =>
                    setValue('repPhoneNumber', value)
                  )}
                  errorMessage={errors.repPhoneNumber?.message}
                  hasError={!!errors.repPhoneNumber}
                  id="create-team-rep-phone-number"
                  label={`${teamRepLabel} phone number`}
                  maxLength={PHONE_NUMBER_MAX_LENGTH}
                  required
                  showCharacterCounter={false}
                  value={watch('repPhoneNumber')}
                />
                <TextInput
                  {...register('repEmail')}
                  errorMessage={errors.repEmail?.message}
                  hasError={!!errors.repEmail}
                  id="create-team-rep-email"
                  label={`${teamRepLabel} email`}
                  readOnly={isRep}
                  required
                  value={watch('repEmail')}
                />
              </S.HorizontalInputGroup>
              <Checkbox
                ariaLabel={`Make me the ${teamRepLabel}`}
                id="create-team-rep-make-self-rep"
                checked={isRep}
                label={`Make me the ${teamRepLabel}`}
                onCheckedChange={toggleIsRep}
                size="large"
              />
            </InputGroup>
          </Fieldset>
        </Form>
      </FormProvider>
    );
  }
);

/**
 * TODO: Replace with Tooltip component once available. Separated
 * out into its own component to keep the main component less cluttered.
 */
function TeamRepInfo({ teamRepLabel }: { teamRepLabel: string }) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild>
          <InfoIcon />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <S.TeamRepInfo sideOffset={5}>
            If your team does not yet have a {teamRepLabel}, assign yourself and
            you can transfer ownership later.
            <Tooltip.Arrow className="TooltipArrow" />
          </S.TeamRepInfo>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
