import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    summaryData: null,
};
const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setSummaryData(state, action) {
            state.summaryData = action.payload;
        },
    },
});
export const { setSummaryData } = checkoutSlice.actions;
export const getSummaryData = (state) => state.checkout.summaryData;
export default checkoutSlice.reducer;
