import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal, Button, Typography, Form } from '@la/ds-ui-components';
import { truncateWithEllipsis } from '@la/utilities';
import { useMediaQuery } from '../../lib/hooks';
import { breakpointQueries } from '../../lib/media-queries/breakpoints';
import { extractCustomFieldValue } from '../../tournaments/registration/Wizard/components/CreateTeamForm/CreateTeamForm';
import { CustomFields, createValidationSchema, } from '../../tournaments/registration/Wizard/components/CustomFieldsForm/CustomFieldsForm';
import { getIncompleteRequiredFieldsTeams } from '../TeamSelectionSection/utils/validation';
import * as S from './EditTeamFieldsModal.styles';
const EDIT_TEAMS_FORM_ID = 'bulk-edit-teams';
const EditTeamFieldsModal = ({ teams, open, onClose, customFields, addedTeamsIds, handleFieldsUpdate, }) => {
    const [selectedTeam, setSelectedTeam] = useState(teams[0].id);
    const isTabletPortaitUp = useMediaQuery(breakpointQueries.tabletPortraitUp);
    const pendingTeams = getIncompleteRequiredFieldsTeams(customFields, teams.filter((team) => addedTeamsIds.includes(team.id)));
    const teamFieldsValidation = pendingTeams.reduce((schema, team) => schema.concat(createValidationSchema(team.id)), yup.object({}));
    const editTeamsFieldsValidation = yup.object({}).concat(teamFieldsValidation);
    const defaultValues = pendingTeams.reduce((p, c) => {
        var _a;
        return Object.assign(Object.assign({}, p), { [c.id]: extractCustomFieldValue(customFields, (_a = teams.find((t) => t.id === c.id)) === null || _a === void 0 ? void 0 : _a.formFields) });
    }, {});
    const methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(editTeamsFieldsValidation),
    });
    useEffect(() => {
        const element = document.getElementById(selectedTeam);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [selectedTeam]);
    const renderTeams = () => {
        return pendingTeams.map((team) => {
            const isSelected = selectedTeam === team.id;
            return (_jsx(S.TeamItemContainer, Object.assign({ "$isSelected": isSelected, onClick: () => setSelectedTeam(team.id) }, { children: _jsx(Typography, Object.assign({ variant: "ui", size: "large", weight: isSelected ? 'bold' : 'regular' }, { children: truncateWithEllipsis(team.name, 25) })) }), team.id));
        });
    };
    const renderFields = () => {
        return pendingTeams.map((team) => {
            return (_jsxs(S.TeamContainer, Object.assign({ id: team.id }, { children: [_jsx(Typography, Object.assign({ variant: "ui", size: "large", weight: "bold" }, { children: team.name })), _jsx(S.TeamFieldsContainer, Object.assign({ "$isTabletPortraitUp": isTabletPortaitUp }, { children: _jsx(S.CustomField, { children: _jsx(CustomFields, { inputSize: "large", columns: isTabletPortaitUp ? 2 : 1, name: team.id }) }) }), team.id)] }), team.id));
        });
    };
    return (_jsx(Modal, Object.assign({ size: "large", open: open, onOpenChange: onClose, title: "Add team details", primaryAction: _jsx(Button, Object.assign({ form: EDIT_TEAMS_FORM_ID, type: "submit" }, { children: "Save and continue" })), tertiaryAction: _jsx(Button, Object.assign({ variant: "text", onClick: onClose }, { children: "Discard changes" })) }, { children: _jsxs(S.EditTeamsContainer, { children: [isTabletPortaitUp ? (_jsx(S.TeamsLeftPanel, { children: _jsx(S.TeamLeftPanelInnerContainer, { children: renderTeams() }) })) : null, _jsxs(S.EditTeamsContent, Object.assign({ "$isTabletPortraitUp": isTabletPortaitUp }, { children: [_jsx(Typography, Object.assign({ variant: "ui", size: "large" }, { children: "Before you continue, this program requires additional information. Please complete the following fields before completing registration." })), _jsx(S.FormFieldsContainer, { children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsx(Form, Object.assign({ id: EDIT_TEAMS_FORM_ID, noValidate: true, onSubmit: methods.handleSubmit(handleFieldsUpdate) }, { children: renderFields() })) })) })] }))] }) })));
};
export { EditTeamFieldsModal };
