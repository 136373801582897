import { DragEvent, useEffect, useMemo, useState } from 'react';
import { FacadeRegistrant } from '@la/types';
import { RolloverTeamType } from '../../../../utils/team';
import {
  getMemberTileId,
  getRoleType,
  MemberTile,
} from '../MemberTile/MemberTile';
import { SelectedMembersOptions } from '../SelectedMembersOptions/SelectedMembersOptions';
import { RegistrantState } from '../types/member';
import * as S from './MemberOption.styles';

export type MemberOptionProps = {
  /**
   * Whether or not the active state should display for the member.
   */
  active?: boolean;
  /**
   * All members in the roster, selected or not.
   */
  allMembers: RegistrantState[];
  /**
   * Whether or not this option should be disabled from selection.
   */
  disabled?: boolean;
  /**
   * Whether or not to display the placeholder for the content where the option
   * originated from. Is a separate property from `isDragging` because we do not
   * want the placeholder to be a part of the drag image.
   */
  hideOverflow?: boolean;
  /**
   * Whether or not the option is being dragged.
   */
  isDragging?: boolean;
  /**
   * Whether or not the dragging action was triggered by this option.
   */
  isDragOrigin?: boolean;
  /**
   * The member whose details should be displayed. Includes the selected state.
   */
  member: RegistrantState;
  /**
   * Triggered when the option is done being dragged.
   */
  onDragEnd?: () => void;
  /**
   * Triggered when the option starts being dragged.
   */
  onDragStart?: (e: DragEvent<HTMLLIElement>, member: RegistrantState) => void;
  /**
   * Triggered when the remove icon button is clicked. Will not render the remove icon
   * button if not defined.
   * @param member The member to be removed.
   */
  onRemoveClick?: (member: FacadeRegistrant) => void;
  /**
   * Triggered when the checkbox is selected. Does not render certain
   * styles when undefined.
   * @param selected Whether or not the option is selected.
   * @param member The member whose selection state is changing.
   */
  onSelectChange?: (selected: boolean, member: FacadeRegistrant) => void;
  /**
   * All selected members. This is used to properly set the drag image that
   * appears when dragging starts.
   */
  selectedMembers: RegistrantState[];
  /**
   * The type of team (ORIGIN or DESTINATION). Determines the ids generated for
   * member option.
   */
  teamType: RolloverTeamType;
};

/* MemberOption */
export function MemberOption({
  active = false,
  allMembers = [],
  disabled,
  hideOverflow = false,
  isDragging = false,
  isDragOrigin = false,
  member,
  onDragEnd,
  onDragStart,
  onRemoveClick,
  onSelectChange,
  selectedMembers,
  teamType,
}: Readonly<MemberOptionProps>) {
  const { selected } = member;

  const memberOptionId = getMemberOptionId(
    member.registeredUserId,
    teamType,
    getRoleType(member)
  );
  const [height, setHeight] = useState<number>();

  const dragImageMembers = useMemo(() => {
    return getDragMembers(member, isDragOrigin, selectedMembers, allMembers);
  }, [allMembers, isDragOrigin, member, selectedMembers]);
  const includedInDrag = useMemo(() => {
    return !!dragImageMembers.find(
      (m) => m.registeredUserId === member.registeredUserId
    );
  }, [dragImageMembers, member]);

  /**
   * Sets the height dynamically so that the drag overlay can
   * match in height with the option, because we need a fixed height
   * set to prevent any overflow showing.
   */
  useEffect(() => {
    const optionElement: HTMLElement | null =
      document.getElementById(memberOptionId);
    if (optionElement) {
      setHeight(optionElement.offsetHeight);
    }
  }, [member, memberOptionId]);

  const onMemberOptionDragStart = (e: DragEvent<HTMLLIElement>): void => {
    if (onDragStart && !disabled) {
      onDragStart(e, member);
    }
  };

  return (
    <S.MemberOption
      $height={height}
      $hideOverflow={hideOverflow}
      $selectable={!!onSelectChange}
      $selected={selected}
      draggable={!!(onDragEnd && onDragStart && !disabled)}
      id={memberOptionId}
      key={memberOptionId}
      onDragEnd={onDragEnd}
      onDragStart={onMemberOptionDragStart}
    >
      {!isDragging || hideOverflow ? (
        <MemberTile
          {...member}
          active={active}
          onRemoveClick={onRemoveClick}
          onSelectChange={onSelectChange}
          selected={selected}
          teamType={teamType}
        />
      ) : null}
      {/* We render all the members so that the drag image that is displayed contains all the 
      members rather than just the originating drag member */}
      {isDragOrigin && isDragging ? (
        <SelectedMembersOptions
          members={dragImageMembers}
          teamType={teamType}
        />
      ) : null}
      {includedInDrag && hideOverflow ? (
        <S.DragOverlay $height={height} />
      ) : null}
    </S.MemberOption>
  );
}
/* */

export function getMemberOptionId(
  userId: number,
  teamType: RolloverTeamType,
  roleType?: string
): string {
  return `option-${getMemberTileId(userId, teamType, roleType)}`;
}

export function getDragMembers(
  member: RegistrantState,
  isDragOrigin: boolean,
  selectedMembers: RegistrantState[],
  allMembers: RegistrantState[]
) {
  const isIncluded = !!selectedMembers.find(
    (m) => m.registeredUserId === member.registeredUserId
  );
  if (isIncluded) {
    return selectedMembers;
  }

  // In order to render the unselected member in the correct spot in
  // the list of member options of the drag image.
  return allMembers.filter(
    (m) =>
      (isDragOrigin && m.registeredUserId === member.registeredUserId) ||
      selectedMembers.find((sm) => m.registeredUserId === sm.registeredUserId)
  );
}
