// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8{max-height:168px;overflow-y:scroll}.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8.kwHb6rkHJWdGMBBvFeD0{max-height:none}@media(min-width: 600px){.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8{max-height:none}}.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 h1,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 h2,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 h3,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 h4,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 h5,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 h6,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 p,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 ol,.xWrjMNCmfATj4zqT6ZzO .VhAMnVp8aqUscOHdTvh8 ul{max-width:none}.xWrjMNCmfATj4zqT6ZzO .vNdG7NNKeLk0OdNhiMYA{padding:16px 0}@media(min-width: 600px){.xWrjMNCmfATj4zqT6ZzO .vNdG7NNKeLk0OdNhiMYA{display:none}}`, "",{"version":3,"sources":["webpack://./src/domains/Tournaments/TournamentDetailsPage/AboutSection/AboutSection.module.scss","webpack://./src/lib/styles/mixins/_breakpoints.scss"],"names":[],"mappings":"AAGE,4CACE,gBAAA,CACA,iBAAA,CACA,iEACE,eAAA,CCDJ,yBDHA,4CAOI,eAAA,CAAA,CAEF,saASE,cAAA,CAGJ,4CACE,cAAA,CCnBF,yBDkBA,4CAGI,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutSection": `xWrjMNCmfATj4zqT6ZzO`,
	"content": `VhAMnVp8aqUscOHdTvh8`,
	"expanded": `kwHb6rkHJWdGMBBvFeD0`,
	"viewDetails": `vNdG7NNKeLk0OdNhiMYA`
};
export default ___CSS_LOADER_EXPORT___;
