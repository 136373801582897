import { useFlags } from 'launchdarkly-react-client-sdk';
import { Card, CardBody, CardHeader } from '@la/ds-ui-components';
import { FileUploadFormField } from '@la/types';
import { formatFormFieldsForWorkflow, getLAHostnameParts } from '@la/utilities';
import {
  CustomFieldsForm,
  CustomFieldsFormFields,
} from 'components/RegistrationInfoCard/CustomFieldsForm/CustomFieldsForm';
import { updateWorkflowRegistration } from 'lib/apis/updateWorkflowRegistration';
import {
  getProgramRole,
  useRegistration,
} from 'lib/context/RegistrationContext/RegistrationContext';

export const FILE_UPLOAD_FORM_FIELDS_NAME = 'fileUploadFormFilelds';

export const RegistrationFileUpload = () => {
  const { tournamentTeamRegistrationWizardVersion } = useFlags();

  const {
    currentStep,
    dispatch,
    fileUploadFormFields,
    formSteps,
    onNextClick,
    uploadFile,
    decodedData,
    name,
    selectedPlayer,
    loggedInUserId,
    nonFileUploadFormFields,
  } = useRegistration();

  const fields = Object.values(fileUploadFormFields);
  const { subdomain } = getLAHostnameParts();

  if (!fields.length) {
    return null;
  }

  const handleFileUploadSubmit = async ({
    [formSteps[currentStep]]: customFields,
  }: CustomFieldsFormFields): Promise<void> => {
    const fields = customFields.filter(
      (field): field is FileUploadFormField => field.type === 'FILE_UPLOAD'
    );

    const fileFields: {
      formFieldId: number;
      type: 'FILE_UPLOAD';
      values: [name: string, uuid: string];
    }[] = [];

    const promises = fields.map((field) => {
      const { propertyDefinitionId, value, type } = field;

      if (!value || !value.file) {
        return null;
      }

      return uploadFile({
        file: value,
        propertyDefinitionId: propertyDefinitionId.toString(),
        userId: loggedInUserId.toString(),
      }).then(({ uuid }) => {
        fileFields.push({
          values: [value.name, uuid],
          formFieldId: propertyDefinitionId,
          type,
        });

        dispatch({
          type: 'SET_FILE_UPLOAD_FORM_FIELD',
          payload: {
            id: field.propertyDefinitionId,
            field: {
              ...field,
              value: { ...value, uuid },
            },
          },
        });
      });
    });

    await Promise.all(promises);

    if (!decodedData) {
      return;
    }

    const registrationFormFields = formatFormFieldsForWorkflow(
      Object.values(nonFileUploadFormFields)
    );

    updateWorkflowRegistration(
      {
        siteId: parseInt(decodedData.site),
        name,
        programRole: getProgramRole(decodedData.type),
        programStaffId:
          decodedData.type !== 'player'
            ? parseInt(decodedData.role)
            : undefined,
        registeredUserId:
          decodedData.type === 'player'
            ? parseInt(selectedPlayer)
            : loggedInUserId,
        registeringUserId: loggedInUserId,
        programId: parseInt(decodedData.prid),
        teamIdOg: parseInt(decodedData.team),
        formFields: [fileFields, registrationFormFields].flat(),
        workflowVersion: Math.trunc(tournamentTeamRegistrationWizardVersion),
      } as any,
      subdomain
    ).then(onNextClick);
  };

  return (
    <Card>
      <CardHeader>Documents</CardHeader>
      <CardBody>
        <CustomFieldsForm
          columns={1}
          gap={24}
          formFields={fields}
          id={formSteps[currentStep]}
          onSubmit={handleFileUploadSubmit}
        />
      </CardBody>
    </Card>
  );
};
