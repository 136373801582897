export const ZIP_CODE_MAX_LENGTH = 5;
const ALLOWED_ACTIONS = [
    'Backspace',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
];
export const maskZipCodeKeyUp = (e) => {
    let value = e.currentTarget.value;
    if (ALLOWED_ACTIONS.includes(e.key)) {
        return value;
    }
    value = value.replace(/[^0-9]/g, '').substring(0, ZIP_CODE_MAX_LENGTH);
    return value;
};
export const maskZipCodeKeyDown = (e) => {
    var _a;
    const value = (_a = e.currentTarget.value) !== null && _a !== void 0 ? _a : '';
    if (ALLOWED_ACTIONS.includes(e.key)) {
        return value;
    }
    if (!e.ctrlKey && !e.metaKey && isNaN(Number(e.key))) {
        e.preventDefault();
    }
    return value;
};
export const getMaxZipCodeHandlers = (
// eslint-disable-next-line no-unused-vars
callback) => ({
    onKeyUp: (e) => callback(maskZipCodeKeyUp(e)),
    onKeyDown: (e) => callback(maskZipCodeKeyDown(e)),
});
