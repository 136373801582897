import styled from 'styled-components';
import { IconButton } from '@la/ds-ui-components';
import { media } from '../../../lib/media-queries/mixins';
export const MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH = 28;
export const MainContentHeaderBackButton = styled(IconButton) `
  & svg {
    stroke: ${({ theme }) => { var _a; return (_a = theme.semantic) === null || _a === void 0 ? void 0 : _a.primary.reactiveTextColor; }};
  }

  &:focus-within {
    outline-color: ${({ theme }) => { var _a; return (_a = theme.semantic) === null || _a === void 0 ? void 0 : _a.primary.reactiveTextColor; }};
  }

  position: absolute;
  left: 12px;
  width: ${MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH}px;
  height: ${MAIN_CONTENT_HEADER_BACK_BUTTON_WIDTH}px;
  padding: 0;

  ${media.TabletLandscapeUp `
    display: none;
  `}
`;
