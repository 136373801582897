export function getSiteIdFromURL() {
    const pathname = window.location.pathname;
    const matches = pathname.match(/sites\/(.*)\//);
    if (!matches) {
        return 0;
    }
    // Pull out the number between '/sites/' and the following /.
    return parseInt(matches[1]);
}
export function getProgramIdFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const programId = urlParams.get('bid');
    if (!programId) {
        return 0;
    }
    return parseInt(programId);
}
