import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer } from 'react';
import { teamRegistrationInitialState, teamRegistrationReducer, } from './reducer';
const teamRegistrationInitialProps = {
    state: {
        payer: null,
        wizardState: null,
        divisions: null,
        isMC: true,
        selectedPaymentOption: null,
        paymentPricing: null,
    },
    setPayer: () => { },
    setWizardState: () => { },
    setDivisions: () => { },
    setSelectedPaymentOption: () => { },
    setPaymentsPricing: () => { },
};
export const TeamRegistrationContext = createContext(teamRegistrationInitialProps);
export const TeamRegistrationProvider = ({ children, }) => {
    const [state, dispatch] = useReducer(teamRegistrationReducer, teamRegistrationInitialState);
    const setPayer = (payer) => {
        dispatch({
            type: 'SET_PAYER',
            payload: { payer },
        });
    };
    const setWizardState = (wizardState) => {
        dispatch({
            type: 'SET_WIZARD_STATE',
            payload: { wizardState },
        });
    };
    const setDivisions = (divisions) => {
        dispatch({
            type: 'SET_DIVISIONS',
            payload: { divisions },
        });
    };
    const setSelectedPaymentOption = (optionId) => {
        dispatch({
            type: 'SET_SELECTED_PAYMENT_OPTION',
            payload: { optionId },
        });
    };
    const setPaymentsPricing = (paymentsPricing) => {
        dispatch({
            type: 'SET_PAYMENTS_PRICING',
            payload: { paymentsPricing },
        });
    };
    return (_jsx(TeamRegistrationContext.Provider, Object.assign({ value: {
            state,
            setPayer,
            setWizardState,
            setDivisions,
            setSelectedPaymentOption,
            setPaymentsPricing,
        } }, { children: children })));
};
