import { RadioGroup, TextInput, Typography } from '@la/ds-ui-components';
import { ChildYouthAthleteAccess } from '../YouthAthleteAccess.types';
import * as S from '../EditMobileAppAccess.styles';

interface YouthAthleteSectionProps
  extends Omit<ChildYouthAthleteAccess, 'siteId' | 'youthAthleteId'> {
  emailReadOnly?: boolean;
  emailErrorMsg?: string;
  isDisabled: boolean;
  showCanChat: boolean;
  onChangeEmail: (value: string | undefined) => void;
  onChangePermission: (value: boolean) => void;
  onChangeChat: (value: boolean) => void;
}

export default function YouthAthleteSection({
  firstName,
  userId,
  email,
  isDisabled,
  hasYouthAthletePermission,
  canChat,
  emailReadOnly,
  emailErrorMsg,
  showCanChat,
  onChangeEmail,
  onChangePermission,
  onChangeChat,
}: YouthAthleteSectionProps) {
  return (
    <S.MobileAppAccessSection id={`user-${userId}`}>
      <Typography variant="headline" size="medium">
        {firstName}'s mobile app access settings
      </Typography>
      <Typography
        variant="ui"
        size="large"
        weight="bold"
        className={isDisabled ? 'disabled' : ''}
      >
        This is the email address tied to your child's profile. This will be
        used for the child's mobile app login.
      </Typography>
      <S.MobileAppAccessTextInputContainer>
        <TextInput
          onChange={(e) => {
            onChangeEmail(e.target.value);
          }}
          hasError={!!emailErrorMsg}
          errorMessage={emailErrorMsg}
          id={userId + '-email'}
          label="Child's Email"
          required={!emailReadOnly && hasYouthAthletePermission}
          readOnly={emailReadOnly}
          value={email}
          tooltipText="To change your child's email, use the family dashboard"
          disabled={isDisabled}
        />
      </S.MobileAppAccessTextInputContainer>
      <div>
        <Typography
          variant="ui"
          size="medium"
          weight="bold"
          className={isDisabled ? 'disabled' : ''}
        >
          I give permission for this child to join the mobile app
        </Typography>
        <RadioGroup
          onValueChange={(value) => {
            onChangePermission(value === 'yes');
          }}
          ariaLabel="I give permission for this child to join the mobile app"
          options={[
            {
              id: userId + '-permission-yes',
              label: 'Yes',
              value: 'yes',
            },
            {
              id: userId + '-permission-no',
              label: 'No',
              value: 'no',
            },
          ]}
          defaultValue={hasYouthAthletePermission ? 'yes' : 'no'}
          orientation="horizontal"
          disabled={isDisabled}
        />
      </div>
      {showCanChat ? (
        <div>
          <S.RadioLabel
            variant="ui"
            size="medium"
            weight="bold"
            disabled={!hasYouthAthletePermission || isDisabled}
          >
            I give permission for this child to chat with coaches, staff, other
            parents, and players on the mobile app.
          </S.RadioLabel>
          <RadioGroup
            onValueChange={(value) => {
              onChangeChat(value === 'yes');
            }}
            ariaLabel="I give permission for this child to chat with coaches, staff, other parents, and players on the mobile app."
            disabled={!hasYouthAthletePermission || isDisabled}
            options={[
              {
                id: userId + '-chat-yes',
                label: 'Yes',
                value: 'yes',
              },
              {
                id: userId + '-chat-no',
                label: 'No',
                value: 'no',
              },
            ]}
            defaultValue={canChat ? 'yes' : 'no'}
            orientation="horizontal"
          />
        </div>
      ) : null}
    </S.MobileAppAccessSection>
  );
}
