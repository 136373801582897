import {PageBanner} from "components/PageBanner/PageBanner";
import {Link} from "react-router-dom";
import {Typography} from "@la/ds-ui-components";
import styles from '../Checkout.module.scss';


/*EmptyCartNotice */
export default function EmptyCartNotice() {
  return (
    <>
      <PageBanner bannerType="informative">
        This is our new registration experience. Only{' '}
        <strong>Next Generation tournaments</strong> will display here.{' '}
        <Link
          to="https://leagueapps.zendesk.com/hc/en-us/articles/20798190416023"
          target="_blank"
        >
          Learn more
        </Link>
      </PageBanner>
      <div className={styles.emptyCart}>
        <Typography variant="headline" size="medium">
          Your cart is currently empty
        </Typography>
        <Typography variant="ui" size="large">
          Return here after you have added a registration or product to your
          cart.
        </Typography>
      </div>
    </>
  );
}
/* */
