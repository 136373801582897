import axios from 'axios';
import { extractAccessToken } from 'lib/auth/auth';
import { getBaseURL } from 'lib/utils/urlUtils';

const baseUrl = getBaseURL();

const getStructuredStateSetting = async (siteId: string) => {
  const endpoint = `${baseUrl}/api/sites/${siteId}/structuredStateField`;
  const token = extractAccessToken();
  const response = await axios.get<{ structuredStateFieldEnabled: boolean }>(
    endpoint,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data.structuredStateFieldEnabled;
};

export { getStructuredStateSetting };
