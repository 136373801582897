import { DragEvent, useMemo, useState } from 'react';
import { Button, Typography } from '@la/ds-ui-components';
import { FacadeRegistrant, Roster } from '@la/types';
import { isPlayer } from '@la/utilities';
import { TeamDetails } from 'domains/RosterManagement/RosterRollover/TeamDetails/TeamDetails';
import { RolloverTeamType } from '../../../utils/team';
import { getDragMembers, MemberOption } from './MemberOption/MemberOption';
import { RegistrantState } from './types/member';
import * as S from './RolloverTeam.styles';

export const CHANGE_TEAM_LABEL = 'Change team';

export type RolloverTeamState = {
  players: RegistrantState[];
  staff: RegistrantState[];
};

export type RolloverTeamProps = {
  /**
   * The members being added to the roster.
   */
  addedMembers?: FacadeRegistrant[];
  /**
   * Triggered when a player or staff is being removed from the destination team roster.
   * @param member The player or staff being removed.
   */
  onRemove?: (member: FacadeRegistrant) => void;
  /**
   * Triggered when players' and/or staff's selection state is changed.
   * @param state The changed selection state.
   */
  onSelectedStateChange?: (state: RolloverTeamState) => void;
  /**
   * Triggered when `showTeamSelectionOption` is `true` and the change
   * team button is clicked.
   */
  onTeamSelectionClick?: () => void;
  /**
   * Current members state (e.g. selected, disabled state).
   */
  rolloverMembersState?: RolloverTeamState | null;
  /**
   * The currently selected members.
   */
  selectedMembers?: RegistrantState[];
  /**
   * The team to render players and staff for.
   */
  teamRoster: Roster;
  /**
   * The type of team (ORIGIN or DESTINATION). Determines the text that
   * appears when there are no members.
   */
  type: RolloverTeamType;
};

/* RolloverTeam */
export function RolloverTeam({
  addedMembers = [],
  onRemove,
  onSelectedStateChange,
  onTeamSelectionClick,
  rolloverMembersState,
  selectedMembers,
  teamRoster,
  type,
}: Readonly<RolloverTeamProps>) {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [originDragTile, setOriginDragTile] = useState<number>();
  const [hideOverflow, setHideOverflow] = useState<boolean>(false);

  const allMembers = useMemo(() => {
    return [
      ...(rolloverMembersState?.players ?? []),
      ...(rolloverMembersState?.staff ?? []),
    ];
  }, [rolloverMembersState]);

  const onDragEnd = (): void => {
    setIsDragging(false);
    setOriginDragTile(undefined);
    setHideOverflow(false);
  };

  const onDragStart = (
    e: DragEvent<HTMLLIElement>,
    member: RegistrantState
  ): void => {
    if (e.dataTransfer) {
      e.dataTransfer.dropEffect = 'copy';
      e.dataTransfer.setData(
        'application/json',
        JSON.stringify(
          getDragMembers(member, true, selectedMembers ?? [], allMembers)
        )
      );
      setIsDragging(true);
      setOriginDragTile(member.registeredUserId);
      setTimeout(() => {
        setHideOverflow(true);
      }, 1);
    }
  };

  const onSelectChange = (
    selected: boolean,
    member: FacadeRegistrant
  ): void => {
    if (onSelectedStateChange) {
      updateMemberSelectionState(
        selected,
        member,
        rolloverMembersState,
        onSelectedStateChange
      );
    }
  };

  const baseMemberOptionProps = {
    allMembers,
    hideOverflow,
    isDragging,
    selectedMembers: selectedMembers ?? [],
    teamType: type,
  };
  const memberOptionProps =
    type === RolloverTeamType.Origin
      ? { ...baseMemberOptionProps, onDragEnd, onDragStart, onSelectChange }
      : { ...baseMemberOptionProps };

  return (
    <S.RolloverTeam>
      <S.SummaryName>
        <Typography size="small" variant="headline">
          {teamRoster.teamInfo.name}
        </Typography>
        {onTeamSelectionClick ? (
          <S.ChangeTeamButton>
            <Button onClick={onTeamSelectionClick} size="small" variant="text">
              {CHANGE_TEAM_LABEL}
            </Button>
          </S.ChangeTeamButton>
        ) : null}
      </S.SummaryName>
      <TeamDetails
        team={{ ...teamRoster.teamInfo, siteName: teamRoster.siteName }}
      />
      <S.Members>
        <S.MembersList data-testid={getMemberSectionId('player', type)}>
          <S.MembersTitle>Players</S.MembersTitle>
          {rolloverMembersState && rolloverMembersState.players.length > 0 ? (
            rolloverMembersState.players.map((player) => {
              const isAdded = !!addedMembers?.find(
                (m) => m.registeredUserId === player.registeredUserId
              );

              return (
                <MemberOption
                  {...memberOptionProps}
                  active={isAdded}
                  key={`rollover-team-player-${player.registeredUserId}`}
                  isDragOrigin={player.registeredUserId === originDragTile}
                  member={player}
                  onRemoveClick={
                    type === RolloverTeamType.Destination && isAdded
                      ? onRemove
                      : undefined
                  }
                />
              );
            })
          ) : (
            <S.NoMembersText>
              There are no players on this team. <br />
              {getNoMembersText('player', type)}
            </S.NoMembersText>
          )}
        </S.MembersList>
        <S.MembersList data-testid={getMemberSectionId('staff', type)}>
          <S.MembersTitle>Staff</S.MembersTitle>
          {rolloverMembersState && rolloverMembersState.staff.length > 0 ? (
            rolloverMembersState.staff.map((staff) => {
              const isAdded = !!addedMembers?.find(
                (m) => m.registeredUserId === staff.registeredUserId
              );

              return (
                <MemberOption
                  {...memberOptionProps}
                  active={isAdded}
                  key={`rollover-team-staff-${staff.registeredUserId}`}
                  isDragOrigin={staff.registeredUserId === originDragTile}
                  member={staff}
                  onRemoveClick={
                    type === RolloverTeamType.Destination && isAdded
                      ? onRemove
                      : undefined
                  }
                />
              );
            })
          ) : (
            <S.NoMembersText>
              There are no staff on this team. <br />
              {getNoMembersText('staff', type)}
            </S.NoMembersText>
          )}
        </S.MembersList>
      </S.Members>
    </S.RolloverTeam>
  );
}

export function getMemberSectionId(
  memberType: 'player' | 'staff',
  type: RolloverTeamType
): string {
  const memberTypeName = memberType === 'player' ? 'players' : 'staff';
  const typeName = type === RolloverTeamType.Origin ? 'origin' : 'destination';
  return `${memberTypeName}-${typeName}`;
}

export function getNoMembersText(
  memberType: 'player' | 'staff',
  type: RolloverTeamType
): string {
  return type === RolloverTeamType.Destination
    ? `Start adding ${memberType === 'player' ? 'players' : 'staff'} now!`
    : 'Please select another team.';
}

export function updateMemberSelectionState(
  selected: boolean,
  member: RegistrantState,
  currentState: RolloverTeamState | null | undefined,
  onChange: (state: RolloverTeamState) => void
) {
  if (isPlayer(member)) {
    const newState = {
      players:
        currentState?.players.map((m) => {
          if (m.registeredUserId === member.registeredUserId) {
            return { ...m, selected };
          }
          return m;
        }) ?? [],
      staff: currentState?.staff ?? [],
    };
    onChange(newState);
  } else {
    const newState = {
      players: currentState?.players ?? [],
      staff:
        currentState?.staff.map((m) => {
          if (m.registeredUserId === member.registeredUserId) {
            return { ...m, selected };
          }
          return m;
        }) ?? [],
    };
    onChange(newState);
  }
}
