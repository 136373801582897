/*PaymentMethodBadgeGroup */
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import {
  PaymentMethodInfo,
  PaymentMethod,
  StoredCreditCard,
  StoredBankAccount,
} from '../Checkout.types';
import PaymentMethodBadge from './PaymentMethodBadge';
import styles from './PaymentMethodBadge.module.scss';

export function PaymentMethodBadgeGroup() {
  const {
    selectedPaymentMethod,
    storedPaymentMethods,
    temporaryPaymentMethod,
  } = useConsolidatedCheckout();

  return (
    <>
      {storedPaymentMethods.paymentOptionsCount > 0
        ? renderStoredPaymentMethods(
            storedPaymentMethods,
            selectedPaymentMethod
          )
        : null}
      {temporaryPaymentMethod
        ? renderAdditionalPaymentMethod(
            temporaryPaymentMethod,
            selectedPaymentMethod
          )
        : null}
    </>
  );
}
/* */

function renderStoredPaymentMethods(
  storedPaymentMethods: PaymentMethodInfo,
  selectedPaymentMethod?: PaymentMethod
) {
  const { storedBankAccounts, storedCreditCards } = storedPaymentMethods;

  function renderStoredBankAccount(storedBankAccounts: StoredBankAccount[]) {
    return (
      <PaymentMethodBadge
        isSelectedPaymentMethod={
          storedBankAccounts[0].paymentMethodId ===
          selectedPaymentMethod?.paymentMethodId
        }
        paymentMethod={storedBankAccounts[0]}
        paymentMethodType="stored"
      />
    );
  }

  function renderStoredCreditCard(storedCreditCards: StoredCreditCard[]) {
    return (
      <PaymentMethodBadge
        isSelectedPaymentMethod={
          storedCreditCards[0].paymentMethodId ===
          selectedPaymentMethod?.paymentMethodId
        }
        paymentMethod={storedCreditCards[0]}
        paymentMethodType="stored"
      />
    );
  }

  return (
    <div className={styles.paymentMethodBadgeGroup} id="stored-payment-methods">
      <p className={styles.badgeGroupLabel}>Stored Payment Methods</p>
      {storedBankAccounts[0]
        ? renderStoredBankAccount(storedBankAccounts)
        : null}
      {storedCreditCards[0] ? renderStoredCreditCard(storedCreditCards) : null}
    </div>
  );
}

function renderAdditionalPaymentMethod(
  temporaryPaymentMethod: PaymentMethod,
  selectedPaymentMethod?: PaymentMethod
) {
  return temporaryPaymentMethod ? (
    <div
      className={styles.paymentMethodBadgeGroup}
      id="additional-payment-methods"
    >
      <p className={styles.badgeGroupLabel}>Additional Payment Methods</p>
      <PaymentMethodBadge
        isSelectedPaymentMethod={
          temporaryPaymentMethod.paymentMethodId ===
          selectedPaymentMethod?.paymentMethodId
        }
        paymentMethod={temporaryPaymentMethod}
        paymentMethodType="temporary"
      />
    </div>
  ) : null;
}
