var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getStaffRoles } from '../../api/getStaffRoles';
import baseApi from './baseApi';
export const staffRoleApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getStaffRoles: builder.query({
            queryFn: ({ siteId }) => __awaiter(void 0, void 0, void 0, function* () {
                const staffRoles = yield getStaffRoles({ siteId });
                return { data: staffRoles };
            }),
        }),
        getTeamRepLabel: builder.query({
            queryFn: ({ siteId }) => __awaiter(void 0, void 0, void 0, function* () {
                const staffRoles = yield getStaffRoles({ siteId });
                const teamRepRole = staffRoles.find((staffRole) => staffRole.isTeamRep);
                return { data: teamRepRole ? teamRepRole.role : 'Team Rep' };
            }),
        }),
    }),
});
export const { useGetStaffRolesQuery, useGetTeamRepLabelQuery } = staffRoleApi;
