import { useState } from 'react';

export const useTeamFilters = () => {
  const [teamName, setTeamName] = useState('');
  const [programId, setProgramId] = useState('all');
  const [subprogramId, setSubprogramId] = useState('all');
  const [sort, setSort] = useState<'createdOn' | 'teamName'>('teamName');

  const onSetProgramId = (id: string) => {
    setProgramId(id);
    setSubprogramId('all');
  };

  return {
    teamName,
    setTeamName,
    programId,
    setProgramId: onSetProgramId,
    subprogramId,
    setSubprogramId,
    sort,
    setSort,
  };
};
