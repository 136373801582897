// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cTFt_8WI9eLZ4zmIy3tw{display:flex;flex-direction:column;gap:6px;overflow-x:auto}@media(min-width: 600px){.cTFt_8WI9eLZ4zmIy3tw{gap:8px}}.cTFt_8WI9eLZ4zmIy3tw .NNg2NLsnsCIpObA5RuXl{font:var(--ui-small-regular-caps);text-transform:uppercase}.VuuLcLot3PH5brAuCsZy{display:flex;flex-direction:row;align-items:center;gap:8px;width:100%;height:56px;padding:12px;border-radius:4px;border:1px solid var(--blue-grey-400);background-color:var(--white);cursor:pointer}.VuuLcLot3PH5brAuCsZy span:has(>svg){margin:0 6px 0 4px}.VuuLcLot3PH5brAuCsZy.PMYAkoDVEEDRjNIpMK0Q{height:32px;padding:0 12px;border:none;background-color:rgba(0,0,0,0)}.VuuLcLot3PH5brAuCsZy>img{width:38px;height:24px;border:1px solid var(--blue-grey-400);border-radius:2px;background-color:var(--white)}`, "",{"version":3,"sources":["webpack://./src/domains/ConsolidatedCheckout/PaymentMethodBadge/PaymentMethodBadge.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,eAAA,CAAA,yBAJF,sBAOI,OAAA,CAAA,CAGF,4CACE,iCAAA,CACA,wBAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,qCAAA,CACA,6BAAA,CACA,cAAA,CAEA,qCACE,kBAAA,CAGF,2CACE,WAAA,CACA,cAAA,CACA,WAAA,CACA,8BAAA,CAGF,0BACE,UAAA,CACA,WAAA,CACA,qCAAA,CACA,iBAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentMethodBadgeGroup": `cTFt_8WI9eLZ4zmIy3tw`,
	"badgeGroupLabel": `NNg2NLsnsCIpObA5RuXl`,
	"paymentMethodBadge": `VuuLcLot3PH5brAuCsZy`,
	"additionalPaymentMethod": `PMYAkoDVEEDRjNIpMK0Q`
};
export default ___CSS_LOADER_EXPORT___;
