var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { extractAccessToken, getBaseURL } from '@la/utilities';
const baseUrl = getBaseURL();
const uploadFile = ({ siteId, propertyDefinitionId, userId, file, filename, }) => __awaiter(void 0, void 0, void 0, function* () {
    const endpoint = `${baseUrl}/api/sites/${siteId}/file`;
    const metadata = {
        userId: parseInt(userId),
        propertyDefinitionId: parseInt(propertyDefinitionId),
    };
    const formData = new FormData();
    const blob = new Blob([file]);
    formData.append('file', blob, filename);
    formData.append('metaData', new Blob([JSON.stringify(metadata)], {
        type: 'application/json',
    }));
    const token = extractAccessToken();
    const response = yield axios.post(endpoint, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
});
export { uploadFile };
