import * as yup from 'yup';
export const FIELD_REQUIRED_ERROR = 'This field is required.';
export const FILE_FIELD_REQUIRED_ERROR = 'Please upload required documentation.';
export const MAX_DIGITS_ERROR = 'Field length should have no more than 16 digits.';
export const createValidationSchema = (id, readOnly) => {
    return yup.object({
        [id]: yup.array().of(yup.lazy((field) => {
            let value;
            const { type, usageLevel } = field;
            switch (type) {
                case 'NUMERIC':
                    value = yup
                        .number()
                        .nullable()
                        .transform((_, val) => (val === 0 || val ? Number(val) : null))
                        .test('len', MAX_DIGITS_ERROR, (number) => {
                        return (Object.is(number, null) || number.toString().length <= 16);
                    });
                    if (!readOnly && usageLevel === 'REQUIRED') {
                        value = value.required(FIELD_REQUIRED_ERROR);
                    }
                    break;
                case 'PICK_LIST':
                    value = yup
                        .number()
                        .nullable()
                        .transform((_, val) => (val === 0 || val ? Number(val) : null));
                    if (!readOnly && usageLevel === 'REQUIRED') {
                        value = value.required(FIELD_REQUIRED_ERROR);
                    }
                    break;
                case 'FILE_UPLOAD':
                    value = yup
                        .object({
                        name: yup.string(),
                        file: yup.mixed().nullable(),
                        uuid: yup.string().optional(),
                    })
                        .default(undefined);
                    if (!readOnly && usageLevel === 'REQUIRED') {
                        value = value.required(FILE_FIELD_REQUIRED_ERROR);
                    }
                    break;
                case 'MULTIPLE_CHECKBOXES':
                    value = yup.array().of(yup.mixed());
                    if (!readOnly && usageLevel === 'REQUIRED') {
                        value = value
                            .min(1, FIELD_REQUIRED_ERROR)
                            .required(FIELD_REQUIRED_ERROR);
                    }
                    break;
                default:
                    value = yup.string().trim();
                    if (!readOnly && usageLevel === 'REQUIRED') {
                        value = value.required(FIELD_REQUIRED_ERROR);
                    }
                    break;
            }
            const properties = {
                propertyDefinitionId: yup.number(),
                programId: yup.number().optional(),
                type: yup.string(),
                name: yup.string(),
                usageLevel: yup.string(),
                order: yup.number(),
                items: yup.array().optional(),
                value,
            };
            return yup.object(properties);
        })),
    });
};
